import {
  SHDotGroup,
  SHDotGroupProps,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { ReactNode } from "react";

export interface TabLabelProps {
  icon?: ReactNode;
  label?: ReactNode;
  disabled?: boolean;
  dotGroupProps?: Omit<SHDotGroupProps, "disabled">;
  isActive?: boolean;
}

export const TabLabel = ({
  icon,
  label,
  disabled,
  dotGroupProps,
  isActive,
}: TabLabelProps) => {
  const renderStringLabel = (stringLabel: string) => {
    return (
      <SHStack>
        <SHTypography
          variant={isActive ? "subtitle2" : "body2"}
          sx={{
            opacity: 0,
            height: "0px",
            fontWeight: 600,
          }}
        >
          {stringLabel}
        </SHTypography>
        <SHTypography
          variant={isActive ? "subtitle2" : "body2"}
          fontWeight={isActive ? 600 : 500}
          disabled={disabled}
        >
          {stringLabel}
        </SHTypography>
      </SHStack>
    );
  };

  return (
    <SHStack direction="row" spacing={1} alignItems="center">
      {icon}
      <SHStack direction="row" spacing={0.5} alignItems="start">
        {typeof label === "string" ? renderStringLabel(label) : label}
        <SHDotGroup {...dotGroupProps} disabled={disabled} paddingTop={"5px"} />
      </SHStack>
    </SHStack>
  );
};
