import { ViewingGroupDTO } from "@models/viewing-groups/entities/viewingGroups";
import { SpecialViewingGroup } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";

export const customViewingGroupSort = (
  items: ViewingGroupDTO[] = [],
): ViewingGroupDTO[] => {
  const objectValues = Object.values(SpecialViewingGroup);

  return items
    .map((i) => {
      const name = i.name as SpecialViewingGroup;

      return {
        ...i,
        order: objectValues.includes(name)
          ? objectValues.findIndex((s) => s === name)
          : objectValues.length + 1,
      };
    })
    .sort((a, b) => a.order - b.order);
};
