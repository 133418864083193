import { useNotification } from "@hooks/useNotification";
import { S3ObjectDTO } from "@models/core/storage";
import { ProductDocumentDetailDTO } from "@models/document-library/entities/document";
import {
  createDocument,
  getProductSelectionList,
  updateDocument,
  getDocumentById,
  publishDocumentById,
} from "@services/document-library/documentLibraryService";
import {
  deleteAvatar,
  uploadAvatar,
} from "@services/file-storage/fileStorageService";
import { getViewingGroupSelectionBySupplierId } from "@services/viewing-group/viewingGroupService";
import { renameFile } from "@utils/data-type/file";
import { isEmpty, isNil } from "lodash";
import { useState } from "react";
import { ErrorCode, FileRejection } from "react-dropzone";

export const useDocumentLibrary = () => {
  const { notify } = useNotification();
  const loadListProductSelection = async () => {
    const { data, isSuccess } = await getProductSelectionList();
    if (isSuccess) {
      return data;
    } else {
      notify("Loading product list failed", {
        variant: "error",
        close: true,
      });
    }
  };
  const loadListViewingGroupSelectionBySupplier = async (
    supplierId: string,
  ) => {
    const { data, isSuccess } = await getViewingGroupSelectionBySupplierId(
      supplierId,
    );
    if (isSuccess) {
      return data;
    }
    notify("Loading viewing group list failed", {
      variant: "error",
      close: true,
    });
  };
  const handleSubmitDocument = async (
    document: ProductDocumentDetailDTO,
    isEdit = false,
    isSuperAdmin = false,
    callbacks?: { onSuccess?: () => void; onError?: () => void },
  ) => {
    const { onSuccess, onError } = callbacks || {};
    const { isSuccess, message } = isEdit
      ? await updateDocument(document)
      : await createDocument(document);

    if (isSuccess) {
      onSuccess?.();
      if (isSuperAdmin) {
        notify(`Document ${isEdit ? "published" : "created and published"}.`, {
          variant: "success",
          close: true,
        });
        return;
      }
      notify("Your document has been submitted for review.", {
        variant: "success",
        close: true,
      });
    } else {
      onError?.();
      notify(message, {
        variant: "error",
        close: true,
      });
    }
  };
  const loadDocument = async (
    documentId: string,
    callbacks?: {
      onSuccess?: (data: ProductDocumentDetailDTO | undefined) => void;
      onError?: () => void;
    },
  ) => {
    const { onSuccess, onError } = callbacks || {};
    const { data, isSuccess } = await getDocumentById(documentId);
    if (isSuccess) {
      onSuccess?.(data);
      return data;
    } else {
      onError?.();
      notify("Load document failed", {
        variant: "error",
        close: true,
      });
    }
  };
  const publishDocument = async (documentId: string) => {
    const { data, isSuccess } = await publishDocumentById(documentId);

    if (isSuccess) {
      notify(data, {
        variant: "success",
        close: true,
      });
    }
  };

  return {
    loadListProductSelection,
    loadListViewingGroupSelectionBySupplier,
    handleSubmitDocument,
    loadDocument,
    publishDocument,
  } as const;
};

interface UseFileUploadProps {
  onSuccess?: (selectedFile: File, data: S3ObjectDTO) => void;
  onError?: () => void;
  onDeleteSuccess?: () => void;
  onDeleteFailed?: () => void;
  onInvalidFile?: () => void;
}

export const useSingleFileHandler = ({
  onSuccess,
  onError,
  onDeleteSuccess,
  onInvalidFile,
  onDeleteFailed,
}: UseFileUploadProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState<string | null>(null);
  const [data, setData] = useState<S3ObjectDTO | null>(null);
  const { notify } = useNotification();

  const handleOnFileUpload = async (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    maximumFileSize: number,
  ) => {
    if (!isEmpty(fileRejections)) {
      const code = fileRejections[0].errors?.[0].code;

      const errorMessages = new Map<string, string>([
        [ErrorCode.TooManyFiles, "You can only upload one file at a time."],
        [
          ErrorCode.FileInvalidType,
          "Unsupported file format. Please upload another file.",
        ],
        [
          ErrorCode.FileTooLarge,
          `Please limit file size to ${maximumFileSize / 1024 / 1024}MB.`,
        ],
        [
          ErrorCode.FileTooSmall,
          "File size too small. Please upload another file.",
        ],
      ]);

      if (!isNil(code) && !isNil(errorMessages.get(code))) {
        onInvalidFile?.();
        setUploadMessage(errorMessages.get(code) ?? null);
        return;
      }
    }

    if (isEmpty(acceptedFiles)) return;
    setUploadMessage(null);
    setIsUploading(true);
    const file = new FormData();
    file.append("file", renameFile(acceptedFiles[0]));
    const selectedFile = renameFile(acceptedFiles[0]);

    const { data, message } = await uploadAvatar(file);
    if (data) {
      setData(data);
      if (onSuccess) onSuccess(selectedFile, data);
    } else {
      if (onError) onError();

      notify(message, { variant: "error", close: true });
    }

    setIsUploading(false);
  };
  const handleOnDelete = async (s3Key: string) => {
    const { isSuccess, message } = await deleteAvatar(s3Key);
    if (isSuccess) {
      setData(null);
      if (onDeleteSuccess) onDeleteSuccess();
    } else {
      if (onDeleteFailed) onDeleteFailed();
      notify(message, { variant: "error", close: true });
    }
  };

  return {
    isUploading,
    uploadMessage,
    data,
    handleOnFileUpload,
    handleOnDelete,
  } as const;
};
