import { SHDivider, SHStack, SHTextField } from "@components/design-systems";
import {
  useUpdateModelsTab,
  useUpdateSectionData,
} from "@hooks/useInvestmentProduct";
import { HighlightType } from "@models/configuration";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductModelsDTO } from "@models/product/investment-product/entities/model";
import {
  InvestmentBannerStatus,
  InvestmentDataStatus,
} from "@models/product/investment-product/enums/status";
import { DEBOUNCE_DELAY } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/configs";
import {
  getFeaturePaths,
  getFeatureProductDataPaths,
  getModelPath,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import { toggleSaveDraftButtonDisabled } from "@redux/slices/product/investment-product";
import { RootState, useAppDispatch } from "@redux/store";
import { isEmpty, isEqual, debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMount } from "react-use";

export interface ModelsTitleTextFieldProps {
  selectedIndex: [number, number];
  feature: InvestmentProductConfigurationFeature;
}

export const ModelTitleTextField = ({
  selectedIndex,
  feature,
}: ModelsTitleTextFieldProps) => {
  const originalTitle = feature.productData?.value;

  /** Hook **/
  const {
    investmentProductUI: { isSaving },
    investmentProductBannerInfo,
  } = useSelector((state: RootState) => state.investmentProduct);
  const dispatch = useAppDispatch();
  const isPendingApproval = investmentProductBannerInfo?.some((banner) =>
    isEqual(banner.status, InvestmentBannerStatus.PendingApproval),
  );
  const { control, getValues, setValue } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const [isMounting, setIsMounting] = useState(true);
  useMount(() => setIsMounting(false));
  /** Memo **/
  const modelFieldPath = useMemo(
    () => getModelPath(selectedIndex),
    [selectedIndex],
  );
  const handleUpdateModelsTab = useUpdateModelsTab();
  const watchedFormData = useWatch({
    control,
    name: "investmentProductModels",
  });
  const isAnySectionModified = useMemo(() => {
    const formData = getValues("investmentProductModels") ?? [];
    return formData.some((item) => item.isModified);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFormData]);

  const isAnySectionEdited = useMemo(() => {
    const formData = getValues("investmentProductModels") ?? [];
    return formData.some((item) => item.highlightType === HighlightType.Edited);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFormData]);

  const isAnySectionMissingData = useMemo(() => {
    const formData = getValues("investmentProductModels") ?? [];

    return formData.some(
      (item) => item.dataStatus === InvestmentDataStatus.MissingData,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFormData]);

  const featureProductDataPath = useMemo(
    () => getFeatureProductDataPaths(selectedIndex, 0, 0),
    [selectedIndex],
  );
  const featurePath = useMemo(
    () => getFeaturePaths(selectedIndex, 0, 0),
    [selectedIndex],
  );
  const handleUpdateSectionData = useUpdateSectionData(selectedIndex, 0);
  /** Handler **/
  const handleModelTitleChange = debounce((newValue: string) => {
    const publishedValue = getValues(featureProductDataPath.publishedValue);
    const dataStatus = isEmpty(newValue)
      ? InvestmentDataStatus.MissingData
      : InvestmentDataStatus.Filled;
    setValue(featureProductDataPath.dataStatus, dataStatus);
    setValue(featurePath.dataStatus, dataStatus);
    setValue(featurePath.isModified, !isEqual(newValue, originalTitle));
    setValue(
      featureProductDataPath.isModified,
      !isEqual(newValue, originalTitle),
    );
    if (publishedValue !== newValue) {
      setValue(featureProductDataPath.highlightType, HighlightType.Edited);
      setValue(featurePath.highlightType, HighlightType.Edited);
    } else {
      setValue(featureProductDataPath.highlightType, null);
      setValue(featurePath.highlightType, null);
    }
    setValue(modelFieldPath.name, newValue);
    handleUpdateSectionData();
  }, DEBOUNCE_DELAY);

  useEffect(() => {
    dispatch(toggleSaveDraftButtonDisabled(!isAnySectionModified));
    if (!isMounting) {
      handleUpdateModelsTab({
        isAnySectionModified,
        isAnySectionEdited,
        isAnySectionMissingData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAnySectionModified,
    isAnySectionEdited,
    isAnySectionMissingData,
    isMounting,
  ]);

  return (
    <>
      <SHStack direction={"row"} width={"100%"}>
        <Controller
          control={control}
          name={featureProductDataPath.value}
          render={({ field: { ref, ...other } }) => (
            <SHTextField
              disabled={isSaving || isPendingApproval}
              maxLength={100}
              value={getValues(featureProductDataPath.value)}
              sx={{
                "& .MuiInput-input": {
                  fontSize: "24px",
                  fontWeight: "600",
                  fontFamily: "Clash Display",
                  padding: "0px !important",
                  paddingTop: "2px !important",
                  width: "243px",
                },
              }}
              onChange={(event) => {
                other.onChange(event.target.value);
                handleModelTitleChange(event.target.value);
              }}
            />
          )}
        />
      </SHStack>
      <SHDivider sx={{ height: "1px", width: "100%" }} />
    </>
  );
};
