import { DownloadButtonProps } from "@components/buttons/download";
import { SHIconLoading } from "@components/design-systems";
import { CheckSVG } from "@components/svgs";
import { DELAY_TIME } from "@constants";
import { IconButton, Tooltip, tooltipClasses, useTheme } from "@mui/material";
import shadows from "@mui/material/styles/shadows";

type CheckedButtonProps = DownloadButtonProps & {
  variant?: "primary" | "outlined";
};

export const CheckedButton = ({
  width = "30px",
  height = "30px",
  isLoading = false,
  disabled = false,
  tooltipTitle = "Edit",
  backgroundColor,
  borderColor,
  iconColor = "#25324B",
  sx,
  variant = "outlined",
  ...others
}: CheckedButtonProps) => {
  const { palette } = useTheme();
  const isPrimary = variant === "primary";

  return (
    <Tooltip
      title={tooltipTitle}
      enterDelay={DELAY_TIME}
      enterNextDelay={DELAY_TIME}
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
              {
                marginTop: "6px",
              },
          },
        },
      }}
    >
      <IconButton
        {...others}
        disableRipple
        disabled={disabled}
        sx={{
          ...sx,
          width,
          height,
          p: 0,
          backgroundColor: isPrimary
            ? backgroundColor ?? palette.primary.main
            : backgroundColor ?? palette.common.white,
          borderColor: isPrimary
            ? borderColor
            : borderColor ?? palette.common.black,
          borderStyle: isPrimary ? "none" : "solid",
          borderWidth: isPrimary ? 0 : "1px",
          boxShadow: shadows[1],
          "&:hover": {
            backgroundColor: isPrimary ? palette.primary.dark : undefined,
            borderColor: isPrimary ? undefined : palette.primary[800],
            boxShadow: "none",
          },
          "&:active": {
            backgroundColor: isPrimary
              ? palette.primary.main
              : palette.primary.dark,
            borderColor: isPrimary ? palette.error.dark : palette.common.black,
            boxShadow: `inset 0 4px 4px ${palette.primary[900]}`,
            "&>svg>path": {
              stroke: isPrimary ? undefined : palette.common.white,
            },
          },
          "&:disabled": {
            border: `1px solid ${palette.text.disabled}`,
            backgroundColor: palette.common.white,
            "&>svg>path": {
              stroke: isPrimary ? undefined : palette.text.disabled,
              fill: isPrimary ? palette.text.disabled : undefined,
            },
          },
        }}
      >
        {isLoading ? (
          <SHIconLoading size={15} />
        ) : (
          <CheckSVG
            stroke={isPrimary ? palette.common.white : iconColor}
            width={16}
            height={16}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};
