import { SHButton, SHButtonProps } from "@components/design-systems";
import { RollBackSVG } from "@components/svgs";
import { DELAY_TIME } from "@constants";
import { Tooltip, useTheme } from "@mui/material";

export const RestoreButton = ({ ...props }: SHButtonProps) => {
  const { palette } = useTheme();
  return (
    <Tooltip title="Restore" enterDelay={DELAY_TIME}>
      <SHButton
        {...props}
        sx={{
          padding: "5px",
          "&:active": {
            "&>svg>path": {
              fill: palette.common.white,
            },
          },
        }}
        variant="outlined"
      >
        <RollBackSVG color={palette.common.black} />
      </SHButton>
    </Tooltip>
  );
};
