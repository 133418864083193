/** import */
import { CheckedButton } from "@components/buttons/check";
import { DownloadButton } from "@components/buttons/download";
import { EditButton } from "@components/buttons/edit";
import { GrayDeleteButton } from "@components/buttons/gray-delete";
import { PlusButton } from "@components/buttons/plus";
import { RollBackButton } from "@components/buttons/rollback";
import { SHAvatar, SHContainer, SHDataGrid, SHDataGridRef, SHStack, SHTypography } from "@components/design-systems";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { StatusBadge } from "@components/status-badge";
import { TruncatedPopupBlock } from "@components/truncated-popup-block";
import { APIExtRoutes } from "@constants";
import { DateFormat } from "@constants/format";
import { useDocumentLibrary } from "@hooks/useDocumentLibrary";
import { useNotification } from "@hooks/useNotification";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBar } from "@layouts/top-bar";
import {
  DocumentODataDTO,
  DocumentProductSelectionDTO,
  ProductDocumentDetailDTO,
} from "@models/document-library/entities/document";
import { ArchiveDocumentConfirmDialog } from "@pages/document-library/components/document-archive-dialog";
import DocumentFilterButton from "@pages/document-library/components/document-filter-button";
import DocumentUploadDialog from "@pages/document-library/components/document-upload-dialog";
import {
  DOCUMENT_SEARCH_PLACEHOLDER,
  DocumentStatusFilterOptions,
  EMPTY_DOCUMENT_LIST_MESSAGE,
} from "@pages/document-library/constant";
import { getDocumentDownloadFileName } from "@pages/document-library/util";
import { TextOverflowEllipsis } from "@pages/platform-analysis/components/text-overflow-ellipsis";
import { createColumnHelper, TableState, VisibilityState } from "@tanstack/react-table";
import { nameOfFactory } from "@utils";
import { format } from "date-fns";
import { isEmpty } from "lodash";
import { useMemo, useRef, useState } from "react";
import { generatePath } from "react-router";
import { useEffectOnce, useToggle } from "react-use";

/** Declaration */

const columnHelper = createColumnHelper<DocumentODataDTO>();

const nameOf = nameOfFactory<DocumentODataDTO>();

const pickColumns: (keyof DocumentODataDTO)[] = [
  "id",
  "title",
  "description",
  "productName",
  "productLogoUrl",
  "lastSubmittedBy",
  "url",
  "type",
  "fileType",
  "dateOfDocument",
  "status",
  "showArchive",
  "showEdit",
  "showDownload",
  "showPublish",
  "showRestore",
];
const DocumentLibrary = () => {
  /** Hook */
  const [openCreateDialog, toggleOpenCreateDialog] = useToggle(false);

  const { isSuperAdmin, canCreateDocument } = useUserPermissions();

  const [openArchiveDialog, toggleOpenArchiveDialog] = useToggle(false);
  const { notify } = useNotification();

  const dataGridRef = useRef<SHDataGridRef>(null);

  const columnVisibility: VisibilityState = useMemo(
    () => ({
      [nameOf("lastSubmittedBy")]: canCreateDocument ?? false,
      [nameOf("status")]: canCreateDocument ?? false,
    }),
    [canCreateDocument],
  );

  const [openEditDocumentDialog, toggleOpenEditDocumentDialog] =
    useToggle(false);

  const {
    loadListProductSelection,
    handleSubmitDocument,
    loadDocument,
    publishDocument,
  } = useDocumentLibrary();
  /** State */

  const [tableState, setTableState] = useState<Partial<TableState>>({
    ...DefaultDataState,
    sorting: [{ id: nameOf("dateOfDocument"), desc: true }],
  });

  const [periodDateRange, setPeriodDateRange] = useState({
    fromDate: "",
    toDate: "",
  });

  const [isLoadingProductList, setIsLoadingProductList] = useState(false);

  const [listProduct, setListProduct] = useState<DocumentProductSelectionDTO[]>(
    [],
  );

  const [selectedDocument, setSelectedDocument] =
    useState<ProductDocumentDetailDTO>(new ProductDocumentDetailDTO());

  const [loadingDocumentId, setLoadingDocumentId] = useState<string | null>(
    null,
  );

  const [isPublishingDocumentId, setPublishingDocumentId] = useState<
    string | null
  >(null);

  const [downloadingDocumentId, setDownloadingDocumentId] = useState<
    string | null
  >(null);

  const showCreateButton = canCreateDocument && !isEmpty(listProduct);

  /** Function */

  const loadListProduct = async () => {
    setIsLoadingProductList(true);
    const result = await loadListProductSelection();
    setListProduct(result ?? []);
    setIsLoadingProductList(false);
  };

  const handleOpenEditDialog = async (documentId: string) => {
    setLoadingDocumentId(documentId);
    const document = await loadDocument(documentId);
    if (document) {
      setSelectedDocument(document);
      toggleOpenEditDocumentDialog();
    }
    setLoadingDocumentId(null);
  };

  const handlePublishDocument = async (documentId: string) => {
    setPublishingDocumentId(documentId);
    await publishDocument(documentId);
    dataGridRef?.current?.refreshOdata();
    setPublishingDocumentId(null);
  };

  const handleRefreshOData = () => {
    if (dataGridRef.current) {
      dataGridRef.current.refreshOdata();
    }
  };

  const handleDownloadDocument = (documentDTO: DocumentODataDTO) => {
    const { id, url, productName, title, dateOfDocument } = documentDTO;
    setDownloadingDocumentId(id);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = window.URL.createObjectURL(blob);
        const fileName = getDocumentDownloadFileName({
          url,
          productName,
          title,
          dateOfDocument,
        });
        const aTag = document.createElement("a");
        aTag.href = blobURL;
        aTag.setAttribute("download", fileName ?? "");
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
        notify(`Document ${fileName} downloaded successfully`, {
          variant: "success",
          close: true,
        });
      })
      .catch(() => {
        notify("Failed to download document", {
          variant: "error",
          close: true,
        });
      })
      .finally(() => {
        setDownloadingDocumentId(null);
      });
  };

  /** Side Effect */

  useEffectOnce(() => {
    canCreateDocument && loadListProduct();
  });

  /** Render */

  const columns = useMemo(
    () => [
      columnHelper.accessor("title", {
        header: "Document",
        cell: (props) => (
          <SHStack direction={"column"}>
            {/* Title */}
            <TruncatedPopupBlock
              truncateProps={{
                lines: 2,
              }}
              typographyProps={{
                variant: "subtitle2",
                lineHeight: 1.2,
              }}
            >
              {props.cell.getValue()}
            </TruncatedPopupBlock>
            {/* Description */}
            <TruncatedPopupBlock
              truncateProps={{
                lines: 2,
              }}
              typographyProps={{
                variant: "body2",
                lineHeight: 1.2,
              }}
            >
              {props.row.original.description}
            </TruncatedPopupBlock>
          </SHStack>
        ),
        enableColumnFilter: false,
        meta: {
          sx: {
            width: canCreateDocument ? "270px" : "520px",
          },
        },
      }),
      columnHelper.accessor("productName", {
        header: "Product",
        enableColumnFilter: false,

        cell: (props) => (
          <SHStack direction={"row"} spacing={1} alignItems={"center"}>
            <SHAvatar
              width={"24px"}
              height={"24px"}
              isCircular={false}
              objectFit="contain"
              src={props.row.original.productLogoUrl}
            />
            <TextOverflowEllipsis value={props.cell.getValue() ?? ""} />
          </SHStack>
        ),
        meta: {
          sx: {
            width: canCreateDocument ? undefined : "180px",
          },
        },
      }),
      columnHelper.accessor("lastSubmittedBy", {
        header: "Last edit",
        enableColumnFilter: false,
        cell: (props) => <SHTypography>{props.cell.getValue()}</SHTypography>,
      }),
      columnHelper.accessor("fileType", {
        header: "File",
        enableColumnFilter: false,
        cell: (props) => <SHTypography>{props.cell.getValue()}</SHTypography>,
      }),
      columnHelper.accessor("type", {
        header: "Type",
        enableColumnFilter: false,
        cell: (props) => <SHTypography>{props.cell.getValue()}</SHTypography>,
      }),
      columnHelper.accessor("dateOfDocument", {
        header: "Date",
        cell: (props) => {
          const date = props.cell.getValue();
          return date ? format(new Date(date), DateFormat) : "";
        },
        enableColumnFilter: false,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (props) => <StatusBadge status={props.cell.getValue()} />,
        meta: {
          filterData: DocumentStatusFilterOptions,
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "actionCol",
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (props) => {
          const {
            showRestore,
            showArchive,
            showEdit,
            showDownload,
            showPublish,
            id: documentId,
          } = props.row.original;
          const isDownloading = downloadingDocumentId === documentId;
          return (
            <SHStack spacing={1} direction="row">
              {showDownload && (
                <DownloadButton
                  isLoading={isDownloading}
                  disabled={isDownloading}
                  onClick={() => handleDownloadDocument(props.row.original)}
                  variant="primary"
                  tooltipTitle="Download"
                />
              )}
              {showPublish && (
                <CheckedButton
                  isLoading={isPublishingDocumentId === documentId}
                  onClick={() => handlePublishDocument(documentId)}
                  tooltipTitle="Publish"
                />
              )}
              {showEdit && (
                <EditButton
                  variant="outlined"
                  isLoading={
                    loadingDocumentId === documentId && !isLoadingProductList
                  }
                  onClick={() => handleOpenEditDialog(documentId)}
                  tooltipTitle="Edit"
                />
              )}
              {showArchive && (
                <GrayDeleteButton
                  onClick={toggleOpenArchiveDialog}
                  tooltipTitle="Archive"
                />
              )}
              {showRestore && <RollBackButton tooltipTitle="Restore" />}
            </SHStack>
          );
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      loadingDocumentId,
      isLoadingProductList,
      isPublishingDocumentId,
      downloadingDocumentId,
    ],
  );

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack spacing={3} sx={{ paddingY: 3 }}>
        <TopBar
          title="Document library"
          tools={
            <>
              {showCreateButton && (
                <PlusButton
                  onClick={toggleOpenCreateDialog}
                  variant="contained"
                  size="extraMedium"
                >
                  New document
                </PlusButton>
              )}
              {isLoadingProductList && (
                <SHSkeleton
                  delay={false}
                  height={38}
                  width={"163px"}
                  sx={{ borderRadius: "40px" }}
                />
              )}
            </>
          }
        />
        <SHDataGrid
          onChangeState={(state) => {
            setTableState(state);
          }}
          ref={dataGridRef}
          rightToolbar={
            <DocumentFilterButton
              onPeriodFilter={(period) => {
                const fromDate = period?.fromDate
                  ? format(period.fromDate, "yyyy-MM-dd")
                  : "";
                const toDate = period?.toDate
                  ? format(period.toDate, "yyyy-MM-dd")
                  : "";

                setPeriodDateRange({
                  fromDate,
                  toDate,
                });
              }}
              onTypeFilter={(typeFilterValue) => {
                setTableState((prevState) => ({
                  ...prevState,
                  columnFilters: [
                    ...(prevState.columnFilters || []).filter(
                      (f) => f.id !== nameOf("type"),
                    ),
                    { id: nameOf("type"), value: typeFilterValue },
                  ],
                }));
              }}
            />
          }
          state={{
            ...tableState,
            columnVisibility,
          }}
          columns={columns as any}
          odata={{
            pickColumns,
            url: generatePath(APIExtRoutes.odataDocuments, {
              productId: "",
              productType: "",
              dateTimeStart: periodDateRange.fromDate,
              dateTimeEnd: periodDateRange.toDate,
            }),
            globalFilterColumns: [nameOf("title"), nameOf("productName")],
          }}
          emptyMessage={EMPTY_DOCUMENT_LIST_MESSAGE}
          searchBoxProps={{
            placeholder: DOCUMENT_SEARCH_PLACEHOLDER,
          }}
        />
      </SHStack>
      {/* Dialogs */}
      {openEditDocumentDialog && (
        <DocumentUploadDialog
          isEdit
          onSubmit={async (data) => {
            await handleSubmitDocument(data, true, isSuperAdmin, {
              onSuccess: () => {
                toggleOpenEditDocumentDialog(false);
                setSelectedDocument(new ProductDocumentDetailDTO());
                handleRefreshOData();
              },
            });
          }}
          isLoadingProductList={isLoadingProductList}
          onClose={toggleOpenEditDocumentDialog}
          isOpen={openEditDocumentDialog}
          dialogTitle="Edit document"
          productSelectionList={listProduct}
          buttonLabel={
            isSuperAdmin
              ? "Save changes and publish document"
              : "Submit changes for review"
          }
          initialValues={selectedDocument}
        />
      )}
      {openCreateDialog && (
        <DocumentUploadDialog
          onSubmit={async (data) => {
            await handleSubmitDocument(data, false, isSuperAdmin, {
              onSuccess: () => {
                toggleOpenCreateDialog(false);
                handleRefreshOData();
              },
            });
          }}
          isLoadingProductList={isLoadingProductList}
          onClose={toggleOpenCreateDialog}
          isOpen={openCreateDialog}
          dialogTitle="Document Upload"
          productSelectionList={listProduct}
          buttonLabel={isSuperAdmin ? "Create document" : "Submit document"}
          initialValues={new ProductDocumentDetailDTO()}
        />
      )}
      {openArchiveDialog && (
        <ArchiveDocumentConfirmDialog
          open={openArchiveDialog}
          onClose={toggleOpenArchiveDialog}
          onOk={toggleOpenArchiveDialog}
          isLoading={false}
        />
      )}
    </SHContainer>
  );
};

export default DocumentLibrary;
