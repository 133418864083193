import { ProductType } from "@models/product/enums/productType";
import {
  updateEditMode as updatePlatformEditMode,
  updateLoadingEditMode as updatePlatformLoadingEditMode,
  updateProductType as updatePlatformProductType,
  updateShowBeingKickedDialog as updatePlatformShowBeingKickedDialog,
  updateShowClaimDeniedDialog as updatePlatformShowClaimDeniedDialog,
  updateShowKickoutDialog as updatePlatformShowKickoutDialog,
} from "@redux/slices/product";
import {
  updateEditMode as updateInvestmentProductEditMode,
  updateLoadingEditMode as updateInvestmentProductLoadingEditMode,
  updateProductType as updateInvestmentProductProductType,
  updateShowBeingKickedDialog as updateInvestmentProductShowBeingKickedDialog,
  updateShowClaimDeniedDialog as updateInvestmentProductShowClaimDeniedDialog,
  updateShowKickoutDialog as updateInvestmentProductShowKickoutDialog,
} from "@redux/slices/product/investment-product";
import { RootState } from "@redux/store";
import { generatePath } from "react-router";
import { PageRoutes } from "@constants";
import { InvalidProductTypeMessageTemplate } from "@pages/platform-profiles/constant";

// Product state action interfaces
export interface KickOutDialogProductStateAction {
  updateShowBeingKickedDialog: (payload: boolean) => void;
}

export interface ClaimingDeniedDialogProductStateAction {
  updateLoadingEditMode: (payload: boolean) => void;
  updateShowClaimDeniedDialog: (payload: boolean) => void;
}

export interface ConfirmKickoutDialogProductStateAction {
  updateEditMode: (payload: boolean) => void;
  updateLoadingEditMode: (payload: boolean) => void;
  updateShowKickoutDialog: (payload: boolean) => void;
}

export interface KickOutAdminDialogProductStateAction {
  updateEditMode: (payload: boolean) => void;
  updateLoadingEditMode: (payload: boolean) => void;
  updateShowClaimDeniedDialog: (payload: boolean) => void;
}

// Product configs
interface KickOutDialogProductConfig {
  getShowBeingKickedDialog: (state: RootState) => boolean | undefined;
  getBeingKickedBy: (state: RootState) => string | undefined;
  actionTypes: DialogActionTypes["Platform"] | DialogActionTypes["Investment"];
  actionCreators: {
    updateShowBeingKickedDialog:
      | typeof updatePlatformShowBeingKickedDialog
      | typeof updateInvestmentProductShowBeingKickedDialog;
  };
}

interface ClaimingDeniedDialogProductConfig {
  getShowClaimDeniedDialog: (state: RootState) => boolean | undefined;
  actionTypes: DialogActionTypes["Platform"] | DialogActionTypes["Investment"];
  actionCreators: {
    updateLoadingEditMode:
      | typeof updatePlatformLoadingEditMode
      | typeof updateInvestmentProductLoadingEditMode;
    updateShowClaimDeniedDialog:
      | typeof updatePlatformShowClaimDeniedDialog
      | typeof updateInvestmentProductShowClaimDeniedDialog;
  };
}

interface ConfirmKickoutDialogProductConfig {
  getShowKickoutDialog: (state: RootState) => boolean | undefined;
  getCurrentEditor: (state: RootState) => string | undefined;
  actionTypes: DialogActionTypes["Platform"] | DialogActionTypes["Investment"];
  actionCreators: {
    updateEditMode:
      | typeof updatePlatformEditMode
      | typeof updateInvestmentProductEditMode;
    updateLoadingEditMode:
      | typeof updatePlatformLoadingEditMode
      | typeof updateInvestmentProductLoadingEditMode;
    updateShowKickoutDialog:
      | typeof updatePlatformShowKickoutDialog
      | typeof updateInvestmentProductShowKickoutDialog;
  };
  getNavigationPath: (supplierId: string, productId: string) => string;
}

interface KickOutAdminDialogProductConfig {
  getShowClaimDeniedDialog: (state: RootState) => boolean | undefined;
  actionTypes: DialogActionTypes["Platform"] | DialogActionTypes["Investment"];
  actionCreators: {
    updateEditMode:
      | typeof updatePlatformEditMode
      | typeof updateInvestmentProductEditMode;
    updateLoadingEditMode:
      | typeof updatePlatformLoadingEditMode
      | typeof updateInvestmentProductLoadingEditMode;
    updateShowClaimDeniedDialog:
      | typeof updatePlatformShowClaimDeniedDialog
      | typeof updateInvestmentProductShowClaimDeniedDialog;
  };
  getNavigationPath: (supplierId: string, productId: string) => string;
}

interface ProductCardViewConfig {
  updateEditMode:
    | typeof updatePlatformEditMode
    | typeof updateInvestmentProductEditMode;
  updateProductType:
    | typeof updatePlatformProductType
    | typeof updateInvestmentProductProductType;
}

interface ProductListViewConfig {
  checkEdit: (
    productId: string,
    onSuccess?: (() => void) | undefined,
  ) => Promise<void>;
  updateEditMode:
    | typeof updatePlatformEditMode
    | typeof updateInvestmentProductEditMode;
  updateProductType:
    | typeof updatePlatformProductType
    | typeof updateInvestmentProductProductType;
}

// Action types
type DialogActionTypeKeys =
  | "updateEditMode"
  | "updateProductType"
  | "updateShowBeingKickedDialog"
  | "updateLoadingEditMode"
  | "updateShowClaimDeniedDialog"
  | "updateShowKickoutDialog";

interface DialogActionTypes {
  Platform: {
    [K in DialogActionTypeKeys]?: string;
  };
  Investment: {
    [K in DialogActionTypeKeys]?: string;
  };
}

const KickOutDialogActionTypes: DialogActionTypes = {
  Platform: {
    updateShowBeingKickedDialog: updatePlatformShowBeingKickedDialog.type,
  },
  Investment: {
    updateShowBeingKickedDialog:
      updateInvestmentProductShowBeingKickedDialog.type,
  },
};

const ClaimingDeniedDialogActionTypes: DialogActionTypes = {
  Platform: {
    updateLoadingEditMode: updatePlatformLoadingEditMode.type,
    updateShowClaimDeniedDialog: updatePlatformShowClaimDeniedDialog.type,
  },
  Investment: {
    updateLoadingEditMode: updateInvestmentProductLoadingEditMode.type,
    updateShowClaimDeniedDialog:
      updateInvestmentProductShowClaimDeniedDialog.type,
  },
};

const ConfirmKickoutDialogActionTypes: DialogActionTypes = {
  Platform: {
    updateEditMode: updatePlatformEditMode.type,
    updateLoadingEditMode: updatePlatformLoadingEditMode.type,
    updateShowKickoutDialog: updatePlatformShowKickoutDialog.type,
  },
  Investment: {
    updateEditMode: updateInvestmentProductEditMode.type,
    updateLoadingEditMode: updateInvestmentProductLoadingEditMode.type,
    updateShowKickoutDialog: updateInvestmentProductShowKickoutDialog.type,
  },
};

const KickOutAdminDialogActionTypes: DialogActionTypes = {
  Platform: {
    updateEditMode: updatePlatformEditMode.type,
    updateLoadingEditMode: updatePlatformLoadingEditMode.type,
    updateShowClaimDeniedDialog: updatePlatformShowClaimDeniedDialog.type,
  },
  Investment: {
    updateEditMode: updateInvestmentProductEditMode.type,
    updateLoadingEditMode: updateInvestmentProductLoadingEditMode.type,
    updateShowClaimDeniedDialog:
      updateInvestmentProductShowClaimDeniedDialog.type,
  },
};

// Functions
export const validateProductType = (
  productType: ProductType | string | undefined | null,
  onError: (errorMessage: string) => void,
): boolean => {
  const defaultProductType = productType ?? ProductType.Platform;
  const isValid = Object.values(ProductType).includes(
    defaultProductType as ProductType,
  );

  if (!isValid) {
    onError(InvalidProductTypeMessageTemplate({ productType: productType }));
  }

  return isValid;
};

export const getProductClaimingMethod = ({
  productType,
  platformClaimingMethod,
  investmentClaimingMethod,
}: {
  productType: ProductType | undefined;
  platformClaimingMethod: (
    productId: string,
    callback?: () => void,
  ) => Promise<void>;
  investmentClaimingMethod: (
    productId: string,
    callback?: () => void,
  ) => Promise<void>;
}) => {
  if (productType === ProductType.Platform) {
    return platformClaimingMethod;
  }

  return investmentClaimingMethod;
};

export const getKickOutDialogProductConfig = (
  productType: ProductType | undefined,
): KickOutDialogProductConfig => {
  if (productType === ProductType.Platform) {
    return {
      getShowBeingKickedDialog: (state: RootState) =>
        state.product.productUI?.showBeingKickedDialog,
      getBeingKickedBy: (state: RootState) =>
        state.product.productUI?.beingKickedBy,
      actionTypes: KickOutDialogActionTypes.Platform,
      actionCreators: {
        updateShowBeingKickedDialog: updatePlatformShowBeingKickedDialog,
      },
    };
  }

  return {
    getShowBeingKickedDialog: (state: RootState) =>
      state.investmentProduct.investmentProductUI?.showBeingKickedDialog,
    getBeingKickedBy: (state: RootState) =>
      state.investmentProduct.investmentProductUI?.beingKickedBy,
    actionTypes: KickOutDialogActionTypes.Investment,
    actionCreators: {
      updateShowBeingKickedDialog: updateInvestmentProductShowBeingKickedDialog,
    },
  };
};

export const getClaimingDeniedDialogProductConfig = (
  productType: ProductType | undefined,
): ClaimingDeniedDialogProductConfig => {
  if (productType === ProductType.Platform) {
    return {
      getShowClaimDeniedDialog: (state: RootState) =>
        state.product.productUI?.showClaimDeniedDialog,
      actionTypes: ClaimingDeniedDialogActionTypes.Platform,
      actionCreators: {
        updateLoadingEditMode: updatePlatformLoadingEditMode,
        updateShowClaimDeniedDialog: updatePlatformShowClaimDeniedDialog,
      },
    };
  }

  return {
    getShowClaimDeniedDialog: (state: RootState) =>
      state.investmentProduct.investmentProductUI?.showClaimDeniedDialog,
    actionTypes: ClaimingDeniedDialogActionTypes.Investment,
    actionCreators: {
      updateLoadingEditMode: updateInvestmentProductLoadingEditMode,
      updateShowClaimDeniedDialog: updateInvestmentProductShowClaimDeniedDialog,
    },
  };
};

export const getConfirmKickoutDialogProductConfig = (
  productType: ProductType | undefined,
): ConfirmKickoutDialogProductConfig => {
  if (productType === ProductType.Platform) {
    return {
      getShowKickoutDialog: (state: RootState) =>
        state.product.productUI?.showKickoutDialog,
      getCurrentEditor: (state: RootState) =>
        state.product.productUI?.currentEditor,
      actionTypes: ConfirmKickoutDialogActionTypes.Platform,
      actionCreators: {
        updateEditMode: updatePlatformEditMode,
        updateLoadingEditMode: updatePlatformLoadingEditMode,
        updateShowKickoutDialog: updatePlatformShowKickoutDialog,
      },
      getNavigationPath: (supplierId: string, productId: string) =>
        generatePath(PageRoutes.supplierProducts, { supplierId, productId }),
    };
  }

  return {
    getShowKickoutDialog: (state: RootState) =>
      state.investmentProduct.investmentProductUI?.showKickoutDialog,
    getCurrentEditor: (state: RootState) =>
      state.investmentProduct.investmentProductUI?.currentEditor,
    actionTypes: ConfirmKickoutDialogActionTypes.Investment,
    actionCreators: {
      updateEditMode: updateInvestmentProductEditMode,
      updateLoadingEditMode: updateInvestmentProductLoadingEditMode,
      updateShowKickoutDialog: updateInvestmentProductShowKickoutDialog,
    },
    getNavigationPath: (supplierId: string, investmentProductId: string) =>
      generatePath(PageRoutes.investmentProductsDetail, {
        supplierId,
        investmentProductId,
      }),
  };
};

export const getKickOutAdminDialogProductConfig = (
  productType: ProductType | undefined,
): KickOutAdminDialogProductConfig => {
  if (productType === ProductType.Platform) {
    return {
      getShowClaimDeniedDialog: (state: RootState) =>
        state.product.productUI?.showClaimDeniedDialog,
      actionTypes: KickOutAdminDialogActionTypes.Platform,
      actionCreators: {
        updateEditMode: updatePlatformEditMode,
        updateLoadingEditMode: updatePlatformLoadingEditMode,
        updateShowClaimDeniedDialog: updatePlatformShowClaimDeniedDialog,
      },
      getNavigationPath: (supplierId: string, productId: string) =>
        generatePath(PageRoutes.supplierProducts, { supplierId, productId }),
    };
  }

  return {
    getShowClaimDeniedDialog: (state: RootState) =>
      state.investmentProduct.investmentProductUI?.showClaimDeniedDialog,
    actionTypes: KickOutAdminDialogActionTypes.Investment,
    actionCreators: {
      updateEditMode: updateInvestmentProductEditMode,
      updateLoadingEditMode: updateInvestmentProductLoadingEditMode,
      updateShowClaimDeniedDialog: updateInvestmentProductShowClaimDeniedDialog,
    },
    getNavigationPath: (supplierId: string, investmentProductId: string) =>
      generatePath(PageRoutes.investmentProductsDetail, {
        supplierId,
        investmentProductId,
      }),
  };
};

export const getCardViewProductConfig = (
  productType: ProductType | undefined,
): ProductCardViewConfig => {
  if (productType === ProductType.Platform) {
    return {
      updateEditMode: updatePlatformEditMode,
      updateProductType: updatePlatformProductType,
    };
  }

  return {
    updateEditMode: updateInvestmentProductEditMode,
    updateProductType: updateInvestmentProductProductType,
  };
};

export const getListViewProductConfig = ({
  productType,
  checkEditProduct,
  checkEditInvestmentProduct,
}: {
  productType: ProductType | undefined;
  checkEditProduct: (
    productId: string,
    onSuccess?: (() => void) | undefined,
  ) => Promise<void>;
  checkEditInvestmentProduct: (
    productId: string,
    onSuccess?: (() => void) | undefined,
  ) => Promise<void>;
}): ProductListViewConfig => {
  if (productType === ProductType.Platform) {
    return {
      checkEdit: checkEditProduct,
      updateEditMode: updatePlatformEditMode,
      updateProductType: updatePlatformProductType,
    };
  }

  return {
    checkEdit: checkEditInvestmentProduct,
    updateEditMode: updateInvestmentProductEditMode,
    updateProductType: updateInvestmentProductProductType,
  };
};
