import {
  SHBox,
  SHContainer,
  SHIconLoading,
  SHStack,
  SHTab,
  SHTabs,
} from "@components/design-systems";
import { SocketClientEvent } from "@constants";
import { useEditInvestmentProduct } from "@hooks/useEditInvestmentProduct";
import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { useIsNew } from "@hooks/useIsNew";
import { TopBarPlacement } from "@layouts/top-bar";
import { HighlightType } from "@models/configuration";
import {
  InvestmentDataStatus,
  InvestmentProductDataTab,
} from "@models/product/investment-product/enums/status";
import { ProductBeingKickedOutDialog } from "@pages/platform-profiles/components/dialog-being-kick-out";
import { ConfirmKickOutDialog } from "@pages/platform-profiles/components/dialog-confirm-kick-out";
import { ConfirmKickOutAdminDialog } from "@pages/platform-profiles/components/dialog-kick-out-admin";
import { otherConfigTabs } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/util";
import {
  updateEditMode as updateInvestmentProductEditMode,
  updateInvestmentProductManagerData,
  updateInvestmentProductName,
  updateSelectedTab,
} from "@redux/slices/product/investment-product";
import { RootState } from "@redux/store";
import { SignalRService } from "@services/signalr/signalRService";
import { isEqual } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { EditESGTab } from "./components/tabs/esg";
import { EditManagerTab } from "./components/tabs/manager";
import { EditModelsTab } from "./components/tabs/model";
import { EditSeriesTab } from "./components/tabs/series";
import { InvestmentProductTopBarContainer } from "./components/top-bar-container";
import { useToggle, useUnmount } from "react-use";
import UnsavedDialog from "@components/dialogs/unsaved";
import { EditVisibilityTab } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility";
import { useUserPermissions } from "@hooks/userUserPermission";

export interface EditInvestmentProductFormRef {
  getValue: () => any | undefined;
  submit: () => Promise<void>;
  saveDraft: () => Promise<EditInvestmentProductSaveDraftResult>;
  changeTab: () => Promise<EditInvestmentProductSaveDraftResult>;
  resetForm: (data?: any) => void;
  onPostSubmit: () => Promise<void>;
}

export interface EditInvestmentProductSaveDraftResult {
  isSuccess: boolean;
  reloadBanner?: boolean;
}

export const EditInvestmentProduct = () => {
  const dispatch = useDispatch();
  const {
    loadInvestmentProductGroups,
    loadInvestmentProductBannerInfo,
    resetInvestmentProductBannerInfo,
    submitForReviewAsync,
    revertDraftAsync,
    approveAsync,
    recallSubmissionAsync,
  } = useInvestmentProduct();
  const { checkEditInvestmentProduct, handleOnReleaseLockProductEditing } =
    useEditInvestmentProduct();
  const tabFormRef = useRef<EditInvestmentProductFormRef | null>(null);
  const { investmentProductId } = useParams<{ investmentProductId: string }>();
  const { supplierId } = useParams<{ supplierId: string }>();
  const [searchParams] = useSearchParams();
  const isNew = useIsNew();
  const { isSuperAdmin } = useUserPermissions();
  const versionId = searchParams?.get("version") ?? undefined;
  const configurationVersionId =
    searchParams?.get("configurationVersion") ?? undefined;
  const investmentConfigurationVersion =
    searchParams?.get("investmentConfigurationVersion") ?? undefined;

  const {
    investmentProductGroups,
    investmentProductUI: {
      selectedEditTab,
      investmentProductName,
      isLoadingInvestmentProductGroups,
      isLoading,
      isSaveDraftButtonDisabled,
      isSaving,
      productType,
    },
  } = useSelector((state: RootState) => state.investmentProduct);

  useEffect(() => {
    const connection = SignalRService.getHubConnection();
    connection?.onreconnected(() => {
      if (investmentProductId && !isNew) {
        checkEditInvestmentProduct(investmentProductId);
      }
    });

    return () => {
      connection?.off(SocketClientEvent.onReconnected);
      if (investmentProductId && !isNew) {
        handleOnReleaseLockProductEditing(investmentProductId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investmentProductId, isNew]);

  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const [isReverting, toggleReverting] = useToggle(false);
  const [isApproving, toggleApproving] = useToggle(false);
  const [isRecalling, toggleRecalling] = useToggle(false);

  const handleOnTabChange = async (value: string) => {
    const result = await tabFormRef.current?.changeTab();

    if (result?.isSuccess) {
      dispatch(updateSelectedTab({ selectedTab: +value, isEditMode: true }));
    }

    if (result?.reloadBanner && !isNew) {
      await loadInvestmentProductBannerInfo(investmentProductId || "");
    }
  };

  const handleOnSubmit = async () => {
    toggleSubmitting(true);

    try {
      if (!isSaveDraftButtonDisabled) {
        const isSaveDraftSucceeded = await tabFormRef.current?.saveDraft();

        if (!isSaveDraftSucceeded) {
          return;
        }
      }

      const response = await submitForReviewAsync(investmentProductId || "");

      if (!response) {
        return;
      }

      await loadInvestmentProductGroups(
        investmentProductId ?? "new",
        versionId ?? "0",
        configurationVersionId ?? "0",
        investmentConfigurationVersion ?? "0",
      );

      await loadInvestmentProductBannerInfo(investmentProductId || "");

      await tabFormRef.current?.onPostSubmit();
    } finally {
      toggleSubmitting(false);
    }
  };

  const handleOnRevert = async () => {
    toggleReverting(true);

    try {
      const response = await revertDraftAsync(investmentProductId || "");

      if (!response) {
        return;
      }

      await loadInvestmentProductBannerInfo(investmentProductId || "");
      await tabFormRef.current?.onPostSubmit();
    } finally {
      toggleReverting(false);
    }
  };

  const handleOnApprove = async () => {
    toggleApproving(true);

    try {
      const response = await approveAsync(investmentProductId || "");

      if (!response) {
        return;
      }
      await loadInvestmentProductGroups(
        investmentProductId ?? "new",
        versionId ?? "0",
        configurationVersionId ?? "0",
        investmentConfigurationVersion ?? "0",
      );

      await loadInvestmentProductBannerInfo(investmentProductId || "");
      await tabFormRef.current?.onPostSubmit();
    } finally {
      toggleApproving(false);
    }
  };

  const handleOnSaveDraft = async () => {
    await tabFormRef?.current?.saveDraft();

    if (!isNew) {
      await loadInvestmentProductBannerInfo(investmentProductId || "");
    }
  };

  const handleOnRecallSubmission = async () => {
    toggleRecalling(true);

    try {
      const response = await recallSubmissionAsync(investmentProductId || "");

      if (!response) {
        return;
      }

      await loadInvestmentProductBannerInfo(investmentProductId || "");
    } finally {
      toggleRecalling(false);
    }
  };

  useEffect(() => {
    if (isNew || !!versionId) {
      resetInvestmentProductBannerInfo();
      return;
    }

    loadInvestmentProductBannerInfo(investmentProductId || "");
    // eslint-disable-next-line
  }, [investmentProductId]);

  useUnmount(() => {
    resetInvestmentProductBannerInfo();
    dispatch(updateInvestmentProductEditMode(false));
  });

  useEffect(() => {
    loadInvestmentProductGroups(
      investmentProductId ?? "new",
      versionId ?? "0",
      configurationVersionId ?? "0",
      investmentConfigurationVersion ?? "0",
    );
    // eslint-disable-next-line
  }, [
    investmentProductId,
    versionId,
    configurationVersionId,
    investmentConfigurationVersion,
  ]);

  useEffect(() => {
    return () => {
      resetInvestmentProductBannerInfo();
      dispatch(updateInvestmentProductManagerData());
      dispatch(updateSelectedTab({ selectedTab: 0, isEditMode: true }));
      dispatch(updateInvestmentProductName());
    };
    // eslint-disable-next-line
  }, []);

  const isLoadingTopBar = useMemo(() => {
    return [
      investmentProductName === undefined && !isNew,
      isLoadingInvestmentProductGroups,
      isLoading,
    ].some(Boolean);
  }, [
    investmentProductName,
    isNew,
    isLoadingInvestmentProductGroups,
    isLoading,
  ]);

  const renderTabs = () => {
    if (!investmentProductGroups?.length) return [];
    const investmentProductGroupsData = [
      ...investmentProductGroups,
      ...otherConfigTabs,
    ];
    return [
      ...investmentProductGroupsData.map((group, index) => {
        let component: JSX.Element = <></>;
        switch (group?.id) {
          case InvestmentProductDataTab.Manager:
            component = <EditManagerTab ref={tabFormRef} />;
            break;
          case InvestmentProductDataTab.Series:
            component = (
              <EditSeriesTab
                ref={tabFormRef}
                allowAddNewSeriesModel={isSuperAdmin}
              />
            );
            break;
          case InvestmentProductDataTab.Models:
            component = (
              <EditModelsTab ref={tabFormRef} allowAddNewModel={isSuperAdmin} />
            );
            break;
          case InvestmentProductDataTab.ESG:
            component = <EditESGTab ref={tabFormRef} />;
            break;
          case InvestmentProductDataTab.Visibility:
            component = <EditVisibilityTab ref={tabFormRef} />;
            break;
        }

        return {
          label: group.id,
          component: component,
          dotGroupProps: {
            blueDot: isEqual(
              group.dataStatus,
              InvestmentDataStatus.MissingData,
            ),
            orangeDot: isEqual(group.highlightType, HighlightType.Edited),
          },
          disabled: isSaving,
          icon:
            isSaving && isEqual(selectedEditTab, index) ? (
              <SHIconLoading />
            ) : null,
        } as SHTab;
      }),
    ];
  };

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <SHStack sx={{ paddingY: 3 }} spacing={3}>
        <TopBarPlacement />
        <SHBox component={"form"}>
          <InvestmentProductTopBarContainer
            onSaveDraft={handleOnSaveDraft}
            onSubmit={handleOnSubmit}
            onRevert={handleOnRevert}
            onApprove={handleOnApprove}
            onRecallSubmission={handleOnRecallSubmission}
            isDisabledSaveDraft={isSaveDraftButtonDisabled}
            isSubmitting={isSubmitting}
            isLoading={isLoadingTopBar}
            isReverting={isReverting}
            isApproving={isApproving}
            isRecallingSubmission={isRecalling}
          />
          <SHTabs
            value={selectedEditTab.toString()}
            onValueChange={handleOnTabChange}
            isHandleByProps
            isLoading={isLoadingInvestmentProductGroups}
            tabs={renderTabs()}
          />
        </SHBox>
      </SHStack>
      <ConfirmKickOutAdminDialog
        supplierId={supplierId}
        productId={investmentProductId}
        productType={productType}
      />
      <ConfirmKickOutDialog
        supplierId={supplierId}
        productId={investmentProductId}
        productType={productType}
      />
      <ProductBeingKickedOutDialog productType={productType} />
      <UnsavedDialog isDirty={!isSaveDraftButtonDisabled} />
    </SHContainer>
  );
};
