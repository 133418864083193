import { SHFilterOption } from "@components/design-systems";
import { DocumentPeriod } from "@models/document-library/enums/documentPeriod";
import { DocumentTypes } from "@models/document-library/enums/documentTypes";
import { DocumentStatus } from "@models/document-library/enums/status";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { SpecialViewingGroup } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { endOfMonth } from "date-fns";

export const DocumentStatusFilterOptions: SHFilterOption[] = Object.values(
  DocumentStatus,
).map((status) => ({
  label: status,
  value: status,
}));

export const PeriodFilterOptions: SHFilterOption[] = [
  { label: DocumentPeriod.LastMonth, value: 1 },
  { label: DocumentPeriod.LastThreeMonths, value: 3 },
  { label: DocumentPeriod.LastSixMonths, value: 6 },
  { label: DocumentPeriod.LastTwelveMonths, value: 12 },
  { label: DocumentPeriod.Custom, value: "Custom" },
];

export const TypeFilterOptions: SHFilterOption[] =
  DocumentTypes.toFilterOptions();

export const EMPTY_DOCUMENT_LIST_MESSAGE =
  "No documents. Click on “New document” to add!";
export const DOCUMENT_SEARCH_PLACEHOLDER = "Search documents or product ";

export const DefaultPeriodFilterDate = {
  fromDate: new Date(Date.UTC(2022, 0, 1, 0, 0, 0, 0)),
  toDate: endOfMonth(new Date()),
};

/**
 * MIME_types @link https://developer.mozilla.org/en-US/docs/Web/HTTP/MIME_types/Common_types
 */
export const DocumentUploadAcceptFile = {
  "image/*": [], // Image files
  "application/pdf": [".pdf"], // PDF
  "application/vnd.ms-excel": [".xls"], // XLS
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ], // XLSX
  "application/msword": [".doc"], // DOC
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ], // DOCX
  "text/csv": [".csv"], // CSV
};

export enum FileTypes {
  PDF = "PDF",
  IMAGE = "IMAGE",
  WORD = "WORD",
  EXCEL = "EXCEL",
}

export const DOCUMENT_SIZE_LIMIT = 20 * 1024 * 1024; // 20MB
export const DOCUMENT_DESCRIPTION_MAX_LENGTH = 3000;

export const FileTypeMap = new Map<string, FileTypes>([
  ["png", FileTypes.IMAGE],
  ["jpg", FileTypes.IMAGE],
  ["jpeg", FileTypes.IMAGE],
  ["gif", FileTypes.IMAGE],
  ["bmp", FileTypes.IMAGE],
  ["webp", FileTypes.IMAGE],
  ["svg", FileTypes.IMAGE],
  ["doc", FileTypes.WORD],
  ["docx", FileTypes.WORD],
  ["xls", FileTypes.EXCEL],
  ["xlsx", FileTypes.EXCEL],
  ["csv", FileTypes.EXCEL],
  ["pdf", FileTypes.PDF],
]);

export const DocumentDefaultPlatformViewingGroup: ViewingGroupSelectionDTO[] = [
  {
    id: "1",
    name: SpecialViewingGroup.AllSuitabilityHubUsers,
    order: 0,
    isSelected: true,
  },
];

export const MAX_MONTH_OFFSET = 3;
export const DocumentFileNameDateFormat = "yyyyMMdd";
export const TIMESTAMP_LENGTH = 13;
