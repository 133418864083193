import IMAPLogo from "@assets/images/logo/imap-logo.png";
import { SHImage, SHStack } from "@components/design-systems";
import { DELAY_TIME } from "@constants";
import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { IconMessage } from "@pages/suppliers/constants";
import { SVGProps } from "react";

interface ImapIconTooltipProps {
  iconProps?: SVGProps<SVGSVGElement>;
  tooltipProps?: Omit<TooltipProps, "children">;
}
const ImapIconTooltip = ({ iconProps, tooltipProps }: ImapIconTooltipProps) => {
  return (
    <Tooltip
      {...tooltipProps}
      title={IconMessage.IMAP_FEATURE_AWARD}
      enterDelay={DELAY_TIME}
      enterNextDelay={DELAY_TIME}
      placement="top"
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
              {
                marginBottom: "5px",
              },
          },
        },
      }}
    >
      <SHStack sx={{mb: "1px"}}>
        <SHImage
          style={{
            pointerEvents: "none"
          }}
          src={IMAPLogo}
          height={iconProps?.height ?? "20px"}
          width={iconProps?.width ?? "20px"}
        />
      </SHStack>
    </Tooltip>
  );
};

export default ImapIconTooltip;
