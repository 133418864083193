import { SHButton, SHButtonProps } from "@components/design-systems";
import { PageRoutes } from "@constants";
import { useNotification } from "@hooks/useNotification";
import { useUserPermissions } from "@hooks/userUserPermission";
import { useTheme } from "@mui/material";
import { toString } from "lodash";
import { useCallback } from "react";
import { NavigateOptions, To, useNavigate } from "react-router-dom";
import Balancer from "react-wrap-balancer";

export interface LinkToOptions {
  to: To;
  options?: NavigateOptions;
  delta?: number;
  isExternal?: boolean;
}

export interface NavButtonProps extends SHButtonProps {
  linkTo: LinkToOptions;
  isActive?: boolean;
}

export const NavButton = ({
  linkTo,
  isActive,
  ...buttonProps
}: NavButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const { palette } = useTheme();

  // #region IMAP Judges
  const { user } = useUserPermissions();
  const { notify } = useNotification();
  const { to } = linkTo;
  const isRestricted =
    [
      PageRoutes.newsAndUpdates,
      PageRoutes.platformAnalysis,
      PageRoutes.smartSearchDev,
      PageRoutes.smartSearchStaging,
      PageRoutes.smartSearchProd,
    ].includes(toString(to)) && user?.isRestrictedUser;
  // #endregion

  const handleOnClick = useCallback(() => {
    // #region IMAP Judges
    if (isRestricted) {
      notify("Your account cannot view this page", {
        variant: "error",
        close: true,
      });
      return;
    }
    // #endregion

    if (linkTo.isExternal) {
      window.open(toString(linkTo.to), "_blank");
      return;
    }

    if (typeof linkTo.delta === "number") {
      navigate(linkTo.delta);
    } else {
      navigate(linkTo.to, linkTo.options);
    }
  }, [
    isRestricted,
    linkTo.delta,
    linkTo.isExternal,
    linkTo.options,
    linkTo.to,
    navigate,
    notify,
  ]);

  return (
    <SHButton
      {...buttonProps}
      variant="text"
      color="inherit"
      onClick={handleOnClick}
      sx={{
        paddingX: "15px",
        minHeight: "42px",
        lineHeight: "16.8px",
        borderColor: isActive ? palette.primary.dark : "transparent",
        fontSize: { sm: "12px", md: "13px", lg: "14px" },
        fontWeight: isActive ? 600 : 500,
      }}
    >
      <Balancer>{buttonProps.children}</Balancer>
    </SHButton>
  );
};
