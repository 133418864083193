import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import {
  SHLabel,
  SHSelect,
  SHStack
} from "@components/design-systems";
import { useInputDotIndicator } from "@hooks/useInvestmentProduct";
import { FieldTypeId, HighlightType } from "@models/configuration";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { CustomLabel } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/custom-label";
import { SeriesPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series";
import { getFeatureDataPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series/utils";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { hexToRGBA } from "@utils";
import { debounce, isEmpty, isEqual, isNil } from "lodash";
import { useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

interface SeriesFeatureDropdownProps {
  feature: InvestmentProductConfigurationFeature;
  investmentProductSeriesData: InvestmentProductSeriesDTO;
  selectedIndex: [number, number];
  isDisabled?: boolean;
}

const SeriesFeatureDropdown = ({
  feature,
  investmentProductSeriesData,
  selectedIndex = [0, 0],
  isDisabled = false,
}: SeriesFeatureDropdownProps) => {
  const { control, setValue, getValues } = useFormContext<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>();
  const defaultValue = useMemo(
    () => feature.lovData?.find((opt) => opt.isDefault)?.id,
    [feature.lovData],
  );
  const {
    investmentSeriesDataPath,
    sectionModifiedPath,
    featureModifiedPath,
    featureProductDataPath,
    featureProductHighlightTypePath,
    featureProductDataModifiedPath,
    featureProductDataStatusPath,
    featureProductDataPublishedPath,
    featureViewingGroupSelectionsPath,
    investmentSeriesDataHighlightTypePath,
    sectionPath,
    investmentSeriesDataModifiedPath,
  } = getFeatureDataPath(selectedIndex, investmentProductSeriesData, feature);
  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductHighlightTypePath as SeriesPath,
      dataStatus: featureProductDataStatusPath as SeriesPath,
    },
  });
  const watchedValue = useWatch({
    control,
    name: [featureProductDataPath, featureProductDataPublishedPath],
  });
  const productDataValue = useMemo(
    () => getValues(featureProductDataPath),
    // eslint-disable-next-line
    [watchedValue, selectedIndex],
  );

  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    const isModified = !isEqual(feature.viewingGroupSelections, newValue);

    setValue(
      `${investmentSeriesDataPath}.isModified` as SeriesPath,
      isModified,
    );
    setValue(sectionModifiedPath, isModified);
    setValue(featureModifiedPath, isModified);
    setValue(featureProductDataModifiedPath, isModified);
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );
  const handleNoRoleValueChange = (
    dropdownValue = "",
    isModifiedDropdownValue: boolean,
  ) => {
    const features =
      investmentProductSeriesData.sections?.[selectedIndex[1]].features;
    const isTriggerHandleNoRole =
      dropdownValue === "NoRole" || productDataValue === "NoRole";

    isTriggerHandleNoRole &&
      features?.forEach((feature, featureIndex) => {
        const featurePath =
          `${sectionPath}.features.${featureIndex}` as SeriesPath;
        const featureModifiedPath = `${featurePath}.isModified` as SeriesPath;
        const featureDataStatusPath = `${featurePath}.dataStatus` as SeriesPath;
        const featureHighlightTypePath =
          `${featurePath}.highlightType` as SeriesPath;
        const featureProductDataModifiedPath =
          `${featurePath}.productData.isModified` as SeriesPath;
        const featureProductDataStatusPath =
          `${featurePath}.productData.dataStatus` as SeriesPath;
        const featureProductHighlightTypePath =
          `${featurePath}.productData.highlightType` as SeriesPath;
        const featureProductDataValuePath =
          `${featurePath}.productData.value` as SeriesPath;
        const featureProductDataPublishedValuePath =
          `${featurePath}.productData.publishedValue` as SeriesPath;
        const featureProductValue =
          getValues(featureProductDataValuePath) ?? "";
        const featureProductPublishedValue =
          getValues(featureProductDataPublishedValuePath) ?? "";

        if (feature.fieldType?.id !== FieldTypeId.Dropdown) {
          if (dropdownValue === "NoRole") {
            setValue(featureModifiedPath, isModifiedDropdownValue);
            setValue(featureHighlightTypePath, null);
            setValue(featureDataStatusPath, null);
            setValue(featureProductDataModifiedPath, isModifiedDropdownValue);
            setValue(featureProductHighlightTypePath, null);
            setValue(featureProductDataStatusPath, null);
          } else {
            const isModified = !isEqual(
              featureProductValue,
              feature.productData?.value,
            );
            const dataStatus =
              isNil(featureProductValue) || isEmpty(featureProductValue)
                ? InvestmentDataStatus.MissingData
                : InvestmentDataStatus.Filled;
            const highlightType = !isEqual(
              featureProductValue,
              featureProductPublishedValue,
            )
              ? HighlightType.Edited
              : null;

            setValue(featureModifiedPath, isModified);
            setValue(featureHighlightTypePath, highlightType);
            setValue(featureProductDataModifiedPath, isModified);
            setValue(featureProductHighlightTypePath, highlightType);

            if (feature.fieldType?.id !== FieldTypeId.Slideshow) {
              setValue(featureDataStatusPath, dataStatus);
              setValue(featureProductDataStatusPath, dataStatus);
            }
          }
        }
      });
  };
  const handleOnChange = (newValue?: string) => {
    const publishedData = getValues(featureProductDataPublishedPath) ?? "";
    const highlightType = !isEqual(newValue, publishedData)
      ? HighlightType.Edited
      : null;
    const dataStatus =
      isNil(newValue) || isEmpty(newValue)
        ? InvestmentDataStatus.MissingData
        : InvestmentDataStatus.Filled;
    const isModified = !isEqual(newValue, feature.productData?.value);

    setValue(featureProductDataPath, newValue);
    setValue(sectionModifiedPath, true);
    setValue(investmentSeriesDataModifiedPath, isModified);
    setValue(investmentSeriesDataHighlightTypePath, highlightType);
    setValue(featureProductHighlightTypePath, highlightType);
    setValue(featureProductDataStatusPath, dataStatus);
    setValue(featureProductDataModifiedPath, isModified);
    setValue(featureModifiedPath, isModified);
    handleNoRoleValueChange(newValue, isModified);
  };

  return (
    <SHStack
      direction={"row"}
      alignItems="center"
      justifyContent={"flex-start"}
      gap={2.5}
      mb={1}
    >
      <SHStack width={"38%"}>
        <Controller
          name={featureProductDataPath as SeriesPath}
          control={control}
          render={({ field: { ref, ...other } }) => {
            return (
              <SHStack direction="column" gap={1}>
                <SHSelect
                  label={
                    <SHLabel
                      label={
                        <CustomLabel
                          featureData={feature}
                          dotProps={{
                            orangeDot: isShowOrangeDot,
                            blueDot: isShowBlueDot,
                          }}
                        />
                      }
                    />
                  }
                  {...other}
                  dataItemKey="id"
                  displayField="name"
                  placeholder="Select"
                  data={feature.lovData ?? []}
                  value={productDataValue ?? defaultValue}
                  allowClear={!defaultValue && !!other.value}
                  onClear={(value) => {
                    other.onChange(value);
                    handleOnChange(value);
                  }}
                  disabled={isDisabled}
                  sx={{
                    "& .sh-select-placeholder": {
                      color: `${hexToRGBA("#3D98FF", 0.6)} !important`,
                    },
                  }}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    other.onChange(newValue);
                    handleOnChange(newValue);
                  }}
                />
              </SHStack>
            );
          }}
        />
      </SHStack>
      {feature.allowedVisibilitySetting && (
        <SHStack width={"30%"}>
          <Controller
            name={featureViewingGroupSelectionsPath as SeriesPath}
            control={control}
            render={({ field: { ref, ...other } }) => (
              <SelectViewingGroups
                textFieldProps={{
                  label: `Visible to`,
                }}
                onChange={(value) => {
                  other.onChange(value);
                  debounceVisibilityChange(value);
                }}
                value={
                  (getValues(
                    featureViewingGroupSelectionsPath as SeriesPath,
                  ) as ViewingGroupSelectionDTO[] | null) || []
                }
                viewingGroupData={feature.viewingGroupSelections ?? []}
                disabled={isDisabled}
              />
            )}
          />
        </SHStack>
      )}
    </SHStack>
  );
};

export default SeriesFeatureDropdown;
