import { InformationButton } from "@components/buttons/information";
import {
  SHBooleanField,
  SHDivider,
  SHImage,
  SHNumericField,
  SHRichTextEditor,
  SHStack,
  SHTextField,
} from "@components/design-systems";
import UnsavedDialog from "@components/dialogs/unsaved";
import { useIsNew } from "@hooks/useIsNew";
import { useUserPermissions } from "@hooks/userUserPermission";
import { FieldGroup } from "@layouts/form/field-group";
import { ProductCollapseMenu } from "@layouts/products/collapse-menu";
import { ProductFeaturesLayout } from "@layouts/products/features";
import {
  ConfigurationFeature,
  ConfigurationGroup,
  ConfigurationSection,
  FieldTypeId,
  HighlightType,
  SectionType,
} from "@models/configuration";
import { ImageField } from "@models/core";
import { ProductListDTO } from "@models/product/entities/product";
import { ProductDataStatus } from "@models/product/enums/productDataStatus";
import { useTheme } from "@mui/material";
import { GalleryField } from "@pages/suppliers/_id/_products/_id/edit/components/gallery-field";
import { ESGTabSkeleton } from "@pages/suppliers/_id/_products/_id/edit/components/skeletons/ESGTabSkeleton";
import { InformationTabSkeleton } from "@pages/suppliers/_id/_products/_id/edit/components/skeletons/InformationTabSkeleton";
import { OverviewTabSkeleton } from "@pages/suppliers/_id/_products/_id/edit/components/skeletons/OverviewTabSkeleton";
import {
  checkHasBeenModified,
  toNativeValue,
  toStringValue,
} from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { ProductTopBarContainer } from "@pages/suppliers/_id/_products/_id/edit/components/top-bar-container";
import { UploadField } from "@pages/suppliers/_id/_products/_id/edit/components/upload-field";
import SubSectionTitle from "@pages/suppliers/_id/_products/_id/view/components/SubSectionTitle";
import { updateActiveGroup, updateProductName } from "@redux/slices/product";
import { RootState, useAppDispatch } from "@redux/store";
import { firstSectionMapping } from "@services/product/mock";
import { isEmpty, some } from "lodash";
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ViewFeatureSkeleton } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-feature/components/view-feature-skeleton";

export interface ProductTabFormRef {
  getValue: () => any | undefined;
  resetForm: (data?: any) => void;
  isFeesTab?: boolean;
}

export interface ProductTabFormProps {
  group: ConfigurationGroup;
  onSubmit: (data: ProductListDTO) => void;
  isSubmitting?: boolean;
}

export const ProductTabForm = React.forwardRef<
  ProductTabFormRef,
  ProductTabFormProps
>(({ group, onSubmit, isSubmitting: isSubmittingProp }, ref) => {
  const {
    control,
    watch,
    reset,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {} as ProductListDTO,
  });
  const { palette } = useTheme();

  const { productId } = useParams<{ productId: string }>();
  const { supplierId } = useParams<{ supplierId: string }>();

  const { dirtyFields } = useFormState({ control });
  const isDirty = !isEmpty(dirtyFields);
  const [selectedIndex, setSelectedIndex] = useState<[number, number]>([0, 0]);
  const dispatch = useAppDispatch();
  const isNew = useIsNew();
  const {
    productUI: { showViewReleaseLog, isLoadingProductData },
    productBannerInfo,
  } = useSelector((state: RootState) => state.product);
  const { isAdminGroup } = useUserPermissions();
  const changeProductNameTimeOut = useRef<NodeJS.Timeout | null>(null);
  const changeOneFieldTimeOut = useRef<NodeJS.Timeout | null>(null);

  const isDraft = useMemo(() => {
    return some(productBannerInfo, ["status", "Draft"]);
  }, [productBannerInfo]);
  const isPendingApproval = useMemo(() => {
    return some(productBannerInfo, ["status", "Pending approval"]);
  }, [productBannerInfo]);

  const isDisabled = useMemo(() => {
    return isSubmitting || showViewReleaseLog || isPendingApproval;
  }, [isSubmitting, showViewReleaseLog, isPendingApproval]);

  const isDisabledSaveDraftButton = useMemo(() => {
    return !isDirty || !isValid || isSubmitting || isSubmittingProp;
  }, [isDirty, isValid, isSubmitting, isSubmittingProp]);
  const isDisabledSubmitForReviewButton = useMemo(() => {
    return !isDraft;
  }, [isDraft]);

  useEffect(() => {
    return () => {
      if (changeProductNameTimeOut.current)
        clearTimeout(changeProductNameTimeOut.current);
      if (changeOneFieldTimeOut.current)
        clearTimeout(changeOneFieldTimeOut.current);
    };
    // eslint-disable-next-line
  }, []);

  useImperativeHandle(ref, () => ({
    getValue() {
      if (!isDirty) return;
      return getValues();
    },
    resetForm(data) {
      reset(data ?? {});
    },
  }));

  const handleOnChangeProductName = (value: string) => {
    if (changeProductNameTimeOut.current)
      clearTimeout(changeProductNameTimeOut.current);
    changeProductNameTimeOut.current = setTimeout(() => {
      dispatch(updateProductName(value));
    }, 200);
  };

  const handleOnEditOneField = ({
    sectionIndex,
    subSectionIndex,
    featureIndex,
  }: {
    sectionIndex: number;
    subSectionIndex?: number;
    featureIndex: number;
  }) => {
    changeOneFieldTimeOut.current = setTimeout(() => {
      const group0 = getValues(`groups.0`);
      //Set isModified
      setValue(`groups.0.isModified`, true);
      setValue(`groups.0.sections.${sectionIndex}.isModified`, true);
      if (subSectionIndex === undefined) {
        setValue(
          `groups.0.sections.${sectionIndex}.features.${featureIndex}.isModified`,
          true,
        );
        setValue(
          `groups.0.sections.${sectionIndex}.features.${featureIndex}.productData.isModified`,
          true,
        );
      } else {
        setValue(
          `groups.0.sections.${sectionIndex}.subSections.${subSectionIndex}.isModified` as any,
          true,
        );
        setValue(
          `groups.0.sections.${sectionIndex}.subSections.${subSectionIndex}.features.${featureIndex}.isModified` as any,
          true,
        );
        setValue(
          `groups.0.sections.${sectionIndex}.subSections.${subSectionIndex}.features.${featureIndex}.productData.isModified` as any,
          true,
        );
      }
      //End Set isModified
      let groupDataStatus = ProductDataStatus.Filled;
      let groupHighlight = HighlightType.Added;

      group0.sections?.forEach((section, sectionI) => {
        let sectionDataStatus = section.dataStatus;
        let sectionHighlight = section.highlightType;

        if (sectionIndex === sectionI) {
          sectionDataStatus = ProductDataStatus.Filled;
          sectionHighlight = HighlightType.Added;

          if (subSectionIndex === undefined) {
            section.features?.forEach((feature, featureI) => {
              let featureDataStatus = feature.dataStatus;
              let featureHighlight = feature.highlightType;

              if (featureIndex === featureI) {
                featureDataStatus = ProductDataStatus.Filled;
                featureHighlight = HighlightType.Added;
                if (
                  isEmpty(feature?.productData?.value) &&
                  feature.fieldType?.id !== FieldTypeId.Slideshow
                )
                  featureDataStatus = ProductDataStatus.MissingData;

                if (!isNew) {
                  //Change highlightType
                  featureHighlight = checkHasBeenModified(feature?.productData)
                    ? HighlightType.Edited
                    : HighlightType.Added;
                }
              }

              if (featureDataStatus !== ProductDataStatus.Filled) {
                sectionDataStatus = ProductDataStatus.MissingData;
              }
              if (featureHighlight === HighlightType.Edited) {
                sectionHighlight = HighlightType.Edited;
              }
              setValue(
                `groups.0.sections.${sectionI}.features.${featureI}.dataStatus`,
                featureDataStatus,
              );
              setValue(
                `groups.0.sections.${sectionI}.features.${featureI}.productData.dataStatus`,
                featureDataStatus,
              );

              if (!isNew) {
                //set highlightType
                setValue(
                  `groups.0.sections.${sectionI}.features.${featureI}.highlightType`,
                  featureHighlight,
                );
                setValue(
                  `groups.0.sections.${sectionI}.features.${featureI}.productData.highlightType`,
                  featureHighlight,
                );
              }
            });
          } else {
            section.subSections?.forEach((subSection, subSectionI) => {
              let subSectionDataStatus = subSection.dataStatus;
              let subSectionHighlight = subSection.highlightType;

              if (subSectionIndex === subSectionI) {
                subSectionDataStatus = ProductDataStatus.Filled;
                subSectionHighlight = HighlightType.Added;

                subSection.features?.forEach(
                  (feature: ConfigurationFeature, featureI: number) => {
                    let featureDataStatus = feature.dataStatus;
                    let featureHighlight = feature.highlightType;

                    if (featureIndex === featureI) {
                      featureDataStatus = ProductDataStatus.Filled;
                      featureHighlight = HighlightType.Added;

                      if (
                        isEmpty(feature?.productData?.value) &&
                        feature.fieldType?.id !== FieldTypeId.Slideshow
                      )
                        featureDataStatus = ProductDataStatus.MissingData;

                      if (!isNew) {
                        //Change highlightType
                        featureHighlight = checkHasBeenModified(
                          feature?.productData,
                        )
                          ? HighlightType.Edited
                          : HighlightType.Added;
                      }
                    }
                    if (featureDataStatus !== ProductDataStatus.Filled) {
                      subSectionDataStatus = ProductDataStatus.MissingData;
                    }
                    if (featureHighlight === HighlightType.Edited) {
                      subSectionHighlight = HighlightType.Edited;
                    }
                    setValue(
                      `groups.0.sections.${sectionI}.subSections.${subSectionI}.features.${featureI}.dataStatus` as any,
                      featureDataStatus,
                    );
                    setValue(
                      `groups.0.sections.${sectionI}.subSections.${subSectionI}.features.${featureI}.productData.dataStatus` as any,
                      featureDataStatus,
                    );
                    if (!isNew) {
                      //set highlightType
                      setValue(
                        `groups.0.sections.${sectionI}.subSections.${subSectionI}.features.${featureI}.highlightType` as any,
                        featureHighlight,
                      );
                      setValue(
                        `groups.0.sections.${sectionI}.subSections.${subSectionI}.features.${featureI}.productData.highlightType` as any,
                        featureHighlight,
                      );
                    }
                  },
                );
              }

              if (subSectionDataStatus !== ProductDataStatus.Filled) {
                sectionDataStatus = ProductDataStatus.MissingData;
              }
              if (subSectionHighlight === HighlightType.Edited) {
                sectionHighlight = HighlightType.Edited;
              }
              setValue(
                `groups.0.sections.${sectionI}.subSections.${subSectionI}.dataStatus` as any,
                subSectionDataStatus,
              );
              if (!isNew) {
                //set highlightType
                setValue(
                  `groups.0.sections.${sectionI}.subSections.${subSectionI}.highlightType` as any,
                  subSectionHighlight,
                );
              }
            });
          }
        }

        if (sectionDataStatus !== ProductDataStatus.Filled) {
          groupDataStatus = ProductDataStatus.MissingData;
        }
        if (sectionHighlight === HighlightType.Edited) {
          groupHighlight = HighlightType.Edited;
        }

        setValue(`groups.0.sections.${sectionI}.dataStatus`, sectionDataStatus);
        if (!isNew) {
          //set highlightType
          setValue(
            `groups.0.sections.${sectionI}.highlightType`,
            sectionHighlight,
          );
        }
      });
      setValue(`groups.0.dataStatus`, groupDataStatus);
      if (!isNew) {
        //set highlightType
        setValue(`groups.0.highlightType`, groupHighlight);
      }
      dispatch(
        updateActiveGroup({
          dataStatus: groupDataStatus,
          highlightType: !isNew ? groupHighlight : undefined,
        } as ConfigurationGroup),
      );
    }, 100);
  };

  const renderDynamicField = (
    feature: ConfigurationFeature,
    sectionIndex: number,
    featureIndex: number,
    isProductNameField?: boolean,
  ) => {
    const productDataField = `groups.0.sections.${sectionIndex}.features.${featureIndex}.productData`;
    const fieldValueName = `${productDataField}.value`;
    const fieldGoodScoreName = `${productDataField}.isPositiveScore`;
    const placeholder = "Please enter detail";
    const error =
      errors.groups?.[0]?.sections?.[sectionIndex]?.features?.[featureIndex]
        ?.productData?.value;

    const postfixLabel =
      feature.description !== undefined && !isEmpty(feature.description) ? (
        <InformationButton content={feature.description} />
      ) : undefined;

    const dotGroupProps = {
      greenDot: feature.isBusinessMetric,
      redDot: feature.highlightType === HighlightType.Edited,
      blueDot: feature.dataStatus === ProductDataStatus.MissingData,
    };

    const renderGoodScore = () => {
      if (
        !isAdminGroup ||
        !feature.isBusinessMetric ||
        !feature.isPositiveScore
      )
        return null;
      return (
        <Controller
          name={fieldGoodScoreName as any}
          control={control}
          render={({ field: { ref, ...other } }) => (
            <SHBooleanField
              {...other}
              isSwitch
              startAdornment="Good score?"
              isShowClearButton={false}
              disabled={isDisabled}
              onChange={(value) => {
                other.onChange(value);
                handleOnEditOneField({ sectionIndex, featureIndex });
              }}
            />
          )}
        />
      );
    };

    const renderControl = () => {
      switch (feature.fieldType?.id) {
        case FieldTypeId.Number:
          return (
            <Controller
              name={fieldValueName as any}
              control={control}
              render={({ field: { ref, ...other } }) => (
                <SHNumericField
                  {...other}
                  numericFormatProps={{
                    thousandSeparator: true,
                  }}
                  value={toNativeValue(other.value, FieldTypeId.Number)}
                  onChange={(event, value?: number | null) => {
                    other.onChange(toStringValue(value, FieldTypeId.Number));
                    handleOnEditOneField({ sectionIndex, featureIndex });
                  }}
                  fullWidth
                  label={feature.name}
                  placeholder={placeholder}
                  error={!!error}
                  helperText={error?.message}
                  postfixLabel={postfixLabel}
                  dotGroupProps={dotGroupProps}
                  disabled={isDisabled}
                />
              )}
            />
          );
        case FieldTypeId.NumberMillion:
          return (
            <Controller
              name={fieldValueName as any}
              control={control}
              render={({ field: { ref, ...other } }) => (
                <SHNumericField
                  {...other}
                  onChange={(event) => {
                    other.onChange(event.target.value);
                    handleOnEditOneField({ sectionIndex, featureIndex });
                  }}
                  numericFormatProps={{
                    thousandSeparator: true,
                    prefix: "$",
                    suffix: "m",
                  }}
                  fullWidth
                  label={feature.name}
                  placeholder={placeholder}
                  error={!!error}
                  helperText={error?.message}
                  postfixLabel={postfixLabel}
                  dotGroupProps={dotGroupProps}
                  disabled={isDisabled}
                />
              )}
            />
          );
        case FieldTypeId.TextShort:
          return (
            <Controller
              name={fieldValueName as any}
              control={control}
              render={({ field: { ref, ...other } }) => (
                <SHTextField
                  {...other}
                  value={other.value ?? ""}
                  fullWidth
                  label={feature.name}
                  placeholder={placeholder}
                  onChange={(event) => {
                    other.onChange(event.target.value);
                    if (isProductNameField)
                      handleOnChangeProductName(event.target.value);

                    handleOnEditOneField({ sectionIndex, featureIndex });
                  }}
                  error={!!error}
                  maxLength={100}
                  hasCounter
                  helperText={error?.message}
                  postfixLabel={postfixLabel}
                  dotGroupProps={dotGroupProps}
                  disabled={isDisabled}
                />
              )}
            />
          );
        case FieldTypeId.TextLong:
          return (
            <Controller
              name={fieldValueName as any}
              control={control}
              render={({ field: { ref, value, onChange } }) => (
                <SHRichTextEditor
                  value={value}
                  label={feature.name}
                  postfixLabel={postfixLabel}
                  onChange={(newValue) => {
                    if (newValue !== (getValues(fieldValueName as any) || "")) {
                      onChange(newValue);
                      handleOnEditOneField({ sectionIndex, featureIndex });
                    }
                  }}
                  maxLength={3000}
                  height={165}
                  dotGroupProps={dotGroupProps}
                  disabled={isDisabled}
                />
              )}
            />
          );
        case FieldTypeId.YesNo:
          return (
            <Controller
              name={fieldValueName as any}
              control={control}
              render={({ field: { ref, ...other } }) => (
                <SHBooleanField
                  {...other}
                  value={toNativeValue(other.value, FieldTypeId.YesNo)}
                  onChange={(event, value?: boolean | null) => {
                    other.onChange(toStringValue(value, FieldTypeId.YesNo));
                    handleOnEditOneField({ sectionIndex, featureIndex });
                  }}
                  label={feature.name}
                  postfixLabel={postfixLabel}
                  dotGroupProps={dotGroupProps}
                  disabled={isDisabled}
                />
              )}
            />
          );
        case FieldTypeId.Image:
          return (
            <Controller
              name={fieldValueName as any}
              control={control}
              render={({ field: { ref, ...other } }) => {
                const imageField = toNativeValue(
                  other.value,
                  FieldTypeId.Image,
                );
                return (
                  <UploadField
                    label={feature.name}
                    postfixLabel={postfixLabel}
                    dotGroupProps={dotGroupProps}
                    showImage={!!imageField}
                    isEditMode
                    value={imageField}
                    onChange={(value: ImageField | null) => {
                      other.onChange(toStringValue(value, FieldTypeId.Image));
                      handleOnEditOneField({ sectionIndex, featureIndex });
                    }}
                    disabled={isDisabled}
                  />
                );
              }}
            />
          );
        default:
          return null;
      }
    };

    return (
      <SHStack
        key={feature.id}
        spacing={1}
        sx={{
          width:
            feature.fieldType?.id === FieldTypeId.Image
              ? undefined
              : { xs: "100%", md: 520 },
        }}
      >
        {renderControl()}
        {renderGoodScore()}
      </SHStack>
    );
  };

  const renderDynamicFieldFeature = (
    feature: ConfigurationFeature,
    sectionIndex: number,
    featureIndex: number,
    subSectionIndex?: number,
    sectionName?: string,
  ) => {
    const productDataField = `groups.0.sections.${sectionIndex}.subSections.${subSectionIndex}.features.${featureIndex}.productData`;
    const fieldValueName = `${productDataField}.value`;
    const fieldAdditionalValueName = `${productDataField}.additionalValue`;
    const fieldGoodScoreName = `${productDataField}.isPositiveScore`;
    if (feature.fieldType?.id === FieldTypeId.Slideshow)
      return (
        <GalleryField
          dotGroupProps={{
            redDot: feature.highlightType === HighlightType.Edited,
            blueDot: feature.dataStatus === ProductDataStatus.MissingData,
            greenDot: feature.isBusinessMetric,
          }}
          value={
            toNativeValue(
              watch(fieldValueName as any),
              FieldTypeId.Slideshow,
            ) || []
          }
          galleryBoxTitle={sectionName}
          onChange={(value) => {
            setValue(
              fieldValueName as any,
              !value.length ? "" : toStringValue(value, FieldTypeId.Slideshow),
              {
                shouldDirty: true,
              },
            );
            handleOnEditOneField({
              sectionIndex,
              subSectionIndex,
              featureIndex,
            });
          }}
          key={feature.id}
          title={feature.name}
          subTitle={feature.description}
          subTitleColor={palette.text.fourth}
          disabled={isDisabled}
        />
      );

    return (
      <FieldGroup
        key={feature.id}
        title={feature.name}
        subTitle={feature.description}
        subTitleColor={palette.text.fourth}
        dotGroupProps={{
          redDot: feature.highlightType === HighlightType.Edited,
          blueDot: feature.dataStatus === ProductDataStatus.MissingData,
          greenDot: feature.isBusinessMetric,
        }}
      >
        <SHStack
          spacing={2}
          direction="column"
          sx={{
            width: { xs: "100%", md: 520 },
          }}
        >
          <Controller
            name={fieldValueName as any}
            control={control}
            render={({ field: { ref, ...other } }) => (
              <SHBooleanField
                {...other}
                value={toNativeValue(other.value, FieldTypeId.YesNo)}
                onChange={(event, value?: boolean | null) => {
                  other.onChange(toStringValue(value, FieldTypeId.YesNo));
                  handleOnEditOneField({
                    sectionIndex,
                    subSectionIndex,
                    featureIndex,
                  });
                }}
                label={"Does this feature exist on the platform?"}
                disabled={isDisabled}
              />
            )}
          />
          {watch(fieldValueName as any) === "true" && (
            <Controller
              name={fieldAdditionalValueName as any}
              control={control}
              render={({ field: { ref, ...other } }) => (
                <SHRichTextEditor
                  {...other}
                  label={"Enter more detail about this feature"}
                  maxLength={3000}
                  height={165}
                  onChange={(value) => {
                    other.onChange(value);
                    handleOnEditOneField({
                      sectionIndex,
                      subSectionIndex,
                      featureIndex,
                    });
                  }}
                  disabled={isDisabled}
                />
              )}
            />
          )}
          {feature.isBusinessMetric && isAdminGroup && feature.isPositiveScore && (
            <Controller
              name={fieldGoodScoreName as any}
              control={control}
              render={({ field: { ref, ...other } }) => (
                <SHBooleanField
                  {...other}
                  isSwitch
                  startAdornment="Good score?"
                  isShowClearButton={false}
                  disabled={isDisabled}
                  onChange={(value) => {
                    other.onChange(value);
                    handleOnEditOneField({
                      sectionIndex,
                      subSectionIndex,
                      featureIndex,
                    });
                  }}
                />
              )}
            />
          )}
        </SHStack>
      </FieldGroup>
    );
  };

  const renderFormBody = () => {
    const currentGroupData: ConfigurationGroup = watch("groups.0");
    if (isLoadingProductData || !currentGroupData) {
      if (group.name === "Overview") return <OverviewTabSkeleton />;
      if (group.name === "Information") return <InformationTabSkeleton />;
      if (group.name === "ESG") return <ESGTabSkeleton />;
      return <ViewFeatureSkeleton />;
    }

    if (group.sectionType === SectionType.Standard) {
      const firstStandardSection = firstSectionMapping[group.name];
      return (
        <SHStack direction={"column"} mt={3}>
          {firstStandardSection && (
            <FieldGroup
              firstLine
              title={firstStandardSection.title}
              subTitle={firstStandardSection.description}
              subTitleColor={palette.text.fourth}
            />
          )}
          {currentGroupData?.sections?.map((section, sectionIndex) => (
            <React.Fragment key={section.id}>
              <SHDivider />
              <FieldGroup
                icon={
                  section.iconUrl ? (
                    <SHImage
                      width={"20px"}
                      height={"20px"}
                      src={section.iconUrl}
                    />
                  ) : undefined
                }
                title={section.name}
                subTitle={section.description}
                subTitleColor={palette.text.fourth}
                dotGroupProps={{
                  redDot: section.highlightType === HighlightType.Edited,
                  blueDot: section.dataStatus === ProductDataStatus.MissingData,
                }}
              >
                <SHStack spacing={3} direction="column" alignItems={"flex-end"}>
                  {section.features?.map((feature, featureIndex) =>
                    renderDynamicField(
                      feature,
                      sectionIndex,
                      featureIndex,
                      section.name === "Banner" &&
                        feature.name === "Platform name",
                    ),
                  )}
                </SHStack>
              </FieldGroup>
            </React.Fragment>
          ))}
        </SHStack>
      );
    }
    const selectedSection = currentGroupData?.sections?.[selectedIndex[0]]
      ?.subSections?.[selectedIndex[1]] as ConfigurationSection;
    return (
      <ProductFeaturesLayout
        leftSide={
          <ProductCollapseMenu
            sections={currentGroupData?.sections}
            selectedIndex={selectedIndex}
            onChange={setSelectedIndex}
            dotGroupProps={{
              redDot: true,
              blueDot: true,
            }}
          />
        }
        rightSide={
          <SHStack>
            <SubSectionTitle subSection={selectedSection} />
            {selectedSection?.features &&
              selectedSection.features.length > 1 && <SHDivider />}
            {selectedSection?.features?.map((feature, featureIndex) =>
              renderDynamicFieldFeature(
                feature,
                selectedIndex[0],
                featureIndex,
                selectedIndex[1],
                selectedSection?.name,
              ),
            )}
          </SHStack>
        }
        isLoading={isLoadingProductData}
      />
    );
  };

  return (
    <form>
      <ProductTopBarContainer
        isNew={isNew}
        isSubmitting={isSubmitting}
        isDisabledSaveDraft={isDisabledSaveDraftButton}
        isDisabledSubmitForReview={isDisabledSubmitForReviewButton}
        productId={productId}
        supplierId={supplierId}
        data={getValues()}
        onSaveDraft={handleSubmit(onSubmit)}
        resetForm={(data) => reset(data as ProductListDTO)}
      />
      {renderFormBody()}
      <UnsavedDialog isDirty={isDirty && !isSubmitting && !isSubmittingProp} />
    </form>
  );
});
