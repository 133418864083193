import { useAuth0 } from "@auth0/auth0-react";
import { PageRoutes } from "@constants/routes";
import { SocketClientEvent, SocketServerEvent } from "@constants/socket";
import { LocalStorage } from "@constants/storage";
import { useCurrentPath } from "@hooks/useCurrentPath";
import { HttpTransportType, HubConnectionState } from "@microsoft/signalr";
import { KickoutType, RefreshType } from "@models/core";
import {
  updateForceRefreshFirmStartTime,
  updateIsCheckingUserAccess,
  updateShowForceRefreshDialog,
  updateTriggerForceRefreshFirm,
} from "@redux/slices/global";
import {
  IPendingPostCount,
  pendingPostCountChange,
} from "@redux/slices/news-feed";
import { SignalRService } from "@services/signalr/signalRService";
import { generateUUID } from "@utils";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useEffectOnce } from "react-use";
import { useBroadcastChannel } from "./useBroadcastChannel";
import { IPendingDocumentCount, pendingDocumentCountChange } from "@redux/slices/document-library";

export function useAccessChecking() {
  const [browserId, setBrowserId] = useState<string>(
    localStorage.getItem(LocalStorage.browserId) || generateUUID(),
  );
  const dispatch = useDispatch();
  const { logoutBroadcast } = useBroadcastChannel();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const currentPath = useCurrentPath();

  const registerSocket = () => {
    const connection = SignalRService.getHubConnection();
    if (connection && connection.state === HubConnectionState.Disconnected) {
      connection.start();

      connection.on(SocketClientEvent.onConnected, (data: string) => {
        dispatch(updateIsCheckingUserAccess(true));
        connection.invoke(SocketServerEvent.onAccessChecking, browserId);
      });

      connection.on(
        SocketClientEvent.onCompleteAccessChecking,
        (data: { kickOut: boolean }) => {
          dispatch(updateIsCheckingUserAccess(false));
          console.log(data);
          if (data?.kickOut) {
            navigate(currentPath);
          }
        },
      );

      connection.on(SocketClientEvent.onMultiLogin, (data: any) => {
        console.log(data);
        navigate(`${PageRoutes.callback}?action=multi-login-new`);
      });

      connection.on(
        SocketClientEvent.onKickout,
        (data: { type: KickoutType }) => {
          console.log(data);
          localStorage.clear();
          logoutBroadcast(
            `${process.env.REACT_APP_AUTH0_REDIRECT_URL}${PageRoutes.callback}?action=kick-out&type=${data.type}`,
          );
        },
      );

      connection.on(
        SocketClientEvent.onRefreshUser,
        (data: { type: RefreshType }) => {
          console.log("Receive force refresh page action", data);
          if (data.type === RefreshType.UpdatingSubscription) {
            console.log("Updating firm");
            dispatch(updateShowForceRefreshDialog(true));
            dispatch(
              updateForceRefreshFirmStartTime(new Date().getTime() / 1000),
            );
          }
          if (data.type === RefreshType.CompletedUpdateSubscription) {
            console.log("Complete update");
            dispatch(updateTriggerForceRefreshFirm(true));
          }
        },
      );

      connection.on(SocketClientEvent.onError, (data: any) => {
        console.log(`Error: ${data}`);
      });

      connection.on(SocketClientEvent.reconnected, (data: any) => {});

      connection.on(SocketClientEvent.disconnected, (data: any) => {
        console.log("onDisconnected | socketId: " + data);
      });

      connection.on(
        SocketClientEvent.onPendingPostCountChangedEvent,
        (event: IPendingPostCount) => {
          dispatch(pendingPostCountChange(event));
        },
      );

      connection.on(
        SocketClientEvent.onPendingDocumentCountChangedEvent,
        (event: IPendingDocumentCount) => {
          dispatch(pendingDocumentCountChange(event));
        },
      );
    }
  };

  useEffectOnce(() => {
    if (!localStorage.getItem(LocalStorage.browserId)) {
      localStorage.setItem(LocalStorage.browserId, browserId);
    }
    setBrowserId(browserId);

    //initialize and register login channels
    SignalRService.initialize(
      {
        accessTokenFactory: async () =>
          await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          }),
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      },
      true,
    );
    registerSocket();
  });
}
