import { SHBox, SHStack, SHTypography } from "@components/design-systems";
import { FormHelperText, LinearProgress, Link, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

export interface SHUploadFileProps {
  height?: number | string;
  width?: number | string;
  dropzoneOptions?: DropzoneOptions;
  fileIcon?: React.ReactNode;
  isUploading?: boolean;
  fileSupportText?: string;
  error?: boolean;
  helperText?: string | null;
  buttonText?: string;
}

export const SHUploadFile = React.forwardRef(
  (
    {
      height = 120,
      width = 520,
      dropzoneOptions,
      fileIcon,
      isUploading,
      error,
      helperText,
      buttonText = "Click to replace",
      fileSupportText,
    }: SHUploadFileProps,
    ref,
  ): JSX.Element => {
    const { palette } = useTheme();
    const { getRootProps, getInputProps } = useDropzone({
      ...dropzoneOptions,
    });

    const allowedFiles: string = useMemo(() => {
      if (!dropzoneOptions?.accept) return "";
      const allowedExtensions: string[] = Object.values(
        dropzoneOptions.accept,
      ).flat();
      return allowedExtensions
        .map((item, index) => {
          if (index === allowedExtensions.length - 1) {
            return `or${item.replace(".", " ")}`;
          }
          return item.replace(".", "");
        })
        .toString()
        .replace(/,/g, ", ")
        .replace(", or", " or")
        .toUpperCase();
    }, [dropzoneOptions?.accept]);

    return (
      <SHStack>
        <SHBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          data-testid="sh-upload-file"
          sx={{
            width,
            height,
            margin: "center",
            borderWidth: "1px",
            borderStyle: "dashed",
            borderColor: error ? palette.error.light : palette.text.disabled,
          }}
          {...getRootProps()}
          ref={ref}
        >
          <input {...getInputProps()} />
          <SHStack alignItems="center" spacing={0.5} textAlign={"center"}>
            {fileIcon}
            <SHTypography variant="body2">
              <Link
                variant="body2"
                color={
                  dropzoneOptions?.disabled
                    ? palette.text.disabled
                    : palette.text.secondary
                }
                component="label"
                underline="none"
                sx={{
                  paddingRight: 0.5,
                  fontWeight: 600,
                  cursor: dropzoneOptions?.disabled ? "default" : "pointer",
                }}
              >
                {buttonText}
              </Link>
              or drag and drop
            </SHTypography>
            <SHTypography variant="body4" colorVariant="disabled">
              {fileSupportText ? fileSupportText : allowedFiles}
            </SHTypography>
            {dropzoneOptions?.maxSize && (
              <SHTypography variant="caption" colorVariant="disabled">
                Maximum file size {dropzoneOptions.maxSize / (1024 * 1024)} MB
              </SHTypography>
            )}
            {isUploading && (
              <LinearProgress color="secondary" sx={{ width: "100%" }} />
            )}
          </SHStack>
        </SHBox>
        {helperText && (
          <FormHelperText error={error}>{helperText}</FormHelperText>
        )}
      </SHStack>
    );
  },
);
