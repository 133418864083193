import SelectSupplier from "@components/auto-completes/supplier";
import { CloseButton } from "@components/buttons/close";
import {
  SHButton,
  SHDialog,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import CustomUnsavedDialog from "@components/dialogs/custom-unsaved";
import { email } from "@constants/email";
import { useViewingGroup } from "@hooks/useViewingGroup";
import {
  CreateViewingGroupDTO,
  UpdateViewingGroupDTO,
  ViewingGroupDTO,
} from "@models/viewing-groups/entities/viewingGroups";

import { DialogContent, DialogTitle, Link } from "@mui/material";
import { VIS_MAX_TITLE_LENGTH } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/constants";
import { RootState } from "@redux/store";
import { theme } from "@themes";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
interface ViewingGroupDialogProps {
  isOpen: boolean;
  dialogTitle: string;
  initialData: ViewingGroupDTO | null;
  onClose: () => void;
  buttonLabel?: string;
  onSubmit?: () => void;
  isSuperAdmin?: boolean;
  isNew?: boolean;
}
const SupportingText = () => {
  return (
    <SHTypography
      fontSize="13px"
      fontWeight="400"
      textAlign={"left"}
      marginY={1}
    >
      You can limit the visibility of specific product information to designated
      viewing groups. For example:
      <ul
        style={{
          marginTop: "5px",
          marginBottom: "5px",
        }}
      >
        <li
          style={{
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          A Private Label SMA can be restricted to particular licensees or
          platforms.
        </li>
        <li
          style={{
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          Responses to specific questions can be limited if they contain
          sensitive intellectual property.
        </li>
      </ul>
      To set up or manage your viewing groups, contact the SuitabilityHub team
      at{" "}
      <Link
        color={theme.palette.text.secondary}
        component="a"
        underline="none"
        sx={{
          cursor: "pointer",
        }}
        href={`mailto:${email.support}`}
      >
        {email.support}.
      </Link>{" "}
      Once a group is established, you’ll be able to restrict access to selected
      information accordingly.
    </SHTypography>
  );
};
const ViewingGroupDialog = ({
  isOpen,
  dialogTitle,
  initialData,
  onClose,
  onSubmit,
  isNew,
  isSuperAdmin,
  buttonLabel = "Save changes and publish viewing group",
}: ViewingGroupDialogProps) => {
  const { supplierLov } = useSelector((state: RootState) => state.viewingGroup);
  const { supplierId } = useParams();
  const { handleCreateViewingGroup, handleUpdateViewingGroup } =
    useViewingGroup();
  const [openUnsavedDialog, setOpenUnsavedDialog] = useState(false);

  const formMethod = useForm<ViewingGroupDTO>({
    mode: "onChange",
    defaultValues: {
      ...initialData,
    },
  });
  const {
    control,
    formState: { errors, isDirty, isValid, isSubmitting },
    handleSubmit,
    reset,
  } = formMethod;
  const onDiscard = () => {
    setOpenUnsavedDialog(false);
    onClose();
  };
  const onKeepEditing = () => {
    setOpenUnsavedDialog(false);
  };
  const handleOnSubmit = async (data: ViewingGroupDTO) => {
    if (isNew) {
      const createViewingGroupDto: CreateViewingGroupDTO = {
        supplierId: supplierId ?? "",
        name: data.name,
        selectedSupplierIds: data.selectedSuppliers?.map((s) => s.id),
      };
      await handleCreateViewingGroup(createViewingGroupDto, {
        onSuccess: () => {
          onSubmit?.();
          onClose();
        },
      });
    } else {
      const updateViewingGroupDto: UpdateViewingGroupDTO = {
        id: data.id,
        name: data.name,
        supplierId: data.supplierId,
        selectedSupplierIds: data.selectedSuppliers?.map((s) => s.id),
      };
      await handleUpdateViewingGroup(updateViewingGroupDto, {
        onSuccess: () => {
          onSubmit?.();
          onClose();
        },
      });
    }
  };
  useEffect(() => {
    reset({
      ...initialData,
    });
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <form>
      <SHDialog
        open={isOpen}
        maxWidth={false}
        sx={{
          "& .MuiPaper-root": {
            width: "642px",
            height: "auto",
            padding: { md: "20px 60px", lg: "30px 60px" },
            position: "relative",
          },
        }}
      >
        <DialogTitle
          borderBottom={`1px solid ${theme.palette.secondary[100]}`}
          padding={"0 !important"}
        >
          <SHTypography
            variant="h2"
            fontWeight={600}
            lineHeight={"100%"}
            sx={{
              paddingBottom: "10px",
            }}
          >
            {dialogTitle}
          </SHTypography>
          {!isSuperAdmin && <SupportingText />}
        </DialogTitle>
        <SHStack
          sx={{
            position: "absolute",
            top: "22px",
            right: "25px",
          }}
        >
          <CloseButton
            width="12px"
            height="12px"
            onClick={() => {
              if (isDirty) {
                setOpenUnsavedDialog(true);
                return;
              }
              onClose();
            }}
          />
        </SHStack>
        <DialogContent>
          <SHStack spacing={3}>
            <Controller
              control={control}
              name="name"
              rules={{
                required: "Viewing group name is required",
                maxLength: {
                  value: VIS_MAX_TITLE_LENGTH,
                  message: `Viewing group name must be less than ${VIS_MAX_TITLE_LENGTH} characters`,
                },
              }}
              render={({ field }) => (
                <SHTextField
                  disabled={!isSuperAdmin}
                  {...field}
                  required
                  fullWidth
                  label="Name"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : null}
                  placeholder="Enter viewing group name"
                />
              )}
            />
            <Controller
              rules={{
                required: "Suppliers must be selected",
              }}
              control={control}
              name="selectedSuppliers"
              render={({ field }) => (
                <SelectSupplier
                  disabled={!isSuperAdmin}
                  value={field.value}
                  multiple
                  supplierLov={supplierLov}
                  textFieldProps={{
                    required: true,
                    placeholder: "Search for suppliers",
                    label: "Suppliers",
                    error: !!errors.selectedSuppliers,
                    helperText: errors.selectedSuppliers?.message,
                  }}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                />
              )}
            />
          </SHStack>
          {isSuperAdmin && (
            <SHButton
              isLoading={isSubmitting}
              disabled={!isValid || !isDirty || isSubmitting}
              variant="contained"
              size="extraMedium"
              type="submit"
              onClick={handleSubmit(handleOnSubmit)}
              sx={{
                marginTop: { md: "10px", lg: "20px" },
                height: "50px",
                width: "410px",
              }}
            >
              {buttonLabel}
            </SHButton>
          )}
        </DialogContent>
      </SHDialog>
      <CustomUnsavedDialog
        open={openUnsavedDialog}
        onCancel={onKeepEditing}
        onConfirm={onDiscard}
      />
    </form>
  );
};

export default ViewingGroupDialog;
