import {
  fillBlue,
  fillLightBlue,
  fillYellow,
  fillLightYellow,
  exportDate,
  exportPercentage,
  exportCurrency,
  exportNoDecimalPercentage,
  exportNoDecimalCurrency,
} from "./config";
import { Range, utils, WorkSheet } from "xlsx-js-style";
import { drawBorders } from "@pages/managed-accounts/components/buttons/export-excel/utils";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import {
  SMAListExcelDTO,
  SMAListExcelSelectedInvestmentDTO,
} from "@models/managed-accounts/entities/smaList";
import { isNaN } from "lodash";
import { parse, isValid } from "date-fns";
import { ManagedAccountFieldType } from "@models/managed-accounts/enums/fieldType";
import { MAAnalysisTooltipDTO } from "@models/managed-accounts/entities/step/fee";

let rows: any[] = [];
let merges: Range[] = [];
let feeLength = 0;
let smaListExcelData = new SMAListExcelDTO();
let viewModeExcel = "";
let isShowCmFeeFooter = false;
let isShowWebVersionFooter = false;

function pushHeaderTitle(label: string) {
  rows.push(["", ""]);
  rows.push([
    "",
    {
      v: label,
      t: "s",
      s: {
        fill: fillBlue,
        font: {
          bold: true,
          sz: 14,
        },
      },
    },
    ...[...Array(feeLength)].map(() => ({
      v: "",
      t: "s",
      s: {
        fill: fillBlue,
      },
    })),
  ]);
}

function pushSectionTitle(str: string) {
  rows.push([
    "",
    {
      v: str,
      t: "s",
      s: {
        fill: fillBlue,
        font: {
          bold: true,
        },
      },
    },
    ...[...Array(feeLength)].map(() => ({
      v: "",
      t: "s",
      s: {
        fill: fillBlue,
      },
    })),
  ]);
}

function pushHeaderData() {
  rows.push([
    "",
    `For ${smaListExcelData.supplierOrAdviserName} internal use only. Not to be disseminated`,
  ]);
  rows.push(["", ""]);
  rows.push([
    "",
    {
      v: smaListExcelData.isSupplier ? "Owner:" : "Adviser:",
      t: "t",
      s: {
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
      },
    },
    {
      v: `${smaListExcelData.ownerName} of ${smaListExcelData.supplierOrAdviserName}`,
      t: "t",
      s: {
        alignment: {
          vertical: "center",
          horizontal: "left",
          wrapText: true,
        },
      },
    },
  ]);
  rows.push([
    "",
    "Data valid:",
    {
      v: smaListExcelData.dataValidDate,
      ...exportDate,
      s: {
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
      },
    },
  ]);
  rows.push([
    "",
    "Holding value:",
    {
      v: smaListExcelData.investmentSize,
      t: "n",
      z: getDecimalFormat(smaListExcelData.investmentSize.toString(), false),
      s: {
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
      },
    },
  ]);
  rows.push(["", ""]);
}

function pushModelData() {
  merges.push({
    s: {
      r: 9,
      c: 1,
    },
    e: {
      r: 10,
      c: 1,
    },
  });
  rows.push([
    "",
    {
      v: "Model",
      t: "s",
      s: {
        fill: fillLightBlue,
        font: {
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
      },
    },
    ...smaListExcelData.selectedInvestments.map(
      (investment: SMAListExcelSelectedInvestmentDTO) => {
        return {
          v: `${investment.managerName}`,
          t: "s",
          s: {
            fill: fillLightBlue,
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          },
        };
      },
    ),
  ]);
  rows.push([
    "",
    {
      v: "",
      t: "s",
      s: {
        fill: fillLightBlue,
      },
    },
    ...smaListExcelData.selectedInvestments.map(
      (investment: SMAListExcelSelectedInvestmentDTO) => {
        return {
          v: `${investment.name}`,
          t: "s",
          s: {
            fill: fillLightBlue,
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          },
        };
      },
    ),
  ]);
}

function pushPlatformData() {
  merges.push({
    s: {
      r: 11,
      c: 1,
    },
    e: {
      r: 12,
      c: 1,
    },
  });
  rows.push([
    "",
    {
      v: "Platform",
      t: "s",
      s: {
        fill: fillLightBlue,
        font: {
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
      },
    },
    ...smaListExcelData.selectedInvestments.map(
      (investment: SMAListExcelSelectedInvestmentDTO) => {
        return {
          v: `${investment.productName}`,
          t: "s",
          s: {
            fill: fillLightBlue,
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          },
        };
      },
    ),
  ]);
  rows.push([
    "",
    {
      v: "",
      t: "s",
      s: {
        fill: fillLightBlue,
      },
    },
    ...smaListExcelData.selectedInvestments.map(
      (investment: SMAListExcelSelectedInvestmentDTO) => {
        return {
          v: `${investment.subProductName}`,
          t: "s",
          s: {
            fill: fillLightBlue,
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "center",
              wrapText: true,
            },
          },
        };
      },
    ),
  ]);
}

function pushPlatformCodeData() {
  rows.push([
    "",
    {
      v: "Code",
      t: "s",
      s: {
        fill: fillLightBlue,
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
      },
    },
    ...smaListExcelData.selectedInvestments.map(
      (investment: SMAListExcelSelectedInvestmentDTO) => {
        return {
          v: investment.platformCode,
          t: "s",
          s: {
            fill: fillLightBlue,
            alignment: {
              vertical: "center",
              horizontal: "center",
            },
          },
        };
      },
    ),
  ]);
}

function pushSectionsData() {
  smaListExcelData.sections.forEach((section) => {
    const analysisData = section.analysisData;
    const subSections = section.subSections;
    const breakdowns = section.breakdowns;

    if (analysisData) {
      rows.push([
        "",
        {
          v: section.name,
          t: "s",
          s: {
            fill: fillYellow,
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "left",
            },
          },
        },
        ...analysisData?.map((analysis) => {
          return {
            ...getValueFormatType(
              analysis.value ?? "",
              analysis.valueInPercent ?? "",
              analysis.fieldTypeId ?? "",
              section.variableName,
              analysis.tooltip,
            ),
            s: {
              fill: fillYellow,
              font: {
                bold: true,
              },
              alignment: {
                vertical: "center",
                horizontal: "center",
              },
            },
          };
        }),
      ]);
    } else {
      rows.push([
        "",
        {
          v: section.name,
          t: "s",
          s: {
            fill: fillYellow,
            font: {
              bold: true,
            },
            alignment: {
              vertical: "center",
              horizontal: "left",
            },
          },
        },
        ...Array.from(
          Array(smaListExcelData.selectedInvestments.length).keys(),
        ).map(() => {
          return {
            v: "",
            t: "s",
            s: {
              fill: fillYellow,
            },
          };
        }),
      ]);
    }

    if (breakdowns) {
      breakdowns.forEach((breakdown) => {
        const breakdownAnalysisData = breakdown.analysisData || [];

        rows.push([
          "",
          {
            v: breakdown.name,
            t: "s",
            s: {
              alignment: {
                vertical: "center",
                horizontal: "left",
                wrapText: true,
              },
            },
          },
          ...breakdownAnalysisData.map((analysis) => {
            return {
              ...getValueFormatType(
                analysis.value ?? "",
                analysis.valueInPercent ?? "",
                analysis.fieldTypeId ?? "",
                breakdown.variableName,
                analysis.tooltip,
              ),
              s: {
                alignment: {
                  vertical: "center",
                  horizontal: "center",
                  wrapText: true,
                },
              },
            };
          }),
        ]);
      });
    }

    if (subSections) {
      subSections.forEach((subSection) => {
        const subSectionAnalysisData = subSection.analysisData ?? [];
        const subSectionFeeBreakdown = subSection.breakdowns ?? [];
        const renderAnalysisCells =
          subSectionAnalysisData.length === 0
            ? Array.from(
                Array(smaListExcelData.selectedInvestments.length).keys(),
              ).map(() => {
                return {
                  v: "",
                  t: "s",
                  s: {
                    fill: fillLightYellow,
                  },
                };
              })
            : subSectionAnalysisData.map((analysis) => {
                return {
                  ...getValueFormatType(
                    analysis.value ?? "",
                    analysis.valueInPercent ?? "",
                    analysis.fieldTypeId ?? "",
                    subSection.variableName,
                    analysis.tooltip,
                  ),
                  s: {
                    fill: fillLightYellow,
                    font: {
                      bold: true,
                    },
                    alignment: {
                      vertical: "center",
                      horizontal: "center",
                    },
                  },
                };
              });

        rows.push([
          "",
          {
            v: subSection.name,
            t: "s",
            s: {
              fill: fillLightYellow,
              font: {
                bold: true,
              },
              alignment: {
                vertical: "center",
                horizontal: "left",
              },
            },
          },
          ...renderAnalysisCells,
        ]);
        subSectionFeeBreakdown.forEach((feeBreakdown) => {
          const feeBreakdownAnalysisData = feeBreakdown.analysisData || [];

          rows.push([
            "",
            {
              v: feeBreakdown.name,
              t: "s",
              s: {
                alignment: {
                  vertical: "center",
                  horizontal: "left",
                  wrapText: true,
                },
              },
            },
            ...feeBreakdownAnalysisData.map((analysis) => {
              return {
                ...getValueFormatType(
                  analysis.value ?? "",
                  analysis.valueInPercent ?? "",
                  analysis.fieldTypeId ?? "",
                  feeBreakdown.variableName,
                  analysis.tooltip,
                ),
                s: {
                  alignment: {
                    vertical: "center",
                    horizontal: "center",
                  },
                },
              };
            }),
          ]);
        });
      });
    }
  });
}

function pushFooterData() {
  rows.push(["", ""]);

  if (isShowWebVersionFooter) {
    rows.push(["", "*See web version for detailed breakdown"]);
  }

  if (isShowCmFeeFooter) {
    if (smaListExcelData.selectedInvestments.length > 2) {
      merges.push({
        s: {
          r: rows.length,
          c: 1,
        },
        e: {
          r: rows.length,
          c: 3,
        },
      });
    }
    rows.push([
      "",
      {
        v: "**Cash fees not directly disclosed may have been incorporated within indirect or other management fees and costs",
        t: "t",
        s: {
          alignment: {
            vertical: "center",
            horizontal: "left",
            wrapText: !(smaListExcelData.selectedInvestments.length > 2),
          },
        },
      },
    ]);
  }

  merges.push({
    s: {
      r: rows.length,
      c: 1,
    },
    e: {
      r: rows.length,
      c: 2,
    },
  });
  rows.push([
    "",
    "SMA fee analysis disclaimer and assumptions apply. See web version for details",
  ]);
}

function getDecimalFormat(excelValue: string, isPercentage: boolean) {
  const excelValueFloor = Math.floor(+excelValue);
  const decimalPart = +excelValue - excelValueFloor;

  if (isPercentage) {
    return decimalPart === 0 ? exportNoDecimalPercentage.z : exportPercentage.z;
  }

  return decimalPart === 0 ? exportNoDecimalCurrency.z : exportCurrency.z;
}

function getValueFormatType(
  value: string,
  valueInPercent: string,
  fieldType: string,
  variableName = "",
  tooltips: MAAnalysisTooltipDTO | null,
) {
  const formatCellValue = {
    v: "",
    t: "s",
    z: "",
  };
  const alwaysShowPercentageColumns = [
    "Alloc_AU_cash",
    "Alloc_Int_listed",
    "M_turnover",
  ];

  const getNoDataValue = (
    value: string | null,
    hasTooltip: boolean = false,
  ) => {
    if (value === null || value === "") {
      return "No data";
    }

    if (hasTooltip) {
      isShowWebVersionFooter = true;
      return `${value}*`;
    } else {
      return value;
    }
  };

  switch (fieldType) {
    case ManagedAccountFieldType.Currency:
    case ManagedAccountFieldType.Percentage:
      const isPercentage =
        variableName === "Model_min_inv"
          ? false
          : alwaysShowPercentageColumns.includes(variableName)
          ? true
          : viewModeExcel === FeesDisplayStyle.Percentage;
      const valueStr = isPercentage ? valueInPercent : value;

      if (isNaN(parseFloat(valueStr))) {
        if (valueStr) {
          if (variableName === "CM_fee") {
            formatCellValue.v = `${valueStr}**`;
            isShowCmFeeFooter = true;
          } else {
            formatCellValue.v = valueStr;

            if (tooltips !== null) {
              formatCellValue.v = `${valueStr}*`;
              isShowWebVersionFooter = true;
            }
          }
        } else {
          formatCellValue.v = getNoDataValue(valueStr, tooltips !== null);
        }

        return formatCellValue;
      }

      if (tooltips !== null) {
        const valueNumber = parseFloat(valueStr);
        const minusSymbol = valueNumber < 0 ? "-" : "";

        formatCellValue.v = isPercentage
          ? `${valueNumber.toLocaleString("en-US")}%*`
          : `${minusSymbol}$${valueNumber.toLocaleString("en-US")}*`;
        isShowWebVersionFooter = true;
      } else {
        formatCellValue.v = isPercentage
          ? (+valueStr / 100).toString()
          : valueStr;
        formatCellValue.t = "n";
        formatCellValue.z = getDecimalFormat(valueStr, isPercentage);
      }

      return formatCellValue;
    case ManagedAccountFieldType.DateTime:
      const dateParsed = parse(value, "dd/MM/yy", new Date());
      const isValidDate = isValid(dateParsed);

      if (isValidDate) {
        if (tooltips !== null) {
          formatCellValue.v = `${value}*`;
          isShowWebVersionFooter = true;

          return formatCellValue;
        } else {
          return {
            v: dateParsed,
            ...exportDate,
          };
        }
      }

      formatCellValue.v = getNoDataValue(value, tooltips !== null);

      return formatCellValue;
    case ManagedAccountFieldType.YesNo:
    case ManagedAccountFieldType.YesNoText:
      const booleanValue = value !== null ? value.toLowerCase() : "";

      if (
        !(
          booleanValue === "true" ||
          booleanValue === "false" ||
          booleanValue === "no rebates"
        )
      ) {
        formatCellValue.v = getNoDataValue(value, tooltips !== null);
        return formatCellValue;
      }

      if (fieldType === ManagedAccountFieldType.YesNoText) {
        formatCellValue.v =
          booleanValue === "no rebates"
            ? "No rebates"
            : booleanValue === "true"
            ? "Net"
            : "Gross";
      } else {
        formatCellValue.v =
          booleanValue === "no rebates"
            ? "No rebates"
            : booleanValue === "true"
            ? "Yes"
            : "No";
      }

      if (tooltips !== null) {
        formatCellValue.v = formatCellValue.v + "*";
        isShowWebVersionFooter = true;
      }

      return formatCellValue;
    default:
      formatCellValue.v = getNoDataValue(value);

      return formatCellValue;
  }
}

export function getWorkSheet(
  data?: SMAListExcelDTO,
  viewMode: FeesDisplayStyle = FeesDisplayStyle.Percentage,
) {
  if (!data) return utils.aoa_to_sheet([]);

  smaListExcelData = data;
  viewModeExcel = viewMode;
  feeLength = smaListExcelData.selectedInvestments.length;
  pushHeaderTitle("SuitabilityHub SMA portfolio fee breakdown");
  pushHeaderData();
  pushSectionTitle("Fee estimates: Portfolio fees by platform");
  pushModelData();
  pushPlatformData();
  pushPlatformCodeData();
  pushSectionsData();
  pushFooterData();

  const worksheet: WorkSheet = utils.aoa_to_sheet(rows);
  const wscols = [{ wch: 2 }, { wch: 60 }, { wch: 18 }];

  Array.from(Array(feeLength).keys()).forEach(() => wscols?.push({ wch: 18 }));
  worksheet["!cols"] = wscols;
  worksheet["!merges"] = merges;
  drawBorders(worksheet);
  rows = [];
  merges = [];
  isShowCmFeeFooter = false;
  isShowWebVersionFooter = false;

  return worksheet;
}
