import { UserType } from "@models/auth";
import {
  LicenseeDiscountDTO,
  ProductDTO,
} from "@models/supplier/entities/supplierUser";
import { UserMetaData } from "@models/users/entities/user";
import { SupplierStatus } from "../enums/status";
import { SupplierLicenseDTO } from "./licenses";
import { PracticeDTO } from "./practices";

export interface SupplierAggregatedDTO {
  id: string;
  companyName: string;
  companyLogo: string;
  logoS3Key?: string;
  website: string;
  contractName?: string;
  phoneNumber?: string;
  email?: string;
  status: SupplierStatus;
  subscriptionEnd?: Date;
  premiumCount: number;
  bdmCount: number;
  premiumUsedCount: number;
  bdmUsedCount: number;
  supplierLicenseMaps: SupplierLicenseDTO[];
}
export class SupplierDTO {
  id: string = "";
  ownerId?: string | null = null;
  companyName: string = "";
  companyLogo: string = "";
  logoS3Key?: string | null = null;
  website: string = "";
  contractName?: string | null = null;
  phoneNumber?: string | null = null;
  email?: string | null = null;
  status: SupplierStatus = SupplierStatus.Active;
  supplierLicenseMaps: SupplierLicenseDTO[] = [];
  supplierProductMaps: ProductDTO[] = [];
  isLicensee: boolean | null | undefined = true;
  afsl: string | null = null;
  linkedPractices: PracticeDTO[] = [];
  platformResearchAppliedPrice?: string | null = null;
  licenseeDiscounts?: LicenseeDiscountDTO[] = [];
}
export class SupplierLovDTO {
  id: string = "";
  afsl: string | null = null;
  name: string = "";
  isLicensee?: boolean = false;
}
export class SupplierCreateDTO {
  companyName: string = "";
  companyLogo: string = "";
  logoS3Key?: string | null = null;
  website: string = "";
  contractName?: string | null = null;
  phoneNumber?: string | null = null;
  email?: string | null = null;
  status?: string | null = null;
}
export class SupplierUpdateDTO {
  companyName?: string | null = null;
  companyLogo?: string | null = null;
  logoS3Key?: string | null = null;
  website?: string | null = null;
  contractName?: string | null = null;
  phoneNumber?: string | null = null;
  email?: string | null = null;
}
export class SupplierUserAggregatedDTO {
  supplierAccountType: SupplierAccountTypeDTO[] = [];
  supplierLicenseMaps: SupplierLicenseDTO[] = [];
  supplierProductMaps: ProductDTO[] = [];
  auth0Id: string = "";
  firstName: string = "";
  lastName: string = "";
  name: string = "";
  email: string = "";
  jobTitle: string | null = null;
  phoneNumber: string | null = null;
  avatarUrl: string = "";
  avatarS3Key: string = "";
  status: string | null = null;
  isPublic: boolean | null = null;
  publicPhoneNumber: string | null = null;
  preferredContactHours: string | null = null;
  publicEmail: string | null = null;
  linkedInProfileUrl: string | null = null;
  supplierId: string | null = null;
  supplierLicenseId: string | null = null;
  userType: UserType = UserType.SuperAdmin;
  welcomeLink: string | null = null;
  userMetadata: UserMetaData = new UserMetaData();
  regions: string[] = [];
  additionalPermissions: string[] = [];
  preferences: string[] = [];
  associatedProducts: string[] = [];
}
export interface SupplierAccountTypeDTO {
  accountType: string;
  inUsed: number;
  total: number;
  order: number;
}

export class SubscriptionProductDTO {
  id: string = "";
  priceId: string = "";
  priceType: string = "";
  unitPrice: number = 0;
  displayPrice: string = "";
}
