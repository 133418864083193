import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import {
  SHHtmlBlock,
  SHLabel,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHNumericFieldV2 } from "@components/design-systems/sh-numeric-field-v2";
import {
  useEditModelPaths,
  useInputDotIndicator,
  useUpdateSectionData,
} from "@hooks/useInvestmentProduct";
import { HighlightType } from "@models/configuration";
import { ProductDataStatus } from "@models/product/enums/productDataStatus";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductModelsDTO } from "@models/product/investment-product/entities/model";
import { InvestmentBannerStatus } from "@models/product/investment-product/enums/status";
import { CustomLabel } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/custom-label";
import { DEBOUNCE_DELAY } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/configs";
import { castToViewingGroupSelectionDTO } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import { RootState } from "@redux/store";
import { debounce, isEmpty, isEqual, toString } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
interface ModelFeatureCurrencyFieldProps {
  selectedIndex: [number, number];
  sectionIndex: number;
  featureIndex: number;
  feature: InvestmentProductConfigurationFeature;
}

export const ModelFeatureCurrencyField = ({
  selectedIndex,
  sectionIndex,
  featureIndex,
  feature,
}: ModelFeatureCurrencyFieldProps) => {
  const {
    investmentProductUI: { isSaving },
    investmentProductBannerInfo,
  } = useSelector((state: RootState) => state.investmentProduct);
  const originalValue = feature.productData?.value;
  /** Hook */
  const { control, setValue, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const isPendingApproval = investmentProductBannerInfo?.some((banner) =>
    isEqual(banner.status, InvestmentBannerStatus.PendingApproval),
  );
  const { featureProductDataPath, featurePath } = useEditModelPaths(
    selectedIndex,
    sectionIndex,
    featureIndex,
  );
  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductDataPath.highlightType,
      dataStatus: featureProductDataPath.dataStatus,
    },
  });
  const handleUpdateSectionData = useUpdateSectionData(
    selectedIndex,
    sectionIndex,
  );
  /** State */

  /** Handler */
  const handleInputChange = (newValue: string | null) => {
    setValue(
      featureProductDataPath.dataStatus,
      isEmpty(newValue)
        ? ProductDataStatus.MissingData
        : ProductDataStatus.Filled,
    );
    setValue(
      featurePath.dataStatus,
      isEmpty(newValue)
        ? ProductDataStatus.MissingData
        : ProductDataStatus.Filled,
    );
    setValue(featurePath.isModified, !isEqual(newValue, originalValue));
    setValue(
      featureProductDataPath.isModified,
      !isEqual(newValue, originalValue),
    );
    const publishedValue = getValues(featureProductDataPath.publishedValue);

    if (!isEqual(publishedValue, newValue)) {
      setValue(featureProductDataPath.highlightType, HighlightType.Edited);
      setValue(featurePath.highlightType, HighlightType.Edited);
    } else {
      setValue(featureProductDataPath.highlightType, null);
      setValue(featurePath.highlightType, null);
    }
    handleUpdateSectionData();
  };

  const debouncedHandleInputChange = debounce(
    handleInputChange,
    DEBOUNCE_DELAY,
  );

  /** Side effect */

  return (
    <>
      <SHStack direction="column" gap={2} width={"100%"}>
        <SHStack>
          <SHLabel
            label={
              <CustomLabel
                featureData={feature}
                disableInformationButton
                dotProps={{
                  orangeDot: isShowOrangeDot,
                  blueDot: isShowBlueDot,
                  greenDot: feature.isBusinessMetric,
                }}
              />
            }
          />
          <SHHtmlBlock variant="body3" content={feature.description} />
        </SHStack>
        {feature.allowedVisibilitySetting && (
          <SHStack>
            <SHLabel
              label={
                <SHTypography variant="subtitle2">Visible to</SHTypography>
              }
            />
            <Controller
              name={featurePath.viewingGroupSelections}
              control={control}
              render={({ field: { ref, ...other } }) => (
                <SelectViewingGroups
                  disabled={isSaving || isPendingApproval}
                  viewingGroupData={feature.viewingGroupSelections ?? []}
                  value={
                    castToViewingGroupSelectionDTO(
                      getValues(featurePath.viewingGroupSelections),
                    ) ?? []
                  }
                  onChange={(value) => {
                    other.onChange(value);
                    setValue(
                      featurePath.isModified,
                      !isEqual(feature.viewingGroupSelections, value),
                    );
                    handleUpdateSectionData();
                  }}
                />
              )}
            />
          </SHStack>
        )}
      </SHStack>
      <SHStack>
        <Controller
          control={control}
          name={featureProductDataPath["value"]}
          render={({ field: { ref, ...other } }) => (
            <SHNumericFieldV2
              disabled={isSaving || isPendingApproval}
              sx={{
                "& .MuiInput-root > input": {
                  padding: "10px 0px 8px 0px",
                },
                width: "520px",
              }}
              prefix="$"
              thousandSeparator={true}
              fixedDecimalScale
              valueIsNumericString
              placeholder={"e.g. Enter 100 for $100"}
              value={toString(getValues(featureProductDataPath.value))}
              onValueChange={({ value }) => {
                const actualValue = isEmpty(value) ? null : value;
                other.onChange(actualValue);
                debouncedHandleInputChange(actualValue);
              }}
            />
          )}
        />
      </SHStack>
    </>
  );
};
