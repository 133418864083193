import {
  SHAvatar,
  SHBox,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import {
  MicrosoftExcelIconSVG,
  MicrosoftWordIconSVG,
  PDFIconSVG,
} from "@components/svgs";
import { CircleCheckFilledSVG } from "@components/svgs/circle-check-filled";
import { S3ObjectDTO } from "@models/core";
import { Zoom } from "@mui/material";
import { FileTypes } from "@pages/document-library/constant";
import {
  getFileNameWithoutTimestamp,
  getFileType,
} from "@pages/document-library/util";

interface DocumentUploadPreviewProps {
  s3Object: S3ObjectDTO | null;
  iconHeight?: string | number;
  iconWidth?: string | number;
  isAttaching?: boolean;
}
const DocumentUploadPreview = ({
  s3Object,
  iconHeight = "70px",
  iconWidth = "70px",
  isAttaching,
}: DocumentUploadPreviewProps) => {
  if (!s3Object) return null;
  const fileType = getFileType(s3Object.key);
  if (fileType === FileTypes.IMAGE) {
    return (
      <SHAvatar
        isCircular={false}
        objectFit="contain"
        width={"100%"}
        src={s3Object.url}
      />
    );
  }

  let icon = <></>;
  switch (fileType) {
    case FileTypes.WORD:
      icon = <MicrosoftWordIconSVG height={iconHeight} width={iconWidth} />;
      break;
    case FileTypes.EXCEL:
      icon = <MicrosoftExcelIconSVG height={iconHeight} width={iconWidth} />;
      break;
    case FileTypes.PDF:
      icon = <PDFIconSVG width={iconHeight} height={iconWidth} />;
      break;
    default:
      icon = <></>;
  }

  return (
    <SHStack
      direction={"row"}
      alignItems={"center"}
      justifyContent={"center"}
      gap={1}
    >
      <SHBox>{icon}</SHBox>
      <SHStack flex={1} gap={0.5}>
        <SHTypography
          textAlign={"start"}
          variant="body4"
          sx={{
            width: "100%",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            wordBreak: "break-word",
          }}
        >
          {getFileNameWithoutTimestamp(s3Object.key)}
        </SHTypography>

        <SHStack direction="row" alignItems={"center"} gap={1}>
          {isAttaching ? (
            <SHTypography textAlign={"start"} variant="body3">
              Attaching ...
            </SHTypography>
          ) : (
            <>
              <SHTypography paddingTop={0} textAlign={"start"} variant="body3">
                Attached
              </SHTypography>
              <Zoom in={true} timeout={600} key={s3Object.key}>
                <div>
                  <CircleCheckFilledSVG height={"15px"} width={"15px"} />
                </div>
              </Zoom>
            </>
          )}
        </SHStack>
      </SHStack>
    </SHStack>
  );
};

export default DocumentUploadPreview;
