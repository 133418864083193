import { ImageAutoComplete } from "@components/auto-completes/image";
import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { CloseButton } from "@components/buttons/close";
import {
  SHButton,
  SHCheckbox,
  SHDialog,
  SHFormControlLabel,
  SHIconLoading,
  SHSelect,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import { SHDatePicker } from "@components/design-systems/sh-date-picker";
import { SHUploadFile } from "@components/design-systems/sh-upload-file";
import { uploadOptions } from "@components/design-systems/sh-upload-file/config";
import CustomUnsavedDialog from "@components/dialogs/custom-unsaved";
import { PaperClipSVG } from "@components/svgs";
import { email } from "@constants/email";
import {
  useDocumentLibrary,
  useSingleFileHandler,
} from "@hooks/useDocumentLibrary";
import { useUserPermissions } from "@hooks/userUserPermission";
import {
  DocumentProductSelectionDTO,
  ProductDocumentDetailDTO,
} from "@models/document-library/entities/document";
import { DocumentTypes } from "@models/document-library/enums/documentTypes";
import { ProductType } from "@models/product/enums/productType";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { DialogContent, DialogTitle, Link, useTheme } from "@mui/material";
import DocumentUploadPreview from "@pages/document-library/components/document-upload-preview";
import {
  DOCUMENT_DESCRIPTION_MAX_LENGTH,
  DOCUMENT_SIZE_LIMIT,
  DocumentDefaultPlatformViewingGroup,
  DocumentUploadAcceptFile,
} from "@pages/document-library/constant";
import { theme } from "@themes";
import { hexToRGBA, nameOfFactory, toDateType } from "@utils";
import { addMonths, endOfDay } from "date-fns";
import { isEmpty, isEqual, isNull } from "lodash";
import { useEffect, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import { useMount } from "react-use";

const nameOf = nameOfFactory<DocumentProductSelectionDTO>();

interface DocumentUploadDialogProps {
  onClose: () => void;
  buttonLabel?: string;
  isOpen: boolean;
  dialogTitle: string;
  initialValues?: ProductDocumentDetailDTO;
  productSelectionList?: DocumentProductSelectionDTO[];
  isLoadingProductList?: boolean;
  isEdit?: boolean;
  onSubmit?: (data: ProductDocumentDetailDTO) => Promise<void>;
}

const DocumentUploadDialog = ({
  onClose,
  buttonLabel = "Submit document",
  isOpen,
  dialogTitle,
  initialValues,
  productSelectionList = [],
  isLoadingProductList = false,
  isEdit = false,
  onSubmit,
}: DocumentUploadDialogProps) => {
  const { palette } = useTheme();
  /** Hook */
  const { loadListViewingGroupSelectionBySupplier } = useDocumentLibrary();
  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<ProductDocumentDetailDTO>({
    mode: "onChange",
    defaultValues: {
      ...initialValues,
    },
  });

  const { isSuperAdmin } = useUserPermissions();
  const { isUploading, uploadMessage, handleOnFileUpload } =
    useSingleFileHandler({
      onSuccess: (_, data) => {
        setValue("s3File", data.url, uploadOptions.hookForm);
      },
      onInvalidFile: () => {
        setValue("s3File", null, uploadOptions.hookForm);
      },
      onDeleteSuccess: () => {
        setValue("s3File", null, uploadOptions.hookForm);
      },
    });

  /** State */
  const [openUnsaveDialog, setOpenUnsavedDialog] = useState(false);
  const [viewingGroups, setViewingGroups] = useState<
    ViewingGroupSelectionDTO[]
  >(initialValues?.viewingGroupSelections ?? []);
  const { isDirty, isValid } = useFormState({ control });
  const [haveApproval, setHaveApproval] = useState<boolean>(false);
  const [isLoadingViewingGroup, setIsLoadingViewingGroup] = useState(false);
  /** Function */
  const loadViewingGroup = async (supplierId: string) => {
    setIsLoadingViewingGroup(true);
    setValue("viewingGroupSelections", []);
    const viewingGroups = await loadListViewingGroupSelectionBySupplier(
      supplierId,
    );
    setIsLoadingViewingGroup(false);
    setViewingGroups(viewingGroups ?? []);
    setValue("viewingGroupSelections", viewingGroups ?? []);
  };
  const onDiscard = () => {
    setOpenUnsavedDialog(false);
    onClose();
  };
  const onKeepEditing = () => {
    setOpenUnsavedDialog(false);
  };
  // for requirement: if only has one product, auto select it by default
  const handleSetDefaultProduct = async () => {
    const product = productSelectionList[0];
    setValue("productId", product.id);
    setValue("productType", product.productType);
    const isPlatformProduct = isEqual(
      product.productType,
      ProductType.Platform,
    );
    if (!isPlatformProduct) {
      await loadViewingGroup(product.supplierId);
      return;
    }

    setValue("viewingGroupSelections", DocumentDefaultPlatformViewingGroup);
  };

  const renderSelectedProduct = (productId: string) => {
    const product = productSelectionList.find((p) => p.id === productId);
    return product ?? null;
  };
  const handleSelectProduct = (product: DocumentProductSelectionDTO | null) => {
    if (product == null) {
      setValue("viewingGroupSelections", []);
      return;
    }

    const productType = product?.productType;
    setValue("productType", productType);

    if (isEqual(productType, ProductType.Platform)) {
      setValue("viewingGroupSelections", DocumentDefaultPlatformViewingGroup);
      return;
    }

    loadViewingGroup(product.supplierId);
  };
  /** Render */

  useEffect(() => {
    reset({
      ...initialValues,
    });
    // eslint-disable-next-line
  }, [isOpen]);
  useMount(() => {
    if (productSelectionList.length === 1 && !isEdit) {
      handleSetDefaultProduct();
    }
  });
  return (
    <form>
      <SHDialog
        disableBackdropClick
        open={isOpen}
        onClose={onClose}
        width={400}
        maxWidth={false}
        sx={{
          "& .MuiPaper-root": {
            width: "670px",
            height: "auto",
            minHeight: "700px",
            padding: "20px 60px",
            position: "relative",
          },
        }}
      >
        <DialogTitle
          borderBottom={`1px solid ${theme.palette.secondary[100]}`}
          padding={"0 !important"}
        >
          <SHStack
            direction={"row"}
            alignItems={"center"}
            spacing={1}
            justifyContent={"center"}
          >
            <PaperClipSVG />
            <SHTypography variant="h2" fontWeight={600}>
              {dialogTitle}
            </SHTypography>
          </SHStack>
          <SHTypography variant="subtitle2">
            Note: Do not upload disclosure documents
          </SHTypography>
          <SHTypography
            textAlign={"left"}
            fontSize="13px"
            fontWeight="400"
            my={1}
          >
            Upload documents, files and images related to your product. You can
            restrict the visibility of files to certain groups of SuitabilityHub
            users. To create a new visibility group, please contact{" "}
            <Link
              color={theme.palette.text.secondary}
              component="a"
              underline="none"
              sx={{
                cursor: "pointer",
              }}
              href={`mailto:${email.support}`}
            >
              {email.support}.
            </Link>
          </SHTypography>
        </DialogTitle>
        <SHStack
          sx={{
            position: "absolute",
            top: "22px",
            right: "25px",
          }}
        >
          <CloseButton
            width="12px"
            height="12px"
            onClick={() => {
              if (isDirty) {
                setOpenUnsavedDialog(true);
                return;
              }
              onClose();
            }}
          />
        </SHStack>
        <DialogContent
          sx={{
            marginTop: { md: "10px", lg: "15px" },
            padding: 0,
            overflowY: "var(--overflow-overlay)",
          }}
        >
          <SHStack
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Controller
              rules={{
                required: "Product is required",
              }}
              render={({ field }) => (
                <SHStack width={"250px"}>
                  <ImageAutoComplete
                    textFieldProps={{
                      label: "Product",
                      placeholder: "Select a product",
                      required: true,
                      error: !!errors.productId,
                      helperText: errors.productId
                        ? errors.productId.message
                        : null,
                    }}
                    onChange={(_event, value) => {
                      if (value === null) {
                        field.onChange(null);
                        setValue("productType", null);
                        return;
                      }
                      field.onChange(value?.id);
                      handleSelectProduct(value);
                    }}
                    value={renderSelectedProduct(field.value)}
                    loadingText={
                      <SHStack
                        alignItems={"center"}
                        direction="row"
                        spacing={1}
                      >
                        <SHIconLoading />
                        <SHTypography>Looking for products</SHTypography>
                      </SHStack>
                    }
                    labelField={nameOf("productName")}
                    imageField={nameOf("productLogo")}
                    listKey={nameOf("id")}
                    options={productSelectionList}
                    loading={isLoadingProductList}
                  />
                </SHStack>
              )}
              name="productId"
              control={control}
            />
            <Controller
              name={"dateOfDocument"}
              control={control}
              rules={{
                validate: (value) => {
                  const maxDate = endOfDay(addMonths(new Date(), 3));
                  const dateValue = toDateType(value);
                  const startDate = new Date("1/1/2022");

                  if (isNull(dateValue)) {
                    return "Date is required";
                  }
                  if (!(dateValue >= startDate && dateValue <= maxDate)) {
                    return "Invalid date";
                  }
                },
              }}
              render={({ field }) => (
                <SHDatePicker
                  label={"Date of document"}
                  maxDate={addMonths(new Date(), 3)}
                  minDate={new Date("1/1/2022")}
                  value={field.value}
                  sxTextField={{
                    "&>.MuiInput-root>input": {
                      padding: "8px 5px 5px 5px",
                    },
                    width: "250px",
                  }}
                  textFieldProps={{
                    error: !!errors.dateOfDocument,
                    required: true,
                    helperText: errors.dateOfDocument
                      ? errors.dateOfDocument.message
                      : null,
                  }}
                  onChange={(newValue: Date | null) => {
                    if (newValue) {
                      const currentTime = new Date();
                      newValue.setHours(
                        currentTime.getHours(),
                        currentTime.getMinutes(),
                        currentTime.getSeconds(),
                        currentTime.getMilliseconds(),
                      );
                    }
                    field.onChange(newValue);
                  }}
                />
              )}
            />
          </SHStack>
          <Controller
            name="title"
            control={control}
            rules={{ required: "Title is required" }}
            render={({ field }) => (
              <SHStack
                width={"100%"}
                sx={{
                  marginTop: { md: "5px", lg: "10px" },
                }}
              >
                <SHTextField
                  {...field}
                  required
                  maxLength={100}
                  sx={{ width: { xs: "100%", md: "100%" } }}
                  label="Title"
                  error={!!errors.title}
                  helperText={errors.title ? errors.title.message : null}
                  placeholder={"Enter the title for this document"}
                />
              </SHStack>
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <SHStack
                width={"100%"}
                sx={{
                  marginTop: { md: "5px", lg: "10px" },
                }}
              >
                <SHTextField
                  {...field}
                  maxLength={DOCUMENT_DESCRIPTION_MAX_LENGTH}
                  sx={{ width: { xs: "100%", md: "100%" } }}
                  label="Description"
                  placeholder={"What is this document about?"}
                />
              </SHStack>
            )}
          />
          <SHStack
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
          >
            <Controller
              rules={{
                required: "Document type is required",
              }}
              name="documentType"
              control={control}
              render={({ field }) => (
                <SHStack
                  sx={{
                    marginTop: { md: "5px", lg: "10px" },
                    width: "250px",
                  }}
                >
                  <SHSelect
                    required
                    sx={{
                      "& .sh-select-placeholder": {
                        color: `${hexToRGBA(
                          palette.text.primary,
                          0.4,
                        )} !important`,
                        fontSize: "14px",
                        fontWeight: "400 !important",
                      },
                      "& .MuiTypography-root": {
                        fontSize: "14px",
                      },
                    }}
                    error={!!errors.documentType}
                    helperText={!isUploading && errors.documentType?.message}
                    label="Document type"
                    placeholder="Select document type"
                    dataItemKey="id"
                    allowClear={!isEmpty(field.value)}
                    onClear={() => field.onChange(null)}
                    value={field.value}
                    displayField="name"
                    onChange={(value) => field.onChange(value)}
                    data={DocumentTypes.toLovData()}
                  />
                </SHStack>
              )}
            />
            <Controller
              name="viewingGroupSelections"
              control={control}
              render={({ field }) => (
                <SHStack
                  sx={{
                    marginTop: { md: "5px", lg: "10px" },
                    width: "250px",
                  }}
                >
                  <SelectViewingGroups
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                    isLoading={isLoadingViewingGroup}
                    value={field.value}
                    disabled={
                      // get value can use here instead of watch
                      isEmpty(getValues("productId")) ||
                      isEqual(getValues("productType"), ProductType.Platform)
                    }
                    viewingGroupData={viewingGroups}
                    textFieldProps={{
                      label: `Visibility`,
                    }}
                  />
                </SHStack>
              )}
            />
          </SHStack>

          <Controller
            rules={{
              required: "Document attachment is required",
            }}
            control={control}
            name="s3File"
            render={({ field }) => (
              <>
                <SHStack
                  width={"100%"}
                  spacing={1}
                  direction={"row"}
                  sx={{ marginTop: { md: "10.5px", lg: "21px" } }}
                  justifyContent={"center"}
                >
                  <SHUploadFile
                    fileSupportText="PDF, CSV, XLS, XLSX, DOC, DOCX, or an image"
                    width={field.value ? 300 : undefined}
                    buttonText="Click to add"
                    isUploading={isUploading}
                    error={!isUploading && (!!uploadMessage || !!errors.s3File)}
                    helperText={
                      !isUploading
                        ? uploadMessage || errors.s3File?.message
                        : "Uploading document, please wait."
                    }
                    fileIcon={<PaperClipSVG />}
                    dropzoneOptions={{
                      multiple: false,
                      accept: DocumentUploadAcceptFile,
                      onDrop: (acceptedFiles, fileRejections) =>
                        handleOnFileUpload(
                          acceptedFiles,
                          fileRejections,
                          DOCUMENT_SIZE_LIMIT,
                        ),
                      disabled: isUploading,
                      maxSize: DOCUMENT_SIZE_LIMIT,
                    }}
                  />
                  {field.value && (
                    <SHStack
                      height={120}
                      flex={1}
                      direction={"column"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      sx={{
                        paddingX: 1.5,
                        paddingY: 0.5,
                        backdropFilter: "blur(0.5px)",
                        background: `${hexToRGBA("#c5ddfd", 0.1)}`,
                        border: `1px solid ${theme.palette.secondary[100]}`,
                        "& .MuiAvatar-img": {
                          paddingY: "8px !important",
                        },
                      }}
                    >
                      <DocumentUploadPreview
                        isAttaching={isUploading}
                        s3Object={{ key: field.value, url: field.value }}
                      />
                    </SHStack>
                  )}
                </SHStack>
              </>
            )}
          />
          {!isSuperAdmin && (
            <SHStack alignItems={"flex-start"}>
              <SHFormControlLabel
                sx={{ pointerEvents: "none" }}
                control={
                  <SHCheckbox
                    name="haveApproval"
                    sx={{
                      pointerEvents: "auto",
                      paddingLeft: "9px",
                    }}
                    onChange={(_event, value) => {
                      setHaveApproval(value);
                    }}
                  />
                }
                componentsProps={{
                  typography: { align: "left" },
                }}
                label={
                  <SHTypography
                    variant="subtitle2"
                    colorVariant="third"
                    sx={{
                      cursor: "pointer",
                      pointerEvents: "auto",
                    }}
                  >
                    I have approval and I attest this is not a disclosure
                    document (e.g PDS)
                  </SHTypography>
                }
              />
            </SHStack>
          )}

          <SHButton
            onClick={handleSubmit(async (data) => {
              onSubmit && (await onSubmit(data));
            })}
            isLoading={isSubmitting}
            disabled={
              !isDirty ||
              !isValid ||
              isUploading ||
              isSubmitting ||
              !!uploadMessage ||
              (!isSuperAdmin && !haveApproval)
            }
            variant="contained"
            size="extraMedium"
            type="submit"
            sx={{
              marginTop: "15px",
              height: "50px",
              width: "410px",
            }}
          >
            {buttonLabel}
          </SHButton>
        </DialogContent>
      </SHDialog>
      {openUnsaveDialog && (
        <CustomUnsavedDialog
          open={openUnsaveDialog}
          onCancel={onKeepEditing}
          onConfirm={onDiscard}
        />
      )}
    </form>
  );
};

export default DocumentUploadDialog;
