import {
  PAGE_DPI,
  PAGE_HEIGHT,
  PAGE_WIDTH,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
// import DefaultProductLogo from "@assets/images/logo/default-product-logo.png";
// import BackgroundDark from "@assets/images/pdf/pdf_background_dark.jpg";
// import BackgroundLight from "@assets/images/pdf/pdf_background_light.jpg";
// import HeaderLogoDark from "@assets/images/pdf/pdf-header-logo-dark.png";
// import HeaderLogoLight from "@assets/images/pdf/pdf-header-logo-light.png";
// import FooterLogo from "@assets/images/pdf/pdf-footer-logo.png";
// A4 size
export const pageSizeConfig = {
  dpi: PAGE_DPI,
  size: { width: PAGE_WIDTH, height: PAGE_HEIGHT },
};

export enum HtmlTag {
  P = "p",
  STRONG = "strong",
  UL = "ul",
  OL = "ol",
  LI = "li",
  BR = "br",
  EM = "em",
  SPAN = "span",
  U = "u",
}

export const NonTruncatableWords = [
  "www.suitabilityhub.com",
  "performance-related",
  "individuals/entities:",
];

// PDF Images
export const PDFFooterLogoS3 = `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/pdf-footer-logo.png`;

export const PDFHeaderLightLogoS3 = `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/pdf-header-logo-light.png`;
export const PDFHeaderDarkLogoS3 = `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/pdf-header-logo-dark.png`;
export const PDFBackgroundLightS3 = `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/pdf_background_light.jpg`;
export const PDFBackgroundDarkS3 = `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/pdf_background_dark.jpg`;
export const PDFDefaultPlatformLogoS3 = `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/default-product-logo.png`;

export const BusinessMetricIcons = {
  Esg: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/business-metric-icons/esg.png`,
  FinancialStrength: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/business-metric-icons/financial-strength.png`,
  Operational: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/business-metric-icons/operational.png`,
};

export const PDFFeatureSectionIconPrefix = `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/feature-section-icons/`;

export const FeatureSectionIcons = {
  ProductMenu: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/feature-section-icons/Product menu.png`,
  AccountManagement: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/feature-section-icons/Account management.png`,
  CashflowManagement: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/feature-section-icons/Cashflow management.png`,
  ClientAccess: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/feature-section-icons/Client access.png`,
  ClientReporting: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/feature-section-icons/Client reporting.png`,
  PlanningCompliance: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/feature-section-icons/Planning and compliance.png`,
  DecisionSupport: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/feature-section-icons/Decision support.png`,
  Integration: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/feature-section-icons/Integration.png`,
  PortfolioManagement: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/feature-section-icons/Portfolio management.png`,
  Tracking: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/feature-section-icons/Tracking.png`,
  WholeView: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-images/feature-section-icons/Whole of wealth view.png`,
};

export const PDFFonts = {
  ClashDisplayBold: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-fonts/ClashDisplayBold.woff`,
  ClashDisplayRegular: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-fonts/ClashDisplayRegular.woff`,

  EpilogueRegular: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-fonts/Epilogue-Regular.woff`,
  EpilogueSemiBold: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-fonts/Epilogue-SemiBold.woff`,
  EpilogueBold: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-fonts/Epilogue-Bold.woff`,
  EpilogueItalic: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-fonts/Epilogue-Italic.woff`,
  EpilogueBoldItalic: `${process.env.REACT_APP_AUTH0_REDIRECT_URL}/pdf-fonts/Epilogue-BoldItalic.woff`,
};

// TODO: Fonts, images for development in local environment. Remove when deploy
// export const PDFFooterLogoS3 = FooterLogo;

// export const PDFHeaderLightLogoS3 = HeaderLogoLight;
// export const PDFHeaderDarkLogoS3 = HeaderLogoDark;
// export const PDFBackgroundLightS3 = BackgroundLight;
// export const PDFBackgroundDarkS3 = BackgroundDark;
// export const PDFDefaultPlatformLogoS3 = DefaultProductLogo;

// export const PDFFonts = {
//   ClashDisplayBold: "https://fonts.cdnfonts.com/s/65008/ClashDisplayBold.woff",
//   ClashDisplayRegular:
//     "https://fonts.cdnfonts.com/s/65008/ClashDisplayRegular.woff",

//   EpilogueRegular: "https://fonts.cdnfonts.com/s/46635/Epilogue-Regular.woff",
//   EpilogueSemiBold: "https://fonts.cdnfonts.com/s/46635/Epilogue-SemiBold.woff",
//   EpilogueBold: "https://fonts.cdnfonts.com/s/46635/Epilogue-SemiBold.woff",
//   EpilogueItalic: "https://fonts.cdnfonts.com/s/46635/Epilogue-Italic.woff",
//   EpilogueBoldItalic:
//     "https://fonts.cdnfonts.com/s/46635/Epilogue-SemiBold.woff",
// };
