import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { SHLabel, SHStack, SHTypography } from "@components/design-systems";
import { MAX_LONG_TEXT_LENGTH } from "@constants";
import {
  useEditManagerPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { FieldTypeId } from "@models/configuration";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { CustomLabel } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/custom-label";
import {
  EditManagerInputControllerProps,
  handleOnEditManagerFieldChange,
  toEditManagerNativeValue,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { debounce, isEqual } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import SHRichTextEditor2 from "@components/design-systems/sh-rich-text-editor-v2";

export const EditManagerTextLongInput = ({
  isCreatingInvestmentProduct,
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditManagerInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductManagerDTO>();

  const { featureProductDataPath, featureDataPath } = useEditManagerPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductManagerDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const handleOnChange = (newValue: string) => {
    handleOnEditManagerFieldChange({
      isCreatingInvestmentProduct,
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    setValue(
      featureProductDataPath.isModified,
      !isEqual(featureData.viewingGroupSelections, newValue),
    );
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );

  const renderControllerLabel = () => {
    const allowedVisibility = !!featureData?.allowedVisibilitySetting;

    return (
      <SHStack
        direction="row"
        justifyContent="space-between"
        width={{ xs: "100%", md: 520 }}
        mb={1}
        columnGap={3}
      >
        <SHStack
          direction="row"
          alignItems={"center"}
          width={allowedVisibility ? "40%" : "100%"}
        >
          <SHLabel
            label={
              <CustomLabel
                featureData={featureData}
                isDisabled={isDisabled}
                dotProps={{
                  orangeDot: isShowOrangeDot,
                  blueDot: isShowBlueDot,
                  greenDot: !!featureData?.isBusinessMetric,
                }}
              />
            }
          />
        </SHStack>

        {allowedVisibility && (
          <SHStack
            direction={"row"}
            alignItems={"center"}
            gap={1}
            width={"60%"}
          >
            <SHTypography disabled={isDisabled} variant="subtitle2">
              Visible to
            </SHTypography>
            <Controller
              name={featureDataPath.viewingGroupSelections}
              control={control}
              render={({ field: { ref, ...other } }) => (
                <SelectViewingGroups
                  disabled={isDisabled}
                  sx={{
                    flex: 1,
                  }}
                  onChange={(value) => {
                    other.onChange(value);
                    debounceVisibilityChange(value);
                  }}
                  value={
                    (getValues(featureDataPath.viewingGroupSelections) as
                      | ViewingGroupSelectionDTO[]
                      | null) || []
                  }
                  viewingGroupData={featureData.viewingGroupSelections ?? []}
                />
              )}
            />
          </SHStack>
        )}
      </SHStack>
    );
  };

  return (
    <SHStack
      width={{ xs: "100%", md: 520 }}
      gap={1}
      sx={{
        "& .editor-container": {
          height: "280px !important",
        },
      }}
    >
      <Controller
        name={featureProductDataPath.value}
        control={control}
        render={({ field: { value, onChange } }) => (
          <SHRichTextEditor2
            value={toEditManagerNativeValue(value, FieldTypeId.TextLong)}
            label={renderControllerLabel()}
            maxCharacters={MAX_LONG_TEXT_LENGTH}
            isDisabled={isDisabled}
            onChange={(textContent) => {
              onChange(textContent);
              handleOnChange(textContent);
            }}
          />
        )}
      />
    </SHStack>
  );
};
