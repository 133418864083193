export enum AdditionPermissionName {
  ProductManagement = "Product management",
  NewAndUpdateManagement = "Manage news, updates and document uploads",
  UserManagement = "Team member (user) management",
  SubscriptionManagement = "Subscription management",
}

export class UserProfileErrorMessage {
  static readonly MandatoryFieldMissingData =
    "Missing data in mandatory fields. Please complete the areas marked with an orange dot";
}

export const UserProfileTooltipDelayInMs = 0;