import { FieldTypeId, HighlightType } from "@models/configuration";
import {
  InvestmentProductConfigurationFeature,
  InvestmentProductConfigurationGroup,
} from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { defaultTo, isEmpty, isEqual, isNil } from "lodash";
import { FieldPath, UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { ReportingAndCommunicationManagerFeatures } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";

export type EditManagerInputControllerProps = {
  isCreatingInvestmentProduct?: boolean;
  featureData: InvestmentProductConfigurationFeature;
  featureIndex: number;
  sectionIndex: number;
  isManagerCodeField?: boolean;
  isManagerNameField?: boolean;
  isProductTypeDropdown?: boolean;
  isDisabled?: boolean;
  onChange?: ({
    sectionIndex,
    featureIndex,
    newValue,
  }: {
    sectionIndex: number;
    featureIndex: number;
    newValue?: string;
  }) => void;
  updateFormDotData?: () => void;
};

export type EditManagerNumberTextInputControllerProps =
  EditManagerInputControllerProps & {
    suffix?: string;
    prefix?: string;
    placeholder?: string;
  };

export type ManagerGroupDataPath = {
  name: EditManagerFieldPath;
  highlightType: EditManagerFieldPath;
  dataStatus: EditManagerFieldPath;
  isModified: EditManagerFieldPath;
  viewingGroupSelections: EditManagerFieldPath;
  sections?: EditManagerFieldPath[];
};

export type ManagerSectionDataPath = Omit<
  ManagerGroupDataPath,
  "viewingGroupSelections"
> & {
  features?: EditManagerFieldPath[];
};

export type ManagerFeatureDataPath = {
  highlightType: EditManagerFieldPath;
  dataStatus: EditManagerFieldPath;
  isModified: EditManagerFieldPath;
  viewingGroupSelections: EditManagerFieldPath;
};

export type EditManagerFieldPath = FieldPath<{
  group: InvestmentProductConfigurationGroup;
}>;

export type ManagerFeatureProductDataPath = {
  value: EditManagerFieldPath;
  publishedValue: EditManagerFieldPath;
  highlightType: EditManagerFieldPath;
  dataStatus: EditManagerFieldPath;
  isModified: EditManagerFieldPath;
  additionalValue: EditManagerFieldPath;
  publishedAdditionalValue: EditManagerFieldPath;
  isPositiveScore: EditManagerFieldPath;
  fieldType: EditManagerFieldPath;
};

export const toEditManagerNativeValue = (
  value: any,
  fieldType: FieldTypeId,
) => {
  switch (fieldType) {
    case FieldTypeId.Number:
      return isEmpty(value) ? null : +value;
    case FieldTypeId.Slideshow:
    case FieldTypeId.Image:
      return isEmpty(value) ? null : JSON.parse(value);
    case FieldTypeId.YesNo:
      return isEmpty(value) ? null : isEqual(value, "true");
    case FieldTypeId.NumberText:
      return isEmpty(value) ? null : isEqual(value, "N/A") ? value : +value;
    default:
      return value;
  }
};

export const getEditManagerGroupDataFieldPath = (): ManagerGroupDataPath => ({
  name: `group.name` as EditManagerFieldPath,
  highlightType: `group.highlightType` as EditManagerFieldPath,
  isModified: `group.isModified` as EditManagerFieldPath,
  dataStatus: `group.dataStatus` as EditManagerFieldPath,
  viewingGroupSelections:
    `group.viewingGroupSelections` as EditManagerFieldPath,
});

export const getEditManagerSectionDataPath = (
  sectionIndex: number,
): ManagerSectionDataPath => ({
  name: `group.sections.${sectionIndex}.name` as EditManagerFieldPath,
  highlightType:
    `group.sections.${sectionIndex}.highlightType` as EditManagerFieldPath,
  isModified:
    `group.sections.${sectionIndex}.isModified` as EditManagerFieldPath,
  dataStatus:
    `group.sections.${sectionIndex}.dataStatus` as EditManagerFieldPath,
});

export const getEditManagerFeaturePath = (
  sectionIndex: number,
  featureIndex: number,
): EditManagerFieldPath =>
  `group.sections.${sectionIndex}.features.${featureIndex}` as EditManagerFieldPath;

export const handleNoneFieldsChanged = (
  prevDropDownValue: string,
  selectedDropDownValue: string,
  sectionIndex: number,
  getValues: UseFormGetValues<InvestmentProductManagerDTO>,
  setValue: UseFormSetValue<InvestmentProductManagerDTO>,
  featureData: InvestmentProductConfigurationFeature,
) => {
  const dropdownFeatures = getValues(
    `group.sections.${sectionIndex}.features` as EditManagerFieldPath,
  ) as InvestmentProductConfigurationFeature[];
  const isDropDownValueModified =
    featureData.productData?.value !== selectedDropDownValue;
  const isTriggerHandleNoneValue =
    selectedDropDownValue === "None" || prevDropDownValue === "None";
  const sectionPath = getEditManagerSectionDataPath(sectionIndex);
  const groupPath = getEditManagerGroupDataFieldPath();

  if (isTriggerHandleNoneValue) {
    for (
      let featureIdx = 0;
      featureIdx < dropdownFeatures.length;
      featureIdx++
    ) {
      const feature = dropdownFeatures[featureIdx];
      const featurePath = getEditManagerFeatureDataPath(
        sectionIndex,
        featureIdx,
      );
      const featureProductDataPath = getEditManagerFeatureProductDataPath(
        sectionIndex,
        featureIdx,
      );

      if (featureIdx === 0) {
        setValue(featurePath.isModified, isDropDownValueModified);
        setValue(featureProductDataPath.isModified, isDropDownValueModified);
      }

      if (
        !ReportingAndCommunicationManagerFeatures.getFeatureNames().includes(
          dropdownFeatures[featureIdx].name ?? "",
        )
      )
        continue;

      if (feature.fieldType?.id !== FieldTypeId.Dropdown) {
        if (selectedDropDownValue === "None") {
          setValue(featurePath.isModified, isDropDownValueModified);
          setValue(featurePath.highlightType, null);
          setValue(featurePath.dataStatus, InvestmentDataStatus.Filled);
          setValue(featureProductDataPath.isModified, isDropDownValueModified);
          setValue(featureProductDataPath.highlightType, null);
          setValue(
            featureProductDataPath.dataStatus,
            InvestmentDataStatus.Filled,
          );
        } else {
          const featureValue = getValues(featureProductDataPath.value);
          const featurePublishedValue = getValues(
            featureProductDataPath.publishedValue,
          );
          const isModified = featureValue !== featurePublishedValue;
          const dataStatus =
            isNil(featureValue) || isEmpty(featureValue)
              ? InvestmentDataStatus.MissingData
              : InvestmentDataStatus.Filled;
          const highlightType =
            featureValue !== featurePublishedValue
              ? HighlightType.Edited
              : null;

          setValue(featurePath.isModified, isModified);
          setValue(featurePath.highlightType, highlightType);
          setValue(featurePath.dataStatus, dataStatus);
          setValue(featureProductDataPath.isModified, isModified);
          setValue(featureProductDataPath.highlightType, highlightType);
          setValue(featureProductDataPath.dataStatus, dataStatus);
        }
      }
    }
  }

  const isSectionModified = dropdownFeatures.some(
    (feature) => feature.isModified,
  );
  const sectionDataStatus = dropdownFeatures.some(
    (feature) => feature.dataStatus === InvestmentDataStatus.MissingData,
  )
    ? InvestmentDataStatus.MissingData
    : InvestmentDataStatus.Filled;
  const sectionHighlightType = dropdownFeatures.some(
    (feature) => feature.highlightType === HighlightType.Edited,
  )
    ? HighlightType.Edited
    : null;

  setValue(sectionPath.highlightType, sectionHighlightType);
  setValue(sectionPath.dataStatus, sectionDataStatus);
  setValue(sectionPath.isModified, isSectionModified);
  setValue(groupPath.highlightType, sectionHighlightType);
  setValue(groupPath.dataStatus, sectionDataStatus);
  setValue(groupPath.isModified, isSectionModified);
};

export const setDefaultValueForNoneFields = (
  investmentProductManager: InvestmentProductManagerDTO,
  getValues: UseFormGetValues<InvestmentProductManagerDTO>,
  setValue: UseFormSetValue<InvestmentProductManagerDTO>,
) => {
  const noneSectionIdx =
    investmentProductManager.group?.sections?.findIndex((section) =>
      isEqual(
        section.name,
        ReportingAndCommunicationManagerFeatures.ReportingAndCommunicationsSection,
      ),
    ) ?? -1;
  let featureDropDownValue = "";
  const features =
    noneSectionIdx > -1
      ? investmentProductManager.group?.sections?.[noneSectionIdx].features
      : [];

  for (let featureIdx = 0; featureIdx < (features ?? []).length; featureIdx++) {
    const featureProductDataPath = getEditManagerFeatureProductDataPath(
      noneSectionIdx,
      featureIdx,
    );
    const featurePath = getEditManagerFeaturePath(noneSectionIdx, featureIdx);
    const featureValue =
      (getValues(featureProductDataPath.value) as string) ?? "";
    const featurePublishedValue =
      (getValues(featureProductDataPath.publishedValue) as string) ?? "";
    const featureFieldType = getValues(
      featureProductDataPath.fieldType as EditManagerFieldPath,
    ) as string;
    const featureName = getValues(
      `${featurePath}.name` as EditManagerFieldPath,
    ) as string;

    if (featureIdx === 0) {
      featureDropDownValue = featureValue;
      continue;
    }

    if (featureDropDownValue !== "None") break;

    if (
      ReportingAndCommunicationManagerFeatures.getFeatureNames().includes(
        featureName,
      )
    ) {
      switch (featureFieldType) {
        case FieldTypeId.TextLong:
          setValue(
            featureProductDataPath.value,
            isEmpty(featureValue) ? "N/A" : featurePublishedValue,
          );
          break;
        case FieldTypeId.YesNo:
          setValue(
            featureProductDataPath.value,
            isEmpty(featureValue) ? "false" : featurePublishedValue,
          );
          break;
      }
    }
  }
};

export const getEditManagerFeatureDataPath = (
  sectionIndex: number,
  featureIndex: number,
): ManagerFeatureDataPath => {
  const featurePath = getEditManagerFeaturePath(sectionIndex, featureIndex);

  return {
    highlightType: `${featurePath}.highlightType` as EditManagerFieldPath,
    dataStatus: `${featurePath}.dataStatus` as EditManagerFieldPath,
    isModified: `${featurePath}.isModified` as EditManagerFieldPath,
    viewingGroupSelections:
      `${featurePath}.viewingGroupSelections` as EditManagerFieldPath,
  };
};

export const getEditManagerFeatureProductDataPath = (
  sectionIndex: number,
  featureIndex: number,
): ManagerFeatureProductDataPath => {
  const featurePath = getEditManagerFeaturePath(sectionIndex, featureIndex);

  return {
    value: `${featurePath}.productData.value` as EditManagerFieldPath,
    publishedValue:
      `${featurePath}.productData.publishedValue` as EditManagerFieldPath,
    dataStatus: `${featurePath}.productData.dataStatus` as EditManagerFieldPath,
    additionalValue:
      `${featurePath}.productData.additionalValue` as EditManagerFieldPath,
    highlightType:
      `${featurePath}.productData.highlightType` as EditManagerFieldPath,
    isModified: `${featurePath}.productData.isModified` as EditManagerFieldPath,
    publishedAdditionalValue:
      `${featurePath}.productData.publishedAdditionalValue` as EditManagerFieldPath,
    isPositiveScore:
      `${featurePath}.productData.isPositiveScore` as EditManagerFieldPath,
    fieldType: `${featurePath}.fieldType.id` as EditManagerFieldPath,
  };
};

export const isShowHideReportingCommunications = (
  getValues: UseFormGetValues<InvestmentProductManagerDTO>,
  sectionIndex: number,
  featureIndex: number,
) => {
  const dropDownProductDataPath = getEditManagerFeatureProductDataPath(
    sectionIndex,
    0,
  );
  const dropDownProductDataValue = getValues(dropDownProductDataPath.value);
  const dropDownProductDataFieldType = getValues(
    dropDownProductDataPath.fieldType,
  );
  const featurePath = getEditManagerFeaturePath(sectionIndex, featureIndex);
  const featureName =
    (getValues(`${featurePath}.name` as EditManagerFieldPath) as string) ?? "";

  if (
    ReportingAndCommunicationManagerFeatures.getFeatureNames().includes(
      featureName,
    )
  ) {
    return (
      dropDownProductDataFieldType === FieldTypeId.Dropdown &&
      dropDownProductDataValue === "None"
    );
  }

  return false;
};

export const handleOnEditManagerGoodScoreFieldChange = ({
  originalValue,
  newValue,
  setValue,
  featureProductDataPath,
}: {
  originalValue?: boolean | null;
  newValue?: boolean | null;
  setValue: UseFormSetValue<InvestmentProductManagerDTO>;
  featureProductDataPath: ManagerFeatureProductDataPath;
}) => {
  setValue(
    featureProductDataPath.isModified,
    !isEqual(!!originalValue, newValue),
  );
  setValue(featureProductDataPath.isPositiveScore, newValue);
};

export const handleOnEditManagerFieldChange = ({
  originalValue,
  isCreatingInvestmentProduct,
  newValue,
  getValues,
  setValue,
  featureProductDataPath,
  ignoreDataStatus = false,
}: {
  originalValue?: string | null;
  isCreatingInvestmentProduct?: boolean;
  newValue: string;
  getValues: UseFormGetValues<InvestmentProductManagerDTO>;
  setValue: UseFormSetValue<InvestmentProductManagerDTO>;
  featureProductDataPath: ManagerFeatureProductDataPath;
  ignoreDataStatus?: boolean;
}) => {
  const publishedValue = defaultTo(
    getValues(featureProductDataPath.publishedValue),
    "",
  );

  let highlightType: HighlightType | null;
  let dataStatus: InvestmentDataStatus | null;

  if (isCreatingInvestmentProduct) {
    highlightType = !!newValue ? HighlightType.Added : null;
  } else {
    highlightType = !isEqual(publishedValue, newValue)
      ? HighlightType.Edited
      : null;
  }

  if (ignoreDataStatus) {
    dataStatus = InvestmentDataStatus.Filled;
  } else {
    dataStatus = !!newValue
      ? InvestmentDataStatus.Filled
      : InvestmentDataStatus.MissingData;
  }

  setValue(featureProductDataPath.highlightType, highlightType);
  setValue(featureProductDataPath.dataStatus, dataStatus);
  setValue(
    featureProductDataPath.isModified,
    !isEqual(originalValue || "", newValue),
  );
};

export const handleEditManagerYesNoFieldChange = ({
  originalValue,
  newValue,
  isCreatingInvestmentProduct,
  getValues,
  setValue,
  featureProductDataPath,
}: {
  originalValue?: string | null;
  newValue: string;
  isCreatingInvestmentProduct?: boolean;
  getValues: UseFormGetValues<InvestmentProductManagerDTO>;
  setValue: UseFormSetValue<InvestmentProductManagerDTO>;
  featureProductDataPath: ManagerFeatureProductDataPath;
}) => {
  const publishedValue = defaultTo(
    getValues(featureProductDataPath.publishedValue),
    "",
  );

  let highlightType: HighlightType | null;
  let dataStatus: InvestmentDataStatus | null;

  if (isCreatingInvestmentProduct) {
    highlightType = !!newValue ? HighlightType.Added : null;
  } else {
    highlightType = !isEqual(publishedValue, newValue)
      ? HighlightType.Edited
      : null;
  }

  dataStatus = !!newValue
    ? InvestmentDataStatus.Filled
    : InvestmentDataStatus.MissingData;

  const newValueIsTrue = isEqual(newValue, "true");
  if (!isCreatingInvestmentProduct && newValueIsTrue) {
    const publishedAdditionalValue =
      getValues(featureProductDataPath.publishedAdditionalValue) ?? "";
    const additionalValue =
      getValues(featureProductDataPath.additionalValue) ?? "";

    const additionalValueEdited = !isEqual(
      publishedAdditionalValue,
      additionalValue,
    );

    highlightType = additionalValueEdited
      ? HighlightType.Edited
      : highlightType;
  }

  setValue(featureProductDataPath.highlightType, highlightType);
  setValue(featureProductDataPath.dataStatus, dataStatus);
  setValue(
    featureProductDataPath.isModified,
    !isEqual(originalValue || "", newValue),
  );
};

export const handleEditManagerAdditionalValueChange = ({
  originalValue,
  isCreatingInvestmentProduct,
  newValue,
  getValues,
  setValue,
  featureProductDataPath,
}: {
  originalValue?: string | null;
  isCreatingInvestmentProduct?: boolean;
  newValue?: string | null;
  getValues: UseFormGetValues<InvestmentProductManagerDTO>;
  setValue: UseFormSetValue<InvestmentProductManagerDTO>;
  featureProductDataPath: ManagerFeatureProductDataPath;
}) => {
  const publishedAdditionalValue = defaultTo(
    getValues(featureProductDataPath.publishedAdditionalValue),
    "",
  );

  let highlightType: HighlightType | null;

  if (isCreatingInvestmentProduct) {
    highlightType = !!newValue ? HighlightType.Added : null;
    setValue(featureProductDataPath.highlightType, highlightType);
    setValue(
      featureProductDataPath.isModified,
      !isEqual(originalValue || "", newValue),
    );
    return;
  } else {
    highlightType = !isEqual(publishedAdditionalValue, newValue)
      ? HighlightType.Edited
      : null;
  }

  const mainValue = getValues(featureProductDataPath.value) ?? "";
  const publishedMainValue =
    getValues(featureProductDataPath.publishedValue) ?? "";
  const mainAdditionalValue =
    getValues(featureProductDataPath.additionalValue) ?? "";
  const publishedMainAdditionalValue =
    getValues(featureProductDataPath.publishedAdditionalValue) ?? "";
  const mainValueEdited = !isEqual(mainValue, publishedMainValue);
  const mainAdditionalValueEdited = !isEqual(
    mainAdditionalValue,
    publishedMainAdditionalValue,
  );

  highlightType =
    mainValueEdited || mainAdditionalValueEdited
      ? HighlightType.Edited
      : highlightType;

  setValue(featureProductDataPath.highlightType, highlightType);
  setValue(
    featureProductDataPath.isModified,
    !isEqual(originalValue || "", newValue),
  );
};
