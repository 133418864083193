import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { InvestmentProductModelsDTO } from "@models/product/investment-product/entities/model";
import { isEmpty, isEqual } from "lodash";
import { useMemo } from "react";
import { VisibilityMessage } from "@pages/suppliers/constants";

interface UseInvestmentViewFeatureStatusProps {
  data: InvestmentProductModelsDTO[] | InvestmentProductSeriesDTO[] | undefined;
}

export const useInvestmentViewFeatureStatus = ({
  data,
}: UseInvestmentViewFeatureStatusProps) => {
  const dataStatus = useMemo(() => {
    const status = {
      hasData: true,
      errorMessage: "",
    };

    if (isEmpty(data)) {
      status.hasData = false;
      status.errorMessage = "Data coming soon";
    }

    if (data?.length === 1) {
      const isViewRestricted = isEqual(data[0].id, "Restricted");

      status.hasData = !isViewRestricted;
      status.errorMessage = isViewRestricted
        ? VisibilityMessage.FEATURE_LEVEL
        : "";
    }

    return status;
  }, [data]);

  return {
    dataStatus,
  };
};
