import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { SHSelect, SHStack } from "@components/design-systems";
import {
  useEditManagerPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { HighlightType } from "@models/configuration";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { CustomLabel } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/custom-label";
import {
  EditManagerInputControllerProps,
  handleNoneFieldsChanged,
  handleOnEditManagerFieldChange,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { hexToRGBA } from "@utils";
import { debounce, isEqual } from "lodash";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useMount } from "react-use";
import { CURRENT_SUPPORTED_INVESTMENT_PRODUCT_TYPES } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/constant";
import { ProductType } from "@models/product/enums/productType";

export const EditManagerDropDownInput = ({
  isCreatingInvestmentProduct,
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
  updateFormDotData,
  isProductTypeDropdown,
}: EditManagerInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductManagerDTO>();

  const { featureProductDataPath, featureDataPath } = useEditManagerPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductManagerDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const defaultValue =
    useMemo(
      () => featureData.lovData?.find((opt) => opt.isDefault)?.id,
      [featureData.lovData],
    ) ?? null;

  const lovData = useMemo(() => {
    if (!isProductTypeDropdown) return featureData?.lovData;

    return featureData?.lovData?.map((opt) => {
      const isSupportedProductType =
        CURRENT_SUPPORTED_INVESTMENT_PRODUCT_TYPES.includes(
          opt.id as ProductType,
        );

      if (!isSupportedProductType)
        return {
          ...opt,
          isDisabled: true,
        };

      return opt;
    });
  }, [isProductTypeDropdown, featureData?.lovData]);

  useMount(() => {
    const value = getValues(featureProductDataPath.value);
    if (!value && !!defaultValue) {
      setValue(featureProductDataPath.value, defaultValue);
      setValue(featureProductDataPath.highlightType, HighlightType.Added);
      setValue(featureProductDataPath.dataStatus, InvestmentDataStatus.Filled);
    }
  });
  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    setValue(
      featureProductDataPath.isModified,
      !isEqual(featureData.viewingGroupSelections, newValue),
    );
    onChange && onChange({ sectionIndex, featureIndex });
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );

  const handleOnChange = (newValue: string, prevValue: string) => {
    handleOnEditManagerFieldChange({
      isCreatingInvestmentProduct,
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
    handleNoneFieldsChanged(
      prevValue,
      newValue,
      sectionIndex,
      getValues,
      setValue,
      featureData,
    );
    updateFormDotData && updateFormDotData();
  };

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  return (
    <SHStack width={{ xs: "100%", md: 520 }} direction={"row"} gap={2}>
      <Controller
        name={featureProductDataPath.value}
        control={control}
        render={({ field: { ref, ...other } }) => {
          return (
            <SHSelect
              {...other}
              fullWidth
              label={
                <CustomLabel
                  featureData={featureData}
                  isDisabled={isDisabled}
                  dotProps={{
                    orangeDot: isShowOrangeDot,
                    blueDot: isShowBlueDot,
                    greenDot: !!featureData?.isBusinessMetric,
                  }}
                />
              }
              dataItemKey="id"
              displayField="name"
              placeholder="Select"
              disabled={isDisabled}
              data={lovData ?? []}
              value={other.value ? other.value : defaultValue}
              sx={{
                "& .sh-select-placeholder": {
                  color: `${hexToRGBA("#3D98FF", 0.6)} !important`,
                },
                flex: 1,
              }}
              allowClear={!defaultValue && !!other.value}
              onClear={(value) => {
                const prevDropDownValue = getValues(
                  featureProductDataPath.value,
                ) as string;

                other.onChange(value);
                debouncedHandleInputChange(value ?? "", prevDropDownValue);
              }}
              onChange={(event) => {
                const newValue = event.target.value;
                const prevDropDownValue = getValues(
                  featureProductDataPath.value,
                ) as string;

                other.onChange(newValue);
                debouncedHandleInputChange(newValue, prevDropDownValue);
              }}
            />
          );
        }}
      />
      {featureData.allowedVisibilitySetting && (
        <Controller
          name={featureDataPath.viewingGroupSelections}
          control={control}
          render={() => (
            <SelectViewingGroups
              disabled={isDisabled}
              textFieldProps={{
                label: `Visible to`,
              }}
              sx={{
                flexBasis: "40%",
              }}
              onChange={(value) => {
                setValue(featureDataPath.viewingGroupSelections, value);
                debounceVisibilityChange(value);
              }}
              value={
                (getValues(featureDataPath.viewingGroupSelections) as
                  | ViewingGroupSelectionDTO[]
                  | null) || []
              }
              viewingGroupData={featureData.viewingGroupSelections ?? []}
            />
          )}
        />
      )}
    </SHStack>
  );
};
