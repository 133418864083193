import { InformationButton } from "@components/buttons/information";
import { SHBox, SHDotGroup, SHTypography } from "@components/design-systems";
import ImapIconTooltip from "@components/images/imap-tooltip";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { Variant } from "@mui/material/styles/createTypography";
import { IconMessage } from "@pages/suppliers/constants";
import { generateFeatureName } from "@utils/text-format";
import { useMemo } from "react";

type CustomLabelVariant = Variant | "body3" | "body4";

interface CustomLabelProps {
  variant?: CustomLabelVariant;
  featureData: InvestmentProductConfigurationFeature;
  isDisabled?: boolean;
  disableInformationButton?: boolean;
  dotProps: {
    blueDot?: boolean;
    orangeDot?: boolean;
    redDot?: boolean;
    greenDot?: boolean;
  };
}

export const CustomLabel = ({
  variant = "subtitle2",
  featureData,
  isDisabled = false,
  disableInformationButton = false,
  dotProps = {
    blueDot: false,
    orangeDot: false,
    redDot: false,
    greenDot: false,
  },
}: CustomLabelProps) => {
  const { last, first } = generateFeatureName(
    featureData?.name ?? "",
    featureData.description,
  );

  const imapIconLabel = useMemo(() => {
    return !!featureData.isImapAwardFeature ? (
      <SHBox
        component={"span"}
        style={{
          display: "inline-block",
          marginLeft: "0px",
          marginRight: "7px",
          marginTop: (featureData.description && !disableInformationButton) ? "3px" : "undefine",
          verticalAlign: "-3px",
        }}
      >
        <ImapIconTooltip
          tooltipProps={{
            title: IconMessage.IMAP_FEATURE_AWARD,
          }}
          iconProps={{
            height: "18px",
            width: "18px",
          }}
        />
      </SHBox>
    ) : undefined;
  }, [featureData.isImapAwardFeature, featureData.description, disableInformationButton]);

  const postfixLabel = useMemo(() => {
    if (disableInformationButton) return undefined;
    return !!featureData.description ? (
      <InformationButton content={featureData.description} />
    ) : undefined;
  }, [featureData.description, disableInformationButton]);

  return (
    <SHTypography variant={variant} disabled={isDisabled}>
      <SHBox display="flex" alignItems="flex-start">
        {imapIconLabel}
        <SHBox component="span" style={{ display: "inline-block" }}>
          {first}
          <SHBox
            component={"span"}
            style={{ display: "inline-block", position: "relative" }}
          >
            {last}
            <SHBox
              component={"span"}
              style={{
                display: "inline-block",
                verticalAlign: "7px",
                marginLeft: "5px",
              }}
            >
              <SHBox display={"flex"} gap={0.5}>
                <SHDotGroup
                  blueDot={dotProps.blueDot}
                  orangeDot={dotProps.orangeDot}
                  redDot={dotProps.redDot}
                  greenDot={dotProps.greenDot}
                />
              </SHBox>
            </SHBox>

            <SHBox
              component={"span"}
              style={{
                display: "inline-block",
                verticalAlign: "3px",
              }}
            >
              {postfixLabel}
            </SHBox>
          </SHBox>
        </SHBox>
      </SHBox>
    </SHTypography>
  );
};
