import {
  SHBox,
  SHButton,
  SHCheckbox,
  SHFilterOption,
  SHFormControlLabel,
  SHPopper,
  SHRadio,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHDatePicker } from "@components/design-systems/sh-date-picker";
import { FilterSVG } from "@components/svgs";
import { DocumentPeriod } from "@models/document-library/enums/documentPeriod";

import { FormGroup, useTheme } from "@mui/material";
import {
  DefaultPeriodFilterDate,
  MAX_MONTH_OFFSET,
  PeriodFilterOptions,
  TypeFilterOptions,
} from "@pages/document-library/constant";
import { getDateRange } from "@pages/document-library/util";
import { hexToRGBA } from "@utils";
import { addMonths, endOfMonth, startOfMonth } from "date-fns";
import { isEqual, isNil } from "lodash";
import React, { useEffect, useRef, useState } from "react";
interface DocumentFilterButtonProps {
  onPeriodFilter?: (periodDateRange: DateRangeFilter | null) => void;
  onTypeFilter?: (typeFilterValue: string[]) => void;
}
export type DateRangeFilter = {
  fromDate: Date | null;
  toDate: Date | null;
};
export type FormattedDateRangeFilter = {
  fromDate: string | null;
  toDate: string | null;
};
const DocumentFilterButton = ({
  onPeriodFilter,
  onTypeFilter,
}: DocumentFilterButtonProps) => {
  const { palette } = useTheme();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  const [periodFilterValue, setPeriodFilterValue] =
    useState<SHFilterOption | null>(null);

  const [typeFilterOptions, setTypeFilterOptions] =
    useState<SHFilterOption[]>(TypeFilterOptions);
  const [isAllTypeFilter, setIsAllTypeFilter] = useState<boolean>(true);
  const [typeFilterValue, setTypeFilterValue] = useState<string[]>(
    TypeFilterOptions.map((option) => option.value),
  );

  const [periodDateRange, setPeriodDateRange] = useState<DateRangeFilter>(
    DefaultPeriodFilterDate,
  );

  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const handlePeriodFilterChange = (
    newPeriodFilterValue: SHFilterOption | null,
  ) => {
    let newDateRange: DateRangeFilter;

    if (newPeriodFilterValue === null) {
      newDateRange = { fromDate: null, toDate: null };
    } else if (newPeriodFilterValue?.value === DocumentPeriod.Custom) {
      newDateRange = DefaultPeriodFilterDate;
    } else {
      newDateRange = getDateRange(newPeriodFilterValue.value);
    }

    setPeriodDateRange(newDateRange);
    setPeriodFilterValue(newPeriodFilterValue);

    if (onPeriodFilter) {
      onPeriodFilter(newDateRange);
    }
  };

  const handleTypeFilterChange = (value: string | null) => {
    if (isNil(value)) {
      toggleAllTypeFilter();
      return;
    }

    updateTypeFilter(value);
  };

  const toggleAllTypeFilter = () => {
    const newValue = isAllTypeFilter
      ? []
      : TypeFilterOptions.map((option) => option.value);
    setTypeFilterValue(newValue);
    setIsAllTypeFilter(!isAllTypeFilter);

    if (onTypeFilter) {
      onTypeFilter(newValue);
    }
  };

  const updateTypeFilter = (value: string) => {
    const newTypeFilterValue = typeFilterValue.includes(value)
      ? typeFilterValue.filter((filterValue) => !isEqual(filterValue, value))
      : [...typeFilterValue, value];

    const isAllSelected = isEqual(
      TypeFilterOptions.length,
      newTypeFilterValue.length,
    );

    setTypeFilterValue(newTypeFilterValue);
    setIsAllTypeFilter(isAllSelected);

    if (isAllSelected) {
      setTypeFilterOptions(TypeFilterOptions);
    }

    if (onTypeFilter) {
      onTypeFilter(newTypeFilterValue);
    }
  };

  const isCheckedTypeFilter = (value: string | null) => {
    return isNil(value) ? isAllTypeFilter : typeFilterValue.includes(value);
  };

  useEffect(() => {
    if (!open) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest(".MuiPickersPopper-root")
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const FilterIcon = () => (
    <SHStack
      width={"16px"}
      height={"16px"}
      alignItems={"center"}
      justifyContent="start"
    >
      <FilterSVG
        width={"15px"}
        height={"15px"}
        data-testid="filter-icon"
        fill={palette.primary.main}
      />
    </SHStack>
  );

  return (
    <>
      <SHButton
        color="primary"
        variant="text"
        size="extraMedium"
        onClick={handleClick}
        ref={buttonRef}
        startIcon={<FilterIcon />}
      >
        Filters
      </SHButton>
      <SHPopper
        anchorEl={anchorEl}
        open={open}
        placement="bottom-end"
        popperOptions={{ modifiers: [{ name: "flip", enabled: false }] }}
      >
        <SHStack
          ref={wrapperRef}
          sx={{
            boxSizing: "border-box",
            boxShadow: "none",
            borderWidth: 1,
            borderColor: palette.divider,
            borderStyle: "solid",
            borderRadius: 0,
            backgroundColor: hexToRGBA(palette.common.white, 0.95),
            paddingX: 2,
            paddingY: 1,
            minWidth: "170px",
            filter: "drop-shadow(1px 1px 2px rgba(65, 73, 89, 0.25))",
          }}
        >
          <SHStack
            flexDirection={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            gap={2}
          >
            {/* period filter */}
            <SHStack>
              <SHStack spacing={1} direction="row" alignItems="center">
                <FilterSVG />
                <SHTypography variant="body1">Date range</SHTypography>
              </SHStack>
              <FormGroup sx={{ padding: "2px" }}>
                <SHFormControlLabel
                  size="small"
                  checked={periodFilterValue === null}
                  control={<SHRadio size={"small"} />}
                  onClick={() => handlePeriodFilterChange(null)}
                  label={"All"}
                />
                {PeriodFilterOptions.map((option) => (
                  <SHFormControlLabel
                    key={option.value}
                    checked={periodFilterValue?.value === option.value}
                    onClick={() => {
                      handlePeriodFilterChange(option);
                    }}
                    size="small"
                    control={<SHRadio size={"small"} />}
                    label={option.label}
                  />
                ))}
                {periodFilterValue?.value === DocumentPeriod.Custom && (
                  <SHStack
                    direction={"row"}
                    gap={1}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <SHDatePicker
                      sxTextField={{
                        width: "120px",
                      }}
                      maxDate={
                        periodDateRange.toDate
                          ? periodDateRange.toDate
                          : endOfMonth(addMonths(new Date(), MAX_MONTH_OFFSET))
                      }
                      onChange={(date) => {
                        setPeriodDateRange((prev) => {
                          const updatedRange = {
                            ...prev,
                            fromDate: date ? startOfMonth(date) : null,
                          };
                          onPeriodFilter && onPeriodFilter(updatedRange);
                          return updatedRange;
                        });
                      }}
                      value={periodDateRange?.fromDate}
                      minDate={new Date("1/1/2022")}
                      views={["year", "month"]}
                      inputFormat="MMM yyyy"
                    />
                    <SHBox>-</SHBox>
                    <SHDatePicker
                      maxDate={endOfMonth(
                        addMonths(new Date(), MAX_MONTH_OFFSET),
                      )}
                      minDate={
                        periodDateRange.fromDate
                          ? periodDateRange.fromDate
                          : new Date("1/1/2022")
                      }
                      sxTextField={{
                        width: "120px",
                      }}
                      onChange={(date) => {
                        setPeriodDateRange((prev) => {
                          const updatedRange = {
                            ...prev,
                            toDate: date ? endOfMonth(date) : null,
                          };
                          onPeriodFilter && onPeriodFilter(updatedRange);
                          return updatedRange;
                        });
                      }}
                      value={periodDateRange?.toDate}
                      views={["year", "month"]}
                      inputFormat="MMM yyyy"
                    />
                  </SHStack>
                )}
              </FormGroup>
            </SHStack>
            {/* end period filter */}
            {/* type filter */}
            <SHStack>
              <SHStack spacing={1} direction="row" alignItems="center">
                <FilterSVG />
                <SHTypography variant="body1">Type</SHTypography>
              </SHStack>
              <FormGroup sx={{ padding: "2px" }}>
                <SHFormControlLabel
                  size="small"
                  control={
                    <SHCheckbox
                      size={"small"}
                      checked={isCheckedTypeFilter(null)}
                      onClick={() => handleTypeFilterChange(null)}
                    />
                  }
                  label={"All"}
                />
                {typeFilterOptions.map((option) => (
                  <SHFormControlLabel
                    key={option.value}
                    size="small"
                    control={
                      <SHCheckbox
                        size={"small"}
                        checked={isCheckedTypeFilter(option.value)}
                        onClick={() => handleTypeFilterChange(option.value)}
                      />
                    }
                    label={option.label}
                  />
                ))}
              </FormGroup>
            </SHStack>
            {/* end type filter */}
          </SHStack>
        </SHStack>
      </SHPopper>
    </>
  );
};

export default DocumentFilterButton;
