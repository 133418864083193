import { APIExtRoutes } from "@constants";
import { SupplierLovDTO } from "@models/supplier/entities/suppliers";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSupplierLov } from "@services/viewing-group/viewingGroupService";

export interface ViewingGroupStore {
  supplierLov: SupplierLovDTO[];
  ui: {
    isLoadingSupplierLov: boolean;
  };
}

const initialState: ViewingGroupStore = {
  supplierLov: [],
  ui: {
    isLoadingSupplierLov: false,
  },
};
const viewingGroupSlice = createSlice({
  name: "viewingGroup",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSupplierLovThunk.pending, (state, action) => {
      state.ui.isLoadingSupplierLov = true;
    });
    builder.addCase(getSupplierLovThunk.fulfilled, (state, action) => {
      if (action.payload.isSuccess && action.payload.data) {
        state.supplierLov = action.payload.data;
      }
      state.ui.isLoadingSupplierLov = false;
    });
    builder.addCase(getSupplierLovThunk.rejected, (state, action) => {
      state.ui.isLoadingSupplierLov = false;
    });
  },
});

export const getSupplierLovThunk = createAsyncThunk(
  APIExtRoutes.supplierLov,
  async () => {
    return await getSupplierLov();
  },
);
export default viewingGroupSlice.reducer;
