import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHLabel,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTableHead,
  SHTableRow,
  SHTypography,
} from "@components/design-systems";
import { SHNumberTextField } from "@components/design-systems/sh-number-text-field";
import { SHNumericFieldV2 } from "@components/design-systems/sh-numeric-field-v2";
import { MAX_LONG_TEXT_LENGTH } from "@constants";
import { DateFormat } from "@constants/format";
import { useUpdateSectionData } from "@hooks/useInvestmentProduct";
import { FieldTypeId, HighlightType } from "@models/configuration";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import {
  InvestmentProductConfigurationModelSection,
  InvestmentProductModelsDTO,
  ProductModelValueDTO,
} from "@models/product/investment-product/entities/model";
import {
  InvestmentBannerStatus,
  InvestmentDataStatus,
} from "@models/product/investment-product/enums/status";
import { useTheme } from "@mui/material";
import DependField from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/depend-field";
import { TableBooleanField } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/table-boolean";
import { TableDatePicker } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/table-date-picker";
import { TableDropDown } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/table-drop-down";
import { TableFieldLabel } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/table-label";
import TableTextField from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/table-text-field";
import { TableViewingGroupSelection } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/table-viewing-group-selection";
import {
  areCompositeValuesEqual,
  EditModelFieldPath,
  FeaturePath,
  FeatureProductDataPath,
  getFeaturePaths,
  getFeatureProductDataPaths,
  isPercentageFeatureWithinLimit,
  updateCompositeJsonField,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import {
  EditProductDefaults,
  SpecialModelsSection,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { FRCell } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/table-cell";
import { checkStringIsNumber, tryParseJson } from "@pages/suppliers/_id/util";
import {
  DependedFeature,
  InvestmentFeatureHiddenInViewMode,
} from "@pages/suppliers/constants";
import { RootState } from "@redux/store";
import { format, isValid } from "date-fns";
import { defaultTo, isEmpty, isEqual, isNil, toNumber, toString } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useMount } from "react-use";
import SHRichTextEditor2 from "@components/design-systems/sh-rich-text-editor-v2";

const DEFAULT_CELL_WIDTH = 35;
const DEFAULT_FIRST_CELL_WIDTH = 25;
const DEFAULT_NUM_OF_CELL = 1;
const DEFAULT_NUM_OF_HEAD_COL = 1;
const MAX_TABLE_HEADER_WIDTH = 70;

interface ModelPageTableEditProps {
  section: InvestmentProductConfigurationModelSection;
  sectionIndex: number;
  selectedIndex: [number, number];
}

const ModelPageTableEdit = ({
  section,
  sectionIndex,
  selectedIndex,
}: ModelPageTableEditProps) => {
  /** Destruct */
  let {
    tableHeaderCategory,
    features: sectionFeatures,
    name: sectionName,
  } = section;
  /** Hook */
  const {
    investmentProductUI: { isSaving },
    investmentProductBannerInfo,
  } = useSelector((state: RootState) => state.investmentProduct);
  const { control, getValues, setValue } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const { palette } = useTheme();
  const handleUpdateSectionData = useUpdateSectionData(
    selectedIndex,
    sectionIndex,
  );
  const isPendingApproval = investmentProductBannerInfo?.some((banner) =>
    isEqual(banner.status, InvestmentBannerStatus.PendingApproval),
  );
  const [isMounted, setIsMounted] = useState<boolean>(false);
  useMount(() => setIsMounted(true));

  /**
   *  Update data status and highlight type for composite field which value is json format
   */

  const updateCompositeDataStatus = (
    productDataPath: FeatureProductDataPath,
    featurePath: FeaturePath,
    jsonValue: string,
    feature: InvestmentProductConfigurationFeature,
  ) => {
    const originalValue = feature.productData?.value;
    const isModified = !isEqual(jsonValue, originalValue);
    const productDataValues = tryParseJson(jsonValue) as ProductModelValueDTO[];
    if (!Array.isArray(productDataValues)) return;

    const isFilled = productDataValues.every((item) => !isEmpty(item.value));
    setValue(
      productDataPath.dataStatus,
      isFilled ? InvestmentDataStatus.Filled : InvestmentDataStatus.MissingData,
    );
    setValue(
      featurePath.dataStatus,
      isFilled ? InvestmentDataStatus.Filled : InvestmentDataStatus.MissingData,
    );

    const publishedValue = toString(getValues(productDataPath.publishedValue));
    setValue(
      productDataPath.highlightType,
      !areCompositeValuesEqual(publishedValue, jsonValue)
        ? HighlightType.Edited
        : null,
    );
    setValue(
      featurePath.highlightType,
      !areCompositeValuesEqual(publishedValue, jsonValue)
        ? HighlightType.Edited
        : null,
    );
    setValue(productDataPath.isModified, isModified);
    setValue(featurePath.isModified, isModified);
  };

  const updateFieldPaths = useCallback(() => {
    const passiveShareIndex = sectionFeatures?.findIndex(
      (feature) => feature.name === DependedFeature.PASSIVE_SHARE,
    );
    const targetDefensiveIndex = sectionFeatures?.findIndex(
      (feature) => feature.name === DependedFeature.TARGET_DEFENSIVE_EXPOSURE,
    );
    const passiveShareFeature = sectionFeatures?.find(
      (feature) => feature.name === DependedFeature.PASSIVE_SHARE,
    );
    const targetDefensiveFeature = sectionFeatures?.find(
      (feature) => feature.name === DependedFeature.TARGET_DEFENSIVE_EXPOSURE,
    );

    if (passiveShareIndex && passiveShareIndex !== -1) {
      setPassiveShareFieldPath(
        getFeatureProductDataPaths(
          selectedIndex,
          sectionIndex,
          passiveShareIndex,
        ),
      );

      setPassiveShareFeaturePath(
        getFeaturePaths(selectedIndex, sectionIndex, passiveShareIndex),
      );
    }
    if (passiveShareFeature) {
      setPassiveShareFeature(passiveShareFeature);
    }
    if (targetDefensiveFeature) {
      setTargetDefensiveFeature(targetDefensiveFeature);
    }

    if (targetDefensiveIndex && targetDefensiveIndex !== -1) {
      setTargetDefensiveFieldPath(
        getFeatureProductDataPaths(
          selectedIndex,
          sectionIndex,
          targetDefensiveIndex,
        ),
      );
      setTargetDefensiveFeaturePath(
        getFeaturePaths(selectedIndex, sectionIndex, targetDefensiveIndex),
      );
    }
  }, [sectionFeatures, sectionIndex, selectedIndex]);
  const updateDisableAAFeature = useCallback(() => {
    const targetExistFeatureIndex = sectionFeatures?.findIndex(
      (feature) =>
        feature.name === InvestmentFeatureHiddenInViewMode.TARGET_EXISTS,
    );

    if (
      targetExistFeatureIndex !== undefined &&
      targetExistFeatureIndex !== -1 &&
      isMounted
    ) {
      const targetExistFeaturePath = getFeatureProductDataPaths(
        selectedIndex,
        sectionIndex,
        targetExistFeatureIndex,
      );
      const targetExistValue = getValues(targetExistFeaturePath.value) as
        | string
        | null;
      setIsDisabledAAFeature(targetExistValue === "false");
    }
    // eslint-disable-next-line
  }, [isMounted, sectionFeatures, sectionIndex, selectedIndex]);

  /** Effect */
  useEffect(() => {
    updateFieldPaths();
  }, [updateFieldPaths]);
  useEffect(() => {
    updateDisableAAFeature();
  }, [updateDisableAAFeature]);

  /** State */
  const [passiveShareFieldPath, setPassiveShareFieldPath] =
    useState<FeatureProductDataPath | null>(null);
  const [targetDefensiveFieldPath, setTargetDefensiveFieldPath] =
    useState<FeatureProductDataPath | null>(null);
  const [passiveShareFeaturePath, setPassiveShareFeaturePath] =
    useState<FeaturePath | null>(null);
  const [targetDefensiveFeaturePath, setTargetDefensiveFeaturePath] =
    useState<FeaturePath | null>(null);
  const [targetDefensiveFeature, setTargetDefensiveFeature] =
    useState<InvestmentProductConfigurationFeature | null>(null);
  const [passiveShareFeature, setPassiveShareFeature] =
    useState<InvestmentProductConfigurationFeature | null>(null);
  const [isDisabledAAFeature, setIsDisabledAAFeature] = useState(false);
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>(
    {},
  );

  const handleRowClick = (feature: InvestmentProductConfigurationFeature) => {
    if (!feature || !feature.allowedVisibilitySetting || !feature.id) return;
    const featureId = feature.id;
    setExpandedRows((prev) => ({
      ...prev,
      [featureId]: !prev[featureId],
    }));
  };

  if (isEmpty(sectionFeatures)) return <></>;

  let cellWidth = DEFAULT_CELL_WIDTH;
  let firstCellWidth = DEFAULT_FIRST_CELL_WIDTH;
  let numOfCell = DEFAULT_NUM_OF_CELL;
  let numberOfHeadCol = DEFAULT_NUM_OF_HEAD_COL;

  if (tableHeaderCategory?.values) {
    numOfCell = tableHeaderCategory.values.length;
    cellWidth = MAX_TABLE_HEADER_WIDTH / numOfCell;
  }
  if (isEmpty(tableHeaderCategory?.values)) {
    numberOfHeadCol += 1;
  }

  /**Input Change handling */
  /**
   * handle update depended field
   * for case a feature value will calculate from another (passive share, target growth exposure)
   *
   */

  const handleUpdateDependedField = (
    newValue: string | null,
    featureName?: string,
  ) => {
    if (isEmpty(featureName)) return;
    if (
      featureName === DependedFeature.ACTIVE_SHARE &&
      passiveShareFieldPath &&
      passiveShareFeaturePath &&
      passiveShareFeature
    ) {
      const passiveShareValue = checkStringIsNumber(newValue)
        ? 100 - toNumber(newValue)
        : newValue || "";

      handleInputChange(
        toString(passiveShareValue),
        passiveShareFieldPath,
        passiveShareFeaturePath,
        undefined,
        passiveShareFeature,
      );
    } else if (
      featureName === DependedFeature.TARGET_GROWTH_EXPOSURE &&
      targetDefensiveFieldPath &&
      targetDefensiveFeaturePath &&
      targetDefensiveFeature
    ) {
      const targetDefensiveValue = checkStringIsNumber(newValue)
        ? 100 - toNumber(newValue)
        : newValue || "";

      handleNumericChange(
        toString(targetDefensiveValue),
        targetDefensiveFieldPath,
        targetDefensiveFeaturePath,
        undefined,
        targetDefensiveFeature,
      );
    }
  };
  const handleInputChange = (
    newValue: string,
    productDataPath: FeatureProductDataPath,
    featurePath: FeaturePath,
    headerId: string | undefined,
    feature: InvestmentProductConfigurationFeature,
  ) => {
    const publishedValue = getValues(productDataPath.publishedValue);
    const originalValue = feature.productData?.value;
    //single field and empty value
    if (isEmpty(newValue) && isEmpty(headerId)) {
      setValue(productDataPath.value, null);
      setValue(productDataPath.dataStatus, InvestmentDataStatus.MissingData);
      setValue(featurePath.dataStatus, InvestmentDataStatus.MissingData);
      setValue(productDataPath.isModified, originalValue !== null);
      setValue(featurePath.isModified, originalValue !== null);
      setValue(
        productDataPath.highlightType,
        publishedValue == null ? null : HighlightType.Edited,
      );
      setValue(
        featurePath.highlightType,
        publishedValue == null ? null : HighlightType.Edited,
      );
      return;
    }
    //single field and has value
    if (isEmpty(headerId)) {
      setValue(productDataPath.value, newValue);
      setValue(
        productDataPath.highlightType,
        publishedValue !== newValue ? HighlightType.Edited : null,
      );
      setValue(
        featurePath.highlightType,
        publishedValue !== newValue ? HighlightType.Edited : null,
      );
      setValue(productDataPath.dataStatus, InvestmentDataStatus.Filled);
      setValue(featurePath.dataStatus, InvestmentDataStatus.Filled);
      setValue(featurePath.isModified, !isEqual(originalValue, newValue));
      setValue(productDataPath.isModified, !isEqual(originalValue, newValue));

      return;
    }
    // composite field
    const oldValue = getValues(productDataPath.value) as string;
    const updatedProductDataValues = updateCompositeJsonField(
      newValue,
      oldValue,
      headerId,
    );
    setValue(productDataPath.value, updatedProductDataValues);
    updateCompositeDataStatus(
      productDataPath,
      featurePath,
      updatedProductDataValues,
      feature,
    );
  };
  const handleNumericChange = (
    newValue: string,
    productDataPath: FeatureProductDataPath,
    featurePath: FeaturePath,
    headerId: string | undefined,
    feature: InvestmentProductConfigurationFeature,
  ) => {
    const originalValue = getValues(productDataPath.publishedValue);
    const publishedValue = getValues(productDataPath.publishedValue);
    const numberPublishedValue = toNumber(publishedValue);
    const numberNewValue = toNumber(newValue);
    let isEdited = !isEqual(numberNewValue, numberPublishedValue);
    if (isNaN(numberNewValue) || isNaN(numberPublishedValue)) {
      isEdited = !isEqual(newValue, publishedValue);
    }
    if (isEmpty(newValue) && isEmpty(headerId)) {
      // individual field, not match any headerId
      setValue(productDataPath.value, null);
      setValue(
        productDataPath.highlightType,
        isEmpty(publishedValue) ? null : HighlightType.Edited,
      );
      setValue(
        featurePath.highlightType,
        isEmpty(publishedValue) ? null : HighlightType.Edited,
      );

      setValue(productDataPath.dataStatus, InvestmentDataStatus.MissingData);
      setValue(featurePath.dataStatus, InvestmentDataStatus.MissingData);
      setValue(productDataPath.isModified, !isEmpty(originalValue));
      setValue(featurePath.isModified, !isEmpty(originalValue));
      return;
    }
    if (isEmpty(headerId)) {
      setValue(productDataPath.value, newValue);
      setValue(productDataPath.dataStatus, InvestmentDataStatus.Filled);
      setValue(
        productDataPath.highlightType,
        isEdited ? HighlightType.Edited : null,
      );
      setValue(
        featurePath.highlightType,
        isEdited ? HighlightType.Edited : null,
      );
      setValue(featurePath.dataStatus, InvestmentDataStatus.Filled);
      setValue(featurePath.isModified, !isEqual(newValue, originalValue));
      setValue(productDataPath.isModified, !isEqual(newValue, originalValue));

      return;
    }
    // has headerId -> composite field
    const oldValue = getValues(productDataPath.value) as string;
    const updatedProductDataValues = updateCompositeJsonField(
      newValue,
      oldValue,
      headerId,
    );
    setValue(productDataPath.value, updatedProductDataValues);

    updateCompositeDataStatus(
      productDataPath,
      featurePath,
      updatedProductDataValues,
      feature,
    );
  };

  const handleDateTimeChange = (
    newValue: Date | null,
    productDataPath: FeatureProductDataPath,
    featurePath: FeaturePath,
    headerId: string | undefined,
    feature: InvestmentProductConfigurationFeature,
  ) => {
    const originalValue = feature.productData?.value;
    // single field
    const publishedValue = getValues(productDataPath.publishedValue);
    if (newValue === null && isEmpty(headerId)) {
      setValue(productDataPath.value, null);
      setValue(productDataPath.dataStatus, InvestmentDataStatus.MissingData);
      setValue(featurePath.dataStatus, InvestmentDataStatus.MissingData);
      setValue(productDataPath.isModified, !isEmpty(originalValue));
      setValue(featurePath.isModified, !isEmpty(originalValue));
      setValue(
        productDataPath.highlightType,
        isEmpty(publishedValue) ? null : HighlightType.Edited,
      );
      setValue(
        featurePath.highlightType,
        isEmpty(publishedValue) ? null : HighlightType.Edited,
      );

      return;
    }
    if (!isValid(newValue)) return;
    const inputDateStr = format(newValue ?? new Date(), DateFormat);
    if (isEmpty(headerId)) {
      setValue(productDataPath.value, inputDateStr);
      setValue(
        productDataPath.highlightType,
        publishedValue !== inputDateStr ? HighlightType.Edited : null,
      );
      setValue(
        featurePath.highlightType,
        publishedValue !== inputDateStr ? HighlightType.Edited : null,
      );
      setValue(productDataPath.dataStatus, InvestmentDataStatus.Filled);
      setValue(featurePath.dataStatus, InvestmentDataStatus.Filled);
      setValue(featurePath.isModified, !isEqual(inputDateStr, originalValue));
      setValue(
        productDataPath.isModified,
        !isEqual(inputDateStr, originalValue),
      );
      return;
    }
    // composite field
    const oldValue = getValues(productDataPath.value) as string;
    const updatedProductDataValues = updateCompositeJsonField(
      inputDateStr,
      oldValue,
      headerId,
    );
    setValue(productDataPath.value, updatedProductDataValues);
    updateCompositeDataStatus(
      productDataPath,
      featurePath,
      updatedProductDataValues,
      feature,
    );
  };
  /**Input value display rendering */
  const renderProductDataValue = (
    fieldPath: EditModelFieldPath,
    headerId: string | undefined,
  ) => {
    const productValue = getValues(fieldPath) as string | undefined;
    if (isEmpty(headerId)) return productValue || "";
    if (isEmpty(productValue)) return "";
    const productDataValues: ProductModelValueDTO[] | string = tryParseJson(
      productValue || "",
    );
    if (typeof productDataValues !== "object") {
      return productDataValues;
    }
    const productDataValue = productDataValues.find(
      (value) => value.headerId === headerId,
    );
    return productDataValue?.value || "";
  };
  const renderNumericValue = (
    fieldPath: EditModelFieldPath,
    headerId: string | undefined,
  ) => {
    const stringValue = toString(renderProductDataValue(fieldPath, headerId));
    return stringValue;
  };

  const renderFeatureFormField = (
    feature: InvestmentProductConfigurationFeature,
    productData: ProductModelValueDTO,
    featureIndex: number,
  ) => {
    const featureProductDataPath = getFeatureProductDataPaths(
      selectedIndex,
      sectionIndex,
      featureIndex,
    );
    const featurePath = getFeaturePaths(
      selectedIndex,
      sectionIndex,
      featureIndex,
    );
    let fieldType = feature.fieldType?.id;
    if (feature.fieldType?.id === FieldTypeId.Composite) {
      fieldType = feature.secondaryFieldType?.id;
    }

    switch (fieldType) {
      case FieldTypeId.YesNo: {
        return (
          <TableBooleanField
            isDisabled={isSaving || isPendingApproval}
            headerId={productData.headerId}
            selectedIndex={selectedIndex}
            featureIndex={featureIndex}
            sectionIndex={sectionIndex}
            feature={feature}
            isShowClearButton={
              !isEqual(
                feature.name,
                InvestmentFeatureHiddenInViewMode.TARGET_EXISTS,
              )
            }
            onChange={(value) => {
              const isTargetExist = defaultTo(value, true);
              if (isEqual(sectionName, SpecialModelsSection.AssetAllocation)) {
                setIsDisabledAAFeature(!isTargetExist);
              }
            }}
          />
        );
      }

      case FieldTypeId.Dropdown: {
        return (
          <Controller
            name={featureProductDataPath.value}
            control={control}
            render={({ field }) => (
              <TableDropDown
                feature={feature}
                featureIndex={featureIndex}
                sectionIndex={sectionIndex}
                selectedIndex={selectedIndex}
                sx={{
                  width: `calc(100% / ${numberOfHeadCol})`,
                  "& .MuiInput-root > .MuiSelect-select": {
                    paddingLeft: "0px",
                  },
                }}
                onClear={(value) => {
                  handleInputChange(
                    value ?? "",
                    featureProductDataPath,
                    featurePath,
                    productData.headerId,
                    feature,
                  );

                  handleUpdateSectionData();
                }}
                disabled={isSaving || isPendingApproval}
                onChange={(e) => {
                  handleInputChange(
                    e.target.value,
                    featureProductDataPath,
                    featurePath,
                    productData.headerId,
                    feature,
                  );
                  handleUpdateSectionData();
                }}
              />
            )}
          />
        );
      }

      case FieldTypeId.Percentage: {
        if (sectionName === SpecialModelsSection.AssetAllocation) {
          return (
            <Controller
              name={featureProductDataPath.value}
              control={control}
              render={({ field }) => (
                <SHNumericFieldV2
                  fullWidth
                  disabled={
                    isSaving || isDisabledAAFeature || isPendingApproval
                  }
                  sx={{
                    "& .MuiInput-root > input": {
                      padding: "10px 0px 8px 0px",
                    },
                  }}
                  suffix="%"
                  thousandSeparator={true}
                  valueIsNumericString
                  placeholder={"e.g. Enter 0.25 for 0.25%"}
                  value={renderNumericValue(
                    featureProductDataPath.value,
                    productData.headerId,
                  )}
                  onValueChange={({ value }) => {
                    handleNumericChange(
                      value,
                      featureProductDataPath,
                      featurePath,
                      productData.headerId,
                      feature,
                    );
                    handleUpdateSectionData();
                  }}
                />
              )}
            />
          );
        }

        return (
          <Controller
            name={featureProductDataPath.value}
            control={control}
            render={({ field }) => (
              <SHNumericFieldV2
                fullWidth
                disabled={isSaving || isPendingApproval}
                sx={{
                  "& .MuiInput-root > input": {
                    padding: "10px 0px 8px 0px",
                  },
                }}
                suffix="%"
                thousandSeparator={true}
                fixedDecimalScale
                valueIsNumericString
                placeholder={"e.g. Enter 0.25 for 0.25%"}
                value={renderNumericValue(
                  featureProductDataPath.value,
                  productData.headerId,
                )}
                onValueChange={(values) => {
                  handleNumericChange(
                    values.value,
                    featureProductDataPath,
                    featurePath,
                    productData.headerId,
                    feature,
                  );
                  handleUpdateSectionData();
                }}
              />
            )}
          />
        );
      }
      case FieldTypeId.PercentageText: {
        if (isEqual(feature.name, DependedFeature.TARGET_DEFENSIVE_EXPOSURE)) {
          return (
            <DependField
              featureIndex={featureIndex}
              sectionIndex={sectionIndex}
              selectedIndex={selectedIndex}
            />
          );
        }
        if (isEqual(feature.name, DependedFeature.PASSIVE_SHARE)) {
          return (
            <DependField
              featureIndex={featureIndex}
              sectionIndex={sectionIndex}
              selectedIndex={selectedIndex}
            />
          );
        }
        return (
          <Controller
            name={featureProductDataPath.value}
            control={control}
            render={({ field }) => (
              <SHBox sx={{ width: `calc(100% / ${numberOfHeadCol})` }}>
                <SHNumberTextField
                  sx={{
                    "& .MuiInput-root > input": {
                      padding: "10px 0px 8px 0px",
                    },
                  }}
                  fullWidth
                  max={
                    isPercentageFeatureWithinLimit(feature)
                      ? EditProductDefaults.MaxPercentageValue
                      : undefined
                  }
                  placeholder="e.g. Enter 0.25 for 0.25%"
                  allowNegative={!isPercentageFeatureWithinLimit(feature)}
                  suffix="%"
                  value={renderProductDataValue(
                    featureProductDataPath.value,
                    productData.headerId,
                  )}
                  disabled={isSaving || isPendingApproval}
                  onChange={(value) => {
                    handleNumericChange(
                      value || "",
                      featureProductDataPath,
                      featurePath,
                      productData.headerId,
                      feature,
                    );
                    handleUpdateDependedField(value, feature.name);
                    handleUpdateSectionData();
                  }}
                />
              </SHBox>
            )}
          />
        );
      }
      case FieldTypeId.NumberText: {
        return (
          <Controller
            name={featureProductDataPath.value}
            control={control}
            render={({ field }) => (
              <SHBox sx={{ width: `calc(100% / ${numberOfHeadCol})` }}>
                <SHNumberTextField
                  sx={{
                    "& .MuiInput-root > input": {
                      padding: "10px 0px 8px 0px",
                    },
                  }}
                  fullWidth
                  placeholder="e.g. Enter 0.25 or -0.25"
                  value={renderProductDataValue(
                    featureProductDataPath.value,
                    productData.headerId,
                  )}
                  disabled={isSaving || isPendingApproval}
                  onChange={(value) => {
                    handleInputChange(
                      value || "",
                      featureProductDataPath,
                      featurePath,
                      productData.headerId,
                      feature,
                    );
                    handleUpdateDependedField(value, feature.name);
                    handleUpdateSectionData();
                  }}
                />
              </SHBox>
            )}
          />
        );
      }
      case FieldTypeId.CurrencyText: {
        return (
          <Controller
            name={featureProductDataPath.value}
            control={control}
            render={({ field }) => (
              <SHBox sx={{ width: `calc(100% / ${numberOfHeadCol})` }}>
                <SHNumberTextField
                  sx={{
                    "& .MuiInput-root > input": {
                      padding: "10px 0px 8px 0px",
                    },
                  }}
                  fullWidth
                  prefix="$"
                  allowNegative={false}
                  thousandSeparator
                  placeholder="e.g. Enter 0.25 for 0.25%"
                  value={renderProductDataValue(
                    featureProductDataPath.value,
                    productData.headerId,
                  )}
                  disabled={isSaving || isPendingApproval}
                  onChange={(value) => {
                    handleInputChange(
                      value || "",
                      featureProductDataPath,
                      featurePath,
                      productData.headerId,
                      feature,
                    );
                    handleUpdateDependedField(value, feature.name);
                    handleUpdateSectionData();
                  }}
                />
              </SHBox>
            )}
          />
        );
      }
      case FieldTypeId.DateTime: {
        let width = `calc((100% /${numOfCell} ) - 16px)`;
        if (!isEmpty(productData.headerId))
          width = `calc(100% / ${numberOfHeadCol})`;
        return (
          <Controller
            name={featureProductDataPath.value}
            control={control}
            render={({ field: { ref, ...other } }) => (
              <TableDatePicker
                featureIndex={featureIndex}
                sectionIndex={sectionIndex}
                selectedIndex={selectedIndex}
                width={width}
                disabled={isSaving || isPendingApproval}
                onChange={(newValue: Date | null) => {
                  handleDateTimeChange(
                    newValue,
                    featureProductDataPath,
                    featurePath,
                    productData.headerId,
                    feature,
                  );
                  handleUpdateSectionData();
                }}
              />
            )}
          />
        );
      }

      case FieldTypeId.Number: {
        return (
          <Controller
            name={featureProductDataPath.value}
            control={control}
            render={({ field }) => (
              <SHNumericFieldV2
                fullWidth
                disabled={isSaving || isPendingApproval}
                thousandSeparator
                sx={{
                  "& .MuiInput-root > input": {
                    padding: "10px 0px 8px 0px",
                  },
                }}
                placeholder={"Please enter detail"}
                value={renderNumericValue(
                  featureProductDataPath.value,
                  productData.headerId,
                )}
                onValueChange={({ value }) => {
                  handleNumericChange(
                    value,
                    featureProductDataPath,
                    featurePath,
                    productData.headerId,
                    feature,
                  );
                  handleUpdateSectionData();
                }}
              />
            )}
          />
        );
      }
      case FieldTypeId.TextLong: {
        return (
          <Controller
            name={featureProductDataPath.value}
            control={control}
            render={({ field }) => (
              <SHStack height={"280px"}>
                <SHRichTextEditor2
                  debounceAbortMethod={"flush"}
                  maxCharacters={MAX_LONG_TEXT_LENGTH}
                  isDisabled={isSaving || isPendingApproval}
                  value={renderProductDataValue(
                    featureProductDataPath.value,
                    productData.headerId,
                  )}
                  onChange={(newValue) => {
                    handleInputChange(
                      newValue,
                      featureProductDataPath,
                      featurePath,
                      productData.headerId,
                      feature,
                    );
                    handleUpdateSectionData();
                  }}
                />
              </SHStack>
            )}
          />
        );
      }

      default: {
        return (
          <TableTextField
            key={feature.id}
            sx={{
              width: `calc(100% / ${numberOfHeadCol})`,
              "& .MuiInput-root > input": {
                padding: "10px 0px 8px 0px",
              },
            }}
            featureProductDataPath={featureProductDataPath}
            productData={productData}
            featureIndex={featureIndex}
            sectionIndex={sectionIndex}
            selectedIndex={selectedIndex}
            disabled={isSaving || isPendingApproval}
            onChange={(e) => {
              handleInputChange(
                e.target.value,
                featureProductDataPath,
                featurePath,
                productData.headerId,
                feature,
              );
              handleUpdateSectionData();
            }}
          />
        );
      }
    }
  };
  return (
    <SHTableContainer
      sx={{
        paddingTop: "12px",
        overflowY: "hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SHTable>
        <SHTableHead>
          <SHTableRow
            sx={{
              th: {
                height: "50.26px",
                lineHeight: "1rem !important",
                paddingY: "0px !important",
              },
            }}
          >
            <FRCell
              sx={{
                width: `${firstCellWidth}% !important`,
              }}
              colSpan={numberOfHeadCol}
            >
              <SHTypography
                lineHeight="1rem !important"
                variant="body4"
                fontWeight={600}
              >
                {sectionName}
              </SHTypography>
            </FRCell>
            {tableHeaderCategory?.values.map((product) => {
              return (
                <FRCell
                  key={product.id}
                  sx={{
                    width: `${cellWidth}% !important`,
                    padding: `10px ${
                      numOfCell === 4 ? "9px" : "16px"
                    } !important`,
                  }}
                >
                  <SHStack direction="row" flexWrap={"nowrap"}>
                    <SHTypography
                      variant="body4"
                      fontWeight={600}
                      lineHeight="1rem !important"
                    >
                      {product.name}
                    </SHTypography>
                    {!isEmpty(product.description) && (
                      <InformationButton content={product.description} />
                    )}
                  </SHStack>
                </FRCell>
              );
            })}
          </SHTableRow>
        </SHTableHead>
        <SHTableBody
          sx={{
            "&.MuiTableBody-root::before": {
              lineHeight: "0px",
              fontSize: "0px",
            },
          }}
        >
          {/* Empty row for separation between header and body */}
          <SHTableRow
            style={{
              height: "13px",
              border: 0,
              borderBottomWidth: 1,
              background: "transparent",
            }}
          ></SHTableRow>
          {/* Features */}
          {sectionFeatures?.map((feature, featureIndex) => {
            let productDataValues: ProductModelValueDTO[] | string =
              tryParseJson(
                isNil(feature.productData?.value)
                  ? ""
                  : feature.productData?.value ?? "",
              );

            if (typeof productDataValues !== "object") {
              productDataValues = [
                {
                  headerId: "",
                  value: productDataValues,
                },
              ];
            }
            return (
              <React.Fragment key={feature.id}>
                <SHTableRow
                  sx={{
                    borderLeftWidth: 1,
                    borderRightWidth: 1,
                    borderTopWidth: featureIndex !== 0 ? 0 : 1,
                    backgroundColor:
                      featureIndex % 2 === 0
                        ? `${palette.secondary[50]} !important`
                        : "transparent !important",
                    td: { overflow: "hidden" },
                  }}
                >
                  <FRCell
                    onClick={() => handleRowClick(feature)}
                    sx={{
                      width: `10% !important`,
                      lineHeight: "1rem !important",
                      height: 5,
                      cursor: feature.allowedVisibilitySetting ? "pointer" : "",
                    }}
                  >
                    <SHStack direction="row" alignItems="center" spacing={1}>
                      <TableFieldLabel
                        feature={feature}
                        featureIndex={featureIndex}
                        sectionIndex={sectionIndex}
                        selectedIndex={selectedIndex}
                        isExpanded={expandedRows[feature.id ?? ""]}
                      />
                    </SHStack>
                  </FRCell>

                  {productDataValues?.map((value) => {
                    const numberOfOtherCell =
                      numOfCell - ((productDataValues.length ?? 1) - 1);
                    return (
                      <FRCell
                        key={value.headerId}
                        sx={{
                          width: `${cellWidth}% !important`,
                          lineHeight: "1rem !important",
                          padding: `10px ${
                            numOfCell === 4 ? "9px" : "16px"
                          } !important`,
                        }}
                        colSpan={numberOfOtherCell}
                      >
                        {renderFeatureFormField(feature, value, featureIndex)}
                      </FRCell>
                    );
                  })}
                </SHTableRow>
                {expandedRows[feature.id ?? ""] &&
                  feature.allowedVisibilitySetting && (
                    <SHTableRow
                      sx={{
                        backgroundColor:
                          featureIndex % 2 === 0
                            ? `${palette.secondary[50]} !important`
                            : "transparent !important",
                        td: { overflow: "hidden" },
                        transition: "all 0.3s ease",
                      }}
                    >
                      <FRCell
                        sx={{
                          width: `10% !important`,
                          lineHeight: "1rem !important",
                          height: 5,
                          paddingLeft: "4.7%",
                        }}
                      >
                        <SHLabel
                          label={
                            <SHTypography
                              variant="subtitle2"
                              disabled={isSaving || isPendingApproval}
                            >
                              Visible to
                            </SHTypography>
                          }
                        />
                      </FRCell>
                      <FRCell
                        sx={{
                          lineHeight: "1rem !important",
                          padding: `10px ${
                            numOfCell === 4 ? "9px" : "16px"
                          } !important`,
                        }}
                        colSpan={numOfCell}
                      >
                        <TableViewingGroupSelection
                          isDisabled={isSaving || isPendingApproval}
                          feature={feature}
                          featureIndex={featureIndex}
                          sectionIndex={sectionIndex}
                          selectedIndex={selectedIndex}
                        />
                      </FRCell>
                    </SHTableRow>
                  )}
              </React.Fragment>
            );
          })}
        </SHTableBody>
      </SHTable>
    </SHTableContainer>
  );
};

export default ModelPageTableEdit;
