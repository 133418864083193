import {
  $getSelection,
  $isRangeSelection,
  $isRootNode,
  LexicalCommand,
  LexicalEditor,
} from "lexical";
import {
  INSERT_UNORDERED_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_CHECK_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
} from "@lexical/list";

// Source: https://stackoverflow.com/a/8234912/2013580
const urlRegExp = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
);

export function handleOrderList(
  editor: LexicalEditor,
  orderType: "bullet" | "number" | "check",
) {
  let isOrderList = false;

  editor.update(() => {
    const selection = $getSelection();
    let orderListCommand: LexicalCommand<void>;

    switch (orderType) {
      case "bullet":
        orderListCommand = INSERT_UNORDERED_LIST_COMMAND;
        break;
      case "number":
        orderListCommand = INSERT_ORDERED_LIST_COMMAND;
        break;
      default:
        orderListCommand = INSERT_CHECK_LIST_COMMAND;
    }

    if (!$isRangeSelection(selection)) return;
    let node = selection.anchor.getNode();

    while (node) {
      if ($isListNode(node) && node.getListType() === orderType) {
        isOrderList = true;
        break;
      }

      if ($isRootNode(node)) break;
      node = node.getParent() ?? node;
    }

    editor.dispatchCommand(
      isOrderList ? REMOVE_LIST_COMMAND : orderListCommand,
      undefined,
    );
  });

  return isOrderList;
}

const SUPPORTED_URL_PROTOCOLS = new Set([
  "http:",
  "https:",
  "mailto:",
  "sms:",
  "tel:",
]);

export function sanitizeUrl(url: string): string {
  try {
    const parsedUrl = new URL(url);
    // eslint-disable-next-line no-script-url
    if (!SUPPORTED_URL_PROTOCOLS.has(parsedUrl.protocol)) {
      return "about:blank";
    }
  } catch {
    return url;
  }
  return url;
}

export function validateUrl(url: string): boolean {
  // TODO Fix UI for link insertion; it should never default to an invalid URL such as https://.
  // Maybe show a dialog where they user can type the URL before inserting it.
  return url === "https://" || urlRegExp.test(url);
}
