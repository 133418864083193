import { SHDialog, SHStack } from "@components/design-systems";
import { CloseButton } from "@components/buttons/close";
import { defaultToolbarConfig } from "../../config";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import {
  StyledContentEditable,
  StyledEditorContainer,
} from "../text-editor-styled";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "../text-editor-toolbar";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import ReadOnlyPlugin from "@components/design-systems/sh-rich-text-editor-v2/components/text-editor-read-only";
import AutoListPlugin from "@components/design-systems/sh-rich-text-editor-v2/components/text-editor-auto-list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $insertNodes } from "lexical";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useEffect, useRef } from "react";
import { useSharedState } from "@components/design-systems/sh-rich-text-editor-v2/context/TextEditorSharedStateContext";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";

interface DialogEditorProps {
  onClose: () => void;
  isOpen: boolean;
  placeholder?: string;
  maxCharacters?: number;
  isDisabled?: boolean;
  initialHtml: string;
}

const DialogEditorPlugin = ({
  onClose,
  isOpen,
  placeholder = "",
  maxCharacters = 500,
  isDisabled = false,
  initialHtml,
}: DialogEditorProps) => {
  const toolbarConfig = { ...defaultToolbarConfig, showDialog: false };
  const isFirstRender = useRef(true);
  const [editor] = useLexicalComposerContext();
  const { dialogState, setDialogState } = useSharedState();
  const getEditorHtml = () =>
    editor.getEditorState().read(() => {
      const plainText = $getRoot().getTextContent();
      return plainText === "" ? "" : $generateHtmlFromNodes(editor, null);
    });

  const handleOnClose = () => {
    setDialogState({
      textContent: getEditorHtml(),
      isUpdate: true,
    });
    onClose();
  };
  // Initialize value from props
  useEffect(() => {
    if (isFirstRender && initialHtml !== null) {
      editor.update(() => {
        const root = $getRoot();
        // Create a html empty paragraph when value is empty to avoid parsing dom exception
        let textContent = initialHtml === "" ? "<p></p>" : initialHtml;
        root.clear();

        const parser = new DOMParser();
        const dom = parser.parseFromString(textContent, "text/html");
        const nodes = $generateNodesFromDOM(editor, dom);
        $insertNodes(nodes);
        root.selectEnd();
      });
      isFirstRender.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, initialHtml]);

  return (
    <SHDialog
      open={isOpen}
      disableEscapeKeyDown
      onClose={handleOnClose}
      maxWidth={"xl"}
      sx={{
        "& .MuiPaper-root": {
          border: 0,
          overflow: "hidden",
          padding: "0 20px",
          width: "90vw",
          height: "65vh",
          ".sh-dialog-container": {
            width: "100%",
            height: "100%",
          },
        },
      }}
      body={
        <SHStack
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <SHStack
            sx={{
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingY: "10px",
              position: "relative",
            }}
          >
            <SHStack
              sx={{
                position: "relative",
                top: 0,
              }}
            >
              <CloseButton width="12px" height="12px" onClick={handleOnClose} />
            </SHStack>
          </SHStack>
          <StyledEditorContainer>
            <SHStack className="editor-container">
              <SHStack className="editor-inner">
                <RichTextPlugin
                  contentEditable={
                    <StyledContentEditable
                      aria-placeholder={placeholder}
                      placeholder={
                        <SHStack className="editor-placeholder">
                          {placeholder}
                        </SHStack>
                      }
                    />
                  }
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <ToolbarPlugin
                  config={toolbarConfig}
                  maxCharacters={maxCharacters}
                  isDisabled={isDisabled}
                />
                <HistoryPlugin />
                <ListPlugin />
                <AutoListPlugin />
                <ReadOnlyPlugin isReadOnly={isDisabled} />
              </SHStack>
            </SHStack>
          </StyledEditorContainer>
        </SHStack>
      }
    />
  );
};

export default DialogEditorPlugin;
