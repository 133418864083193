import mainBgImage from "@assets/images/background/main.svg";
import { PlusButton } from "@components/buttons/plus";
import {
  SHButton,
  SHContainer,
  SHSearchBox,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { NavBar } from "@components/nav-bar";
import { useNewsFeed } from "@hooks/useNewsFeed";
import { useNotification } from "@hooks/useNotification";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBar } from "@layouts/top-bar";
import {
  NewsFeedProductDTO,
  PostDetailDTO,
} from "@models/news-and-updates/entities/post";
import { Link, useTheme } from "@mui/material";
import {
  resetData,
  updateFilters,
  updatePaginationToken,
  updatePostData,
} from "@redux/slices/news-feed";
import { RootState, useAppDispatch } from "@redux/store";
import {
  getListAssociatedProduct,
  getListPost,
  getListPublishedProduct,
} from "@services/newsfeed/postService";
import { isUndefined } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useEffectOnce, useWindowSize } from "react-use";
import { NewsAndUpdatesCollapseMenu } from "./components/collapse-menu";
import { PostDetailDialog } from "./components/posts/post-detail-dialog";
import { PostList } from "./components/posts/post-list";
import { MenuBarSkeleton } from "./components/skeletons/MenuBarSkeleton";
import { PostListSkeleton } from "./components/skeletons/PostListSkeleton";
import { updateFilterByRole } from "./utils";
import { generatePath, useNavigate } from "react-router";
import { PageRoutes } from "@constants";
import AccessDeniedDialog from "@components/dialogs/access-denied";

export const NewsAndUpdates = () => {
  const { palette } = useTheme();
  const windowSize = useWindowSize();
  const { notify } = useNotification();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);

  const headerRef: React.Ref<any> = useRef();
  const topbarRef: React.Ref<any> = useRef();
  const sidebarRef: React.Ref<any> = useRef();

  const { canCreatePost, isSuperAdmin } = useUserPermissions();
  const [isLoadingPublishedProducts, setIsLoadingPublishedProducts] =
    useState(false);
  const [isLoadingAssociatedProducts, setIsLoadingAssociatedProducts] =
    useState(false);
  const [isLoadingMorePosts, setIsLoadingMorePosts] = useState(false);
  const [isDisplayCreateDialog, setDisplayCreateDialog] =
    useState<boolean>(false);
  const [publishedProductData, setPublishedProductData] = useState<
    NewsFeedProductDTO[]
  >([]);
  const [associatedProductData, setAssociatedProductData] = useState<
    NewsFeedProductDTO[]
  >([]);

  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [topbarHeight, setTopbarHeight] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string | null>();
  const { isAdminGroup, isAdviserTrialGroup } = useUserPermissions();

  const {
    postFilters,
    postData,
    paginationToken,
    ui: { isLoadingPost },
  } = useSelector((state: RootState) => state.newsFeed);
  const dispatch = useAppDispatch();
  const { loadPosts } = useNewsFeed();

  useEffectOnce(() => {
    if (user?.isRestrictedUser) return;
    loadPublishedProducts();
    canCreatePost && loadAssociatedProducts();
    const newFilter = updateFilterByRole(isAdminGroup, postFilters);
    dispatch(updateFilters(newFilter));
    loadPosts(newFilter);

    if (!headerRef.current || !topbarRef.current) return; // wait for the element refs to be available
    const resizeObserver = new ResizeObserver(() => {
      setHeaderHeight(headerRef.current.clientHeight);
      setTopbarHeight(topbarRef.current.clientHeight);
    });
    resizeObserver.observe(headerRef.current, topbarRef.current);

    //clean up
    return () => {
      resizeObserver.disconnect();
      dispatch(resetData());
    };
  });

  const onClickCreatePost = (value: boolean) => {
    setDisplayCreateDialog(value);
  };
  const onCloseCreatePostDialog = () => {
    setDisplayCreateDialog(false);
  };

  const handleOnCreatePost = async () => {
    loadPosts();
  };

  const handleOnChangeSearchValue = (value: string | null) => {
    setSearchValue(value);
    const newFilter = { ...postFilters, filterString: value };
    dispatch(updateFilters(newFilter));
    loadPosts(newFilter);
  };

  const loadMorePosts = async () => {
    setIsLoadingMorePosts(true);
    const { data, message } = await getListPost(postFilters, paginationToken);
    if (data) {
      if (postData && data.result) {
        const newListPost = [...postData, ...data.result];
        dispatch(updatePostData(newListPost));
      }
      dispatch(updatePaginationToken(data.paginationToken));
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setIsLoadingMorePosts(false);
  };

  const loadPublishedProducts = async () => {
    setIsLoadingPublishedProducts(true);
    const { data, message } = await getListPublishedProduct();
    if (data) {
      setPublishedProductData(data);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setIsLoadingPublishedProducts(false);
  };

  const loadAssociatedProducts = async () => {
    setIsLoadingAssociatedProducts(true);
    const { data, message } = await getListAssociatedProduct();
    if (data) {
      setAssociatedProductData(data);
    } else {
      notify(message, {
        variant: "error",
        close: true,
      });
    }
    setIsLoadingAssociatedProducts(false);
  };

  const isLoadingAll = useMemo(() => {
    if (isUndefined(postData)) {
      return (
        isLoadingPublishedProducts ||
        isLoadingAssociatedProducts ||
        isLoadingPost
      );
    }
    return false;
  }, [
    postData,
    isLoadingPublishedProducts,
    isLoadingAssociatedProducts,
    isLoadingPost,
  ]);

  const isLoadingPostList = useMemo(() => {
    return isUndefined(postData) ? isLoadingAll : isLoadingPost;
  }, [postData, isLoadingAll, isLoadingPost]);
  // IMAP Judges
  if (user?.isRestrictedUser) return <AccessDeniedDialog />;

  return (
    <SHStack
      sx={{
        backgroundAttachment: "fixed",
        width: "100vw",
        height: "100vh",
        overflow: "auto",
        backgroundImage: `url(${mainBgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
      direction="column"
    >
      <NavBar ref={headerRef} />
      <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
        <SHStack
          ref={topbarRef}
          sx={{
            paddingY: 3,
            position: "fixed",
            top: headerHeight,
            zIndex: 2,
          }}
        >
          <TopBar title="News & updates" />
        </SHStack>
        <SHStack spacing={3} sx={{ overflow: "hidden" }}>
          <SHStack direction={"row"}>
            {isLoadingAll ? (
              <SHStack
                ref={sidebarRef}
                sx={{
                  position: "fixed",
                  top: headerHeight + topbarHeight,
                  width: "290px",
                  height:
                    windowSize.height -
                    (sidebarRef?.current?.getBoundingClientRect()?.top ?? 0),
                }}
              >
                <MenuBarSkeleton />
              </SHStack>
            ) : (
              <SHStack
                ref={sidebarRef}
                sx={{
                  position: "fixed",
                  top: headerHeight + topbarHeight,
                  width: "290px",
                  height:
                    windowSize.height -
                    (sidebarRef?.current?.getBoundingClientRect()?.top ?? 0),
                  borderRight: 1,
                  borderColor: palette.common.black,
                  overflowY: "hidden",
                  "&:hover": {
                    overflowY: "var(--overflow-overlay)",
                  },
                  "::-webkit-scrollbar": {
                    width: "10px",
                    height: "10px",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                  "::-webkit-scrollbar-thumb": {
                    backgroundColor: `${palette.text.third}`,
                    boxShadow: "1px 1px 2px rgba(65, 73, 89, 0.4)",
                    backdropFilter: "blur(0.5px)",
                    borderRadius: "25px",
                  },
                  "::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "rgba(81, 91, 111, 0.75);",
                  },
                  "::-webkit-scrollbar-thumb:active": {
                    backgroundColor: "rgba(81, 91, 111, 0.5);",
                    boxShadow: "inset 1px 1px 2px #25324B",
                  },
                }}
              >
                <NewsAndUpdatesCollapseMenu
                  productData={publishedProductData}
                />
              </SHStack>
            )}
            <SHStack
              overflow={"hidden"}
              paddingLeft={"20px"}
              width={"100%"}
              sx={{ marginLeft: "295px", marginTop: `${topbarHeight}px` }}
            >
              <SHStack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ marginBottom: 3 }}
              >
                {isLoadingAll ? (
                  <>
                    <SHSkeleton
                      height={38}
                      width={309}
                      sx={{ borderRadius: "40px" }}
                    />
                    <SHSkeleton
                      height={38}
                      width={120}
                      sx={{ borderRadius: "40px" }}
                    />
                  </>
                ) : (
                  <>
                    <SHSearchBox
                      isLoading={isLoadingPost && !!searchValue}
                      onChange={(value) => {
                        handleOnChangeSearchValue(value);
                      }}
                      placeholder="Search posts"
                    />
                    {canCreatePost && (
                      <PlusButton
                        variant="contained"
                        size="extraMedium"
                        onClick={() => {
                          onClickCreatePost(true);
                        }}
                      >
                        New post
                      </PlusButton>
                    )}
                  </>
                )}
              </SHStack>
              {isLoadingPostList ? (
                <PostListSkeleton />
              ) : (
                <PostList
                  productData={associatedProductData}
                  footer={
                    paginationToken &&
                    (isAdviserTrialGroup ? (
                      <SHTypography variant="subtitle1">
                        Please upgrade your subscription
                        <Link
                          color={palette.text.primary}
                          component="a"
                          underline="always"
                          onClick={() => {
                            navigate(
                              `${generatePath(PageRoutes.practiceOverview, {
                                practiceId: user?.userMetadata?.adviser_firm_id,
                              })}/subscription`,
                            );
                          }}
                          sx={{
                            paddingX: 0.5,
                            cursor: "pointer",
                            textDecorationColor: palette.text.primary,
                          }}
                        >
                          here
                        </Link>
                        to see more posts
                      </SHTypography>
                    ) : (
                      <SHButton
                        variant="contained"
                        size="extraMedium"
                        isLoading={isLoadingMorePosts}
                        disabled={isLoadingMorePosts}
                        sx={{ marginTop: 5 }}
                        onClick={() => loadMorePosts()}
                      >
                        Load more posts
                      </SHButton>
                    ))
                  }
                />
              )}
            </SHStack>
          </SHStack>
        </SHStack>
        <PostDetailDialog
          isDisplay={isDisplayCreateDialog}
          dialogTitle="Create a post"
          buttonLabel={isSuperAdmin ? "Create post" : "Submit for review"}
          onClose={onCloseCreatePostDialog}
          onSubmit={handleOnCreatePost}
          productData={associatedProductData}
          initialValues={
            associatedProductData?.length === 1
              ? { ...new PostDetailDTO(), product: associatedProductData[0] }
              : undefined
          }
        />
      </SHContainer>
    </SHStack>
  );
};
