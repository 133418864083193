import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getRoot,
  KEY_DOWN_COMMAND,
  COMMAND_PRIORITY_HIGH,
  EditorState,
  $isTextNode,
  TextNode,
  ElementNode,
  $getSelection,
  LexicalNode,
  $isParagraphNode,
  $isRangeSelection,
} from "lexical";
import { $isListItemNode } from "@lexical/list";
import { $trimTextContentFromAnchor } from "@lexical/selection";
import { useEffect, useState } from "react";
import { SHStack, SHTypography } from "@components/design-systems";

interface CharacterLimitPluginProps {
  maxCharacters?: number;
}

const CharacterCountLimitPlugin = ({
  maxCharacters = 500,
}: CharacterLimitPluginProps) => {
  const [editor] = useLexicalComposerContext();
  const [charCount, setCharCount] = useState(0);

  const getCharacterCount = (): number => {
    return editor.getEditorState().read(() => {
      const root = $getRoot();
      let count = 0;
      let lineBreaks = 0;

      const traverseNodes = (node: LexicalNode) => {
        if ($isTextNode(node)) {
          count += (node as TextNode).getTextContent().length;
        }
        if ($isParagraphNode(node) || $isListItemNode(node)) {
          lineBreaks += 1;
        }
        if (node instanceof ElementNode && "getChildren" in node) {
          (node as ElementNode)
            .getChildren()
            .forEach((child) => traverseNodes(child));
        }
      };

      root.getChildren().forEach((node) => traverseNodes(node));
      count = count + (lineBreaks - 1);

      return count < 0 ? 0 : count;
    });
  };

  useEffect(() => {
    const unsubscribeCharacterCount = editor.registerUpdateListener(
      ({ editorState }: { editorState: EditorState }) => {
        const count = getCharacterCount();
        setCharCount(count);
      },
    );

    const unsubscribeKeyDown = editor.registerCommand(
      KEY_DOWN_COMMAND,
      (event: KeyboardEvent) => {
        const currentCount = getCharacterCount();
        const isPrintableKey =
          event.key.length === 1 &&
          !event.ctrlKey &&
          !event.metaKey &&
          !event.altKey;
        const isEnterKey = event.key === "Enter";

        if (currentCount >= maxCharacters && (isPrintableKey || isEnterKey)) {
          event.preventDefault();
          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_HIGH,
    );

    return () => {
      unsubscribeCharacterCount();
      unsubscribeKeyDown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, maxCharacters]);

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.update(() => {
        const selection = $getSelection();
        const textContentLength = getCharacterCount();

        if ($isRangeSelection(selection)) {
          const anchor = selection.anchor;
          const remainingTextLength = textContentLength - maxCharacters;

          if (remainingTextLength > 0) {
            $trimTextContentFromAnchor(editor, anchor, remainingTextLength);
          }
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);

  return (
    <SHStack className="count-word">
      <SHTypography
        sx={{
          paddingTop: "5px",
        }}
        variant="body2"
        colorVariant="disabled"
      >
        {charCount} / {maxCharacters}
      </SHTypography>
    </SHStack>
  );
};

export default CharacterCountLimitPlugin;
