import { SHFilterOption } from "@components/design-systems";
import { DocumentType } from "@models/document-library/entities/document";
import { LovDTO } from "@models/product/entities/subProduct";

export class DocumentTypes {
  public static readonly FactSheet = new DocumentType(
    "FactSheet",
    "Fact sheet",
    "Fact sheet",
  );
  public static readonly ImapAwardEntry = new DocumentType(
    "IMAPAwardEntry",
    "IMAP award entry",
    "IMAP entry",
  );
  public static readonly InformationBrochure = new DocumentType(
    "InformationBrochure",
    "Information brochure",
    "Brochure",
  );
  public static readonly InvestmentApproach = new DocumentType(
    "InvestmentApproach",
    "Investment approach",
    "Approach",
  );
  public static readonly OrganisationalPolicies = new DocumentType(
    "OrganisationalPolicies",
    "Organisational policies",
    "Policy",
  );
  public static readonly Performance = new DocumentType(
    "Performance",
    "Performance",
    "Performance",
  );
  public static readonly SampleCollateral = new DocumentType(
    "SampleCollateral",
    "Sample collateral",
    "Collateral",
  );
  public static readonly Other = new DocumentType("Other", "Other", "Other");

  private static readonly ObjectValues: DocumentType[] = Object.values(
    DocumentTypes,
  ).filter((docType) => docType instanceof DocumentType);

  public static toFilterOptions(): SHFilterOption[] {
    return DocumentTypes.ObjectValues.map((docType) => ({
      label: docType.name,
      value: docType.name,
    }));
  }

  public static toLovData(): LovDTO[] {
    return DocumentTypes.ObjectValues.map((docType) => ({
      id: docType.id,
      name: docType.name,
    }));
  }
}
