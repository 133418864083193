import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
import { SHStack } from "@components/design-systems";

interface ReadOnlyPluginProps {
  isReadOnly: boolean;
}

function ReadOnlyPlugin({ isReadOnly }: ReadOnlyPluginProps) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.setEditable(!isReadOnly); // True = editable, False = read-only
  }, [editor, isReadOnly]);

  return isReadOnly ? <SHStack className="editor-overlay" /> : null;
}

export default ReadOnlyPlugin;
