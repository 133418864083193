import { DocumentStatus } from "@models/document-library/enums/status";
import { ProductType } from "@models/product/enums/productType";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";

export class DocumentODataDTO {
  id: string = "";
  title: string = "";
  productName: string = "";
  productLogoUrl: string = "";
  lastSubmittedBy: string = "";
  dateOfDocument: Date = new Date();
  url: string = "";
  type: string = "";
  status: DocumentStatus = DocumentStatus.Pending;
  description?: string | null = null;
  fileType?: string | null = null;
  showPublish?: boolean = false;
  showEdit?: boolean = false;
  showArchive?: boolean = false;
  showRestore?: boolean = false;
  showDownload?: boolean = false;
}
export class DocumentType {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly shortName: string,
    public readonly isDefault?: boolean,
  ) {}
}
export class ProductDocumentDetailDTO {
  id?: string | null = null;
  productId: string = "";
  title: string = "";
  s3File: string | null = null;
  s3Key?: string | null = null;
  description?: string | null = null;
  documentType: string | null = null;
  productType: ProductType | null = null;
  viewingGroupSelections: ViewingGroupSelectionDTO[] = [];
  dateOfDocument: Date | null = new Date();
}

export class DocumentProductSelectionDTO {
  id: string = "";
  supplierId: string = "";
  productName: string | null = null;
  productLogo: string | null = null;
  productType: ProductType | null = null;
}
