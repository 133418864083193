import { SHDotGroupProps } from "@components/design-systems";
import { SHCollapse } from "@components/design-systems/sh-collapse";
import { useCollapseMenu } from "@hooks/useCollapseMenu ";
import { InvestmentProductModelsDTO } from "@models/product/investment-product/entities/model";
import { useTheme } from "@mui/material";
import MenuItem from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/menu-item";
import { SubMenuItem } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/sub-menu-item";
interface EditModelsCollapseMenuProps {
  investmentProductModelsData?: InvestmentProductModelsDTO[];
  defaultExpandIndexes?: number[];
  selectedIndex: [number, number];
  onChange?: (index: [number, number]) => void;
  dotGroupProps?: Omit<SHDotGroupProps, "disabled">;
  allowAddNewModel?: boolean;
}
export const EditModelsCollapseMenu = ({
  investmentProductModelsData,
  selectedIndex = [0, 0],
  defaultExpandIndexes = [],
  onChange = (index) => {},
  dotGroupProps,
  allowAddNewModel = false,
}: EditModelsCollapseMenuProps) => {
  const { palette } = useTheme();
  const { expandedIndex, handleCollapseToggle } =
    useCollapseMenu(defaultExpandIndexes);

  return (
    <>
      {investmentProductModelsData?.map((group, sectionIndex) => {
        const isExpanded = expandedIndex.includes(sectionIndex);
        return (
          <SHCollapse
            key={group.id}
            title={
              <MenuItem
                onNewButtonClick={() => {
                  handleCollapseToggle(sectionIndex, true);
                }}
                section={group}
                sectionIndex={sectionIndex}
                dotGroupProps={dotGroupProps}
                allowAddNewModel={allowAddNewModel}
              />
            }
            open={isExpanded}
            onCollapse={(open) => {
              handleCollapseToggle(sectionIndex, open);
            }}
            width={"100%"}
            height="auto"
            sx={{
              padding: "20px 10px",
              cursor: "pointer",
              bgcolor: isExpanded ? palette.common.white : "transparent",
              borderColor: palette.secondary[100],
              borderBottomStyle: "solid",
              borderBottomWidth: isExpanded ? 0 : 1,
            }}
          >
            {group.models?.map((models, subSectionIndex) => (
              <SubMenuItem
                models={models}
                sectionIndex={sectionIndex}
                subSectionIndex={subSectionIndex}
                selectedIndex={selectedIndex}
                dotGroupProps={dotGroupProps}
                onChange={onChange}
                key={subSectionIndex}
              />
            ))}
          </SHCollapse>
        );
      })}
    </>
  );
};
