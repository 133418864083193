import { InformationButton } from "@components/buttons/information";
import {
  SHBooleanField,
  SHDotGroup,
  SHSelect,
  SHStack,
  SHTable,
  SHTableBody,
  SHTableContainer,
  SHTableHead,
  SHTableRow,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import { SHNumericFieldV2 } from "@components/design-systems/sh-numeric-field-v2";
import { FieldTypeId, HighlightType } from "@models/configuration";
import { AnalysisVariable } from "@models/platform-analysis/enums/variableName";
import {
  ConfigurationFeeFeatureDTO,
  ConfigurationFeeSectionDTO,
  FeatureHighlightTypeMapping,
  SectionHighlightTypeMapping,
  SectionTypeHighlightTypeMapping,
  SubProductHighlightTypeMapping,
  ValueTypeMapping,
} from "@models/product/entities/subProduct";
import { FeeFieldCondition } from "@models/product/enums/feeFieldCondition";
import { FeeFieldType } from "@models/product/enums/feeFieldType";
import { FeeValueType } from "@models/product/enums/feeValueType";
import { useTheme } from "@mui/material";
import { configDependFields } from "@pages/suppliers/_id/_products/_id/edit/components/fees-and-rates/config";
import {
  getNewProductGroups,
  publishedValueNameMapping,
  valueNameMapping,
  variableNameMapping,
} from "@pages/suppliers/_id/_products/_id/edit/components/fees-and-rates/util";
import { toNativeValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { FRCell } from "@pages/suppliers/_id/_products/_id/view/components/fees-and-rates/components/table-cell";
import {
  updateGroups,
  updateSubProductHighlightTypeMapping,
} from "@redux/slices/product";
import { RootState } from "@redux/store";
import { hexToRGBA } from "@utils";

import { isEmpty, isNil, some, toString } from "lodash";
import { useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "react-use";

interface FREditFeesTableProps {
  section: ConfigurationFeeSectionDTO;
  sectionIndex: number;
  subProductIndex?: number;
  isOtherFee?: boolean;
  isDisabled?: boolean;
  isMounted?: () => void;
  isRateCard?: boolean;
  isEditSchedule?: () => void;
}

export const FREditFeesTable = ({
  section,
  sectionIndex,
  subProductIndex = 0,
  isOtherFee,
  isDisabled,
  isMounted = () => {},
  isRateCard,
  isEditSchedule,
}: FREditFeesTableProps) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { control, setValue, getValues } = useFormContext();
  const { dependFieldsMapping, subProHighlightTypeMapping, productGroups } =
    useSelector((state: RootState) => state.product);
  const { rateCardDependFieldsMapping } = useSelector(
    (state: RootState) => state.rateCard,
  );
  const changeOneFieldTimeOut = useRef<NodeJS.Timeout | null>(null);

  const initSubProductFieldName = !isRateCard
    ? `subProducts.${subProductIndex}.`
    : "";
  const sectionFeeType = isOtherFee
    ? "otherFeeSections"
    : "standardFeeSections";
  const initSectionFieldName = `${initSubProductFieldName}${sectionFeeType}.${sectionIndex}`;

  const handleOnChangeField = (
    featureIndex: number,
    valueType: FeeValueType,
    value?: string | null,
  ) => {
    changeOneFieldTimeOut.current = setTimeout(() => {
      const currentChangeFeature = `${initSectionFieldName}.features.${featureIndex}`;
      setValue(`${currentChangeFeature}.isModified`, true);
      setValue(`${initSectionFieldName}.isModified`, true);

      // Do something in Rate Card
      if (isEditSchedule) isEditSchedule();

      // Show/hide fields
      const variableNameField = getValues(
        `${currentChangeFeature}.${variableNameMapping[valueType]}`,
      );
      const dependFields =
        configDependFields[variableNameField as AnalysisVariable];

      if (dependFields) {
        Object.keys(dependFields).forEach((key) => {
          const fieldsMapping = !isRateCard
            ? dependFieldsMapping
            : rateCardDependFieldsMapping;
          const dependField = fieldsMapping?.[key as AnalysisVariable];
          if (!dependField) return;

          const dependCondition =
            dependFields?.[key as AnalysisVariable]?.condition;

          const dependFieldIsModified = `${initSubProductFieldName}${dependField.modifiedField}`;
          const dependFieldShowHide = `${initSubProductFieldName}${dependField.showHideField}`;
          const dependFieldValue = `${initSubProductFieldName}${dependField.valueField}`;

          setValue(dependFieldValue, null);

          if (!isRateCard) {
            if (
              dependFieldValue ===
              "subProducts.0.otherFeeSections.1.features.7.subProductData.otherValue"
            )
              setValue(
                "subProducts.0.otherFeeSections.1.features.8.showAndAllowUpdateOther",
                false,
              );
          } else {
            if (
              dependFieldValue ===
              "otherFeeSections.0.features.7.rateCardData.otherValue"
            )
              setValue(
                "otherFeeSections.0.features.8.showAndAllowUpdateOther",
                false,
              );
          }

          setValue(dependFieldIsModified, true);

          switch (dependCondition) {
            case FeeFieldCondition.NA:
              setValue(dependFieldShowHide, !isNil(value));
              break;
            case FeeFieldCondition.None:
              setValue(dependFieldShowHide, value !== "None");
              break;
            case FeeFieldCondition.Positive:
              setValue(
                dependFieldShowHide,
                !isNil(value) && value === "true" ? true : false,
              );
              break;
            case FeeFieldCondition.PositiveNumber:
              setValue(
                dependFieldShowHide,
                value !== null && value !== undefined && parseFloat(value) > 0,
              );
              break;
          }
        });
      }

      if (isRateCard) return;
      // Render red dot
      const currentValue = value ?? "";
      const publishedValue =
        getValues(
          `${currentChangeFeature}.${
            !isRateCard ? "subProductData" : "rateCardData"
          }.${publishedValueNameMapping[valueType]}`,
        ) ?? "";

      const subProductMap = subProHighlightTypeMapping;
      const sectionTypeMap = subProHighlightTypeMapping?.[subProductIndex];
      const sectionMap =
        subProHighlightTypeMapping?.[subProductIndex]?.[sectionFeeType];
      const featureMap =
        subProHighlightTypeMapping?.[subProductIndex]?.[sectionFeeType]?.[
          sectionIndex
        ];
      const valueTypeMap =
        subProHighlightTypeMapping?.[subProductIndex]?.[sectionFeeType]?.[
          sectionIndex
        ]?.[featureIndex];

      if (currentValue !== publishedValue) {
        let newValueTypeMap: ValueTypeMapping = { ...valueTypeMap };
        newValueTypeMap[valueType] = true;

        let newFeatureMapping: FeatureHighlightTypeMapping = {
          ...featureMap,
        };
        newFeatureMapping[featureIndex] = newValueTypeMap;

        let newSectionMapping: SectionHighlightTypeMapping = {
          ...sectionMap,
        };
        newSectionMapping[sectionIndex] = newFeatureMapping;

        let newSectionTypeMapping: SectionTypeHighlightTypeMapping = {
          ...sectionTypeMap,
        };
        newSectionTypeMapping[sectionFeeType] = newSectionMapping;

        let newSubProductMap: SubProductHighlightTypeMapping = {
          ...subProductMap,
        };
        newSubProductMap[subProductIndex] = newSectionTypeMapping;

        //Update highlightType Fee & Rate tab
        dispatch(
          updateGroups(
            getNewProductGroups(productGroups, !isEmpty(newSubProductMap)),
          ),
        );

        dispatch(updateSubProductHighlightTypeMapping(newSubProductMap));
      } else {
        let newValueTypeMap: ValueTypeMapping = { ...valueTypeMap };
        delete newValueTypeMap[valueType];

        let newFeatureMapping: FeatureHighlightTypeMapping = {
          ...featureMap,
        };
        newFeatureMapping[featureIndex] = newValueTypeMap;
        if (isEmpty(newFeatureMapping[featureIndex]))
          delete newFeatureMapping[featureIndex];

        let newSectionMapping: SectionHighlightTypeMapping = {
          ...sectionMap,
        };
        newSectionMapping[sectionIndex] = newFeatureMapping;
        if (isEmpty(newFeatureMapping)) delete newSectionMapping[sectionIndex];

        let newSectionTypeMapping: SectionTypeHighlightTypeMapping = {
          ...sectionTypeMap,
        };
        newSectionTypeMapping[sectionFeeType] = newSectionMapping;
        if (isEmpty(newSectionMapping))
          delete newSectionTypeMapping[sectionFeeType];

        let newSubProductMap: SubProductHighlightTypeMapping = {
          ...subProductMap,
        };
        newSubProductMap[subProductIndex] = newSectionTypeMapping;
        if (isEmpty(newSectionTypeMapping))
          delete newSubProductMap[subProductIndex];

        //Update highlightType Fee & Rate tab
        dispatch(
          updateGroups(
            getNewProductGroups(productGroups, !isEmpty(newSubProductMap)),
          ),
        );

        dispatch(updateSubProductHighlightTypeMapping(newSubProductMap));
      }
    }, 200);
  };

  const renderDynamicFields = (
    feature: ConfigurationFeeFeatureDTO,
    featureIndex: number,
    valueType: FeeValueType,
  ) => {
    const fieldName = `${initSectionFieldName}.features.${featureIndex}.${
      !isRateCard ? "subProductData" : "rateCardData"
    }.${valueNameMapping[valueType]}`;

    switch (feature?.fieldType) {
      case FeeFieldType.OpenText:
        return (
          <Controller
            name={fieldName as any}
            control={control}
            render={({ field: { ref, ...other } }) => (
              <SHTextField
                {...other}
                placeholder={"Enter detail"}
                fullWidth
                sx={{
                  "&>.MuiInput-root": {
                    height: "auto",
                    minHeight: "40px",
                    "& .MuiInput-input": {
                      "&::placeholder": {
                        color: "#3D98FF",
                      },
                    },
                  },
                }}
                disabled={isDisabled}
                onChange={(event) => {
                  other.onChange(event.target.value);
                  handleOnChangeField(
                    featureIndex,
                    valueType,
                    event.target.value,
                  );
                }}
                multiline
                maxRows={3}
              />
            )}
          />
        );
      case FeeFieldType.Numeric:
        return (
          <Controller
            name={fieldName as any}
            control={control}
            render={({ field: { ref, ...other } }) => (
              <SHNumericFieldV2
                fullWidth
                thousandSeparator
                valueIsNumericString
                onValueChange={({ value }) => {
                  const isEmptyValue = isEmpty(value);
                  const valueOrNull = isEmptyValue ? null : value;
                  const valueOrUndefined = isEmptyValue ? undefined : value;

                  other.onChange(valueOrNull);
                  handleOnChangeField(
                    featureIndex,
                    valueType,
                    valueOrUndefined,
                  );
                }}
                placeholder={"e.g. 5"}
                sx={{
                  "& .MuiInput-input": {
                    "&::placeholder": {
                      color: "#3D98FF",
                    },
                  },
                }}
                disabled={isDisabled}
                value={toString(other.value)}
              />
            )}
          />
        );
      case FeeFieldType.Currency:
        return (
          <Controller
            name={fieldName as any}
            control={control}
            render={({ field: { ref, ...other } }) => (
              <SHNumericFieldV2
                fullWidth
                prefix="$"
                valueIsNumericString
                allowNegative
                thousandSeparator
                onValueChange={({ value }) => {
                  const isEmptyValue = isEmpty(value);
                  const valueOrNull = isEmptyValue ? null : value;
                  const valueOrUndefined = isEmptyValue ? undefined : value;

                  other.onChange(valueOrNull);
                  handleOnChangeField(
                    featureIndex,
                    valueType,
                    valueOrUndefined,
                  );
                }}
                placeholder={"e.g. Enter 100 for $100"}
                sx={{
                  "& .MuiInput-input": {
                    "&::placeholder": {
                      color: "#3D98FF",
                    },
                  },
                }}
                disabled={isDisabled}
                value={toString(other.value)}
              />
            )}
          />
        );
      case FeeFieldType.Percentage:
        return (
          <Controller
            name={fieldName as any}
            control={control}
            render={({ field: { ref, ...other } }) => (
              <SHNumericFieldV2
                fullWidth
                suffix="%"
                allowNegative
                thousandSeparator
                valueIsNumericString
                onValueChange={({ value }) => {
                  const isEmptyValue = isEmpty(value);
                  const valueOrNull = isEmptyValue ? null : value;
                  const valueOrUndefined = isEmptyValue ? undefined : value;

                  other.onChange(valueOrNull);
                  handleOnChangeField(
                    featureIndex,
                    valueType,
                    valueOrUndefined,
                  );
                }}
                placeholder={"e.g. Enter 0.25 for 0.25%"}
                sx={{
                  "& .MuiInput-input": {
                    "&::placeholder": {
                      color: "#3D98FF",
                    },
                  },
                }}
                disabled={isDisabled}
                value={toString(other.value)}
              />
            )}
          />
        );
      case FeeFieldType.YesNo:
        return (
          <Controller
            name={fieldName as any}
            control={control}
            render={({ field: { ref, ...other } }) => (
              <SHBooleanField
                {...other}
                value={toNativeValue(other.value, FieldTypeId.YesNo)}
                onChange={(event, value?: boolean | null) => {
                  other.onChange(value?.toString() ?? null);
                  handleOnChangeField(
                    featureIndex,
                    valueType,
                    value?.toString(),
                  );
                }}
                disabled={isDisabled}
              />
            )}
          />
        );
      case FeeFieldType.Dropdown:
        return (
          <Controller
            name={fieldName as any}
            control={control}
            render={({ field: { ref, ...other } }) => {
              if (!isRateCard) {
                const defaultValue = feature.lovData?.find(
                  (item) => item.isDefault,
                );
                if (!other.value && defaultValue) {
                  other.onChange(defaultValue?.id);
                }
              }
              return (
                <SHSelect
                  {...other}
                  fullWidth
                  dataItemKey="id"
                  displayField="name"
                  placeholder="Select"
                  data={feature?.lovData ?? []}
                  sx={{
                    "& .sh-select-placeholder": {
                      color: `${hexToRGBA("#3D98FF", 0.6)} !important`,
                    },
                  }}
                  onChange={(event) => {
                    other.onChange(event.target.value);
                    handleOnChangeField(
                      featureIndex,
                      valueType,
                      event.target.value,
                    );
                  }}
                  disabled={isDisabled}
                />
              );
            }}
          />
        );
    }
  };

  useEffectOnce(() => {
    return isMounted();
  });

  useEffect(() => {
    return () => {
      if (changeOneFieldTimeOut.current)
        clearTimeout(changeOneFieldTimeOut.current);
    };
    // eslint-disable-next-line
  }, []);

  const isShowSection = !isOtherFee
    ? some(section?.features, {
        showAndAllowUpdateIDPS: true,
      }) ||
      some(section?.features, {
        showAndAllowUpdatePension: true,
      })
    : some(section?.features, {
        showAndAllowUpdateOther: true,
      });

  return isShowSection ? (
    <SHTableContainer sx={{ overflowY: "hidden" }}>
      <SHTable>
        <SHTableHead>
          <SHTableRow
            sx={{
              borderBottom: "none",
              backgroundColor: `${palette.secondary[50]} !important`,
            }}
          >
            <FRCell isHeader>
              <SHStack direction={"row"} spacing={0.5}>
                <SHTypography variant="body4" fontWeight={600}>
                  {section?.name}
                </SHTypography>
                {!isEmpty(section.description) && (
                  <InformationButton content={section.description} />
                )}
              </SHStack>
            </FRCell>
            <FRCell isHeader>
              {!isOtherFee && (
                <SHTypography variant="body4" fontWeight={600}>
                  IDPS
                </SHTypography>
              )}
            </FRCell>
            <FRCell isHeader>
              {!isOtherFee && (
                <SHTypography variant="body4" fontWeight={600}>
                  Superannuation/Pension
                </SHTypography>
              )}
            </FRCell>
          </SHTableRow>
        </SHTableHead>
        <SHTableBody
          sx={{
            "&.MuiTableBody-root::before": {
              lineHeight: "0px",
              fontSize: "0px",
            },
          }}
        >
          {section?.features?.map((feature, featureIndex) => {
            const {
              showAndAllowUpdateIDPS,
              showAndAllowUpdatePension,
              showAndAllowUpdateOther,
            } = feature;
            const showRedDot = !isEmpty(
              subProHighlightTypeMapping?.[subProductIndex]?.[sectionFeeType]?.[
                sectionIndex
              ]?.[featureIndex],
            );

            const showRedDotForReleaseVersion =
              isDisabled && feature.highlightType === HighlightType.Edited;

            return (
              (showAndAllowUpdateIDPS ||
                showAndAllowUpdatePension ||
                (showAndAllowUpdateOther && isOtherFee)) && (
                <SHTableRow
                  key={feature.id}
                  sx={{
                    borderBottomWidth: 1,
                    backgroundColor: "transparent !important",
                  }}
                >
                  <FRCell>
                    <SHStack direction={"row"} spacing={0.5}>
                      <SHTypography variant="body4">
                        {feature?.name}
                      </SHTypography>
                      <SHStack direction={"row"} alignItems={"center"}>
                        {!isRateCard && (
                          <SHDotGroup
                            paddingBottom={"10px"}
                            paddingRight={"5px"}
                            redDot={showRedDot || showRedDotForReleaseVersion}
                          />
                        )}
                        {!isEmpty(feature.description) && (
                          <InformationButton content={feature.description} />
                        )}
                      </SHStack>
                    </SHStack>
                  </FRCell>
                  <FRCell>
                    {(showAndAllowUpdateIDPS ||
                      (showAndAllowUpdateOther && isOtherFee)) &&
                      renderDynamicFields(
                        feature,
                        featureIndex,
                        !isOtherFee ? FeeValueType.IDPS : FeeValueType.Other,
                      )}
                  </FRCell>
                  <FRCell>
                    {!isOtherFee &&
                      showAndAllowUpdatePension &&
                      renderDynamicFields(
                        feature,
                        featureIndex,
                        FeeValueType.Pension,
                      )}
                  </FRCell>
                </SHTableRow>
              )
            );
          })}
        </SHTableBody>
      </SHTable>
    </SHTableContainer>
  ) : (
    <></>
  );
};
