import { muiAlertTheme } from "@components/design-systems/sh-alert/theme";
import { muiAutocompleteTheme } from "@components/design-systems/sh-auto-complete/theme";
import { muiButtonTheme } from "@components/design-systems/sh-button/theme";
import { muiCheckboxTheme } from "@components/design-systems/sh-checkbox/theme";
import { muiChipTheme } from "@components/design-systems/sh-chip/theme";
import { muiContainerTheme } from "@components/design-systems/sh-container/theme";
import { muiDatePickerTheme } from "@components/design-systems/sh-date-picker/theme";
import { muiDialogTheme } from "@components/design-systems/sh-dialog/theme";
import { muiDividerTheme } from "@components/design-systems/sh-divider/theme";
import { muiFormControlLabel } from "@components/design-systems/sh-form-control-label/theme";
import { muiGridTheme } from "@components/design-systems/sh-grid/theme";
import { muiImageListTheme } from "@components/design-systems/sh-image-list/theme";
import { muiInputAdornmentTheme } from "@components/design-systems/sh-input-adornment/theme";
import { muiMenuItemTheme } from "@components/design-systems/sh-menu-item/theme";
import { muiMenuTheme } from "@components/design-systems/sh-menu/theme";
import { muiPaginationTheme } from "@components/design-systems/sh-pagination/theme";
import { muiPopoverTheme } from "@components/design-systems/sh-popover/theme";
import { muiPopperTheme } from "@components/design-systems/sh-popper/theme";
import { muiRadioTheme } from "@components/design-systems/sh-radio/theme";
import { muiSelectTheme } from "@components/design-systems/sh-select/theme";
import { muiStackTheme } from "@components/design-systems/sh-stack/theme";
import { muiTableBodyTheme } from "@components/design-systems/sh-table-body/theme";
import { muiTableCellTheme } from "@components/design-systems/sh-table-cell/theme";
import { muiTableContainerTheme } from "@components/design-systems/sh-table-container/theme";
import { muiTableFooterTheme } from "@components/design-systems/sh-table-footer/theme";
import { muiTableHeadTheme } from "@components/design-systems/sh-table-head/theme";
import { muiTableRowTheme } from "@components/design-systems/sh-table-row/theme";
import { muiTableTheme } from "@components/design-systems/sh-table/theme";
import { muiTabsTheme } from "@components/design-systems/sh-tabs/theme";
import { muiTextFieldTheme } from "@components/design-systems/sh-text-field/theme";
import { muiToggleButtonOptionsTheme } from "@components/design-systems/sh-toggle-button-options/theme";
import {
  muiTypographyTheme,
  muiTypographyThemeOption,
} from "@components/design-systems/sh-typography/theme";
import { createTheme } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { lightPalette, lightShadows } from "@themes/light";
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    lg2: true;
    lg3: true;
  }
}

const initialTheme = createTheme({
  palette: lightPalette,
  typography: {
    fontFamily: "Epilogue",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      lg2: 1336,
      lg3: 1400,
      xl: 1536,
    },
  },
});

export const theme = createTheme(initialTheme, {
  components: {
    ...muiButtonTheme(initialTheme),
    ...muiContainerTheme(initialTheme),
    ...muiGridTheme(initialTheme),
    ...muiImageListTheme(initialTheme),
    ...muiStackTheme(initialTheme),
    ...muiTextFieldTheme(initialTheme),
    ...muiCheckboxTheme(initialTheme),
    ...muiFormControlLabel(initialTheme),
    ...muiTypographyTheme(initialTheme),
    ...muiInputAdornmentTheme(initialTheme),
    ...muiPaginationTheme(initialTheme),
    ...muiMenuItemTheme(initialTheme),
    ...muiMenuTheme(initialTheme),
    ...muiChipTheme(initialTheme),
    ...muiDialogTheme(initialTheme),
    ...muiRadioTheme(initialTheme),
    ...muiAlertTheme(initialTheme),
    ...muiTabsTheme(initialTheme),
    ...muiDividerTheme(initialTheme),
    ...muiPopperTheme(initialTheme),
    ...muiPopoverTheme(initialTheme),
    ...muiSelectTheme(initialTheme),
    ...muiToggleButtonOptionsTheme(initialTheme),
    ...muiTableTheme(initialTheme),
    ...muiTableBodyTheme(initialTheme),
    ...muiTableCellTheme(initialTheme),
    ...muiTableContainerTheme(initialTheme),
    ...muiTableFooterTheme(initialTheme),
    ...muiTableHeadTheme(initialTheme),
    ...muiTableRowTheme(initialTheme),
    ...muiDatePickerTheme(initialTheme),
    ...muiAutocompleteTheme(initialTheme),
  },
  typography: muiTypographyThemeOption(initialTheme),
  shadows: lightShadows(initialTheme),
});
