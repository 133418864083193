import { SHSearchBox, SHStack, SHTypography } from "@components/design-systems";
import { DefaultOdataErrorMessage } from "@components/design-systems/sh-data-grid/constant";
import { useOData } from "@components/design-systems/sh-data-grid/hook";
import { toODataString } from "@components/design-systems/sh-data-grid/util";
import { ProductList } from "@components/product-list";
import { ProductListSkeleton } from "@components/product-list/ProductListSkeleton";
import { APIExtRoutes } from "@constants/routes";
import { useNotification } from "@hooks/useNotification";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBar } from "@layouts/top-bar";
import { ODataResult } from "@models/core";
import { ProductDTO } from "@models/product/entities/product";
import { ProductType } from "@models/product/enums/productType";
import { TableState } from "@tanstack/react-table";
import { nameOfFactory } from "@utils";
import { isEmpty, isEqual } from "lodash";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useToggle } from "react-use";
import { useAppDispatch } from "@redux/store";
import { generateProductNavigationPath } from "@services/product/util";
import {
  getCardViewProductConfig,
  validateProductType,
} from "@pages/platform-profiles/util";

export default function PlatformProfileCardView(): JSX.Element {
  const showAll = true;
  const nameOf = nameOfFactory<ProductDTO>();

  const { user, isSupplierBasic } = useUserPermissions();
  const navigate = useNavigate();
  const { notify } = useNotification();
  const dispatch = useAppDispatch();

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>();
  const [platformsData, setPlatformsData] = useState<ProductDTO[]>([]);
  const [smaData, setSmaData] = useState<ProductDTO[]>([]);

  const [showPlatformsSection, toggleShowPlatformsSection] = useToggle(true);
  const [showSmaSection, toggleShowSmaSection] = useToggle(true);

  const handleUpdateEditModeAndProductType = (productType: ProductType) => {
    const productActions = getCardViewProductConfig(productType);
    const { updateEditMode, updateProductType } = productActions;
    dispatch(updateEditMode(false));
    dispatch(updateProductType(productType));
  };

  const pickColumns: (keyof ProductDTO)[] = [
    "id",
    "supplierId",
    "productName",
    "productType",
    "productLogo",
    "supplierName",
    "lastModifiedDate",
    "dataStatus",
    "hasCustomVisibility",
  ];

  const odataQueryUrl = useMemo(() => {
    return `${APIExtRoutes.odataProducts}${toODataString(
      {
        pagination: {},
        globalFilter: searchValue,
      } as TableState,
      showAll,
      pickColumns,
      [nameOf("productName")],
    )}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const isEmptySearchData = useMemo(() => {
    return [searchValue, isEmpty(platformsData), isEmpty(smaData)].every(
      Boolean,
    );
  }, [searchValue, platformsData, smaData]);

  useOData({
    odataQueryUrl,
    onChangeIsLoading: (isLoading) => setIsLoadingData(isLoading),
    onDataReceived: (data: ODataResult<any>) => {
      const responseResult = data["value"] || [];
      let platformData: ProductDTO[] = [];
      let smaData: ProductDTO[] = [];

      responseResult.forEach((item: ProductDTO) => {
        switch (item.productType) {
          case ProductType.Platform:
            platformData.push(item);
            break;
          default:
            smaData.push(item);
            break;
        }
      });

      setPlatformsData(platformData);
      const isEmptyPlatformData = isEmpty(platformData);
      const shouldShowPlatformSection =
        !isEmptyPlatformData || (isEmptyPlatformData && !searchValue);
      toggleShowPlatformsSection(shouldShowPlatformSection);

      setSmaData(smaData);
      const isEmptySmaData = isEmpty(smaData);
      const shouldShowSmaSection =
        !isEmptySmaData || (isEmptySmaData && !searchValue);
      toggleShowSmaSection(shouldShowSmaSection);
    },
    onChangeErrorMessage: (error) => {
      notify(error ?? DefaultOdataErrorMessage, {
        variant: "error",
        close: true,
      });
    },
  });

  const handleOnCardClick = ({
    productId,
    supplierId,
    productType,
  }: {
    productId?: string;
    supplierId?: string;
    productType?: ProductType | null;
  }) => {
    if (
      !validateProductType(productType, (errorMessage) => {
        notify(errorMessage, {
          variant: "error",
          close: true,
        });
      })
    )
      return;

    if (user?.isRestrictedUser && productType === ProductType.Platform) {
      notify("Your account cannot view this page", {
        variant: "error",
        close: true,
      });
      return;
    }

    if (
      isSupplierBasic &&
      !isEqual(user?.userMetadata?.supplier_id, supplierId)
    ) {
      notify(
        "Your account type can only view products belonging to your firm.",
        {
          variant: "error",
          close: true,
        },
      );
      return;
    }

    productType ??= ProductType.Platform;
    handleUpdateEditModeAndProductType(productType);
    navigate(
      generateProductNavigationPath({
        productId,
        supplierId,
        productType,
      }),
    );
  };

  return (
    <SHStack spacing={3} sx={{ paddingY: 5 }}>
      <SHSearchBox
        isLoading={isLoadingData && !!searchValue}
        onChange={(value) => {
          setSearchValue(value);
        }}
        placeholder="Search products"
      />
      {isLoadingData ? (
        <ProductListSkeleton />
      ) : (
        <SHStack spacing={3}>
          {showPlatformsSection && (
            <SHStack spacing={3}>
              <TopBar title={"Platforms"} />

              {isEmpty(platformsData) ? (
                <SHStack
                  width={"100%"}
                  spacing={2}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <SHTypography variant="body4">
                    {!searchValue && "No product profiles."}
                  </SHTypography>
                </SHStack>
              ) : (
                <ProductList
                  data={platformsData}
                  onClickProduct={(productId, supplierId, productType) => {
                    handleOnCardClick({
                      productId,
                      supplierId,
                      productType,
                    });
                  }}
                />
              )}
            </SHStack>
          )}

          {showSmaSection && (
            <SHStack spacing={3}>
              <TopBar title={"Managed accounts"} />

              {isEmpty(smaData) ? (
                <SHStack
                  width={"100%"}
                  spacing={2}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <SHTypography variant="body4">
                    {!searchValue && "No product profiles."}
                  </SHTypography>
                </SHStack>
              ) : (
                <ProductList
                  data={smaData}
                  onClickProduct={(productId, supplierId, productType) =>
                    handleOnCardClick({
                      productId,
                      supplierId,
                      productType,
                    })
                  }
                />
              )}
            </SHStack>
          )}

          {isEmptySearchData && (
            <SHStack alignItems={"center"} justifyContent={"center"}>
              <SHTypography variant="body4">
                No results match your criteria.
              </SHTypography>
            </SHStack>
          )}
        </SHStack>
      )}
    </SHStack>
  );
}
