import { SupplierLovDTO } from "@models/supplier/entities/suppliers";
import { ViewingGroupStatus } from "@models/viewing-groups/enum/status";
export class ViewingGroupSelectionDTO {
  id: string = "";
  name: string = "";
  order: number = 0;
  isSelected: boolean = false;
}

export class ViewingGroupDTO {
  id: string = "";
  name: string = "";
  status?: ViewingGroupStatus = ViewingGroupStatus.Active;
  supplierId!: string;
  createdDate?: Date = new Date();
  lastModifiedDate?: Date = new Date();
  selectedSuppliers?: SupplierLovDTO[] = [];
}

export class CreateViewingGroupDTO {
  supplierId!: string;
  name: string = "";
  selectedSupplierIds?: string[] = [];
}
export class UpdateViewingGroupDTO extends CreateViewingGroupDTO {
  id!: string;
}
