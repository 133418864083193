import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import { SHStack, SHTypography } from "@components/design-systems";
import { StyledToolbarPlugin } from "../text-editor-styled";
import { handleOrderList } from "../../utils";
import CharacterCountLimitPlugin from "../text-editor-character-count-limit";
import { defaultToolbarConfig } from "../../config";
import { $isListNode, ListNode } from "@lexical/list";
import { useTheme } from "@mui/material";

const LowPriority = 1;

interface ToolbarPluginProps {
  maxCharacters?: number;
  dialogPlaceHolderMessage?: string;
  config?: ToolbarConfig;
  isDisabled?: boolean;
  toggleOpenCreateDialog?: (isOpen: boolean) => void;
}

export default function ToolbarPlugin({
  maxCharacters = 1000,
  config = defaultToolbarConfig,
  isDisabled = false,
  toggleOpenCreateDialog,
}: ToolbarPluginProps) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const { palette } = useTheme();
  // const [canUndo, setCanUndo] = useState(false);
  // const [canRedo, setCanRedo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isOrderedList, setIsOrderedList] = useState(true);
  const [isUnOrderedList, setIsUnOrderedList] = useState(true);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
    }
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar();
          return false;
        },
        LowPriority,
      ),
      // editor.registerCommand(
      //   CAN_UNDO_COMMAND,
      //   (payload) => {
      //     setCanUndo(payload);
      //     return false;
      //   },
      //   LowPriority,
      // ),
      // editor.registerCommand(
      //   CAN_REDO_COMMAND,
      //   (payload) => {
      //     setCanRedo(payload);
      //     return false;
      //   },
      //   LowPriority,
      // ),
    );
  }, [editor, $updateToolbar]);

  // Check the list node to activate the format list buttons
  useEffect(() => {
    const unregister = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const anchorNode = selection.anchor.getNode();
          const parentType = anchorNode.getParent()?.getType();

          if (parentType !== "root") {
            const listNode =
              parentType === "list"
                ? anchorNode.getParent()
                : anchorNode.getTopLevelElement();
            if ($isListNode(listNode)) {
              const listType = (listNode as ListNode).getListType();
              setIsUnOrderedList(!(listType === "bullet"));
              setIsOrderedList(!(listType === "number"));
            } else {
              setIsUnOrderedList(true);
              setIsOrderedList(true);
            }
          }
        }
      });
    });

    return () => unregister();
  }, [editor]);

  return (
    <StyledToolbarPlugin>
      <SHStack className="toolbar" ref={toolbarRef}>
        <SHStack className="toolbar-left">
          {/*<button*/}
          {/*    onClick={(e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      editor.dispatchCommand(UNDO_COMMAND, undefined);*/}
          {/*    }}*/}
          {/*    className={"toolbar-item spaced"}*/}
          {/*    aria-label="Undo"*/}
          {/*    disabled={!canUndo}*/}
          {/*>*/}
          {/*  <i className="format undo" />*/}
          {/*</button>*/}
          {/*<button*/}
          {/*    onClick={(e) => {*/}
          {/*      e.preventDefault();*/}
          {/*      editor.dispatchCommand(REDO_COMMAND, undefined);*/}
          {/*    }}*/}
          {/*    className={"toolbar-item spaced"}*/}
          {/*    aria-label="Redo"*/}
          {/*    disabled={!canRedo}*/}
          {/*>*/}
          {/*  <i className="format redo" />*/}
          {/*</button>*/}
          {config?.showBold && (
            <button
              onClick={(e) => {
                e.preventDefault();
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
              }}
              className={"toolbar-item spaced " + (isBold ? "active" : "")}
              aria-label="Format Bold"
              disabled={isDisabled}
            >
              <i className="format bold" />
            </button>
          )}
          {config?.showItalic && (
            <button
              onClick={(e) => {
                e.preventDefault();
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
              }}
              className={"toolbar-item spaced " + (isItalic ? "active" : "")}
              aria-label="Format Italics"
              disabled={isDisabled}
            >
              <i className="format italic" />
            </button>
          )}
          {config?.showUnderline && (
            <button
              onClick={(e) => {
                e.preventDefault();
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
              }}
              className={"toolbar-item spaced " + (isUnderline ? "active" : "")}
              aria-label="Format Underline"
              disabled={isDisabled}
            >
              <i className="format underline" />
            </button>
          )}
          {config?.showStrikethrough && (
            <button
              onClick={(e) => {
                e.preventDefault();
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
              }}
              className={
                "toolbar-item spaced " + (isStrikethrough ? "active" : "")
              }
              aria-label="Format Strikethrough"
              disabled={isDisabled}
            >
              <i className="format strikethrough" />
            </button>
          )}
          {/*It might be used in the future*/}
          {/*<button*/}
          {/*  onClick={() => {*/}
          {/*    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");*/}
          {/*  }}*/}
          {/*  className="toolbar-item spaced"*/}
          {/*  aria-label="Left Align"*/}
          {/*>*/}
          {/*  <i className="format left-align" />*/}
          {/*</button>*/}
          {/*<button*/}
          {/*  onClick={() => {*/}
          {/*    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");*/}
          {/*  }}*/}
          {/*  className="toolbar-item spaced"*/}
          {/*  aria-label="Center Align"*/}
          {/*>*/}
          {/*  <i className="format center-align" />*/}
          {/*</button>*/}
          {/*<button*/}
          {/*  onClick={() => {*/}
          {/*    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");*/}
          {/*  }}*/}
          {/*  className="toolbar-item spaced"*/}
          {/*  aria-label="Right Align"*/}
          {/*>*/}
          {/*  <i className="format right-align" />*/}
          {/*</button>*/}
          {/*<button*/}
          {/*  onClick={() => {*/}
          {/*    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");*/}
          {/*  }}*/}
          {/*  className="toolbar-item spaced"*/}
          {/*  aria-label="Justify Align"*/}
          {/*>*/}
          {/*  <i className="format justify-align" />*/}
          {/*</button>*/}
          {config?.showOrderedList && (
            <button
              onClick={(e) => {
                e.preventDefault();
                const orderListStatus = handleOrderList(editor, "number");

                setIsOrderedList(orderListStatus);
                setIsUnOrderedList(true);
              }}
              className={
                "toolbar-item spaced " + (!isOrderedList ? "active" : "")
              }
              aria-label="Ordered List"
              disabled={isDisabled}
            >
              <i className="format ordered-list" />
            </button>
          )}
          {config?.showUnorderedList && (
            <button
              onClick={(e) => {
                e.preventDefault();
                const orderListStatus = handleOrderList(editor, "bullet");

                setIsUnOrderedList(orderListStatus);
                setIsOrderedList(true);
              }}
              className={
                "toolbar-item spaced " + (!isUnOrderedList ? "active" : "")
              }
              aria-label="Unordered List"
              disabled={isDisabled}
            >
              <i className="format unordered-list" />
            </button>
          )}
        </SHStack>
        <SHStack className="toolbar-right">
          {config?.showDialog && (
            <button
              onClick={(e) => {
                e.preventDefault();
                toggleOpenCreateDialog && toggleOpenCreateDialog(true);
              }}
              className="toolbar-item dialog"
              aria-label="Editor Pop-up"
            >
              <i className="format pop-up" />
              <SHTypography
                variant={"body2"}
                color={palette.primary.main}
                fontWeight={500}
                sx={{
                  marginRight: "4px",
                  paddingTop: "6px",
                }}
              >
                Expand
              </SHTypography>
            </button>
          )}
          <CharacterCountLimitPlugin maxCharacters={maxCharacters} />
        </SHStack>
      </SHStack>
    </StyledToolbarPlugin>
  );
}

export interface ToolbarConfig {
  showUndo: boolean;
  showRedo: boolean;
  showBold: boolean;
  showItalic: boolean;
  showUnderline: boolean;
  showStrikethrough: boolean;
  showUnorderedList: boolean;
  showOrderedList: boolean;
  showDialog: boolean;
}
