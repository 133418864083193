import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { SHStack, SHTypography } from "@components/design-systems";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { getEditManagerGroupDataFieldPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { debounce, isEqual } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { email } from "@constants/email";
import { theme } from "@themes";
import { Link } from "@mui/material";
import { InformationButton } from "@components/buttons/information";

// Manager viewing group selection product level
interface ManagerViewingGroupSelectionProps {
  viewingGroupData: ViewingGroupSelectionDTO[] | null;
  onChange?: () => void;
  disabled?: boolean;
}

const VisibilityDescription = () => {
  return (
    <SHTypography variant="body3" disabled>
      You can limit the visibility of certain aspects of your profile to a
      subset of SuitabilityHub users. Visibility groups can contain a mix of
      licensees and platforms. For example, BT Panorama and Centrepoint Alliance
      can be combined as a visibility group. If a visibility group contains a
      licensee, all advisers linked to that licensee will also see the shared
      information. To create, edit or delete visibility groups, please reach out
      to the SuitabilityHub team at{" "}
      <Link
        component="a"
        underline="none"
        color={theme.palette.text.secondary}
        href={`mailto:${email.support}`}
      >
        {email.support}
      </Link>
    </SHTypography>
  );
};
const ManagerViewingGroupSelection = ({
  viewingGroupData,
  onChange,
  disabled,
}: ManagerViewingGroupSelectionProps) => {
  const managerGroupDataPath = getEditManagerGroupDataFieldPath();

  const { control, getValues, setValue } =
    useFormContext<InvestmentProductManagerDTO>();
  const handleSelectViewingGroup = (
    value: ViewingGroupSelectionDTO[] | null,
  ) => {
    setValue(
      managerGroupDataPath.isModified,
      !isEqual(value, viewingGroupData),
    );
    onChange && onChange();
  };
  const debounceHandleSelectViewingGroup = debounce(
    handleSelectViewingGroup,
    EditProductDefaults.DebounceTime,
  );
  return (
    <SHStack
      width={{
        xs: "100%",
        md: 520,
      }}
      sx={{ paddingBottom: 3 }}
    >
      <Controller
        control={control}
        name={managerGroupDataPath.viewingGroupSelections}
        render={({ field: { ref, ...other } }) => (
          <SHStack direction={"column"} gap={0.5}>
            <SHTypography variant="subtitle2">
              This profile is visible to
              <InformationButton content={<VisibilityDescription />} autoFlip />
            </SHTypography>
            <SelectViewingGroups
              disabled={disabled}
              sx={{
                flex: 1,
                width: "100%",
              }}
              value={
                (getValues(managerGroupDataPath.viewingGroupSelections) as
                  | ViewingGroupSelectionDTO[]
                  | null) || []
              }
              onChange={(value) => {
                other.onChange(value);
                debounceHandleSelectViewingGroup(value);
              }}
              viewingGroupData={viewingGroupData ?? []}
            />
          </SHStack>
        )}
      />
    </SHStack>
  );
};

export default ManagerViewingGroupSelection;
