import { SHBox, SHLabel, SHStack } from "@components/design-systems";
import { ArrowNextSVG } from "@components/svgs";
import { useInputDotIndicator } from "@hooks/useInvestmentProduct";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductModelsDTO } from "@models/product/investment-product/entities/model";
import { CustomLabel } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/custom-label";
import { getFeatureProductDataPaths } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import Balancer from "react-wrap-balancer";

interface TableFieldLabelProps {
  selectedIndex: [number, number];
  sectionIndex: number;
  featureIndex: number;
  feature: InvestmentProductConfigurationFeature;
  isExpanded?: boolean;
}

export const TableFieldLabel = ({
  selectedIndex,
  sectionIndex,
  featureIndex,
  feature,
  isExpanded = false,
}: TableFieldLabelProps) => {
  const { control, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const featureProductDataPath = useMemo(
    () => getFeatureProductDataPaths(selectedIndex, sectionIndex, featureIndex),
    [selectedIndex, sectionIndex, featureIndex],
  );

  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductDataPath.highlightType,
      dataStatus: featureProductDataPath.dataStatus,
    },
  });

  return (
    <SHStack direction="row">
      <SHStack direction="row" alignItems={"center"} gap={2}>
        {feature.allowedVisibilitySetting && (
          <SHBox
            component={"span"}
            sx={{
              transform: `rotate(${isExpanded ? 90 : 0}deg)`,

              transition: "all ease-in-out 0.2s",
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <ArrowNextSVG />
          </SHBox>
        )}
        <Balancer>
          <SHLabel
            label={
              <CustomLabel
                variant="body4"
                featureData={feature}
                dotProps={{
                  orangeDot: isShowOrangeDot,
                  blueDot: isShowBlueDot,
                  greenDot: feature.isBusinessMetric,
                }}
              />
            }
          />
        </Balancer>
      </SHStack>
    </SHStack>
  );
};
