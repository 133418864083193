import { EnvUrl, PageRoutes } from "@constants/routes";
import { UserType } from "@models/auth";
import {
  AdminUsersGroup,
  SuppliersAndAdvisersWithoutTrial,
  AdviserUsersGroup,
  AllUsersGroup,
  SupplierUsersGroup,
} from "@models/users/group";
import { LinkToOptions } from "../nav-button";

export enum NarBarName {
  ProductProfile = "Product profiles",
  PlatformAnalysis = "Platform analysis",
  SuitabilityReviews = "Suitability Reviews",
  Practices = "Practices",
  Suppliers = "Suppliers",
  ManagedAccounts = "Managed accounts",
  Configuration = "Configuration",
  NewsAndUpdates = "News & updates",
  Upgrade = "Upgrade",
  SmartSearch = "Smart search",
  DocumentLibrary = "Document library",
}

export const appNavigation: {
  name: NarBarName;
  linkTo: LinkToOptions;
  roles?: UserType[];
  haveDotGroup?: boolean;
  isBeta?: boolean;
}[] = [
  {
    name: NarBarName.ProductProfile,
    linkTo: { to: PageRoutes.platformProfiles },
  },
  {
    name: NarBarName.PlatformAnalysis,
    linkTo: { to: PageRoutes.platformAnalysis },
    roles: [
      UserType.SupplierPremiumOwner,
      UserType.SupplierPremiumStandard,
      UserType.SupplierBdmSale,
    ],
  },
  {
    name: NarBarName.SuitabilityReviews,
    linkTo: { to: PageRoutes.reviews },
    roles: AdviserUsersGroup,
  },
  {
    name: NarBarName.Practices,
    linkTo: { to: PageRoutes.practices },
    roles: AdminUsersGroup,
  },
  {
    name: NarBarName.Suppliers,
    linkTo: { to: PageRoutes.suppliers },
    roles: AdminUsersGroup,
  },
  {
    name: NarBarName.ManagedAccounts,
    linkTo: { to: PageRoutes.managedAccounts },
    roles: [...SuppliersAndAdvisersWithoutTrial],
  },
  {
    name: NarBarName.SmartSearch,
    linkTo: {
      to:
        process.env.REACT_APP_AUTH0_REDIRECT_URL === EnvUrl.product
          ? PageRoutes.smartSearchProd
          : process.env.REACT_APP_AUTH0_REDIRECT_URL === EnvUrl.staging
          ? PageRoutes.smartSearchStaging
          : PageRoutes.smartSearchDev,
      isExternal: true,
    },
    roles: SupplierUsersGroup,
  },
  {
    name: NarBarName.Configuration,
    linkTo: { to: PageRoutes.configuration },
    roles: [UserType.SuperAdmin],
  },
  {
    name: NarBarName.DocumentLibrary,
    linkTo: { to: PageRoutes.documentLibrary },
    roles: AllUsersGroup,
  },
  {
    name: NarBarName.NewsAndUpdates,
    linkTo: { to: PageRoutes.newsAndUpdates },
    roles: AllUsersGroup,
  },
  {
    name: NarBarName.Upgrade,
    linkTo: { to: PageRoutes.practiceSubscription },
    roles: [UserType.AdviserTrial, UserType.AdviserAdminTrial],
  },
];
