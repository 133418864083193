import {
  createContext,
  useContext,
  useState,
  ReactNode,
  SetStateAction,
  Dispatch,
} from "react";
import { EditorState } from "lexical";

interface SharedState {
  editorState: { state: EditorState; sourceId: string } | null;
  setEditorState: Dispatch<
    SetStateAction<{ state: EditorState; sourceId: string } | null>
  >;
  content: string;
  sourceId: string | null;
  setContent: (content: string, sourceId: string) => void;
  dialogState: { textContent: string; isUpdate: boolean } | null;
  setDialogState: Dispatch<
      SetStateAction<{ textContent: string; isUpdate: boolean } | null>
  >;
}

const TextEditorSharedStateContext = createContext<SharedState | undefined>(
  undefined,
);

export function TextEditorSharedStateProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [editorState, setEditorState] = useState<{
    state: EditorState;
    sourceId: string;
  } | null>(null);
  const [content, setContent] = useState<string>("");
  const [sourceId, setSourceId] = useState<string | null>(null);
  const setContentWithSource = (newContent: string, newSourceId: string) => {
    setContent(newContent);
    setSourceId(newSourceId);
  };
  const [dialogState, setDialogState] = useState<{
    textContent: string; isUpdate: boolean
  } | null>({
    textContent: "",
    isUpdate: false,
  });

  return (
    <TextEditorSharedStateContext.Provider
      value={{
        editorState,
        setEditorState,
        content,
        sourceId,
        setContent: setContentWithSource,
        dialogState,
        setDialogState
      }}
    >
      {children}
    </TextEditorSharedStateContext.Provider>
  );
}

export function useSharedState() {
  const context = useContext(TextEditorSharedStateContext);
  if (!context) {
    throw new Error(
      "useSharedState must be used within a TextEditorSharedStateProvider",
    );
  }
  return context;
}
