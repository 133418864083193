import { SHFilterOption } from "@components/design-systems";
import { ProductListDataStatus } from "@models/product/enums/productListDataStatus";
import { ProductStatus } from "@models/product/enums/status";
import { template } from "lodash";

export const InvalidProductTypeMessageTemplate = template(
  "Unsupported product type: <%= productType %>",
);

export const ProductDataStatusFilterOptions = [
  {
    label: "Data required",
    value: ProductListDataStatus.Missing,
  },
  {
    label: "Pending approval",
    value: ProductListDataStatus.Pending,
  },
];

export const ProductStatusFilterOptions: SHFilterOption[] = [
  {
    label: "Active",
    value: ProductStatus.Active,
  },
  {
    label: "Disabled",
    value: ProductStatus.Disabled,
  },
  {
    label: "Archived",
    value: ProductStatus.Archived,
  },
];
