import { SelectProducts } from "@components/auto-completes/products";
import {
  SHAutocomplete,
  SHBooleanField,
  SHBox,
  SHButton,
  SHContainer,
  SHDivider,
  SHIconLoading,
  SHRadio,
  SHRichTextEditor,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import UnsavedDialog from "@components/dialogs/unsaved";
import { StatusBadge } from "@components/status-badge";
import { CheckSVG, CloseSVG } from "@components/svgs";
import { APIExtRoutes, PageRoutes } from "@constants";
import { DateTimeFormat } from "@constants/format";
import { yupResolver } from "@hookform/resolvers/yup";
import { useIsNew } from "@hooks/useIsNew";
import { useProduct } from "@hooks/useProduct";
import { useRateCard } from "@hooks/useRateCard";
import { useSupplier } from "@hooks/useSupplier";
import { useUserPermissions } from "@hooks/userUserPermission";
import { FieldGroup } from "@layouts/form/field-group";
import { TopBar } from "@layouts/top-bar";
import { ExistingFeeScheduleDTO } from "@models/product/entities/product";
import {
  CreateRateCardDTO,
  RateCardDTO,
  RateCardStatus,
  RateCardUniqueNameLevel,
  UpdateRateCardDTO,
} from "@models/rate-card/entities/rateCard";
import { useTheme } from "@mui/material";
import { OverwriteFeeScheduleDialog } from "@pages/platform-analysis/components/dialogs/overwrite-fee-schedule";
import { FREditFeesTable } from "@pages/suppliers/_id/_products/_id/edit/components/fees-and-rates/components/tables";
import { RootState } from "@redux/store";
import { checkRateCardUniqueName } from "@services/rate-card/rateCardService";
import { format } from "date-fns";
import { isEmpty, isNil } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { RateCardSkeleton } from "./RateCardSkeleton";
import {
  buildRateCate,
  convertDataToSubmit,
  generateRateCardFormTitle,
  rateCardValidators,
} from "./util";

export const RateCardManagePage = () => {
  const isNew = useIsNew();
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { isSupplierBdmSale } = useUserPermissions();
  const { rateCardId } = useParams<{ rateCardId: string }>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { rateCardUI, rateCard } = useSelector(
    (state: RootState) => state.rateCard,
  );
  const { loadExistingFeeSchedule, loadSubProduct } = useProduct();
  const {
    loadRateCard,
    loadRateCardLOV,
    createRateCard,
    updateRateCard,
    updateRateCardStatus,
  } = useRateCard();
  const { loadSupplier, refreshSupplier } = useSupplier();

  const formControls = useForm({
    mode: "onChange",
    defaultValues: {
      ...new RateCardDTO(),
    },
    resolver: yupResolver(rateCardValidators),
  });

  const {
    watch,
    reset,
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, dirtyFields },
  } = formControls;

  const isDirty = !isEmpty(dirtyFields);

  const [isOverriding, setIsOverriding] = useState(false);
  const [showOverrideDialog, setShowOverrideDialog] = useState<
    | {
        feeScheduleId: string;
        isSubProduct?: boolean;
      }
    | undefined
  >(undefined);
  const [isChangingStatus, setIsChangingStatus] =
    useState<RateCardStatus | null>();
  const [feeSchedules, setFeeSchedules] = useState<ExistingFeeScheduleDTO[]>();
  const [selectedFeeSchedule, setSelectedFeeSchedule] =
    useState<ExistingFeeScheduleDTO>();

  const [verifyRateCardName, setVerifyRateCardName] = useState<
    "error" | "success" | "checking" | "none"
  >(isNew ? "none" : "success");
  const [isValidName, setIsValidName] = useState<boolean>(true);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const productId = watch("productId");
  const currentStatus = watch("status");
  const { supplierData } = useSelector((state: RootState) => state.supplier);

  const shareLabelForSaleUser = useMemo(() => {
    return isSupplierBdmSale ? true : false;
  }, [isSupplierBdmSale]);

  const isOwner = useMemo(() => {
    return rateCard?.ownerId === user?.auth0Id || isNew;
  }, [isNew, user?.auth0Id, rateCard?.ownerId]);

  const isDirtyRateCardData =
    !isNil(dirtyFields?.standardFeeSections) ||
    !isNil(dirtyFields?.otherFeeSections);

  const [verifyRateCardNameErrorMessage, setVerifyRateCardNameErrorMessage] =
    useState("");

  const sharedWithLinkedPractices =
    watch("isSharedWithLinkedPractices") ?? false;
  const platformId = watch("productId") ?? "";

  const loadRateCardDetails = async () => {
    let rateCard: RateCardDTO | undefined = undefined;

    if (isNew) {
      rateCard = await loadRateCardLOV();
    } else {
      if (!rateCardId) return;
      rateCard = await loadRateCard(rateCardId);
    }

    if (rateCard)
      reset({
        ...rateCard,
        isAvailableForAnalysis: isNew ? true : rateCard?.isAvailableForAnalysis,
        isShared: isNew ? true : rateCard?.isShared,
      });
  };

  const clearTimer = () => {
    if (timer.current) clearTimeout(timer.current);
  };

  const handleRateCardNameChecking = async () => {
    const name = getValues("name");
    clearTimer();
    setIsValidName(false);
    if (!name || name.trim().length === 0 || !user?.userMetadata?.supplier_id) {
      setVerifyRateCardName("none");
      return;
    }
    if (rateCard?.name === name && !isDirty) {
      setVerifyRateCardName("success");
      setIsValidName(true);
      return;
    }
    setVerifyRateCardName("checking");
    timer.current = setTimeout(async () => {
      const { isSuccess, message } = await checkRateCardUniqueName(
        user?.userMetadata?.supplier_id ?? "",
        "",
        name,
        sharedWithLinkedPractices
          ? !isEmpty(platformId)
            ? RateCardUniqueNameLevel.Platform
            : RateCardUniqueNameLevel.Firm
          : RateCardUniqueNameLevel.User,
        platformId,
        rateCardId,
      );
      if (isSuccess) {
        setVerifyRateCardName("success");
        setIsValidName(true);
      } else {
        setVerifyRateCardName("error");
        setVerifyRateCardNameErrorMessage(message!);
      }
    }, 300);
  };

  const renderVerifyRateCardName = () => {
    switch (verifyRateCardName) {
      case "success":
        return <CheckSVG width={18} height={18} />;
      case "error":
        return (
          <SHBox px={"4px"} py={"1px"}>
            <CloseSVG width={10} height={10} />
          </SHBox>
        );
      case "checking":
        return <SHIconLoading size={16} />;
      default:
        return null;
    }
  };

  const loadFeeSchedules = async () => {
    if (!productId) return;
    const response = await loadExistingFeeSchedule(productId);
    if (response)
      setFeeSchedules(response?.filter((item) => item?.id !== rateCardId));
  };

  const handleSubmitRateCard = async (rateCard: RateCardDTO | any) => {
    if (isNew) {
      await handleOnCreateRateCard(rateCard);
    } else {
      await handleOnUpdateRateCard(rateCard);
    }
  };

  const handleOnCreateRateCard = async (rateCard: RateCardDTO) => {
    const res = await createRateCard(
      convertDataToSubmit(rateCard) as CreateRateCardDTO,
    );
    if (res?.id) {
      reset(res);
      navigate(
        generatePath(PageRoutes.platformAnalysisRateCard, {
          rateCardId: res?.id,
        }),
        {
          replace: true,
        },
      );
    }
  };

  const handleOnUpdateRateCard = async (rateCard: RateCardDTO) => {
    const dataSubmit = {
      ...convertDataToSubmit(rateCard),
      isModifiedRateCardData: isDirtyRateCardData,
    } as UpdateRateCardDTO;

    const res = await updateRateCard(dataSubmit);
    if (res) {
      reset(res);
    }
  };

  const handleOnUpdateRateCardStatus = async (status: RateCardStatus) => {
    if (!rateCardId) return;
    setIsChangingStatus(status);
    const res = await updateRateCardStatus(rateCardId, status);
    setIsChangingStatus(null);
    if (res) {
      reset({ ...getValues(), status: res || status });
    }
  };

  const handleOnSelectFeeSchedule = async (
    feeScheduleId: any,
    isSubProduct?: boolean,
  ) => {
    if (!feeScheduleId) return;

    if (isDirtyRateCardData || (!isDirtyRateCardData && !isNew)) {
      setShowOverrideDialog({
        feeScheduleId: feeScheduleId,
        isSubProduct: isSubProduct,
      });
      return;
    }
    handleOnOverrideFeeSchedule(feeScheduleId, isSubProduct);
  };

  const handleOnOverrideFeeSchedule = async (
    feeScheduleId: string,
    isSubProduct?: boolean,
  ) => {
    let response;

    setIsOverriding(true);
    if (isSubProduct) {
      response = await loadSubProduct(productId, feeScheduleId);
    } else {
      response = await loadRateCard(feeScheduleId);
    }
    setIsOverriding(false);
    setShowOverrideDialog(undefined);

    if (response) {
      const newRateCate = buildRateCate(response, getValues(), isSubProduct);
      setValue("standardFeeSections", newRateCate?.standardFeeSections, {
        shouldDirty: true,
      });
      setValue("otherFeeSections", newRateCate?.otherFeeSections, {
        shouldDirty: true,
      });
    }
  };
  const isSharedWithLinkedPractices = watch("isSharedWithLinkedPractices");
  const isDisabledSharedFirms =
    isSubmitting || !isOwner || (isOwner && isSharedWithLinkedPractices);
  useEffect(() => {
    if (user?.userMetadata?.supplier_id)
      loadSupplier(user.userMetadata.supplier_id);
    return () => {
      refreshSupplier();
    };
    // eslint-disable-next-line
  }, [user?.userMetadata?.supplier_id]);

  useEffect(() => {
    loadRateCardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNew, rateCardId]);

  useEffect(() => {
    if (!productId) return;
    loadFeeSchedules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    handleRateCardNameChecking();
    // eslint-disable-next-line
  }, [sharedWithLinkedPractices, platformId]);

  if (rateCardUI.isLoading) return <RateCardSkeleton />;

  return (
    <SHContainer sx={{ px: { xs: "16px", lg: 0 } }}>
      <FormProvider {...formControls}>
        <form onSubmit={handleSubmit(handleSubmitRateCard)}>
          <SHStack sx={{ paddingY: 3 }}>
            <TopBar
              navigate={{ to: `${PageRoutes.platformAnalysis}/rate-card` }}
              title={
                <>
                  {/* Controlled but display only */}
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <SHTypography
                        style={{ marginLeft: 8, lineHeight: "120%" }}
                        variant="h1"
                      >
                        {generateRateCardFormTitle(field.value)}
                      </SHTypography>
                    )}
                  />
                  <SHTypography
                    style={{
                      marginLeft: 8,
                      color: palette.secondary.main,
                      paddingTop: 0,
                      lineHeight: "120%",
                    }}
                    variant="h2"
                  >
                    {`by ${isNew ? user?.name : rateCard?.ownerName}`}
                  </SHTypography>
                </>
              }
              customBadge={
                !isNew && (
                  <StatusBadge status={getValues("status") as RateCardStatus} />
                )
              }
              tools={
                <>
                  <SHButton
                    variant="outlined"
                    size="extraMedium"
                    disabled={isSubmitting}
                    onClick={() =>
                      navigate(`${PageRoutes.platformAnalysis}/rate-card`)
                    }
                  >
                    Cancel
                  </SHButton>

                  {!isNew && currentStatus !== RateCardStatus.Archived && (
                    <SHButton
                      variant="outlined"
                      size="extraMedium"
                      isLoading={isChangingStatus === RateCardStatus.Archived}
                      disabled={!isValid || isSubmitting}
                      onClick={() =>
                        handleOnUpdateRateCardStatus(RateCardStatus.Archived)
                      }
                    >
                      Archive
                    </SHButton>
                  )}
                  {!isNew && currentStatus === RateCardStatus.Archived && (
                    <SHButton
                      variant="outlined"
                      size="extraMedium"
                      isLoading={isChangingStatus === RateCardStatus.Active}
                      disabled={!isValid || isSubmitting}
                      onClick={() =>
                        handleOnUpdateRateCardStatus(RateCardStatus.Active)
                      }
                    >
                      Unarchive
                    </SHButton>
                  )}

                  <SHButton
                    variant="contained"
                    size="extraMedium"
                    type="submit"
                    disabled={
                      !isValid || isSubmitting || !isDirty || !isValidName
                    }
                    isLoading={isSubmitting}
                  >
                    Save
                  </SHButton>
                </>
              }
            />
            <FieldGroup
              title="Rate card settings"
              maxWidth="655px"
              subTitle={
                <SHStack
                  sx={{
                    ">p": {
                      fontSize: "13px",
                      fontWeight: 400,
                    },
                    rowGap: "20px",
                  }}
                >
                  <SHTypography>
                    You can use rate cards to show alternative fee arrangements
                    during your platform fee analysis. Rate cards must be
                    selected during the analysis setup stage to be made visible
                    in the fee analysis.
                  </SHTypography>
                  <SHTypography>
                    For non-licensees, any rate cards you create are private to
                    your firm and will not be visible to advice firms or other
                    platform providers. Licensees have the option to share rate
                    cards with linked advice firms. Users in these firms can
                    include shared rate cards in their analyses, but not edit
                    them.
                  </SHTypography>

                  {!isNew &&
                    rateCard?.lastModifiedDate &&
                    rateCard?.createdDate && (
                      <SHTypography
                        style={{
                          fontWeight: 400,
                        }}
                      >
                        Last edit: {rateCard?.lastUpdatedUserName} on
                        {format(
                          new Date(rateCard?.lastModifiedDate!),
                          ` ${DateTimeFormat}`,
                        )}
                        <span
                          style={{
                            padding: "0 15px",
                            color: palette.secondary[100],
                          }}
                        >
                          |
                        </span>
                        Created:
                        {format(
                          new Date(rateCard?.createdDate!),
                          ` ${DateTimeFormat}`,
                        )}
                      </SHTypography>
                    )}
                </SHStack>
              }
            />
            <SHDivider />
            <FieldGroup
              title="Rate card details"
              subTitle="Provide details to help identify this rate card in the future"
            >
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <SHTextField
                    {...field}
                    hasCounter
                    maxLength={50}
                    label="Name of rate card"
                    required
                    disabled={isSubmitting}
                    error={!!errors.name}
                    sx={{
                      width: { xs: "100%", md: 520, marginBottom: "30px" },
                    }}
                    helperText={
                      errors?.name?.message ??
                      (verifyRateCardName === "error"
                        ? verifyRateCardNameErrorMessage
                        : null)
                    }
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      handleRateCardNameChecking();
                    }}
                    InputProps={{
                      sx: {
                        "&>input": {
                          pr: "32px !important",
                        },
                      },
                      endAdornment: (
                        <SHStack
                          sx={{
                            position: "absolute",
                            right: "8px",
                          }}
                        >
                          {renderVerifyRateCardName()}
                        </SHStack>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <SHRichTextEditor
                    maxLength={3000}
                    label="Notes"
                    placeHolder="Enter notes for this rate card"
                    sx={{ width: { xs: "100%", md: 520 } }}
                    readOnly={isSubmitting}
                    {...field}
                  />
                )}
              />
            </FieldGroup>
            <SHDivider />
            <FieldGroup
              title="Set availability"
              subTitle="Specify if this rate card is available for selection in the platform fee analysis. This setting only impacts your account"
            >
              <Controller
                name="isAvailableForAnalysis"
                control={control}
                render={({ field: { ref, ...other } }) => (
                  <SHBooleanField
                    {...other}
                    sx={{ width: { xs: "100%", md: 520 } }}
                    isSwitch
                    isShowClearButton={false}
                    label="Do you want this rate card to be available for inclusion in your fee analysis?"
                    yesLabel="Available"
                    noLabel="Excluded"
                    width="auto"
                    disabled={isSubmitting}
                  />
                )}
              />
            </FieldGroup>
            <SHDivider />
            {supplierData?.isLicensee && !isSupplierBdmSale && (
              <>
                <FieldGroup
                  title="Share rate card with all linked practices"
                  subTitle="This setting is exclusive to licensees. Share this rate card with all practices linked to your firm"
                >
                  <Controller
                    name="isSharedWithLinkedPractices"
                    control={control}
                    render={({ field: { ref, ...other } }) => (
                      <SHBooleanField
                        sx={{ width: { xs: "100%", md: 520 } }}
                        {...other}
                        isSwitch
                        isShowClearButton={false}
                        label="Do you want to share this rate card with all practices that are linked to your firm?"
                        yesLabel="Shared"
                        noLabel="Not shared"
                        width="auto"
                        disabled={isSubmitting}
                        onChange={(event, value?: boolean | null) => {
                          other.onChange(value);
                          if (value) {
                            setValue("isShared", true, { shouldDirty: true });
                            setValue("isAvailableForAnalysis", true, {
                              shouldDirty: true,
                            });
                          }
                        }}
                      />
                    )}
                  />
                </FieldGroup>
                <SHDivider />
              </>
            )}
            <FieldGroup
              title="Set sharing within your firm"
              subTitle="Choose whether to share this rate card with others in your team. Only the Owner of the rate card can change this setting. Rate cards shared with linked practices must also be shared with your team"
            >
              <Controller
                name="isShared"
                control={control}
                render={({ field: { ref, ...other } }) => (
                  <SHBooleanField
                    sx={{ width: { xs: "100%", md: 520 } }}
                    {...other}
                    isSwitch
                    isShowClearButton={false}
                    label={
                      shareLabelForSaleUser
                        ? "Do you want to share this rate card with other adviser facing users in your team?"
                        : "Do you want to share this rate card with other non-adviser facing users in your team?"
                    }
                    yesLabel="Shared"
                    noLabel="Private"
                    width="auto"
                    disabled={isDisabledSharedFirms}
                  />
                )}
              />
            </FieldGroup>
            <SHDivider />
            <FieldGroup
              title="Link rate card to a platform"
              subTitle="Rate cards must be assigned to a platform"
              required
            >
              <SHStack
                spacing={3}
                direction="column"
                sx={{ width: { xs: "100%", md: 520 } }}
              >
                <Controller
                  name="productId"
                  control={control}
                  render={({ field: { ref, ...other } }) => (
                    <SelectProducts
                      {...other}
                      apiPath={APIExtRoutes.platformAnalysisPublishedProducts}
                      disabled={isSubmitting}
                      multiple={false}
                      maxLength={1}
                      textFieldProps={{
                        label: "Select one from below",
                        placeholder: "Search for platform",
                        required: true,
                        error: !!errors?.productId,
                        helperText: errors?.productId?.message,
                      }}
                      onChange={(value) => {
                        other.onChange(value);
                        setSelectedFeeSchedule(undefined);
                      }}
                    />
                  )}
                />
              </SHStack>
            </FieldGroup>
            <SHDivider />

            {productId && (
              <>
                <FieldGroup
                  title="Load an existing fee schedule"
                  subTitle="You can either build your rate card from scratch, or you can load the fee schedule of an existing product or a rate card that is linked to the platform you chose"
                >
                  <SHAutocomplete
                    disableCloseOnSelect
                    value={selectedFeeSchedule ?? null}
                    disabled={isSubmitting}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    getOptionLabel={(option: ExistingFeeScheduleDTO) =>
                      option?.name ?? ""
                    }
                    textFieldProps={{
                      InputLabelProps: { shrink: true },
                      placeholder: selectedFeeSchedule
                        ? undefined
                        : "Search for a product or rate card",
                      label: "Select one from below",
                    }}
                    onChange={(e, newValue, r, details) => {
                      setSelectedFeeSchedule(
                        newValue as ExistingFeeScheduleDTO,
                      );
                      handleOnSelectFeeSchedule(
                        newValue?.id,
                        newValue?.isSubProduct,
                      );
                    }}
                    options={feeSchedules ?? []}
                    renderOption={(props, option, { selected }) => {
                      const isChecked = selected;
                      return (
                        <li
                          {...props}
                          key={option.id}
                          style={{ padding: "1px 10px" }}
                        >
                          <SHStack
                            alignItems={"center"}
                            direction="row"
                            spacing={1}
                          >
                            <SHRadio checked={isChecked} />
                            <SHTypography variant="body3" fontWeight={500}>
                              {option.name}
                            </SHTypography>
                          </SHStack>
                        </li>
                      );
                    }}
                    sx={{ width: { xs: "100%", md: 520 } }}
                  />
                </FieldGroup>
                <SHDivider />
              </>
            )}

            <FieldGroup
              title="Build your rate card"
              subTitle={
                <SHStack
                  sx={{
                    ">p": {
                      fontSize: "13px",
                      fontWeight: 400,
                      textAlign: "justify",
                    },
                    rowGap: "20px",
                  }}
                >
                  <SHTypography>
                    Create your rate card here. The more comprehensive the
                    information you enter, the better the fee calculator will
                    function - hence why you may wish to load an existing fee
                    schedule (see the step above).
                  </SHTypography>
                  <SHTypography>
                    Any fields you leave blank will take the default values
                    assigned to our calculator which is often, but not always,
                    zero.
                  </SHTypography>
                  <SHTypography>
                    Careful: If you change the linked platform or load from a
                    fee schedule, it can cause you to lose any data already
                    entered into this table
                  </SHTypography>
                </SHStack>
              }
            >
              <SHStack
                sx={{ rowGap: "25px", width: { md: "700px", xs: "100%" } }}
              >
                {/* Standard Fees Table */}
                {watch("standardFeeSections")?.map((section, index) => (
                  <FREditFeesTable
                    key={section.id}
                    section={section}
                    sectionIndex={index}
                    isDisabled={isSubmitting}
                    isRateCard
                    isEditSchedule={() => setSelectedFeeSchedule(undefined)}
                  />
                ))}
                {/* Other Fees Table */}
                {!isNil(watch("otherFeeSections")) && (
                  <>
                    <SHTypography
                      variant={"subtitle2"}
                      textAlign="left"
                      lineHeight={"160%"}
                    >
                      Other product fees and information
                    </SHTypography>
                    {watch("otherFeeSections")?.map((section, index) => (
                      <FREditFeesTable
                        key={section.id}
                        section={section}
                        sectionIndex={index}
                        isDisabled={isSubmitting}
                        isOtherFee
                        isRateCard
                        isEditSchedule={() => setSelectedFeeSchedule(undefined)}
                      />
                    ))}
                  </>
                )}
              </SHStack>
            </FieldGroup>
          </SHStack>
          <UnsavedDialog isDirty={isDirty && !isSubmitting} />
          {showOverrideDialog && (
            <OverwriteFeeScheduleDialog
              onClose={() => setShowOverrideDialog(undefined)}
              onSubmit={() =>
                handleOnOverrideFeeSchedule(
                  showOverrideDialog?.feeScheduleId,
                  showOverrideDialog?.isSubProduct,
                )
              }
              isSubmitting={isOverriding}
            />
          )}
        </form>
      </FormProvider>
    </SHContainer>
  );
};
