import {
  DocumentODataDTO,
  DocumentProductSelectionDTO,
} from "@models/document-library/entities/document";
import { ViewingGroupSelectionDTO } from "./../../models/viewing-groups/entities/viewingGroups";
import { faker } from "@faker-js/faker";
import { DocumentStatus } from "@models/document-library/enums/status";
import { NewsFeedProductDTO } from "@models/news-and-updates/entities/post";
import { SpecialViewingGroup } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { generateUUID } from "@utils";
import { ProductType } from "@models/product/enums/productType";

export const mockDocumentList: DocumentODataDTO[] = Array.from({
  length: 10,
}).map((_, i) => {
  const id = generateUUID();
  const title = `Document ${i + 1}`;
  const productName = `CFS FirstChoice`;
  const productLogoUrl = `https://picsum.photos/150/150`;
  const lastSubmittedBy = faker.person.fullName();
  const dateOfDocument = new Date();
  const url = "";
  const type = "Fact sheet";
  const status = Object.values(DocumentStatus)[i % 3] as DocumentStatus;
  const description = faker.lorem.text();
  const fileType = ".DOCX";

  return {
    id,
    title,
    productName,
    productLogoUrl,
    lastSubmittedBy,
    dateOfDocument,
    url,
    type,
    status,
    description,
    fileType,
  };
});
export const mockProducts: DocumentProductSelectionDTO[] = Array.from({
  length: 10,
}).map((_, i) => ({
  id: generateUUID(),
  productName: `Product ${i + 1}`,
  productLogo: faker.image.urlPicsumPhotos(),
  productType: Object.values(NewsFeedProductDTO)[i % 3] as ProductType,
  supplierId: generateUUID(),
}));

export const ViewingGroupSelectionMock: ViewingGroupSelectionDTO[] = [
  {
    id: SpecialViewingGroup.AllSuitabilityHubUsers,
    name: SpecialViewingGroup.AllSuitabilityHubUsers,
    order: 0,
    isSelected: true,
  },
  {
    id: generateUUID(),
    name: "IMAP Judges",
    order: 1,
    isSelected: false,
  },
  {
    id: generateUUID(),
    name: "Users from my firm",
    order: 2,
    isSelected: false,
  },
  {
    id: generateUUID(),
    name: "Group 3",
    order: 3,
    isSelected: false,
  },
];
