import { FilterValue } from "@components/buttons/filter";
import { filterData } from "@components/buttons/filter/mock";
import {
  SHDivider,
  SHStack,
  SHTextField,
  SHTypography,
} from "@components/design-systems";
import UnsavedDialog from "@components/dialogs/unsaved";
import { useIsNew } from "@hooks/useIsNew";
import { useProduct } from "@hooks/useProduct";
import { ProductFeaturesLayout } from "@layouts/products/features";
import { SubProductList } from "@layouts/products/fees-and-rates/sub-product-list";
import { HighlightType } from "@models/configuration";
import {
  SubProductDTO,
  SubProductNameMapping,
} from "@models/product/entities/subProduct";
import { ProductStatus } from "@models/product/enums/status";
import { useTheme } from "@mui/material";
import { FeeAndRateTabSkeleton } from "@pages/suppliers/_id/_products/_id/edit/components/fees-and-rates/components/skeletons/FeeAndRateTabSekeleton";
import { FRTableSkeleton } from "@pages/suppliers/_id/_products/_id/edit/components/fees-and-rates/components/skeletons/FRTableSekeleton";
import { FREditFeesTable } from "@pages/suppliers/_id/_products/_id/edit/components/fees-and-rates/components/tables";
import {
  convertDataToSubmit,
  getNewProductGroups,
} from "@pages/suppliers/_id/_products/_id/edit/components/fees-and-rates/util";
import { ProductTabFormRef } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form";
import { ProductTopBarContainer } from "@pages/suppliers/_id/_products/_id/edit/components/top-bar-container";
import {
  updateGroups,
  updateSubProductHighlightTypeMapping,
  updateSubProductName,
} from "@redux/slices/product";
import { RootState } from "@redux/store";
import { isEmpty, isNil, parseInt, some } from "lodash";
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useFormState,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useToggle } from "react-use";

export interface SubProductTabFormRef {
  getValue: () => SubProductDTO[] | undefined;
  resetForm: (data?: SubProductDTO[]) => void;
}

export interface SubProductTabFormProps {
  isSubmitting?: boolean;
  onSaveDraft: (isSaving: boolean) => void;
}

export const ProductEditFeesAndRates = React.forwardRef<
  ProductTabFormRef,
  SubProductTabFormProps
>(({ isSubmitting: isSubmittingProp, onSaveDraft }, ref) => {
  const isNew = useIsNew();
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { productId } = useParams<{ productId: string }>();
  const { supplierId } = useParams<{ supplierId: string }>();
  const [searchParams] = useSearchParams();
  const { productGroups } = useSelector((state: RootState) => state.product);

  const version = searchParams?.get("version") ?? undefined;
  const configurationVersion =
    searchParams?.get("configurationVersion") ?? undefined;

  const {
    loadBannerInfo,
    loadSubProductsFees,
    loadNewSubProductTemplate,
    updateExtSubProducts,
    viewSubProductReleaseLog,
  } = useProduct();
  const {
    subProducts,
    productBannerInfo,
    subProductNameMapping,
    productUI: {
      isLoadingNewSubProduct,
      productName,
      isLoadingSubProducts,
      showViewReleaseLog,
    },
  } = useSelector((state: RootState) => state.product);

  const formControls = useForm({
    mode: "onChange",
    defaultValues: { subProducts: [] as SubProductDTO[] },
  });
  const {
    control,
    reset,
    handleSubmit,
    getValues,
    formState: { isSubmitting, isValid },
  } = formControls;
  const { fields, append } = useFieldArray({
    control,
    name: "subProducts",
    keyName: "fieldId",
  });

  const changeSubProductNameTimeOut = useRef<NodeJS.Timeout | null>(null);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [isArchivedSubProduct, setIsArchivedSubProduct] = useState(false);
  const [selectedSubProductIndex, setSelectedSubProductIndex] =
    useState<number>(0);

  const { dirtyFields } = useFormState({ control });
  const isDirty = !isEmpty(dirtyFields) || isArchivedSubProduct;
  const isDraft = useMemo(() => {
    return some(productBannerInfo, ["status", "Draft"]);
  }, [productBannerInfo]);
  const isPendingApproval = useMemo(() => {
    return some(productBannerInfo, ["status", "Pending approval"]);
  }, [productBannerInfo]);
  // const isApproved = useMemo(() => {
  //   return some(productBannerInfo, ["status", "Approved"]);
  // }, [productBannerInfo]);

  const isDisabled = useMemo(() => {
    return (
      isSubmitting ||
      showViewReleaseLog ||
      isPendingApproval ||
      isSubmittingProp
    );
  }, [isSubmitting, showViewReleaseLog, isPendingApproval, isSubmittingProp]);
  const isDisabledSaveDraftButton = useMemo(() => {
    return !isDirty || !isValid || isSubmitting || isSubmittingProp;
  }, [isDirty, isValid, isSubmitting, isSubmittingProp]);
  const isDisabledSubmitForReviewButton = useMemo(() => {
    return !isDraft || isSubmitting || isSubmittingProp;
  }, [isDraft, isSubmitting, isSubmittingProp]);

  // Logic functions
  const handleOnFilter = (value?: FilterValue[]) => {
    if (isEmpty(value)) {
      reset({ subProducts: subProducts });
      return;
    }
    const subProductFiltered = fields?.filter(
      (subProduct) => subProduct.status === value?.[0].value,
    ) as SubProductDTO[];
    reset({ subProducts: subProductFiltered });
  };

  const handleOnUnArchived = (
    subProduct?: SubProductDTO,
    isArchived?: boolean,
  ) => {
    setIsArchivedSubProduct(!isArchivedSubProduct);
    const result = fields?.map((subPro) => {
      if (subPro?.id !== subProduct?.id) return subPro;
      return {
        ...subPro,
        status: isArchived ? ProductStatus.Archived : ProductStatus.Active,
        highlightType: HighlightType.Edited,
      };
    }) as SubProductDTO[];
    reset({ subProducts: result });
  };

  const handleOnNewSubProduct = async () => {
    if (!productId) return;
    const newSubProduct = await loadNewSubProductTemplate(productId);
    if (!isNil(newSubProduct))
      append({
        ...newSubProduct,
        order: !isEmpty(fields)
          ? (fields?.[fields?.length - 1]?.order as number) + 1
          : 1,
      } as SubProductDTO);
    setSelectedSubProductIndex(fields.length);
    //Update highlightType Fee & Rate tab
    dispatch(updateGroups(getNewProductGroups(productGroups, true)));
  };

  const handleOnSubmit = async (formData: { subProducts: SubProductDTO[] }) => {
    if (!productId) return;

    onSaveDraft(true);

    const dataSubmit = convertDataToSubmit(formData?.subProducts);
    const resData = await updateExtSubProducts(productId, dataSubmit ?? []);

    onSaveDraft(false);

    if (resData) {
      reset({ subProducts: resData });
      if (productId) loadBannerInfo(productId);
    } else {
      reset({ subProducts: formData?.subProducts ?? [] });
    }
  };

  const loadSubProducts = async (productId: string) => {
    const response = await loadSubProductsFees(productId, "edit");
    if (!isEmpty(response)) reset({ subProducts: response });
  };

  const loadSubProductsReleaseLog = async (
    productId: string,
    version: string,
    configurationVersion: string,
  ) => {
    const releaseData = await viewSubProductReleaseLog({
      productId,
      version,
      configurationVersion,
    });
    if (releaseData) reset({ subProducts: releaseData });
  };

  useEffect(() => {
    if (!productId || isNew) return;

    if (version && configurationVersion) {
      loadSubProductsReleaseLog(productId, version, configurationVersion);
    } else {
      loadSubProducts(productId);
    }
    // eslint-disable-next-line
  }, [productId, version]);

  useImperativeHandle(ref, () => ({
    getValue() {
      if (!isDirty) return;
      return getValues()?.subProducts;
    },
    resetForm(data) {
      reset({ subProducts: data as SubProductDTO[] });
    },
    isFeesTab: true,
  }));

  // UI functions
  const handleOnMountedTable = (tableIndex: number) => {
    if (
      tableIndex + 1 ===
      fields?.[selectedSubProductIndex]?.standardFeeSections?.length
    )
      setIsLoadingTable(false);
  };

  const handleOnChangeSubProductName = (
    newSubProName: string,
    subProIdx?: number,
  ) => {
    if (changeSubProductNameTimeOut.current)
      clearTimeout(changeSubProductNameTimeOut.current);
    changeSubProductNameTimeOut.current = setTimeout(() => {
      if (!subProductNameMapping) return;
      let newSubProductNameMapping: SubProductNameMapping = {
        ...subProductNameMapping,
      };
      Object?.keys(newSubProductNameMapping)?.forEach((key) => {
        if (parseInt(key) !== subProIdx) return;
        newSubProductNameMapping[parseInt(key)] = newSubProName;
      });
      dispatch(updateSubProductName(newSubProductNameMapping));
    }, 200);
  };

  useEffect(() => {
    //Reset all when unmounted
    return () => {
      dispatch(updateSubProductName(undefined));
      dispatch(updateSubProductHighlightTypeMapping(undefined));
    };
    // eslint-disable-next-line
  }, []);

  const [isRendered, toggleRendered] = useToggle(false);
  useEffect(() => {
    if ((!isLoadingTable && !isLoadingSubProducts) || isEmpty(subProducts)) {
      toggleRendered(true);
    }
    // eslint-disable-next-line
  }, [isLoadingTable, isLoadingSubProducts]);

  return (
    <FormProvider {...formControls}>
      <form>
        <ProductTopBarContainer
          isFeesTab
          isNew={isNew}
          isSubmitting={isSubmitting}
          isDisabledSubmitForReview={isDisabledSubmitForReviewButton}
          isDisabledSaveDraft={isDisabledSaveDraftButton}
          productId={productId}
          supplierId={supplierId}
          data={getValues()?.subProducts}
          onSaveDraft={handleSubmit(handleOnSubmit)}
          resetForm={(data) => reset({ subProducts: data as SubProductDTO[] })}
        />
        <>
          <SHStack display={!isRendered ? "block" : "none"}>
            <FeeAndRateTabSkeleton />
          </SHStack>
          <SHStack display={isRendered ? "block" : "none"}>
            <ProductFeaturesLayout
              leftSide={
                <SubProductList
                  isDisabled={isDisabled || (isLoadingTable && !isEmpty(subProducts))}
                  isEditMode={true}
                  subProducts={fields}
                  selectedSubProductIndex={selectedSubProductIndex}
                  filterProp={{
                    filterData: filterData,
                  }}
                  onChangeFilter={handleOnFilter}
                  onNewSubProduct={handleOnNewSubProduct}
                  onArchivedUnArchived={handleOnUnArchived}
                  isLoadingNewSubProduct={isLoadingNewSubProduct}
                  onChangeSubProduct={(idx) => {
                    setSelectedSubProductIndex(idx);
                    setIsLoadingTable(true);
                  }}
                />
              }
              rightSide={
                !isEmpty(fields) && (
                  <>
                    <FRTableSkeleton
                      sx={{ display: !isLoadingTable ? "none" : "unset" }}
                    />
                    <SHStack
                      spacing={2}
                      sx={{ display: isLoadingTable ? "none" : "unset" }}
                    >
                      {/* Title */}
                      <SHStack>
                        <SHStack direction={"row"} spacing={1}>
                          <SHTypography
                            variant="h2"
                            sx={{ fontWeight: 600, fontSize: "24px" }}
                          >
                            {productName}
                          </SHTypography>
                          <Controller
                            key={selectedSubProductIndex}
                            name={`subProducts.${selectedSubProductIndex}.name`}
                            control={control}
                            render={({ field: { ref, ...other } }) => (
                              <SHTextField
                                {...other}
                                sx={{
                                  "& .MuiInput-input": {
                                    fontSize: "24px",
                                    fontWeight: "600",
                                    fontFamily: "Clash Display",
                                    padding: "0px !important",
                                    paddingTop: "2px !important",
                                    width: "210px",
                                  },
                                }}
                                disabled={isDisabled}
                                onChange={(event) => {
                                  other.onChange(event.target.value);
                                  handleOnChangeSubProductName(
                                    event.target.value,
                                    selectedSubProductIndex,
                                  );
                                }}
                              />
                            )}
                          />
                        </SHStack>
                        <SHTypography
                          variant="body4"
                          sx={{ color: palette.text.fourth }}
                        >
                          These fees will be shown to all SuitabilityHub users.
                          If you have a special rate card, please add/manage it
                          in the “Rate cards” tab of the “Platform analysis”
                          page. If you have a new product you wish to add,
                          please contact SuitabilityHub admin directly at
                          support@suitabilityhub.com
                        </SHTypography>
                      </SHStack>
                      <SHDivider />
                      {/* Standard Fees Table */}
                      {fields?.[
                        selectedSubProductIndex
                      ]?.standardFeeSections?.map((section, index) => (
                        <FREditFeesTable
                          key={`${selectedSubProductIndex}.${section.id}`}
                          section={section}
                          sectionIndex={index}
                          isDisabled={isDisabled}
                          isMounted={() => handleOnMountedTable(index)}
                          subProductIndex={selectedSubProductIndex}
                        />
                      ))}
                      {/* Other Fees Table */}
                      {!isNil(
                        fields?.[selectedSubProductIndex]?.otherFeeSections,
                      ) && (
                        <>
                          <SHTypography
                            variant={"subtitle2"}
                            textAlign="left"
                            lineHeight={"160%"}
                          >
                            Other product fees and information
                          </SHTypography>
                          {fields?.[
                            selectedSubProductIndex
                          ]?.otherFeeSections?.map((section, index) => (
                            <FREditFeesTable
                              key={`${selectedSubProductIndex}.${section.id}`}
                              section={section}
                              sectionIndex={index}
                              isOtherFee
                              isDisabled={isDisabled}
                              isMounted={() => handleOnMountedTable(index)}
                              subProductIndex={selectedSubProductIndex}
                            />
                          ))}
                        </>
                      )}
                    </SHStack>
                  </>
                )
              }
            />
          </SHStack>
        </>
        <UnsavedDialog
          isDirty={isDirty && !isSubmitting && !isSubmittingProp}
        />
      </form>
    </FormProvider>
  );
});
