import { SHLabel, SHLabelProps } from "@components/design-systems";
import { SHAvatar, SHAvatarProps } from "@components/design-systems/sh-avatar";
import { SHStack } from "@components/design-systems/sh-stack";
import {
  SHUploadFile,
  SHUploadFileProps,
} from "@components/design-systems/sh-upload-file";
import { PhotoSVG } from "@components/svgs";

export interface SHUploadImageProps {
  height?: number | string;
  width?: number | string;
  showImage?: boolean;
  helperText?: string | null;
  error?: boolean;
  disabled?: boolean;
}

export const SHUploadImage = ({
  height,
  width,
  dropzoneOptions,
  isUploading,
  actionIcon,
  showImage = true,
  helperText,
  error,
  label,
  postfixLabel,
  required,
  dotGroupProps,
  disabled,
  buttonText,
  ...other
}: SHAvatarProps &
  SHUploadFileProps &
  SHUploadImageProps &
  SHLabelProps): JSX.Element => {
  return (
    <SHStack
      direction="row"
      spacing={5}
      justifyContent="space-between"
      alignItems="start"
    >
      {showImage && (
        <SHStack pt={!!label ? 4 : 0}>
          <SHAvatar {...other} actionIcon={actionIcon} />{" "}
        </SHStack>
      )}
      <SHStack spacing={1}>
        {label && (
          <SHLabel
            label={label}
            disabled={disabled}
            postfixLabel={postfixLabel}
            required={required}
            dotGroupProps={dotGroupProps}
          />
        )}
        <SHUploadFile
          height={height}
          width={width}
          fileIcon={<PhotoSVG />}
          dropzoneOptions={dropzoneOptions}
          isUploading={isUploading}
          error={error}
          helperText={helperText}
          buttonText={buttonText}
        />
      </SHStack>
    </SHStack>
  );
};
