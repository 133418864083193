import { SHHtmlBlock, SHLabel, SHStack } from "@components/design-systems";
import {
  useEditModelPaths,
  useInputDotIndicator,
  useUpdateSectionData,
} from "@hooks/useInvestmentProduct";
import { HighlightType } from "@models/configuration";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductModelsDTO } from "@models/product/investment-product/entities/model";
import {
  InvestmentBannerStatus,
  InvestmentDataStatus,
} from "@models/product/investment-product/enums/status";
import { CustomLabel } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/custom-label";
import { DEBOUNCE_DELAY } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/configs";
import { RootState } from "@redux/store";
import { debounce, isEmpty, isEqual, toString } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import SHRichTextEditor2 from "@components/design-systems/sh-rich-text-editor-v2";
import { MAX_LONG_TEXT_LENGTH } from "@constants";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";

interface ModelFeatureTextEditorProps {
  selectedIndex: [number, number];
  sectionIndex: number;
  featureIndex: number;
  feature: InvestmentProductConfigurationFeature;
}

export const FeatureTextEditor = ({
  selectedIndex,
  sectionIndex,
  featureIndex,
  feature,
}: ModelFeatureTextEditorProps) => {
  const {
    investmentProductUI: { isSaving },
    investmentProductBannerInfo,
  } = useSelector((state: RootState) => state.investmentProduct);
  const originalValue = feature.productData?.value;
  const isPendingApproval = investmentProductBannerInfo?.some((banner) =>
    isEqual(banner.status, InvestmentBannerStatus.PendingApproval),
  );
  /** Hook **/
  const { control, setValue, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const { featureProductDataPath, featurePath } = useEditModelPaths(
    selectedIndex,
    sectionIndex,
    featureIndex,
  );
  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: featureProductDataPath.highlightType,
      dataStatus: featureProductDataPath.dataStatus,
    },
  });
  const handleUpdateSectionData = useUpdateSectionData(
    selectedIndex,
    sectionIndex,
  );

  /** State **/

  /** Effect */

  /** Handler **/
  const handleInputChange = debounce((newValue: string | null) => {
    setValue(
      featureProductDataPath.dataStatus,
      isEmpty(newValue)
        ? InvestmentDataStatus.MissingData
        : InvestmentDataStatus.Filled,
    );
    setValue(
      featurePath.dataStatus,
      isEmpty(newValue)
        ? InvestmentDataStatus.MissingData
        : InvestmentDataStatus.Filled,
    );
    setValue(featurePath.isModified, !isEqual(newValue, originalValue));
    setValue(
      featureProductDataPath.isModified,
      !isEqual(newValue, originalValue),
    );
    const publishedValue = getValues(featureProductDataPath.publishedValue);
    if (publishedValue !== newValue) {
      setValue(featureProductDataPath.highlightType, HighlightType.Edited);
      setValue(featurePath.highlightType, HighlightType.Edited);
    } else {
      setValue(featureProductDataPath.highlightType, null);
      setValue(featurePath.highlightType, null);
    }
    handleUpdateSectionData();
  }, DEBOUNCE_DELAY);
  return (
    <SHStack
      flexDirection="row"
      flexWrap={{ sm: "wrap", lg: "nowrap" }}
      gap={{ sm: 2, lg: 4 }}
    >
      <SHStack width={{ sm: "100%", lg: "320px" }}>
        <SHLabel
          label={
            <CustomLabel
              featureData={feature}
              disableInformationButton
              dotProps={{
                orangeDot: isShowOrangeDot,
                blueDot: isShowBlueDot,
                greenDot: feature.isBusinessMetric,
              }}
            />
          }
        />
        <SHHtmlBlock variant="body3" content={feature.description} />
      </SHStack>
      <SHStack sx={{ width: "520px", height: "280px" }}>
        <Controller
          control={control}
          name={featureProductDataPath.value}
          render={({ field: { ref, ...other } }) => (
            <SHRichTextEditor2
              debounceAbortMethod={"flush"}
              isDisabled={isSaving || isPendingApproval}
              value={toString(getValues(featureProductDataPath.value))}
              label={EditProductDefaults.AdditionalFieldTitle}
              maxCharacters={MAX_LONG_TEXT_LENGTH}
              onChange={(newValue) => {
                const actualValue = isEmpty(newValue) ? null : newValue;
                other.onChange(actualValue);
                handleInputChange(actualValue);
              }}
            />
          )}
        />
      </SHStack>
    </SHStack>
  );
};
