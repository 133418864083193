import {
  useEditManagerPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { FieldTypeId } from "@models/configuration";
import { ImageField } from "@models/core";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { toStringValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { UploadField } from "@pages/suppliers/_id/_products/_id/edit/components/upload-field";
import { CustomLabel } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/custom-label";
import {
  EditManagerInputControllerProps,
  handleOnEditManagerFieldChange,
  toEditManagerNativeValue,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { debounce } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

export const EditManagerImageField = ({
  isCreatingInvestmentProduct,
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditManagerInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductManagerDTO>();

  const { featureProductDataPath } = useEditManagerPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductManagerDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const handleOnChange = (newValue: string) => {
    handleOnEditManagerFieldChange({
      isCreatingInvestmentProduct,
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  return (
    <Controller
      name={featureProductDataPath.value}
      control={control}
      render={({ field: { ref, ...other } }) => {
        const imageField = toEditManagerNativeValue(
          other.value,
          FieldTypeId.Image,
        );

        return (
          <UploadField
            disabled={isDisabled}
            label={
              <CustomLabel
                featureData={featureData}
                isDisabled={isDisabled}
                dotProps={{
                  orangeDot: isShowOrangeDot,
                  blueDot: isShowBlueDot,
                  greenDot: !!featureData?.isBusinessMetric,
                }}
              />
            }
            showImage={!!imageField}
            isEditMode
            value={imageField}
            onChange={(value: ImageField | null) => {
              const newValue = toStringValue(value, FieldTypeId.Image);
              other.onChange(newValue);
              debouncedHandleInputChange(newValue);
            }}
          />
        );
      }}
    />
  );
};
