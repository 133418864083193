import { useTheme } from "@mui/material";
import {
  NonTruncatableWords,
  PDFFonts,
} from "@pages/platform-analysis/components/buttons/export-pdf/config";
import {
  DEFAULT_GAP,
  DEFAULT_GAP_4,
  MARGIN_HORIZONTAL,
  MARGIN_VERTICAL,
  PAGE_FOOTER_HEIGHT,
  REVIEW_FIRST_PAGE_HEADER_HEIGHT,
  REVIEW_PAGE_BODY_HEIGHT,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import { chunkSubstr } from "@pages/platform-analysis/components/buttons/export-pdf/util";
import { Font, StyleSheet } from "@react-pdf/renderer";

Font.register({
  family: "ClashDisplay",
  fonts: [
    {
      src: PDFFonts.ClashDisplayRegular,
      fontStyle: "normal",
    },
    {
      src: PDFFonts.ClashDisplayBold,
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "Epilogue",
  fonts: [
    {
      src: PDFFonts.EpilogueRegular,
      fontStyle: "normal",
    },
    {
      src: PDFFonts.EpilogueItalic,
      fontStyle: "italic",
    },
    {
      src: PDFFonts.EpilogueSemiBold,
      fontWeight: "semibold",
    },
    {
      src: PDFFonts.EpilogueBold,
      fontWeight: "bold",
    },
    {
      src: PDFFonts.EpilogueBoldItalic,
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
});

Font.registerHyphenationCallback((word) => {
  if (word.length > 16 && !NonTruncatableWords.includes(word)) {
    return chunkSubstr(word, 14);
  } else {
    return [word];
  }
});

export const useReviewStyles = () => {
  const { palette } = useTheme();

  const typographyStyle = StyleSheet.create({
    h1: {
      fontFamily: "ClashDisplay",
      fontWeight: "bold",
      fontSize: "35px",
    },
    h2: {
      fontFamily: "ClashDisplay",
      fontWeight: "bold",
      fontSize: "24px",
    },
    title: {
      fontFamily: "Epilogue",
      fontWeight: "bold",
      fontSize: "14px",
    },
    subtitle: {
      fontFamily: "Epilogue",
      fontWeight: "bold",
      fontSize: "12px",
    },
    body1: {
      fontFamily: "Epilogue",
      fontWeight: "normal",
      fontSize: "10px",
    },
    body2: {
      fontFamily: "Epilogue",
      fontWeight: "normal",
      fontSize: "8px",
    },
  });

  const pageStyle = StyleSheet.create({
    container: {
      position: "relative",
      backgroundColor: palette.common.white,
    },
    layout: {
      gap: DEFAULT_GAP,
      alignItems: "stretch",
      flexDirection: "column",
      justifyContent: "space-between",
      marginVertical: MARGIN_VERTICAL,
      marginHorizontal: MARGIN_HORIZONTAL,
    },
    header: {
      gap: DEFAULT_GAP_4,
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      height: REVIEW_FIRST_PAGE_HEADER_HEIGHT,
      // backgroundColor: "#4073ff", //Just turn on for testing
    },
    body: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      height: REVIEW_PAGE_BODY_HEIGHT,
      // backgroundColor: "#c5dfd6", //Just turn on for testing
    },
    footer: {
      alignItems: "center",
      alignContent: "center",
      flexDirection: "column",
      height: PAGE_FOOTER_HEIGHT,
      // backgroundColor: "#4073ff", //Just turn on for testing
    },
  });

  return {
    typographyStyle,
    pageStyle,
  };
};
