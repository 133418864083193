import {
  SHButton,
  SHDataGrid,
  SHDataGridRef,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { DefaultDataState } from "@components/design-systems/sh-data-grid/constant";
import { StatusBadge } from "@components/status-badge";
import { EditSVG } from "@components/svgs";
import { APIExtRoutes } from "@constants";
import { DateTimeFormat } from "@constants/format";
import { useUserPermissions } from "@hooks/userUserPermission";
import { useViewingGroup } from "@hooks/useViewingGroup";
import { ODataResult } from "@models/core";
import { ViewingGroupDTO } from "@models/viewing-groups/entities/viewingGroups";
import { ViewingGroupStatus } from "@models/viewing-groups/enum/status";
import { useTheme } from "@mui/material";
import { ArchiveConfirmDialog } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/components/archive-viewing-group-dialog";
import { ArchiveButton } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/components/buttons/archive";
import { RestoreButton } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/components/buttons/restore";
import ViewingGroupDialog from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/components/viewing-group-dialog";
import { DialogTitles } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/config";
import { customViewingGroupSort } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/util";
import { SpecialViewingGroup } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";

import { createColumnHelper, VisibilityState } from "@tanstack/react-table";
import { nameOfFactory } from "@utils";
import { format, isValid } from "date-fns";
import { isEqual, toString } from "lodash";
import { forwardRef, useMemo, useState } from "react";
import { generatePath, useParams } from "react-router";
import { useToggle } from "react-use";
const nameOf = nameOfFactory<ViewingGroupDTO>();
const columnHelper = createColumnHelper<ViewingGroupDTO>();

const pickColumns: (keyof ViewingGroupDTO)[] = [
  "id",
  "name",
  "createdDate",
  "lastModifiedDate",
  "status",
];
type ViewingGroupAction = "Restore" | "Archive" | "Edit";
type SelectedViewingGroupId = {
  id: string;
  action: ViewingGroupAction;
};

const ViewingGroupTable = forwardRef<SHDataGridRef>((_, ref) => {
  // State

  const [openEditDialog, toggleOpenEditDialog] = useToggle(false);
  const [openDeleteDialog, toggleOpenDeleteDialog] = useToggle(false);
  const [selectedViewingGroupId, setSelectedViewingGroupId] =
    useState<SelectedViewingGroupId | null>(null);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [viewingGroupDetails, setViewingGroupDetails] =
    useState<ViewingGroupDTO | null>(null);
  const { isSuperAdmin, isSupplierGroup } = useUserPermissions();
  const [openRestoreDialog, toggleOpenRestoreDialog] = useToggle(false);
  const columnVisibility: VisibilityState = useMemo(
    () => ({
      actionColAdmin: isSuperAdmin ?? false,
      actionColSupplier: isSupplierGroup ?? false,
    }),
    [isSuperAdmin, isSupplierGroup],
  );

  //Hook

  const { palette } = useTheme();
  const { supplierId } = useParams();
  const { handleUpdateViewingGroupStatus, loadViewingGroupDetails } =
    useViewingGroup();

  //Handlers
  const handleRefreshOData = () => {
    if (ref && typeof ref === "object") {
      ref.current?.refreshOdata();
    }
  };
  const handleOpenEditDialog = async (viewingGroupId: string) => {
    setSelectedViewingGroupId({
      action: "Edit",
      id: viewingGroupId,
    });
    const viewingGroup = await loadViewingGroupDetails(viewingGroupId);
    if (viewingGroup) {
      setViewingGroupDetails(viewingGroup);
      toggleOpenEditDialog(true);
    }
    setSelectedViewingGroupId(null);
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Group name",
        cell: (props) => <SHTypography>{props.cell.getValue()}</SHTypography>,
        enableColumnFilter: false,
      }),
      columnHelper.accessor("lastModifiedDate", {
        header: "Last modified",
        cell: (props) => {
          const date = toString(props.cell.getValue());
          if (!isValid(new Date(date))) return "";
          return date ? format(new Date(date), `${DateTimeFormat}`) : "";
        },
        enableColumnFilter: false,
      }),
      columnHelper.accessor("status", {
        header: "Status",

        cell: (props) => (
          <StatusBadge status={props.cell.getValue() as ViewingGroupStatus} />
        ),
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row, {
        id: "actionColAdmin",
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,

        cell: (props) => {
          const canPerformAction = !isEqual(
            props.row.original.name,
            SpecialViewingGroup.AllSuitabilityHubUsers,
          );
          const isLoadingEditButton =
            isEqual(selectedViewingGroupId?.id, props.row.original.id) &&
            isEqual(selectedViewingGroupId?.action, "Edit");
          return (
            <SHStack spacing={2} direction="row">
              <SHButton
                disabled={!canPerformAction || isLoadingEditButton}
                isLoading={isLoadingEditButton}
                onClick={() => {
                  handleOpenEditDialog(props.row.original.id);
                }}
                startIcon={<EditSVG color={palette.common.white} />}
                variant="contained"
              >
                Edit
              </SHButton>
              {props.row.original.status === ViewingGroupStatus.Active ? (
                <ArchiveButton
                  disabled={!canPerformAction}
                  onClick={() => {
                    setSelectedViewingGroupId({
                      id: props.row.original.id,
                      action: "Archive",
                    });
                    toggleOpenDeleteDialog();
                  }}
                />
              ) : (
                <RestoreButton
                  onClick={async () => {
                    setSelectedViewingGroupId({
                      id: props.row.original.id,
                      action: "Restore",
                    });
                    toggleOpenRestoreDialog();
                  }}
                />
              )}
            </SHStack>
          );
        },
        meta: { sx: { width: "185px" } },
      }),
      columnHelper.accessor((row) => row, {
        id: "actionColSupplier",
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,

        cell: (props) => {
          const isLoadingEditButton =
            isEqual(selectedViewingGroupId?.id, props.row.original.id) &&
            isEqual(selectedViewingGroupId?.action, "Edit");
          const canPerformAction = !isEqual(
            props.row.original.name,
            SpecialViewingGroup.AllSuitabilityHubUsers,
          );
          return (
            <SHButton
              disabled={isLoadingEditButton || !canPerformAction}
              isLoading={isLoadingEditButton}
              onClick={() => {
                handleOpenEditDialog(props.row.original.id);
              }}
              variant="outlined"
            >
              View
            </SHButton>
          );
        },
        meta: { sx: { width: "150px" } },
      }),
    ],
    // eslint-disable-next-line
    [selectedViewingGroupId],
  );

  return (
    <>
      <SHDataGrid
        ref={ref}
        searchBoxProps={{ placeholder: "Search viewing groups" }}
        state={{
          ...DefaultDataState,
          sorting: [{ id: nameOf("name"), desc: false }],
          columnVisibility,
        }}
        columns={columns as any}
        odata={{
          pickColumns,
          url: generatePath(APIExtRoutes.odataViewingGroups, { supplierId }),
          globalFilterColumns: [nameOf("name")],
          onDataReceived: (state, data: ODataResult<ViewingGroupDTO>) => ({
            ...data,
            value: customViewingGroupSort(data.value),
          }),
        }}
        emptyMessage="No data"
      />
      {openEditDialog && (
        <ViewingGroupDialog
          isSuperAdmin={isSuperAdmin}
          onSubmit={handleRefreshOData}
          isOpen={openEditDialog}
          onClose={() => {
            setViewingGroupDetails(null);
            toggleOpenEditDialog(false);
          }}
          buttonLabel="Save changes and publish viewing group"
          dialogTitle={
            isSuperAdmin
              ? DialogTitles.EDIT_VIEWING_GROUP
              : DialogTitles.VIEW_VIEWING_GROUP
          }
          initialData={viewingGroupDetails}
        />
      )}
      {openDeleteDialog && (
        <ArchiveConfirmDialog
          header="Archive viewing group?"
          body="You are about to archive the viewing group. Are you sure?"
          buttonLabel={{
            ok: "Yes, archive viewing group",
            cancel: "Cancel",
          }}
          isLoading={isUpdatingStatus}
          open={openDeleteDialog}
          onClose={() => {
            setSelectedViewingGroupId(null);
            toggleOpenDeleteDialog(false);
          }}
          onOk={async () => {
            setIsUpdatingStatus(true);
            await handleUpdateViewingGroupStatus(
              ViewingGroupStatus.Archived,
              selectedViewingGroupId?.id,
              {
                onSuccess: () => {
                  handleRefreshOData();
                  toggleOpenDeleteDialog(false);
                },
              },
            );
            setSelectedViewingGroupId(null);
            setIsUpdatingStatus(false);
          }}
        />
      )}
      {openRestoreDialog && (
        <ArchiveConfirmDialog
          header="Restore viewing group?"
          body="You are about to restore the viewing group. Are you sure?"
          buttonLabel={{
            ok: "Yes, restore viewing group",
            cancel: "Cancel",
          }}
          isLoading={isUpdatingStatus}
          open={openRestoreDialog}
          onClose={() => {
            setSelectedViewingGroupId(null);
            toggleOpenRestoreDialog(false);
          }}
          onOk={async () => {
            setIsUpdatingStatus(true);
            await handleUpdateViewingGroupStatus(
              ViewingGroupStatus.Active,
              selectedViewingGroupId?.id,
              {
                onSuccess: () => {
                  handleRefreshOData();
                  toggleOpenRestoreDialog(false);
                },
              },
            );
            setSelectedViewingGroupId(null);
            setIsUpdatingStatus(false);
          }}
        />
      )}
    </>
  );
});

export default ViewingGroupTable;
