import { CloseButton } from "@components/buttons/close";
import {
  SHButton,
  SHCheckbox,
  SHPopper,
  SHRadio,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHFormControlLabel } from "@components/design-systems/sh-form-control-label";
import { FilterClearSVG, FilterSVG } from "@components/svgs";
import {
  PlatformForFilterDTO,
  SMAFilterValue,
} from "@models/managed-accounts/entities/filter";
import {
  SMAListCurrentlySelectedFilter,
  SMAListFavouriteFilter,
  SMAListMinimumInvestmentSizeFilter,
} from "@models/managed-accounts/enums/filter";
import { FormGroup, useTheme } from "@mui/material";
import {
  currentlySelectedFilterOptions,
  favouriteFilterOptions,
  minimumInvestmentSizeFilterOptions,
} from "@pages/managed-accounts/constant";
import { initialFilterCalculatedInvestment } from "@pages/managed-accounts/sma-list/config";
import { RootState } from "@redux/store";
import { hexToRGBA } from "@utils";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

export type SMAListFilter = {
  favourites: SMAListFavouriteFilter;
  currentlySelected: SMAListCurrentlySelectedFilter;
  minimumInvestmentSize: SMAListMinimumInvestmentSizeFilter;
  platforms: PlatformForFilterDTO[];
};

export interface SMAListFiltersButtonProps {
  width?: string;
  zIndex?: number;
  onFilters: (filters?: SMAListFilter) => void;
  [key: string]: any;
}

export const SMAListFiltersButton = ({
  width,
  zIndex = 3,
  onFilters,
  ...others
}: SMAListFiltersButtonProps) => {
  const { palette } = useTheme();
  const { filters } = useSelector((state: RootState) => state.smaList);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [platformForFilter, setPlatformForFilter] = useState<
    PlatformForFilterDTO[] | undefined
  >([]);
  const [filterData, setFilterData] = useState<SMAListFilter>();
  const [previousFilterData, setPreviousFilterData] = useState<SMAListFilter>();
  const [isFiltered, setIsFiltered] = useState(false);

  const handleChangeAndApplyFilter = (
    filterData: SMAListFilter | undefined,
  ) => {
    setFilterData(filterData);
    if (onFilters) onFilters(filterData);
    setPreviousFilterData(filterData);

    const initialFilter = { ...initialFilterCalculatedInvestment.filters };
    let isFilterCheck = false;
    for (const filterItemKey in filterData) {
      if (filterItemKey !== "platforms") {
        if (
          initialFilter[filterItemKey as keyof SMAListFilter] !==
          filterData[filterItemKey as keyof SMAListFilter]
        ) {
          isFilterCheck = true;
          break;
        }
      } else {
        if (!platformForFilter) continue;
        if (
          filterData[filterItemKey as keyof SMAListFilter].length <
          platformForFilter.length
        ) {
          isFilterCheck = true;
          break;
        }
      }
    }

    setIsFiltered(isFilterCheck);
  };

  const handleCancelFilter = useCallback(() => {
    setFilterData(previousFilterData);
    setOpen(false);
  }, [previousFilterData]);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };
  
  const handleFilterAllPlatforms = () => {
    if (
      (platformForFilter?.length ?? 0) > 0 &&
      platformForFilter?.every((filterItem) =>
        filterData?.platforms?.some(
          (item) =>
            item.productName === filterItem.productName &&
            item.subProductName === filterItem.subProductName,
        ),
      )
    ) {
      // If all platforms are selected, remove all
      const newFilterData = {
        ...filterData,
        platforms: [],
      } as SMAListFilter;
      handleChangeAndApplyFilter(newFilterData);
    } else {
      // If not all are selected, select all
      const newFilterData = {
        ...filterData,
        platforms: platformForFilter,
      } as SMAListFilter;
      handleChangeAndApplyFilter(newFilterData);
    }
  };

  const isAllPlatformsChecked = () => {
    if (!platformForFilter?.length || !filterData?.platforms) return false;
    return platformForFilter.every((filterItem) =>
      filterData.platforms.some(
        (item) =>
          item.productName === filterItem.productName &&
          item.subProductName === filterItem.subProductName,
      ),
    );
  };

  useEffect(() => {
    if (!open) return;
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        handleCancelFilter();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, buttonRef, open, handleCancelFilter]);

  useEffect(() => {
    setPlatformForFilter(filters.platforms);
    setFilterData(filters);
    setPreviousFilterData(filters);
  }, [filters]);

  return (
    <>
      <SHButton
        ref={buttonRef}
        onClick={handleOnClick}
        color="primary"
        variant="text"
        size="extraMedium"
        startIcon={
          !isFiltered ? (
            <FilterSVG fill={palette.primary.main} />
          ) : (
            <FilterClearSVG fill={palette.primary.main} />
          )
        }
        sx={{ width: "100px" }}
        {...others}
      >
        Filters
      </SHButton>

      <SHPopper
        className="SMAListFilter"
        anchorEl={anchorEl}
        open={open}
        placement="left-start"
        popperOptions={{ modifiers: [{ name: "flip", enabled: false }] }}
        sx={{
          width: width,
          zIndex: zIndex,
          marginRight: "-81px !important",
        }}
      >
        <SHStack
          ref={wrapperRef}
          sx={{
            padding: "15px",
            height: "440px",
            boxShadow: "none",
            boxSizing: "border-box",
            border: `1px solid ${palette.divider}`,
            backgroundColor: hexToRGBA(palette.common.white, 0.95),
            filter: "drop-shadow(1px 1px 2px rgba(65, 73, 89, 0.25))",
          }}
        >
          <SHStack
            flexDirection={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            gap={2}
          >
            <SHStack
              flexDirection="column"
              alignItems={"flex-start"}
              spacing={"15px"}
            >
              {/* Favourites */}
              <SHStack>
                <SHStack flexDirection="row" alignItems="center" gap={1}>
                  <FilterSVG />
                  <SHTypography variant="body1">Favourites</SHTypography>
                </SHStack>
                <FormGroup sx={{ padding: "2px" }}>
                  {favouriteFilterOptions.map(
                    (filterItem: SMAFilterValue, index: number) => (
                      <SHFormControlLabel
                        key={index}
                        size="small"
                        label={filterItem.label}
                        control={
                          <SHRadio
                            checked={
                              filterData?.favourites === filterItem.value
                            }
                            size={"small"}
                            onClick={() => {
                              const newFilterData = {
                                ...filterData,
                                favourites: filterItem.value,
                              } as SMAListFilter;
                              handleChangeAndApplyFilter(newFilterData);
                            }}
                          />
                        }
                        style={{ height: 30 }}
                      />
                    ),
                  )}
                </FormGroup>
              </SHStack>
              {/* Current selected */}
              <SHStack>
                <SHStack flexDirection="row" alignItems="center" gap={1}>
                  <FilterSVG />
                  <SHTypography variant="body1">
                    Currently selected
                  </SHTypography>
                </SHStack>
                <FormGroup sx={{ padding: "2px" }}>
                  {currentlySelectedFilterOptions.map(
                    (filterItem: SMAFilterValue, index: number) => (
                      <SHFormControlLabel
                        key={index}
                        size="small"
                        control={
                          <SHRadio
                            checked={
                              filterData?.currentlySelected === filterItem.value
                            }
                            size={"small"}
                            onClick={() => {
                              const newFilterData = {
                                ...filterData,
                                currentlySelected: filterItem.value,
                              } as SMAListFilter;
                              handleChangeAndApplyFilter(newFilterData);
                            }}
                            style={{ height: 30 }}
                          />
                        }
                        label={filterItem.label}
                      />
                    ),
                  )}
                </FormGroup>
              </SHStack>
              {/* Minimum investment size */}
              <SHStack>
                <SHStack flexDirection="row" alignItems="center" gap={1}>
                  <FilterSVG />
                  <SHTypography variant="body1">
                    Minimum investment size
                  </SHTypography>
                </SHStack>
                <FormGroup sx={{ padding: "2px" }}>
                  {minimumInvestmentSizeFilterOptions.map(
                    (filterItem: SMAFilterValue, index: number) => (
                      <SHFormControlLabel
                        key={index}
                        size="small"
                        control={
                          <SHRadio
                            size={"small"}
                            checked={
                              filterData?.minimumInvestmentSize ===
                              filterItem.value
                            }
                            onClick={() => {
                              const newFilterData = {
                                ...filterData,
                                minimumInvestmentSize: filterItem.value,
                              } as SMAListFilter;
                              handleChangeAndApplyFilter(newFilterData);
                            }}
                            style={{ height: 30 }}
                          />
                        }
                        label={filterItem.label}
                      />
                    ),
                  )}
                </FormGroup>
              </SHStack>
            </SHStack>
            {/* Platforms */}
            <SHStack
              flexDirection="row"
              justifyContent={"space-between"}
              height={"100%"}
              gap={1}
            >
              <SHStack
                flexDirection={"column"}
                justifyContent="space-between"
                width="250px"
              >
                <SHStack>
                  <SHStack
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                    style={{ paddingLeft: "9px" }}
                  >
                    <FilterSVG />
                    <SHTypography variant="body1">Platforms</SHTypography>
                  </SHStack>
                  <SHStack
                    sx={{
                      padding: "2px",
                      maxHeight: "380px",
                      overflowY: "scroll",
                    }}
                  >
                    <FormGroup>
                      {/* All platform filter checkbox */}
                      {!isEmpty(platformForFilter) && (
                        <SHFormControlLabel
                          size="small"
                          style={{ paddingLeft: "10px" }}
                          control={
                            <SHCheckbox
                              size="small"
                              checked={isAllPlatformsChecked()}
                              onClick={handleFilterAllPlatforms}
                            />
                          }
                          label="All"
                        />
                      )}
                      {/* platforms filter */}

                      {platformForFilter?.map(
                        (filterItem: PlatformForFilterDTO, index: number) => (
                          <SHFormControlLabel
                            key={index}
                            size="small"
                            style={{ paddingLeft: "10px" }}
                            control={
                              <SHCheckbox
                                checked={
                                  !filterData?.platforms ||
                                  filterData?.platforms.some(
                                    (item) =>
                                      item.productName ===
                                        filterItem.productName &&
                                      item.subProductName ===
                                        filterItem.subProductName,
                                  )
                                }
                                size={"small"}
                                onClick={() => {
                                  let newList = filterData?.platforms
                                    ? [...filterData.platforms]
                                    : [];

                                  if (
                                    filterData?.platforms.some(
                                      (item) =>
                                        item.productName ===
                                          filterItem.productName &&
                                        item.subProductName ===
                                          filterItem.subProductName,
                                    )
                                  ) {
                                    newList = newList.filter(
                                      (item) =>
                                        item.productName !==
                                          filterItem.productName ||
                                        item.subProductName !==
                                          filterItem.subProductName,
                                    );
                                  } else {
                                    newList.push(filterItem);
                                  }

                                  const newFilterData = {
                                    ...filterData,
                                    platforms: newList,
                                  } as SMAListFilter;
                                  handleChangeAndApplyFilter(newFilterData);
                                }}
                              />
                            }
                            label={`${filterItem.productName} ${filterItem.subProductName}`}
                          />
                        ),
                      )}
                    </FormGroup>
                  </SHStack>
                </SHStack>
              </SHStack>
              <CloseButton
                width="12px"
                height="12px"
                onClick={handleCancelFilter}
              />
            </SHStack>
          </SHStack>
        </SHStack>
      </SHPopper>
    </>
  );
};
