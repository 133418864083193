import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import {
  useEditModelPaths,
  useUpdateSectionData,
} from "@hooks/useInvestmentProduct";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductModelsDTO } from "@models/product/investment-product/entities/model";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { castToViewingGroupSelectionDTO } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import { isEqual } from "lodash";
import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface TableViewingGroupSelectionProps {
  selectedIndex: [number, number];
  sectionIndex: number;
  featureIndex: number;
  feature: InvestmentProductConfigurationFeature;
  onChange?: (value: ViewingGroupSelectionDTO[] | null) => void;
  isDisabled?: boolean;
}

export const TableViewingGroupSelection = ({
  selectedIndex,
  sectionIndex,
  featureIndex,
  feature,
  isDisabled,
  onChange,
}: TableViewingGroupSelectionProps) => {
  const { control, getValues, setValue } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const { featurePath } = useEditModelPaths(
    selectedIndex,
    sectionIndex,
    featureIndex,
  );
  const watchedValue = useWatch({
    control,
    name: [featurePath.viewingGroupSelections],
  });
  const visibilityValue = useMemo(
    () => getValues(featurePath.viewingGroupSelections),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watchedValue, selectedIndex, featureIndex, sectionIndex],
  );
  const handleUpdateSectionData = useUpdateSectionData(
    selectedIndex,
    sectionIndex,
  );
  const handleVisibilityChange = (value: ViewingGroupSelectionDTO[] | null) => {
    setValue(featurePath.viewingGroupSelections, value);
    setValue(
      featurePath.isModified,
      !isEqual(value, feature.viewingGroupSelections),
    );
    handleUpdateSectionData();
  };
  return (
    <SelectViewingGroups
      disabled={isDisabled}
      textFieldProps={{
        sx: {
          width: "50%",
          "& .MuiTypography-root ": {
            padding: "10px 0px 8px 0px",
          },
        },
      }}
      onChange={(value) => {
        onChange && onChange(value);
        handleVisibilityChange(value);
      }}
      value={castToViewingGroupSelectionDTO(visibilityValue)}
      viewingGroupData={feature.viewingGroupSelections ?? []}
    />
  );
};
