import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { SHStack } from "@components/design-systems";
import { SHNumericFieldV2 } from "@components/design-systems/sh-numeric-field-v2";
import {
  useEditManagerPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { FieldTypeId } from "@models/configuration";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { CustomLabel } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/custom-label";
import {
  EditManagerInputControllerProps,
  handleOnEditManagerFieldChange,
  toEditManagerNativeValue,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { debounce, isEqual } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

type EditManagerNumberInputProps = EditManagerInputControllerProps & {
  prefix?: string;
  suffix?: string;
};

export const EditManagerNumberInput = ({
  isCreatingInvestmentProduct,
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  prefix,
  suffix,
  onChange,
}: EditManagerNumberInputProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductManagerDTO>();
  const { featureProductDataPath, featureDataPath } = useEditManagerPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductManagerDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const handleOnChange = (newValue: string) => {
    handleOnEditManagerFieldChange({
      isCreatingInvestmentProduct,
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };
  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    setValue(
      featureProductDataPath.isModified,
      !isEqual(featureData.viewingGroupSelections, newValue),
    );
    onChange && onChange({ sectionIndex, featureIndex });
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );
  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );

  return (
    <SHStack width={{ xs: "100%", md: 520 }} direction={"row"} gap={2}>
      <Controller
        name={featureProductDataPath.value}
        control={control}
        render={({ field: { ref, ...other } }) => (
          <SHNumericFieldV2
            ref={ref}
            sx={{
              flex: 1,
            }}
            disabled={isDisabled}
            thousandSeparator
            prefix={prefix}
            suffix={suffix}
            value={toEditManagerNativeValue(other.value, FieldTypeId.Number)}
            onValueChange={(values) => {
              other.onChange(values.value);
              debouncedHandleInputChange(values.value);
            }}
            fullWidth
            label={
              <CustomLabel
                featureData={featureData}
                isDisabled={isDisabled}
                dotProps={{
                  orangeDot: isShowOrangeDot,
                  blueDot: isShowBlueDot,
                  greenDot: !!featureData?.isBusinessMetric,
                }}
              />
            }
            placeholder={EditProductDefaults.PlaceHolder}
          />
        )}
      />

      {featureData.allowedVisibilitySetting && (
        <Controller
          name={featureDataPath.viewingGroupSelections}
          control={control}
          render={({ field: { ref, ...other } }) => (
            <SelectViewingGroups
              disabled={isDisabled}
              textFieldProps={{
                label: `Visible to`,
              }}
              sx={{
                flexBasis: "40%",
              }}
              onChange={(value) => {
                other.onChange(value);
                debounceVisibilityChange(value);
              }}
              value={
                (getValues(featureDataPath.viewingGroupSelections) as
                  | ViewingGroupSelectionDTO[]
                  | null) || []
              }
              viewingGroupData={featureData.viewingGroupSelections ?? []}
            />
          )}
        />
      )}
    </SHStack>
  );
};
