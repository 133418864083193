import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { SHDividerSkeleton } from "@components/skeletons/design-systems";
import { useTheme } from "@mui/material";

export const FeeAndRateTabSkeleton = () => {
  const { palette } = useTheme();

  return (
    <SHStack direction={"row"}>
      <>
        <SHStack width={"305px"} spacing={0.3} paddingTop={0.3}>
          {Array.from(Array(5).keys()).map((index) => (
            <SHSkeleton
              key={index}
              width={"100%"}
              height="80px"
              delay={false}
            />
          ))}
        </SHStack>
        <SHStack flexGrow={1} sx={{ pl: "25px", py: "25px" }} spacing={2}>
          <SHStack spacing={1}>
            <SHSkeleton width={"300px"} height="40px" delay={false} />
            <SHSkeleton width={"100%"} height="70px" delay={false} />
          </SHStack>
          <SHDividerSkeleton delay={false} />
          {Array.from(Array(2).keys()).map((index) => (
            <SHStack key={index} spacing={1.5}>
              <SHStack
                sx={{
                  padding: "5px",
                  border: `1px solid ${palette.secondary[100]}`,
                }}
              >
                <SHSkeleton
                  width={"100%"}
                  height="40px"
                  variant="rectangular"
                  delay={false}
                />
              </SHStack>
              <SHStack>
                {[0, 1, 2]?.map((item, index) => (
                  <SHStack
                    direction={"row"}
                    sx={{
                      gap: "10px",
                      padding: "5px",
                      border: `1px solid ${palette.secondary[100]}`,
                      borderTop: index > 0 ? "none" : undefined,
                    }}
                  >
                    {Array.from(Array(3).keys()).map((index) => (
                      <SHSkeleton
                        key={index}
                        width={"33.3%"}
                        height="50px"
                        variant="rectangular"
                        delay={false}
                      />
                    ))}
                  </SHStack>
                ))}
              </SHStack>
            </SHStack>
          ))}
        </SHStack>
      </>
    </SHStack>
  );
};
