import { SHBooleanField } from "@components/design-systems";
import { useUpdateSectionData } from "@hooks/useInvestmentProduct";
import { HighlightType } from "@models/configuration";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import {
  InvestmentProductModelsDTO,
  ProductModelValueDTO,
} from "@models/product/investment-product/entities/model";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import {
  getFeaturePaths,
  getFeatureProductDataPaths,
  updateCompositeJsonField,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import { tryParseJson } from "@pages/suppliers/_id/util";
import { isEmpty, isEqual, isNil } from "lodash";
import { useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

interface ModelFeatureBooleanFieldProps {
  headerId: string | undefined;
  selectedIndex: [number, number];
  featureIndex: number;
  sectionIndex: number;
  feature: InvestmentProductConfigurationFeature;
  onChange?: (value: boolean | null | undefined) => void;
  isShowClearButton?: boolean;
  isDisabled?: boolean;
}
/**
 * Only for edit models table
 *
 * @description This Component use value from useFormValues hook (value have watched) to make sure SHBooleanField value updated
 *
 */
export const TableBooleanField = ({
  headerId,
  selectedIndex,
  featureIndex,
  sectionIndex,
  feature,
  onChange,
  isShowClearButton = true,
  isDisabled = false,
}: ModelFeatureBooleanFieldProps) => {
  const originalValue = feature.productData?.value;
  /** Hook */
  const { control, getValues, setValue } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const featureProductDataPath = useMemo(
    () => getFeatureProductDataPaths(selectedIndex, sectionIndex, featureIndex),
    [selectedIndex, sectionIndex, featureIndex],
  );
  const featurePath = useMemo(
    () => getFeaturePaths(selectedIndex, sectionIndex, featureIndex),
    [selectedIndex, sectionIndex, featureIndex],
  );
  const watchedValues = useWatch({
    control,
    name: [featureProductDataPath.value],
  });
  const productValue = useMemo(
    () => getValues(featureProductDataPath.value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watchedValues, selectedIndex],
  ) as string | undefined;
  const handleUpdateSectionData = useUpdateSectionData(
    selectedIndex,
    sectionIndex,
  );
  const updateCompositeDataStatus = (jsonValue: string) => {
    const productDataValues = tryParseJson(jsonValue) as ProductModelValueDTO[];
    if (!Array.isArray(productDataValues)) return;

    const isFilled = productDataValues.every((item) => !isEmpty(item.value));
    setValue(
      featureProductDataPath.dataStatus,
      isFilled ? InvestmentDataStatus.Filled : InvestmentDataStatus.MissingData,
    );
    setValue(
      featurePath.dataStatus,
      isFilled ? InvestmentDataStatus.Filled : InvestmentDataStatus.MissingData,
    );

    const publishedValue = getValues(featureProductDataPath.publishedValue);
    setValue(
      featureProductDataPath.highlightType,
      publishedValue !== jsonValue ? HighlightType.Edited : null,
    );
    setValue(
      featurePath.highlightType,
      publishedValue !== jsonValue ? HighlightType.Edited : null,
    );
    setValue(
      featureProductDataPath.isModified,
      !isEqual(jsonValue, originalValue),
    );
    setValue(featurePath.isModified, !isEqual(jsonValue, originalValue));
    handleUpdateSectionData();
  };

  /** Handler */
  const handleBooleanChange = (
    newValue: boolean | null | undefined,
    headerId: string | undefined,
  ) => {
    const publishedValue = getValues(featureProductDataPath.publishedValue);

    // Case: Non-JSON value and clearing data
    if (isNil(newValue) && isEmpty(headerId)) {
      handleMissingDataCase(publishedValue);
      return;
    }
    // Case: Json value and and clearing data
    if (isNil(newValue) && !isEmpty(headerId)) {
      handleCompositeJsonCase("", headerId);
    }

    const booleanAsString = newValue ? "true" : "false";

    // Case: Non-JSON value and a non-null boolean
    if (isEmpty(headerId)) {
      handleFilledDataCase(booleanAsString, publishedValue);
      return;
    }

    // Case: JSON composite data with headerId
    handleCompositeJsonCase(booleanAsString, headerId);
  };

  // Helper functions for each case
  const handleMissingDataCase = (publishedValue: any) => {
    setValue(featureProductDataPath.value, null);
    setValue(
      featureProductDataPath.dataStatus,
      InvestmentDataStatus.MissingData,
    );
    setValue(featurePath.dataStatus, InvestmentDataStatus.MissingData);
    setValue(
      featureProductDataPath.highlightType,
      !isEmpty(publishedValue) ? HighlightType.Edited : null,
    );
    setValue(
      featurePath.highlightType,
      !isEmpty(publishedValue) ? HighlightType.Edited : null,
    );
    setValue(
      featurePath.highlightType,
      !isEmpty(publishedValue) ? HighlightType.Edited : null,
    );
    setValue(featureProductDataPath.isModified, !isEmpty(originalValue));
    setValue(featurePath.isModified, !isEmpty(originalValue));
    handleUpdateSectionData();
  };

  const handleFilledDataCase = (
    booleanAsString: string,
    publishedValue: any,
  ) => {
    setValue(featureProductDataPath.value, booleanAsString);
    setValue(featureProductDataPath.dataStatus, InvestmentDataStatus.Filled);
    setValue(
      featureProductDataPath.isModified,
      !isEqual(originalValue, booleanAsString),
    );
    setValue(
      featureProductDataPath.highlightType,
      publishedValue === booleanAsString ? null : HighlightType.Edited,
    );
    setValue(featurePath.dataStatus, InvestmentDataStatus.Filled);
    setValue(
      featurePath.highlightType,
      publishedValue === booleanAsString ? null : HighlightType.Edited,
    );

    setValue(featurePath.isModified, !isEqual(originalValue, booleanAsString));
    handleUpdateSectionData();
  };

  const handleCompositeJsonCase = (
    booleanAsString: string,
    headerId: string | undefined,
  ) => {
    const oldValue = getValues(featureProductDataPath.value) as string;
    const updatedProductDataValues = updateCompositeJsonField(
      booleanAsString,
      oldValue,
      headerId,
    );
    setValue(featureProductDataPath.value, updatedProductDataValues);
    updateCompositeDataStatus(updatedProductDataValues);
  };
  const renderProductDataValue = () => {
    if (isEmpty(headerId)) return productValue || "";
    if (isEmpty(productValue)) return "";
    const productDataValues: ProductModelValueDTO[] | string = tryParseJson(
      productValue || "",
    );
    if (typeof productDataValues !== "object") {
      return productDataValues;
    }
    const productDataValue = productDataValues.find(
      (value) => value.headerId === headerId,
    );
    return productDataValue?.value || "";
  };
  const renderBooleanValue = () => {
    const stringValue = renderProductDataValue();
    if (isEmpty(stringValue)) return null;
    return stringValue === "true";
  };

  return (
    <Controller
      name={featureProductDataPath.value}
      control={control}
      render={({ field }) => (
        <SHBooleanField
          isShowClearButton={isShowClearButton}
          value={renderBooleanValue()}
          disabled={isDisabled}
          onChange={(_, value) => {
            handleBooleanChange(value, headerId);
            if (onChange) onChange(value);
          }}
        />
      )}
    />
  );
};
