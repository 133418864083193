import { DataStatusBadge } from "@components/data-status-badge";
import {
  SHButton,
  SHDataGrid,
  SHSearchBoxProps,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { StatusBadge } from "@components/status-badge";
import { EditSVG } from "@components/svgs";
import { DateTimeFormat } from "@constants/format";
import { useEditProduct } from "@hooks/useEditProduct";
import { useEditInvestmentProduct } from "@hooks/useEditInvestmentProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { TopBar } from "@layouts/top-bar";
import { ProductDTO } from "@models/product/entities/product";
import { useTheme } from "@mui/material";
import { ProductBeingKickedOutDialog } from "@pages/platform-profiles/components/dialog-being-kick-out";
// import { PermissionDeniedDialog } from "@pages/platform-profiles/components/dialog-claiming-denied";
import { ProductListDataStatus } from "@models/product/enums/productListDataStatus";
import { ProductStatus } from "@models/product/enums/status";
import { ConfirmKickOutDialog } from "@pages/platform-profiles/components/dialog-confirm-kick-out";
import {
  ProductDataStatusFilterOptions,
  ProductStatusFilterOptions,
} from "@pages/platform-profiles/constant";
import { RootState } from "@redux/store";
import { generateProductNavigationPath } from "@services/product/util";
import { createColumnHelper, TableState } from "@tanstack/react-table";
import { nameOfFactory } from "@utils";
import { format } from "date-fns";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ConfirmKickOutAdminDialog } from "../dialog-kick-out-admin";
import { ProductType } from "@models/product/enums/productType";
import { useNotification } from "@hooks/useNotification";
import { isEqual } from "lodash";
import { ProductTypeNames } from "@models/product/enums/productTypeName";
import {
  getListViewProductConfig,
  validateProductType,
} from "@pages/platform-profiles/util";

interface PlatformProfileListViewProps {
  oDataUrl: string;
  showTitle?: boolean;
  defaultState?: Partial<TableState>;
  emptyMessage?: string;
  rightToolbar?: React.ReactNode;
  searchBoxProps?: SHSearchBoxProps;
}

const pickColumns: (keyof ProductDTO)[] = [
  "id",
  "supplierId",
  "productName",
  "productType",
  "supplierName",
  "lastModifiedDate",
  "status",
  "dataStatus",
];
const columnHelper = createColumnHelper<ProductDTO>();
const nameOf = nameOfFactory<ProductDTO>();

export default function PlatformProfileListView({
  oDataUrl,
  showTitle = false,
  defaultState,
  emptyMessage,
  rightToolbar,
  searchBoxProps,
}: PlatformProfileListViewProps): JSX.Element {
  const navigate = useNavigate();
  const { palette } = useTheme();
  const { checkEditProduct } = useEditProduct();
  const { checkEditInvestmentProduct } = useEditInvestmentProduct();
  const [supplierId, setSupplierId] = useState<string | undefined>(undefined);
  const [productType, setProductType] = useState<ProductType | undefined>(
    undefined,
  );
  const dispatch = useDispatch();
  const {
    productUI: { isLoadingEditMode },
  } = useSelector((state: RootState) => state.product);
  const { canGoToEditProduct, canViewProductStatus, user } =
    useUserPermissions();
  const { notify } = useNotification();

  const [selectedProductId, setSelectedProductId] = useState("");
  const handleUpdateEditMode = (
    productType: ProductType,
    isEditMode: boolean,
  ) => {
    const { updateEditMode, updateProductType } = getListViewProductConfig({
      productType: productType,
      checkEditProduct: checkEditProduct,
      checkEditInvestmentProduct: checkEditInvestmentProduct,
    });
    dispatch(updateProductType(productType));
    dispatch(updateEditMode(isEditMode));
  };

  const handleCheckEditAndUpdateEditMode = async (
    supplierId: string,
    productId: string,
    productType: ProductType,
    isEditMode: boolean,
  ) => {
    const { checkEdit, updateEditMode, updateProductType } =
      getListViewProductConfig({
        productType: productType,
        checkEditProduct: checkEditProduct,
        checkEditInvestmentProduct: checkEditInvestmentProduct,
      });

    await checkEdit(productId, () => {
      dispatch(updateProductType(productType));
      dispatch(updateEditMode(isEditMode));
      navigate(
        generateProductNavigationPath({
          productId: productId,
          supplierId: supplierId,
          productType: productType,
        }),
      );
    });
  };

  const checkProductType = (productType: ProductType) => {
    return validateProductType(productType, (errorMessage) => {
      notify(errorMessage, {
        variant: "error",
        close: true,
      });
    });
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("productName", {
        header: "Product name",
        cell: (props) => <SHTypography>{props.cell.getValue()}</SHTypography>,
        enableColumnFilter: false,
        meta: {
          sx: {
            width: "200px",
          },
        },
      }),
      columnHelper.accessor("productType", {
        header: "Type",
        cell: (props) => (
          <SHTypography>
            {ProductTypeNames.renderTypeNameByTypeId(props.cell.getValue())}
          </SHTypography>
        ),
        meta: {
          filterData: ProductTypeNames.toFilterOptions(),
        },
      }),
      columnHelper.accessor("supplierName", {
        header: "Supplier",
        cell: (props) => <SHTypography>{props.cell.getValue()}</SHTypography>,
        enableColumnFilter: false,
        meta: {
          sx: {
            width: "200px",
          },
        },
      }),
      columnHelper.accessor("lastModifiedDate", {
        header: "Last modified",
        cell: (props) => {
          const date = props.cell.getValue();
          return date ? format(new Date(date), `${DateTimeFormat}`) : "";
        },
        enableColumnFilter: false,
        meta: {
          sx: {
            width: "180px",
          },
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (props) => (
          <StatusBadge status={props.cell.getValue() as ProductStatus} />
        ),
        meta: {
          filterData: ProductStatusFilterOptions,
          sx: {
            width: "140px",
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        header: "Data status",
        id: nameOf("dataStatus"),
        cell: (props) =>
          !canViewProductStatus(props.cell.getValue()?.id) ? null : (
            <DataStatusBadge
              status={props.cell.getValue().dataStatus as ProductListDataStatus}
            />
          ),
        meta: {
          filterData: ProductDataStatusFilterOptions,
          sx: {
            width: "180px",
          },
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "actionCol",
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (props) => (
          <SHStack spacing={2} direction="row">
            <SHButton
              startIcon={<EditSVG color={palette.common.white} />}
              variant="contained"
              disabled={
                isLoadingEditMode ||
                !canGoToEditProduct(props.cell.getValue()?.id)
              }
              isLoading={isLoadingEditMode}
              onClick={async () => {
                const productData = props.cell.getValue();
                const productType =
                  productData?.productType ?? ProductType.Platform;
                const productId = productData?.id || "";
                const supplierId = productData?.supplierId;

                if (!checkProductType(productType)) return;
                if (
                  user?.isRestrictedUser &&
                  isEqual(productType, ProductType.Platform)
                ) {
                  notify("Your account cannot view this page", {
                    variant: "error",
                    close: true,
                  });
                  return;
                }
                setSelectedProductId(productId);
                setSupplierId(supplierId);
                setProductType(productType);

                await handleCheckEditAndUpdateEditMode(
                  supplierId,
                  productId,
                  productType,
                  true,
                );
              }}
            >
              Edit
            </SHButton>
            <SHButton
              variant="outlined"
              onClick={() => {
                const productData = props.cell.getValue();
                const productId = productData?.id || "";
                const productType =
                  productData.productType ?? ProductType.Platform;

                if (!checkProductType(productType)) return;
                if (
                  user?.isRestrictedUser &&
                  isEqual(productType, ProductType.Platform)
                ) {
                  notify("Your account cannot view this page", {
                    variant: "error",
                    close: true,
                  });
                  return;
                }
                const supplierId = productData?.supplierId;
                setSupplierId(productData?.supplierId);

                handleUpdateEditMode(productType, false);
                navigate(
                  generateProductNavigationPath({
                    productId: productId,
                    supplierId: supplierId,
                    productType: productType,
                  }),
                );
              }}
            >
              View
            </SHButton>
          </SHStack>
        ),
        meta: {
          sx: {
            width: "190px",
          },
        },
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <SHStack spacing={3} sx={{ paddingY: showTitle ? 3 : 0 }}>
      {showTitle && <TopBar title={"Product profiles "} />}
      <SHDataGrid
        state={defaultState}
        columns={columns as any}
        emptyMessage={emptyMessage}
        searchBoxProps={searchBoxProps}
        rightToolbar={rightToolbar}
        odata={{
          url: oDataUrl,
          pickColumns: pickColumns,
          globalFilterColumns: [nameOf("productName")],
        }}
      />
      {/* <PermissionDeniedDialog productType={productType} /> */}
      <ConfirmKickOutAdminDialog
        supplierId={supplierId}
        productId={selectedProductId}
        productType={productType}
      />
      <ConfirmKickOutDialog
        supplierId={supplierId}
        productId={selectedProductId}
        productType={productType}
      />
      <ProductBeingKickedOutDialog productType={productType} />
    </SHStack>
  );
}
