import { PlusButton } from "@components/buttons/plus";
import {
  SHDataGridRef,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { useUserPermissions } from "@hooks/userUserPermission";
import { useViewingGroup } from "@hooks/useViewingGroup";
import { TopBar } from "@layouts/top-bar";
import { ViewingGroupDTO } from "@models/viewing-groups/entities/viewingGroups";
import { EditInvestmentProductFormRef } from "@pages/suppliers/_id/_products/investment-product/_id/edit";
import ViewingGroupDialog from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/components/viewing-group-dialog";
import ViewingGroupTable from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/components/viewing-group-table";
import { DialogTitles } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/visibility/config";
import { RootState } from "@redux/store";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { useSelector } from "react-redux";
import { useMount, useToggle } from "react-use";

interface EditVisibilityTabProps {}

export const EditVisibilityTab = forwardRef<
  EditInvestmentProductFormRef,
  EditVisibilityTabProps
>((props: EditVisibilityTabProps, ref) => {
  /* States */
  const [openCreateDialog, toggleOpenCreateDialog] = useToggle(false);
  const { isAdminGroup, isSuperAdmin } = useUserPermissions();
  const { loadSupplierLov } = useViewingGroup();

  /* Hooks */
  const {
    ui: { isLoadingSupplierLov },
  } = useSelector((state: RootState) => state.viewingGroup);
  const dataGridRef = useRef<SHDataGridRef | null>(null);

  useImperativeHandle(ref, () => ({
    getValue() {},
    submit: async () => {},
    resetForm(data) {},
    saveDraft: async () => {
      return { isSuccess: true };
    },
    onPostSubmit: async () => {},
    changeTab: async () => {
      return { isSuccess: true };
    },
  }));

  /* Functions */

  /* Effects */

  useMount(() => {
    isAdminGroup && loadSupplierLov();
  });

  return (
    <SHStack spacing={2} flexDirection={"column"} mt={2}>
      <TopBar
        title={<SHTypography variant="subtitle1">Viewing Groups</SHTypography>}
        tools={
          isAdminGroup && (
            <PlusButton
              isLoading={isLoadingSupplierLov}
              disabled={isLoadingSupplierLov}
              variant="contained"
              onClick={toggleOpenCreateDialog}
              size="extraMedium"
            >
              New Viewing Group
            </PlusButton>
          )
        }
      />
      <ViewingGroupTable ref={dataGridRef} />
      {openCreateDialog && (
        <ViewingGroupDialog
          isSuperAdmin={isSuperAdmin}
          isNew
          onSubmit={() => {
            dataGridRef?.current?.refreshOdata();
          }}
          buttonLabel="Create viewing group"
          dialogTitle={DialogTitles.CREATE_VIEWING_GROUP}
          isOpen={openCreateDialog}
          onClose={toggleOpenCreateDialog}
          initialData={new ViewingGroupDTO()}
        />
      )}
    </SHStack>
  );
});
