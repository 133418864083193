import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import {
  DocumentProductSelectionDTO,
  ProductDocumentDetailDTO,
} from "@models/document-library/entities/document";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export const getProductSelectionList = async (): Promise<
  APIResponse<DocumentProductSelectionDTO[]>
> => {
  const response = await APIGatewayService.get<DocumentProductSelectionDTO[]>(
    APIExtRoutes.productSelection,
  );
  return handleErrorProxy(response);
};

export const createDocument = async (
  document: ProductDocumentDetailDTO,
): Promise<APIResponse<string>> => {
  const response = await APIGatewayService.post<string>(
    APIExtRoutes.documents,
    document,
  );
  return handleErrorProxy(response);
};
export const updateDocument = async (
  document: ProductDocumentDetailDTO,
): Promise<APIResponse<string>> => {
  const response = await APIGatewayService.put<string>(
    APIExtRoutes.documents,
    document,
  );
  return handleErrorProxy(response);
};

export const getDocumentById = async (
  documentId: string,
): Promise<APIResponse<ProductDocumentDetailDTO>> => {
  const response = await APIGatewayService.get<ProductDocumentDetailDTO>(
    generatePath(APIExtRoutes.documentById, { documentId }),
  );
  return handleErrorProxy(response);
};

export const publishDocumentById = async (
  documentId: string,
): Promise<APIResponse<string>> => {
  const response = await APIGatewayService.patch<string>(
    generatePath(APIExtRoutes.publishDocument, { documentId }),
  );

  return handleErrorProxy(response);
};
