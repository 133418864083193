import { IPendingPostCount } from "@redux/slices/news-feed";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IPendingDocumentCount extends IPendingPostCount {}

interface DocumentLibraryStore {
  currentPendingDocuments: IPendingDocumentCount;
  havePendingDocument?: boolean;
}

const initialState: DocumentLibraryStore = {
  currentPendingDocuments: {
    currentCount: 0,
    timestamp: 0,
  },
  havePendingDocument: false,
};

const documentLibrarySlice = createSlice({
  name: "documentLibrary",
  initialState,
  reducers: {
    pendingDocumentCountChange: (
      state,
      action: PayloadAction<IPendingDocumentCount>,
    ) => {
      if (action.payload.timestamp < state.currentPendingDocuments.timestamp) {
        return;
      }

      state.currentPendingDocuments = action.payload;
      state.havePendingDocument =
        state.currentPendingDocuments.currentCount > 0;
    },
  },
});

export const { pendingDocumentCountChange } = documentLibrarySlice.actions;

export default documentLibrarySlice.reducer;
