import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { useAppDispatch } from "@redux/store";
import { useSelector } from "react-redux";
import { ProductType } from "@models/product/enums/productType";
import {
  getKickOutDialogProductConfig,
  KickOutDialogProductStateAction,
} from "@pages/platform-profiles/util";

interface ProductBeingKickedOutDialogProps {
  productType?: ProductType;
}

export const ProductBeingKickedOutDialog = ({
  productType,
}: ProductBeingKickedOutDialogProps) => {
  const dispatch = useAppDispatch();
  const productConfig = getKickOutDialogProductConfig(productType);

  const productActions: KickOutDialogProductStateAction = {
    updateShowBeingKickedDialog: (payload: boolean) =>
      dispatch({
        type: productConfig.actionTypes.updateShowBeingKickedDialog,
        payload,
      }),
  };

  const showBeingKickedDialog = useSelector(
    productConfig.getShowBeingKickedDialog,
  );

  const beingKickedBy = useSelector(productConfig.getBeingKickedBy);

  const handleOnClose = () => {
    productActions.updateShowBeingKickedDialog(false);
  };

  return (
    <>
      <SHDialog
        open={showBeingKickedDialog || false}
        onClose={handleOnClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        width={600}
        header={
          <SHStack alignItems="center" textAlign={"center"} width={{ xs: 590 }}>
            <SHTypography variant="h2">
              You have been kicked out of editing the Product
            </SHTypography>
          </SHStack>
        }
        body={
          <SHStack>
            <SHTypography
              variant="body2"
              sx={{
                fontSize: 16,
                fontWeight: 400,
                width: 430,
              }}
            >
              {beingKickedBy} kicked you out.
            </SHTypography>
          </SHStack>
        }
        footer={
          <SHStack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={7}
          >
            <SHButton
              color="primary"
              variant="contained"
              onClick={handleOnClose}
              size="extraMedium"
            >
              Close
            </SHButton>
          </SHStack>
        }
      />
    </>
  );
};
