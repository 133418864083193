import "@assets/font/index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import PrivateRoute from "@components/private-route";
import RootApp from "@components/root-app";
import { SHRouter } from "@components/router";
import { AppStatus, appStatus } from "@constants/env";
import { PageRoutes } from "@constants/routes";
import {
  AnalysisLayout,
  AnalysisListViewLayout,
} from "@layouts/analysis-layout";
import { MainLayout } from "@layouts/main-layout";
import { ManagedAccountListViewLayout } from "@layouts/managed-account-layout";
import { PrivateLayout } from "@layouts/private-layout";
import { ReviewsListViewLayout } from "@layouts/reviews-layout";
import { UserType } from "@models/auth";
import {
  AdminUsersGroup,
  AdviserAdminUsersGroup,
  AdviserTrialUsersGroup,
  AdviserUsersGroup,
  AllUsersGroup,
  SupplierUsersGroup,
  SuppliersAndAdvisersWithoutTrial,
} from "@models/users/group";
import { ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CallbackPage } from "@pages/auth/callback";
import { Logout } from "@pages/auth/logout";
import { ConfigurationPage } from "@pages/configuration";
import DocumentLibrary from "@pages/document-library";
// import { ConvertToHtml } from "@pages/convert-to-html";
import { MaintenancePage } from "@pages/maintenance";
import { ManagedAccountListView } from "@pages/managed-accounts";
import { ManagedAccountDetail } from "@pages/managed-accounts/_id";
import { SMAListView } from "@pages/managed-accounts/sma-list";
import { NewsAndUpdates } from "@pages/news-and-updates";
import { PlatformAnalysisListView } from "@pages/platform-analysis";
import { FeatureTemplateListView } from "@pages/platform-analysis/_feature-templates";
import { FeatureTemplateManagePage } from "@pages/platform-analysis/_feature-templates/_id";
import { PlatformAnalysisDetail } from "@pages/platform-analysis/_id";
import { RateCardsListView } from "@pages/platform-analysis/_rate-cards";
import { RateCardManagePage } from "@pages/platform-analysis/_rate-cards/_id";
import { PlatformProfilePage } from "@pages/platform-profiles";
import { PracticesPage } from "@pages/practices";
import { ManagePractice } from "@pages/practices/_id";
import { PracticeBillingInfo } from "@pages/practices/_id/_billing-info";
import { PracticeInvoices } from "@pages/practices/_id/_invoices";
import { PracticeOverview } from "@pages/practices/_id/_overview";
import { PracticeSubscription } from "@pages/practices/_id/_subscription";
import { PracticeTeam } from "@pages/practices/_id/_team";
import { MangePracticeUser } from "@pages/practices/_id/_team/_id";
import { ReviewList } from "@pages/reviews";
import { ReviewClientList } from "@pages/reviews/_client";
import { ManageReviewClient } from "@pages/reviews/_client/_id";
import { ReviewClientOverview } from "@pages/reviews/_client/_id/_overview";
import { ReviewFeatureTemplateList } from "@pages/reviews/_feature-template";
import { ReviewDetail } from "@pages/reviews/_id";
import { ReviewRateCardList } from "@pages/reviews/_rate-card";
import { ReviewRateCardManagePage } from "@pages/reviews/_rate-card/_id";
import { SuppliersPage } from "@pages/suppliers";
import { ManageSupplier } from "@pages/suppliers/_id";
import { SupplierInvoices } from "@pages/suppliers/_id/_invoices";
import { SupplierOverview } from "@pages/suppliers/_id/_overview";
import { SupplierProduct } from "@pages/suppliers/_id/_products";
import { ManageProduct } from "@pages/suppliers/_id/_products/_id";
import { InvestmentProductDetails } from "@pages/suppliers/_id/_products/investment-product/_id";
import { SupplierSubscription } from "@pages/suppliers/_id/_subscription";
import { SupplierTeam } from "@pages/suppliers/_id/_team";
import { ManageTeam } from "@pages/suppliers/_id/_team/_id";
import { UsersPage } from "@pages/user";
import { ManageUser } from "@pages/user/_id";
import { CompleteProfilePage } from "@pages/user/_id/_complete-profile";
import store from "@redux/store";
import "@styles/global.scss";
import { theme } from "@themes";
import { createBrowserHistory } from "history";
import { SnackbarProvider } from "notistack";
import { useEffect } from "react";
import { Provider } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

export const shHistory = createBrowserHistory();

function SHApp() {
  useEffect(() => {
    console.log("SuitabilityHub: 1.0.44");
    if (
      shHistory?.location?.search?.toString()?.includes("?error=unauthorized")
    )
      shHistory?.replace(`${PageRoutes.callback}?action=unauthorized`);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {appStatus === AppStatus.Maintenance ? (
          <MaintenancePage />
        ) : (
          <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
            redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URL}
          >
            <Provider store={store}>
              <SnackbarProvider autoHideDuration={5000}>
                <SHRouter history={shHistory}>
                  <RootApp>
                    <Routes>
                      <Route path={PageRoutes.home}>
                        {/* TODO: Need remove when finish task */}
                        {/* <Route path="/pdf" element={<ExportPDFPreview />} /> */}
                        <Route
                          path={PageRoutes.callback}
                          element={<CallbackPage />}
                        />

                        <Route path={PageRoutes.logout} element={<Logout />} />
                        <Route element={<PrivateLayout />}>
                          {/* Use Main Layout */}
                          <Route element={<MainLayout />}>
                            {/* Route for Convert HTML */}
                            {/* <Route
                              path="/convert-to-html"
                              element={<ConvertToHtml />}
                            /> */}
                            {/*  Home Route */}
                            <Route
                              index
                              element={
                                // Note: Apply for now. Remove when Home page is implemented
                                <Navigate to={PageRoutes.platformProfiles} />
                              }
                            />
                            {/*  Users Route */}
                            <Route path={PageRoutes.users}>
                              <Route
                                path=":userId"
                                element={
                                  <PrivateRoute
                                    permission={{
                                      roles: AdminUsersGroup,
                                    }}
                                  >
                                    <ManageUser />
                                  </PrivateRoute>
                                }
                              />
                              <Route
                                path=":userId/complete-profile"
                                element={<CompleteProfilePage />}
                              />
                              <Route
                                path="new"
                                element={
                                  <PrivateRoute
                                    permission={{
                                      roles: [UserType.SuperAdmin],
                                    }}
                                  >
                                    <ManageUser />
                                  </PrivateRoute>
                                }
                              />
                              {/*  Super Admin */}
                              <Route
                                index
                                element={
                                  <PrivateRoute
                                    permission={{
                                      roles: [UserType.SuperAdmin],
                                    }}
                                  >
                                    <UsersPage />
                                  </PrivateRoute>
                                }
                              />
                            </Route>
                            {/*  Suppliers Route */}
                            <Route path={PageRoutes.suppliers}>
                              <Route
                                index
                                element={
                                  <PrivateRoute
                                    permission={{
                                      roles: AdminUsersGroup,
                                    }}
                                  >
                                    <SuppliersPage />
                                  </PrivateRoute>
                                }
                              />

                              <Route
                                path=":supplierId"
                                element={
                                  <PrivateRoute
                                    permission={{
                                      roles: AllUsersGroup,
                                    }}
                                  >
                                    <ManageSupplier />
                                  </PrivateRoute>
                                }
                              >
                                <Route index element={<SupplierOverview />} />
                                <Route path="team" element={<SupplierTeam />} />
                                <Route
                                  path="products"
                                  element={<SupplierProduct />}
                                />
                                <Route
                                  path="subscription"
                                  element={<SupplierSubscription />}
                                />
                                <Route
                                  path="invoices"
                                  element={<SupplierInvoices />}
                                />
                              </Route>
                              <Route path=":supplierId/team">
                                <Route
                                  path=":supplierUserId"
                                  element={<ManageTeam />}
                                />
                              </Route>
                              <Route path=":supplierId/products">
                                <Route
                                  path=":productId"
                                  element={<ManageProduct />}
                                />
                              </Route>
                              <Route path=":supplierId/products/investment-products">
                                <Route
                                  path=":investmentProductId"
                                  element={<InvestmentProductDetails />}
                                />
                              </Route>
                            </Route>
                            {/*  Platform Profiles */}
                            <Route
                              path={PageRoutes.platformProfiles}
                              element={
                                <PrivateRoute
                                  permission={{
                                    roles: AllUsersGroup,
                                  }}
                                >
                                  <PlatformProfilePage />
                                </PrivateRoute>
                              }
                            />
                            {/*  Practices */}
                            <Route path={PageRoutes.practices}>
                              <Route
                                index
                                element={
                                  <PrivateRoute
                                    permission={{
                                      roles: [
                                        ...AdminUsersGroup,
                                        ...AdviserUsersGroup,
                                      ],
                                    }}
                                  >
                                    <PracticesPage />
                                  </PrivateRoute>
                                }
                              />
                              <Route
                                path=":practiceId"
                                element={
                                  <PrivateRoute
                                    permission={{
                                      roles: [
                                        ...AdminUsersGroup,
                                        ...AdviserAdminUsersGroup,
                                        ...AdviserTrialUsersGroup,
                                      ],
                                    }}
                                  >
                                    <ManagePractice />
                                  </PrivateRoute>
                                }
                              >
                                <Route index element={<PracticeOverview />} />
                                <Route path="team" element={<PracticeTeam />} />
                                <Route
                                  path="subscription"
                                  element={<PracticeSubscription />}
                                />
                                <Route
                                  path="billingInfo"
                                  element={<PracticeBillingInfo />}
                                />
                                <Route
                                  path="invoices"
                                  element={<PracticeInvoices />}
                                />
                              </Route>
                              <Route path=":practiceId/team">
                                <Route
                                  path=":auth0Id"
                                  element={<MangePracticeUser />}
                                />
                              </Route>
                            </Route>
                            {/*  Configuration */}
                            <Route
                              path={PageRoutes.configuration}
                              element={
                                <PrivateRoute
                                  permission={{
                                    roles: AdminUsersGroup,
                                  }}
                                >
                                  <ConfigurationPage />
                                </PrivateRoute>
                              }
                            />
                            {/*  Platform Analysis */}
                            <Route
                              path={PageRoutes.platformAnalysis}
                              element={
                                <PrivateRoute
                                  permission={{
                                    roles: [
                                      UserType.SupplierPremiumOwner,
                                      UserType.SupplierPremiumStandard,
                                      UserType.SupplierBdmSale,
                                    ],
                                  }}
                                >
                                  <AnalysisListViewLayout />
                                </PrivateRoute>
                              }
                            >
                              <Route
                                index
                                element={<PlatformAnalysisListView />}
                              />
                              <Route
                                path="rate-card"
                                element={<RateCardsListView />}
                              />
                              <Route
                                path="feature-template"
                                element={<FeatureTemplateListView />}
                              />
                            </Route>
                            <Route
                              path={PageRoutes.platformAnalysisRateCard}
                              element={<RateCardManagePage />}
                            />
                            <Route
                              path={PageRoutes.featureTemplateDetail}
                              element={<FeatureTemplateManagePage />}
                            />
                            {/*  Suitability reviews */}
                            <Route
                              path={PageRoutes.reviews}
                              element={
                                <PrivateRoute
                                  permission={{
                                    roles: [...AdviserUsersGroup],
                                  }}
                                >
                                  <ReviewsListViewLayout />
                                </PrivateRoute>
                              }
                            >
                              <Route index element={<ReviewList />} />
                              <Route
                                path="family-groups"
                                element={<ReviewClientList />}
                              />
                              <Route
                                path="feature-template"
                                element={<ReviewFeatureTemplateList />}
                              />

                              <Route
                                path="rate-card"
                                element={<ReviewRateCardList />}
                              />
                            </Route>

                            {/* Review Rate card Details */}
                            <Route
                              path={PageRoutes.reviewsRateCardDetail}
                              element={
                                <PrivateRoute
                                  permission={{
                                    roles: [...AdviserUsersGroup],
                                  }}
                                >
                                  <ReviewRateCardManagePage />
                                </PrivateRoute>
                              }
                            />

                            {/* Review Client Details */}
                            <Route
                              path={PageRoutes.reviewsFamilyGroupDetail}
                              element={
                                <PrivateRoute
                                  permission={{
                                    roles: [...AdviserUsersGroup],
                                  }}
                                >
                                  <ManageReviewClient />
                                </PrivateRoute>
                              }
                            >
                              <Route index element={<ReviewClientOverview />} />
                              <Route path="reviews" element={<ReviewList />} />
                            </Route>

                            {/*  Managed account */}
                            <Route
                              path={PageRoutes.managedAccounts}
                              element={
                                <PrivateRoute
                                  permission={{
                                    roles: [
                                      ...SuppliersAndAdvisersWithoutTrial,
                                    ],
                                  }}
                                >
                                  <ManagedAccountListViewLayout />
                                </PrivateRoute>
                              }
                            >
                              <Route index element={<SMAListView />} />
                              <Route
                                path="analysis"
                                element={<ManagedAccountListView />}
                              />
                              <Route
                                path="template-portfolio"
                                element={<> Template portfolio list view </>}
                              />
                              <Route
                                path="Custom model"
                                element={<> Custom model list view </>}
                              />
                            </Route>
                            {/*  Document Library */}
                            <Route
                              path={PageRoutes.documentLibrary}
                              element={<DocumentLibrary />}
                            />
                          </Route>

                          {/* Use Analysis Layout */}
                          {/*  Platform Analysis Detail*/}
                          <Route element={<AnalysisLayout />}>
                            <Route
                              path={PageRoutes.platformAnalysisDetail}
                              element={
                                <PrivateRoute
                                  permission={{
                                    roles: [
                                      UserType.SupplierPremiumOwner,
                                      UserType.SupplierPremiumStandard,
                                      UserType.SupplierBdmSale,
                                    ],
                                  }}
                                >
                                  <PlatformAnalysisDetail />
                                </PrivateRoute>
                              }
                            />
                          </Route>
                          {/*  Suitability Review Detail*/}
                          <Route element={<AnalysisLayout />}>
                            <Route
                              path={PageRoutes.reviewsDetail}
                              element={
                                <PrivateRoute
                                  permission={{
                                    roles: AdviserUsersGroup,
                                  }}
                                >
                                  <ReviewDetail />
                                </PrivateRoute>
                              }
                            />
                          </Route>

                          {/* Managed account Detail */}
                          <Route element={<AnalysisLayout />}>
                            <Route
                              path={PageRoutes.managedAccountsDetail}
                              element={
                                <PrivateRoute
                                  permission={{
                                    roles: [...SupplierUsersGroup],
                                  }}
                                >
                                  <ManagedAccountDetail />
                                </PrivateRoute>
                              }
                            />
                          </Route>

                          {/*  News and Updates */}
                          <Route
                            path={PageRoutes.newsAndUpdates}
                            element={<NewsAndUpdates />}
                          />
                        </Route>
                      </Route>
                      {/* Not match */}
                      <Route
                        path="*"
                        element={<Navigate to={PageRoutes.home} replace />}
                      />
                    </Routes>
                  </RootApp>
                </SHRouter>
              </SnackbarProvider>
            </Provider>
          </Auth0Provider>
        )}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default SHApp;
