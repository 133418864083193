import { appNavigation, NarBarName } from "@components/nav-bar/config";
import { RootState } from "@redux/store";
import { useSelector } from "react-redux";

export type haveDotGroupMapping = Record<NarBarName, boolean | undefined>;
export const useNavigationBar = () => {
  const { havePendingPost } = useSelector((state: RootState) => state.newsFeed);
  const { havePendingDocument } = useSelector(
    (state: RootState) => state.documentLibrary,
  );

  const dotGroupMapping: Partial<haveDotGroupMapping> = {
    [NarBarName.NewsAndUpdates]: havePendingPost,
    [NarBarName.DocumentLibrary]: havePendingDocument,
  };

  const navigation = appNavigation.map((nav) => ({
    ...nav,
    haveDotGroup: dotGroupMapping[nav.name],
  }));

  return { navigation };
};
