import { useNotification } from "@hooks/useNotification";
import { CreateViewingGroupDTO } from "@models/viewing-groups/entities/viewingGroups";
import { ViewingGroupStatus } from "@models/viewing-groups/enum/status";
import { getSupplierLovThunk } from "@redux/slices/viewing-group";
import { useAppDispatch } from "@redux/store";
import {
  getViewingGroupDetails,
  patchViewingGroupStatus,
  postViewingGroup,
  putViewingGroup,
} from "@services/viewing-group/viewingGroupService";
import { UpdateViewingGroupDTO } from "./../models/viewing-groups/entities/viewingGroups";

export const useViewingGroup = () => {
  const { notify } = useNotification();
  const dispatch = useAppDispatch();
  const loadSupplierLov = async () => {
    const response = await dispatch(getSupplierLovThunk()).unwrap();
    const { isSuccess, data, message } = response;
    if (isSuccess) {
      return data;
    }
    notify(message, {
      variant: "error",
      close: true,
    });
  };
  const handleCreateViewingGroup = async (
    viewingGroup: CreateViewingGroupDTO,
    callbacks?: { onSuccess?: () => void; onError?: () => void },
  ) => {
    const { onSuccess, onError } = callbacks || {};
    const { isSuccess, message, data } = await postViewingGroup(viewingGroup);
    if (isSuccess) {
      onSuccess?.();
      notify(data, {
        variant: "success",
        close: true,
      });
      return;
    }
    onError?.();
    notify(message, {
      variant: "error",
      close: true,
    });
  };
  const handleUpdateViewingGroupStatus = async (
    status: ViewingGroupStatus,
    viewingGroupId?: string,
    callbacks?: { onSuccess?: () => void; onError?: () => void },
  ) => {
    if (!viewingGroupId) return;
    const { onSuccess, onError } = callbacks || {};
    const { isSuccess, message, data } = await patchViewingGroupStatus(
      viewingGroupId,
      status,
    );
    if (isSuccess) {
      onSuccess?.();
      notify(data, {
        variant: "success",
        close: true,
      });
      return;
    }
    onError?.();
    notify(message, {
      variant: "error",
      close: true,
    });
  };
  const loadViewingGroupDetails = async (viewingGroupId?: string) => {
    if (!viewingGroupId) return;

    const { isSuccess, data } = await getViewingGroupDetails(viewingGroupId);
    if (isSuccess && data) {
      return data;
    }
    notify("Failed to load viewing group details", {
      variant: "error",
      close: true,
    });
  };
  const handleUpdateViewingGroup = async (
    viewingGroup: UpdateViewingGroupDTO,
    callbacks?: { onSuccess?: () => void; onError?: () => void },
  ) => {
    const { onSuccess, onError } = callbacks || {};

    const { isSuccess, message, data } = await putViewingGroup(viewingGroup);
    if (isSuccess) {
      onSuccess?.();
      notify(data, {
        variant: "success",
        close: true,
      });
      return;
    }
    onError?.();
    notify(message, {
      variant: "error",
      close: true,
    });
  };
  return {
    loadSupplierLov,
    loadViewingGroupDetails,
    handleCreateViewingGroup,
    handleUpdateViewingGroupStatus,
    handleUpdateViewingGroup,
  } as const;
};
