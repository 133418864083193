import { MAAnalysisTooltipDTO } from "./step/fee";
import { CalculatedInvestmentDTO } from "@pages/managed-accounts/sma-list/components/tables/model";

export class SMAListExcelDTO {
  supplierOrAdviserName: string = "";
  isSupplier: boolean = false;
  ownerName: string = "";
  investmentSize: number = 0;
  dataValidDate: Date = new Date();
  selectedInvestments: SMAListExcelSelectedInvestmentDTO[] = [];
  sections: SMAListExcelSectionDTO[] = [];
}

export class SMAListExcelSelectedInvestmentDTO {
  id: string = "";
  managerName: string = "";
  name: string = "";
  productName: string = "";
  subProductName: string = "";
  platformCode: string = "";
  order: number = 0;
}

export class SMAListExcelSectionDTO {
  variableName: string = "";
  name: string = "";
  order: number = 0;
  analysisData: SMAListExcelAnalysisDataDTO[] | null = [];
  subSections?: SMAListExcelSectionDTO[] | null = [];
  breakdowns?: SMAListExcelSectionDTO[] | null = [];
}

export class SMAListExcelAnalysisDataDTO {
  selectedInvestmentId: string = "";
  fieldTypeId: string | null = "";
  value: string | null = "";
  valueInPercent: string | null = "";
  order: number = 0;
  tooltip: MAAnalysisTooltipDTO | null = new MAAnalysisTooltipDTO();
}

export class SMAListExcelExcelRequestDTO {
  investmentSize: number = 0;
  selectedInvestments: CalculatedInvestmentDTO[] = [];
}
