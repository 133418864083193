import {
  SHButton,
  SHDotGroupProps,
  SHLabel,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHCollapse } from "@components/design-systems/sh-collapse";
import { InvestmentProductConfigurationSection } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { useTheme } from "@mui/material";
import { generateUUID, hexToRGBA } from "@utils";
import { isEmpty } from "lodash";
import { PlusSVG } from "@components/svgs";
import { useFormContext, useWatch } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { SeriesPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series";
import { HighlightType } from "@models/configuration";
import { useAppDispatch } from "@redux/store";
import { toggleSaveDraftButtonDisabled } from "@redux/slices/product/investment-product";
import { useUpdateModelsTab } from "@hooks/useInvestmentProduct";
import { useMount } from "react-use";
import { useCollapseMenu } from "@hooks/useCollapseMenu ";

export interface ProductCollapseMenuProps {
  investmentProductSeriesData?: InvestmentProductSeriesDTO[];
  selectedIndex?: [number, number];
  defaultExpandIndexes?: number[];
  onChange?: (index: [number, number]) => void;
  dotGroupProps?: Omit<SHDotGroupProps, "disabled">;
  onAddNewModelClick?: () => void;
  isDisabled?: boolean;
  mode?: "view" | "edit";
  allowAddNewSeriesModel?: boolean;
}

const EditSeriesCollapseMenu = ({
  investmentProductSeriesData,
  selectedIndex = [0, 0],
  defaultExpandIndexes = [],
  onChange = (index) => {},
  onAddNewModelClick,
  isDisabled = false,
  mode = "view",
  allowAddNewSeriesModel = false,
}: ProductCollapseMenuProps) => {
  const { palette } = useTheme();
  const { expandedIndex, handleCollapseToggle } =
    useCollapseMenu(defaultExpandIndexes);
  const { getValues } = useFormContext<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>();
  const [isMounted, setIsMounted] = useState(false);
  const dispatch = useAppDispatch();
  const handleUpdateModelsTab = useUpdateModelsTab();

  const seriesData = useWatch({
    name: "investmentProductSeriesData",
  });
  const menuDotStatus = useMemo(() => {
    const investmentProductSeriesDataForm =
      (seriesData as InvestmentProductSeriesDTO[]) ?? [];
    let seriesOrangeDotStatus: [
      {
        seriesIndex: number;
        sectionIndex: number;
        isModified: boolean;
        isShowOrangeDot: boolean;
        isShowBlueDot: boolean;
      },
    ] = [
      {
        seriesIndex: -1,
        sectionIndex: -1,
        isModified: false,
        isShowOrangeDot: false,
        isShowBlueDot: false,
      },
    ];

    if (investmentProductSeriesDataForm.length > 0) {
      for (
        let seriesIdx = 0;
        seriesIdx < investmentProductSeriesDataForm.length;
        seriesIdx++
      ) {
        const sections =
          investmentProductSeriesDataForm[seriesIdx].sections ?? [];

        for (let sectionIdx = 0; sectionIdx < sections.length; sectionIdx++) {
          for (const feature of sections[sectionIdx].features ?? []) {
            let hasEditedField = false;
            let hasMissingDataField = false;
            let hasModifiedField = false;
            const existedSectionIndex = seriesOrangeDotStatus.findIndex(
              (series) =>
                series.seriesIndex === seriesIdx &&
                series.sectionIndex === sectionIdx,
            );
            const dotStatus =
              existedSectionIndex > -1
                ? seriesOrangeDotStatus[existedSectionIndex]
                : {
                    seriesIndex: seriesIdx,
                    sectionIndex: sectionIdx,
                    isModified: false,
                    isShowOrangeDot: false,
                    isShowBlueDot: false,
                  };

            hasModifiedField = feature.productData?.isModified ?? false;
            hasEditedField =
              feature.productData?.highlightType === HighlightType.Edited;
            hasMissingDataField =
              feature.productData?.dataStatus ===
              InvestmentDataStatus.MissingData;

            if (hasModifiedField) {
              dotStatus.isModified = hasModifiedField;
            }

            if (hasEditedField) {
              dotStatus.isShowOrangeDot = hasEditedField;
            }

            if (hasMissingDataField) {
              dotStatus.isShowBlueDot = hasMissingDataField;
            }

            if (existedSectionIndex === -1) {
              seriesOrangeDotStatus.push(dotStatus);
            }
          }
        }
      }
    }

    return seriesOrangeDotStatus;
  }, [seriesData]);
  useMount(() => {
    setIsMounted(true);
  });

  useEffect(() => {
    const isAnySectionEdited = menuDotStatus.some(
      (status) => status.isShowOrangeDot,
    );
    const isAnySectionMissingData = menuDotStatus.some(
      (status) => status.isShowBlueDot,
    );
    const isAnySectionModified = menuDotStatus.some(
      (status) => status.isModified,
    );

    if (isMounted) {
      handleUpdateModelsTab({
        isAnySectionModified,
        isAnySectionEdited,
        isAnySectionMissingData,
      });
      dispatch(toggleSaveDraftButtonDisabled(!isAnySectionModified));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seriesData]);

  const handleCreateConfigurationSeriesModel = () => {
    onAddNewModelClick && onAddNewModelClick();
  };

  if (!investmentProductSeriesData) {
    return <></>;
  }

  if (investmentProductSeriesData.length === 0) {
    return (
      <SHStack
        direction={"row"}
        spacing={0.5}
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <SHTypography p={0} variant="subtitle1">
          Create series
        </SHTypography>
        <SHButton
          // isLoading={isCreatingModel}
          // disabled={isCreatingModel}
          variant="text"
          size="medium"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            handleCreateConfigurationSeriesModel();
          }}
          startIcon={<PlusSVG color={palette.primary.main} />}
        >
          New
        </SHButton>
      </SHStack>
    );
  }

  return (
    <>
      {investmentProductSeriesData.map((group, seriesIndex) => {
        const isExpanded = expandedIndex.includes(seriesIndex);
        const seriesFeatureDataPath = `investmentProductSeriesData.${seriesIndex}.sections.0.features.0`;
        const isEmptyModel = isEmpty(group.sections);
        const seriesNameValue = getValues(
          `${seriesFeatureDataPath}.productData.value` as SeriesPath,
        ) as string;
        const seriesNameOrangeDotStatus = menuDotStatus.some(
          (status) =>
            status.isShowOrangeDot && status.seriesIndex === seriesIndex,
        );
        const seriesNameBlueDotStatus = menuDotStatus.some(
          (status) =>
            status.isShowBlueDot && status.seriesIndex === seriesIndex,
        );

        return (
          <SHCollapse
            key={group.id ?? generateUUID()}
            title={
              <SHStack
                direction="row"
                spacing={0.5}
                justifyContent="space-between"
                alignItems="center"
              >
                <SHStack direction={"row"} gap={1}>
                  <SHLabel
                    dotGroupProps={{
                      orangeDot: seriesNameOrangeDotStatus,
                      blueDot: seriesNameBlueDotStatus,
                    }}
                    label={
                      <SHTypography
                        p={0}
                        variant="subtitle1"
                        sx={{
                          wordBreak: "break-all",
                        }}
                      >
                        {seriesNameValue}
                      </SHTypography>
                    }
                  />
                </SHStack>
                {mode === "edit" && allowAddNewSeriesModel && (
                  <SHButton
                    variant="text"
                    size="medium"
                    color="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCreateConfigurationSeriesModel();
                    }}
                    disabled={isDisabled}
                    startIcon={
                      <PlusSVG
                        color={
                          isDisabled
                            ? palette.text.disabled
                            : palette.primary.main
                        }
                      />
                    }
                  >
                    New
                  </SHButton>
                )}
              </SHStack>
            }
            open={isExpanded}
            onCollapse={(open) => {
              handleCollapseToggle(seriesIndex, open);
            }}
            width={"100%"}
            height="auto"
            sx={{
              padding: "20px 10px",
              cursor: "pointer",
              bgcolor: isExpanded ? palette.common.white : "transparent",
              borderColor: palette.secondary[100],
              borderBottomStyle: "solid",
              borderBottomWidth: isExpanded ? 0 : 1,
            }}
          >
            {isEmptyModel ? (
              <SHTypography
                variant="body2"
                sx={{
                  padding: "20px 10px",
                }}
              >
                No models
              </SHTypography>
            ) : (
              group.sections?.map(
                (
                  section: InvestmentProductConfigurationSection,
                  sectionIndex,
                ) => {
                  const selectedSubSection =
                    selectedIndex[0] === seriesIndex &&
                    selectedIndex[1] === sectionIndex;
                  const sectionDotStatus = menuDotStatus.find(
                    (status) =>
                      status.seriesIndex === seriesIndex &&
                      status.sectionIndex === sectionIndex,
                  );
                  return (
                    <SHStack
                      key={section.id}
                      sx={{
                        padding: "20px 10px",
                        cursor: "pointer",
                        bgcolor: selectedSubSection
                          ? hexToRGBA(palette.primary.light, 0.1)
                          : "transparent",
                        "&:hover": {
                          bgcolor: selectedSubSection
                            ? hexToRGBA(palette.primary.light, 0.15)
                            : hexToRGBA(palette.common.white, 0.5),
                        },
                      }}
                      onClick={() => {
                        onChange([seriesIndex, sectionIndex]);
                      }}
                      direction="row"
                      spacing={0.5}
                      alignItems="start"
                    >
                      <SHLabel
                        dotGroupProps={{
                          orangeDot: sectionDotStatus?.isShowOrangeDot,
                          blueDot: sectionDotStatus?.isShowBlueDot,
                        }}
                        label={
                          <SHTypography
                            variant={selectedSubSection ? "subtitle2" : "body1"}
                          >
                            {section.name}
                          </SHTypography>
                        }
                      />
                    </SHStack>
                  );
                },
              )
            )}
          </SHCollapse>
        );
      })}
    </>
  );
};

export default EditSeriesCollapseMenu;
