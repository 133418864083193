import { SHFilterOption } from "@components/design-systems";
import { SupplierStatus } from "@models/supplier/enums/status";

export const StatusSuppliersFilterOptions: SHFilterOption[] = [
  {
    label: "Active",
    value: SupplierStatus.Active,
  },
  {
    label: "Disabled",
    value: SupplierStatus.Disabled,
  },
  {
    label: "Archived",
    value: SupplierStatus.Archived,
  },
];
export const DefaultSuppliersStatusFilterValue = [
  SupplierStatus.Active,
  SupplierStatus.Disabled,
];

export enum AdditionalVisualizeTable {
  INVESTMENT_APPROACH = "Investment approach",
  ASSET_ALLOCATION_NOTES = "Asset allocation notes",
  ASSET_ALLOCATION = "Asset allocation",
  RISK_MEASURES = "Risk measures",
}

export enum InvestmentFeatureHiddenInViewMode {
  TARGET_EXISTS = "Model has an AA target",
}

export const SubColumnNames = ["Net capital gains (% p.a.)", "Income (% p.a.)"];

export const SpecialSection = [
  "Service offering",
  "Reporting and communications",
];
/**
 * @description Use for edit model field dependence on another field
 * Target growth exposure = 100 - Target defensive exposure
 * Passive Share  = 100 - Active Share
 */

export enum DependedFeature {
  TARGET_GROWTH_EXPOSURE = "Target growth exposure",
  TARGET_DEFENSIVE_EXPOSURE = "Target defensive exposure",
  ACTIVE_SHARE = "Active Share",
  PASSIVE_SHARE = "Passive Share",
}
export enum VisibilityMessage {
  FEATURE_LEVEL = "Restricted view - The manager has not shared this data with your firm",
  PRODUCT_LEVEL = "Limit the visibility of your entire profile",
  SERIES_LEVEL = "Limit visibility of series",
  HAS_CUSTOM_VISIBILITY = "Visibility limited to certain user groups",
}

export enum IconMessage {
  IMAP_FEATURE_AWARD = "This field must be completed if participating in the IMAP awards"
}
