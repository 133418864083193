import { InformationButton } from "@components/buttons/information";
import {
  SHBox,
  SHDivider,
  SHHtmlBlock,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import SidePanelSection from "@components/side-panel-section";
import { CheckSquareSVG, CrossSquareSVG } from "@components/svgs";
import { PageRoutes } from "@constants";
import { UserType } from "@models/auth";
import {
  ConfigurationFeature,
  FieldTypeId,
  SectionDisplayTypeId,
} from "@models/configuration";
import { Link, useTheme } from "@mui/material";
import { RootState } from "@redux/store";
import { lightPalette } from "@themes/light";
import { generateFeatureName } from "@utils/text-format";
import { useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router";

export const ProductViewStandard = () => {
  const {
    productData,
    productUI: { isLoadingProductData },
  } = useSelector((state: RootState) => state.product);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const sections = productData?.groups[0].sections;
  const mainBodySections = sections?.filter(
    (section) =>
      section.sectionDisplayType?.id === SectionDisplayTypeId.MainBodyPage,
  );
  const sidePanelSections = sections?.filter(
    (section) =>
      section.sectionDisplayType?.id === SectionDisplayTypeId.SidePanel ||
      section.sectionDisplayType?.id === SectionDisplayTypeId.SidePanelRow,
  );

  const renderFeatureItem = (feature: ConfigurationFeature) => {
    const featureName = generateFeatureName(feature.name, feature.description);
    const isLongText = feature.fieldType?.id === FieldTypeId.TextLong;
    const getFeatureValue = () => {
      const value = feature.productData?.value;
      if (feature.fieldType?.id === FieldTypeId.YesNo) {
        switch (value) {
          case "true":
            return <CheckSquareSVG />;
          case "false":
            return <CrossSquareSVG />;
        }
      }
      if (!value) {
        return (
          <SHTypography variant="body3" colorVariant="fourth">
            No data
          </SHTypography>
        );
      }
      return (
        <SHHtmlBlock
          textAlign={isLongText ? "left" : "right"}
          variant="body3"
          color={palette.text.fourth}
          content={value}
        />
      );
    };

    if (!sections) {
      return <></>;
    }

    return (
      <>
        <SHStack
          direction={isLongText ? "column" : "row"}
          justifyContent="space-between"
          sx={{
            gap: isLongText ? 0 : 0.5,
            py: "15px",
            wordBreak: "break-word",
          }}
        >
          <SHTypography
            variant="subtitle2"
            textAlign="left"
            width="45%"
            color={lightPalette.text?.primary}
          >
            {featureName.first}
            {feature?.description && (
              <SHBox component={"span"} sx={{ whiteSpace: "nowrap" }}>
                {featureName.last}
                <InformationButton
                  content={feature.description}
                  margin={"-8px 0px 2px 4px !important"}
                />
              </SHBox>
            )}
          </SHTypography>

          <SHBox>{getFeatureValue()}</SHBox>
        </SHStack>
        <SHDivider />
      </>
    );
  };

  const renderMainBody = () => {
    if (!productData || isLoadingProductData) {
      return (
        <SHStack spacing={3}>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"40%"} delay={false} />
            <SHSkeleton height={20} width={"60%"} delay={false} />
            <SHSkeleton height={20} width={"100%"} delay={false} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"40%"} delay={false} />
            <SHSkeleton height={20} width={"60%"} delay={false} />
            <SHSkeleton height={20} width={"100%"} delay={false} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"40%"} delay={false} />
            <SHSkeleton height={20} width={"60%"} delay={false} />
            <SHSkeleton height={20} width={"100%"} delay={false} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"40%"} delay={false} />
            <SHSkeleton height={20} width={"60%"} delay={false} />
            <SHSkeleton height={20} width={"100%"} delay={false} />
          </SHStack>
        </SHStack>
      );
    }
    return (
      <SHStack spacing={2}>
        {mainBodySections?.map((section) => (
          <SHStack>
            <SHStack direction="row" spacing={"5px"} alignItems="start">
              {section.iconUrl && (
                <img
                  src={section.iconUrl}
                  width={24}
                  height={24}
                  alt={section.iconUrl}
                />
              )}

              <SHTypography variant="subtitle2">{section.name}</SHTypography>
              {section.description && (
                <InformationButton content={section.description} />
              )}
            </SHStack>
            {section.features?.map((feature) => renderFeatureItem(feature))}
          </SHStack>
        ))}
      </SHStack>
    );
  };

  const renderSidePanel = () => {
    if (isLoadingProductData) {
      return (
        <SHStack spacing={3}>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"100%"} delay={false} />
            <SHSkeleton height={20} width={"60%"} delay={false} />
            <SHSkeleton height={20} width={"60%"} delay={false} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"100%"} delay={false} />
            <SHSkeleton height={20} width={"60%"} delay={false} />
            <SHSkeleton height={20} width={"60%"} delay={false} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"100%"} delay={false} />
            <SHSkeleton height={20} width={"60%"} delay={false} />
            <SHSkeleton height={20} width={"60%"} delay={false} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={30} width={"100%"} delay={false} />
            <SHSkeleton height={20} width={"60%"} delay={false} />
            <SHSkeleton height={20} width={"60%"} delay={false} />
          </SHStack>
        </SHStack>
      );
    }
    return (
      <SHStack spacing={2}>
        {sidePanelSections?.map((section) => (
          <SidePanelSection section={section} />
        ))}
      </SHStack>
    );
  };

  const showUpgradeToView = () => {
    if (!isLoadingProductData) {
      if (
        user?.userType === UserType.AdviserTrial ||
        user?.userType === UserType.AdviserAdminTrial
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <SHStack>
      {showUpgradeToView() ? (
        <SHStack
          display={"flex"}
          gridTemplateColumns={"6.6fr 3.25fr"}
          marginTop={"25px"}
          gap={"15px"}
        >
          <SHTypography variant="subtitle2">
            Please upgrade your subscription
            <Link
              color={palette.text.primary}
              component="a"
              underline="always"
              onClick={() => {
                navigate(
                  `${generatePath(PageRoutes.practiceOverview, {
                    practiceId: user?.userMetadata?.adviser_firm_id,
                  })}/subscription`,
                );
              }}
              sx={{
                paddingX: 0.5,
                cursor: "pointer",
                textDecorationColor: palette.text.primary,
              }}
            >
              here
            </Link>
            to view detailed information, ESG policies, and features for this
            platform
          </SHTypography>
          {/* <SHStack alignItems="start">
           <SHButton
             color="primary"
             size="extraMedium"
             variant="contained"
           >
             Upgrade subscription
           </SHButton>
         </SHStack> */}
        </SHStack>
      ) : (
        <SHStack
          display={"grid"}
          gridTemplateColumns={"6.6fr 3.25fr"}
          marginTop={"25px"}
          gap={"15px"}
        >
          {renderMainBody()}
          {renderSidePanel()}
        </SHStack>
      )}
    </SHStack>
  );
};
