import { ManagedAccountDTO } from "@models/managed-accounts/entities/analysis";
import {
  MAAnalysisBreakdownDTO,
  MAAnalysisDataDTO,
  MAAnalysisSectionDTO,
  ManagedAccountInvestmentPlatformCodeDTO,
  MAPlatformCodeDTO,
  SelectedSubProductDTO,
} from "@models/managed-accounts/entities/step/fee";
import { SelectedInvestmentDTO } from "@models/managed-accounts/entities/step/setup";
import { ManagedAccountFieldType } from "@models/managed-accounts/enums/fieldType";
import { ManagedAccountFieldTypeValue } from "@models/managed-accounts/enums/fieldTypeValue";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import {
  exportDate,
  exportDateTime,
  fillBlue,
  fillGray,
  fillLightBlue,
  fillLightYellow,
  fillYellow,
} from "@pages/managed-accounts/components/buttons/export-excel/config";
import { isEqual } from "lodash";
import { Range, utils, WorkSheet } from "xlsx-js-style";

let isShowWebVersionFooter = false;
let isShowCmFeeFooter = false;

export const getWorksheet = (
  data?: ManagedAccountDTO,
  viewMode: FeesDisplayStyle = FeesDisplayStyle.Percentage,
): WorkSheet => {
  if (!data) return utils.aoa_to_sheet([]);

  const isDollarMode = viewMode === FeesDisplayStyle.Dollar;
  const feeLength = data?.feeAnalysis?.all?.selectedSubProducts?.length ?? 0;

  const rows: any[] = [];
  const merges: Range[] = [];

  const pushEmptyRow = () => {
    rows.push(["", ""]);
  };

  const pushHeader = (label: string) => {
    rows.push([
      "",
      {
        v: label,
        t: "s",
        s: {
          fill: fillBlue,
          font: {
            bold: true,
            sz: 15,
          },
        },
      },
      ...[...Array(feeLength)].map(() => ({
        v: "",
        t: "s",
        s: {
          fill: fillBlue,
        },
      })),
    ]);
  };

  const pushSubHeader = (label: string) => {
    rows.push([
      "",
      {
        v: label,
        t: "s",
        s: {
          fill: fillBlue,
          font: {
            bold: true,
          },
        },
      },
      ...[...Array(feeLength)].map(() => ({
        v: "",
        t: "s",
        s: {
          fill: fillBlue,
        },
      })),
    ]);
  };

  const pushCaptionSeeWebVersion = () => {
    if (isShowWebVersionFooter) {
      rows.push(["", "*See web version for detailed breakdown"]);
    }

    if (isShowCmFeeFooter) {
      merges.push({
        s: {
          r: rows.length,
          c: 1,
        },
        e: {
          r: rows.length,
          c: 4,
        },
      });
      rows.push([
        "",
        "**Cash fees not directly disclosed may have been incorporated within indirect or other management fees and costs",
      ]);
    }

    merges.push({
      s: {
        r: rows.length,
        c: 1,
      },
      e: {
        r: rows.length,
        c: 2,
      },
    });
    rows.push([
      "",
      "SMA fee analysis disclaimer and assumptions apply. See web version for details",
      ...[...Array(feeLength - 1)].map(() => ""),
      {
        v: `Serial: ${data?.serial}`,
        t: "s",
        s: {
          alignment: { vertical: "center", horizontal: "right" },
        },
      },
    ]);
  };

  const pushTableHeader = (titles: string[]) => {
    rows.push([
      "",
      {
        v: "",
        t: "s",
        s: {
          fill: fillLightBlue,
        },
      },
      ...titles.map((title) => ({
        v: title,
        t: "s",
        s: {
          fill: fillLightBlue,
          font: {
            bold: true,
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
        },
      })),
    ]);
  };

  const pushSectionTitle = (str: string, subSectionTitle?: string[]) => {
    rows.push([
      "",
      {
        v: str,
        t: "s",
        s: {
          fill: fillLightBlue,
          font: {
            bold: true,
          },
        },
      },
      ...(subSectionTitle ?? []).map((title) => ({
        v: title,
        t: "s",
        s: {
          fill: fillLightBlue,
        },
      })),
    ]);
  };

  const pushAnalysisSection = (
    section: MAAnalysisSectionDTO,
    subProductMissingModels: string[],
    subProductMissingAllModels: string[],
    subProductComingSoon: string[],
  ) => {
    rows.push([
      "",
      {
        v: section?.name,
        t: "s",
        s: {
          fill: fillYellow,
          font: {
            bold: true,
          },
        },
      },
      ...(section?.analysisData ?? [...Array(feeLength)]).map(
        (analysisData: MAAnalysisDataDTO) => {
          const data = displaySectionAnalysisData(
            subProductMissingModels,
            subProductMissingAllModels,
            subProductComingSoon,
            analysisData,
            isDollarMode,
            true,
          );
          return {
            v: data.value,
            t: data.type,
            z: data.format,
            s: {
              fill: fillYellow,
              font: {
                bold: true,
              },
              alignment: { vertical: "center", horizontal: "center" },
            },
          };
        },
      ),
    ]);
  };

  const pushAnalysisSubSection = (subSection: MAAnalysisBreakdownDTO) => {
    rows.push([
      "",
      {
        v: subSection?.name,
        t: "s",
        s: {
          fill: fillLightYellow,
          font: {
            bold: true,
          },
        },
      },
      ...(subSection?.analysisData ?? [...Array(feeLength)]).map(
        (title: MAAnalysisDataDTO) => ({
          v: formatAnalysisDataValue({
            variableName: subSection?.variableName,
            analysisData: title,
            subProductMissingModels: subProductMissingModels,
            isDollarMode: isDollarMode,
          }),
          t: "s",
          s: {
            fill: fillLightYellow,
            font: {
              bold: true,
            },
            alignment: { vertical: "center", horizontal: "center" },
          },
        }),
      ),
    ]);
  };

  const pushAnalysisBreakDown = (breakdown: MAAnalysisBreakdownDTO) => {
    rows.push([
      "",
      {
        v: breakdown?.name,
        t: "s",
        s: {
          alignment: {
            vertical: "center",
            horizontal: "left",
            wrapText: true,
          },
        },
      },
      ...(breakdown?.analysisData ?? [...Array(feeLength)]).map(
        (title: MAAnalysisDataDTO) => {
          return {
            v: formatAnalysisDataValue({
              variableName: breakdown?.variableName,
              analysisData: title,
              subProductMissingModels: subProductMissingModels,
              isDollarMode: isDollarMode,
            }),
            t: "s",
            s: {
              alignment: {
                vertical: "center",
                horizontal: "center",
                wrapText: true,
              },
            },
          };
        },
      ),
    ]);
  };

  const pushRows = (str: any[]) => {
    rows.push(str);
  };

  //Push data
  pushEmptyRow();
  pushHeader("SuitabilityHub SMA fee analysis summary");
  pushRows([
    "",
    `For ${data?.portfolioSetup?.firmName} internal use only. Not to be disseminated`,
  ]);
  pushEmptyRow();
  pushRows([
    "",
    {
      v: "Owner:",
      t: "t",
      s: {
        alignment: {
          vertical: "center",
          horizontal: "left",
        },
      },
    },
    {
      v: `${data?.portfolioSetup?.ownerName} of ${data?.portfolioSetup?.firmName}`,
      t: "t",
      s: {
        alignment: {
          vertical: "center",
          horizontal: "left",
          wrapText: true,
        },
      },
    },
  ]);
  pushRows([
    "",
    "Last edit:",
    {
      v: data?.lastModifiedDate,
      ...exportDateTime,
    },
  ]);
  pushRows([
    "",
    "Data valid:",
    {
      v: data?.dataValidDate,
      ...exportDate,
    },
  ]);
  pushEmptyRow();
  pushSectionTitle("Portfolio details for fee estimates", ["Weight", "Value"]);
  data?.portfolioSetup?.selectedInvestments?.map(
    (investment: SelectedInvestmentDTO) =>
      pushRows([
        "",
        investment.displayName,
        {
          v: `${Intl.NumberFormat().format(investment?.weight ?? 0)}%`,
          t: "s",
          s: {
            alignment: {
              vertical: "center",
              horizontal: "right",
              wrapText: true,
            },
          },
        },
        {
          v: `$${Intl.NumberFormat().format(investment?.value ?? 0)}`,
          t: "s",
          s: {
            alignment: {
              vertical: "center",
              horizontal: "right",
              wrapText: true,
            },
          },
        },
      ]),
  );
  pushRows([
    "",
    {
      v: "Total portfolio value:",
      t: "s",
      s: {
        fill: fillGray,
        font: {
          bold: true,
        },
      },
    },
    {
      v: "",
      t: "s",
      s: {
        fill: fillGray,
        font: {
          bold: true,
        },
      },
    },
    {
      v: `$${Intl.NumberFormat().format(
        data?.portfolioSetup?.portfolioSize ?? 0,
      )}`,
      t: "s",
      s: {
        fill: fillGray,
        font: {
          bold: true,
        },
        alignment: {
          vertical: "center",
          horizontal: "right",
          wrapText: true,
        },
      },
    },
  ]);
  pushEmptyRow();
  pushSubHeader("Fee estimates: Portfolio fees by platform");
  pushTableHeader(
    (data?.feeAnalysis?.all?.selectedSubProducts ?? []).map(
      (subProduct: SelectedSubProductDTO) =>
        `${subProduct?.productName}\n${subProduct.name}`,
    ),
  );

  const subProductMissingModels: string[] = [];
  const subProductMissingAllModels: string[] = [];
  const subProductComingSoon: string[] = [];

  data?.feeAnalysis?.all?.selectedSubProducts.forEach((item) => {
    if (item.isComingSoon) {
      subProductComingSoon.push(item.id);
      return;
    }

    if (item.isMissingAllInvestments) {
      subProductMissingAllModels.push(item.id);
      return;
    }

    if (item.isMissingInvestment) {
      subProductMissingModels.push(item.id);
    }
  });

  data?.feeAnalysis?.all?.sections?.forEach((section: MAAnalysisSectionDTO) => {
    pushAnalysisSection(
      section,
      subProductMissingModels,
      subProductMissingAllModels,
      subProductComingSoon,
    );

    section?.breakdowns?.forEach((breakdown: MAAnalysisBreakdownDTO) =>
      pushAnalysisBreakDown(breakdown),
    );

    section?.subSections?.forEach((subSection: MAAnalysisBreakdownDTO) => {
      pushAnalysisSubSection(subSection);
      subSection?.breakdowns?.forEach((breakdown: MAAnalysisBreakdownDTO) =>
        pushAnalysisBreakDown(breakdown),
      );
    });
  });
  pushEmptyRow();

  pushCaptionSeeWebVersion();
  pushSubHeader("Code for each SMA by platform");
  pushTableHeader(
    (data?.feeAnalysis?.all?.selectedSubProducts ?? []).map(
      (subProduct: SelectedSubProductDTO) =>
        `${subProduct?.productName}\n${subProduct.name}`,
    ),
  );
  data?.feeAnalysis?.all?.investmentPlatformCodes?.map(
    (investment: ManagedAccountInvestmentPlatformCodeDTO) =>
      pushRows([
        "",
        `${investment?.managerName} ${investment?.name}`,
        ...(investment?.platformCodeData ?? []).map(
          (platformCodeData: MAPlatformCodeDTO) => ({
            v: platformCodeData?.platformCode ?? "",
            t: "s",
            s: {
              alignment: { vertical: "center", horizontal: "center" },
            },
          }),
        ),
      ]),
  );

  // Inti style cols
  const wscols = [{ wch: 2 }, { wch: 60 }, { wch: 18 }];
  Array.from(Array(feeLength).keys()).forEach(() => wscols?.push({ wch: 18 }));

  //Create worksheet
  const worksheet: WorkSheet = utils.aoa_to_sheet(rows);
  //Styles
  worksheet["!cols"] = wscols;
  worksheet["!merges"] = merges;
  drawBorders(worksheet);
  isShowWebVersionFooter = false;
  isShowCmFeeFooter = false;

  return worksheet;
};

export const drawBorders = (worksheet: WorkSheet) => {
  const range = utils.decode_range(worksheet["!ref"] ?? "");
  const borderStyle = { style: "medium", color: "000000" };

  const applyBorder = (cellAddress: string, border: string) => {
    const cell = worksheet[cellAddress] || { v: "", t: "s" };
    cell.s = cell.s || {};
    cell.s.border = {
      ...cell.s.border,
      [border]: borderStyle,
    };
    worksheet[cellAddress] = cell;
  };

  const applyBorders = (
    startRow: number,
    endRow: number,
    startCol: number,
    endCol: number,
    border: string,
  ) => {
    for (let row = startRow; row <= endRow; row++) {
      for (let col = startCol; col <= endCol; col++) {
        const cellAddress = utils.encode_cell({ r: row, c: col });
        applyBorder(cellAddress, border);
      }
    }
  };

  // Top border
  applyBorders(range.s.r + 1, range.s.r + 1, range.s.c + 1, range.e.c, "top");

  // Bottom border
  applyBorders(range.e.r, range.e.r, range.s.c + 1, range.e.c, "bottom");

  // Left border
  applyBorders(range.s.r + 1, range.e.r, range.s.c + 1, range.s.c + 1, "left");

  // Right border
  applyBorders(range.s.r + 1, range.e.r, range.e.c, range.e.c, "right");
};

const renderNumberFeesValue = (
  value: string,
  valuePercentage: string,
  tableViewMode = FeesDisplayStyle.Dollar,
) => {
  if (isNaN(parseFloat(value ?? ""))) {
    return value;
  }

  const numberDollar = parseFloat(value ?? "0");
  const numberPercentage = parseFloat(valuePercentage ?? "0");

  if (tableViewMode === FeesDisplayStyle.Dollar || !valuePercentage) {
    const negativeValue = numberDollar < 0;
    const dollarValue = Math.abs(numberDollar);
    const data = Intl.NumberFormat().format(dollarValue);
    return `${negativeValue ? "-$" : "$"}` + data;
  }

  const data = Intl.NumberFormat().format(numberPercentage);

  return data + "%";
};

const formatAnalysisDataValue = ({
  variableName,
  subProductMissingModels,
  analysisData,
  isDollarMode,
  showTitleMissingModels = false,
}: {
  variableName: string;
  subProductMissingModels: string[];
  analysisData: MAAnalysisDataDTO;
  isDollarMode?: boolean;
  showTitleMissingModels?: boolean;
}): string => {
  const selectedSubProduct = subProductMissingModels.find((item) =>
    isEqual(item, analysisData?.subProductId),
  );

  if (selectedSubProduct) {
    if (showTitleMissingModels) {
      return "Missing model(s)";
    }
    return "-";
  }

  if (!analysisData) return "";

  if (isEqual(analysisData?.value, ManagedAccountFieldTypeValue.NotDisclosed)) {
    if (isEqual(variableName, "CM_fee")) {
      isShowCmFeeFooter = true;
      return "Not disclosed**";
    }

    return "Not disclosed";
  }

  if (isEqual(analysisData?.value, ManagedAccountFieldTypeValue.Multiple)) {
    isShowWebVersionFooter = true;
    return "Multiple*";
  }

  let asteriskValue = "";

  if (!!analysisData?.tooltip) {
    isShowWebVersionFooter = true;
    asteriskValue = "*";
  }

  switch (analysisData?.fieldTypeId) {
    case ManagedAccountFieldType.YesNo:
      switch (analysisData?.value) {
        case ManagedAccountFieldTypeValue.True:
          return "Yes" + asteriskValue;
        case ManagedAccountFieldTypeValue.False:
          return "No" + asteriskValue;
        default:
          return analysisData?.value ? analysisData?.value + asteriskValue : "";
      }
    case ManagedAccountFieldType.YesNoText:
      switch (analysisData?.value) {
        case ManagedAccountFieldTypeValue.True:
          return "Net" + asteriskValue;
        case ManagedAccountFieldTypeValue.False:
          return "Gross" + asteriskValue;
        default:
          return analysisData?.value ? analysisData?.value + asteriskValue : "";
      }
    case ManagedAccountFieldType.DateTime:
      return analysisData?.value ? analysisData?.value + asteriskValue : "";
    case ManagedAccountFieldType.Currency:
      return (
        renderNumberFeesValue(
          analysisData?.value ?? "",
          analysisData?.valueInPercent ?? "",
          isDollarMode ? FeesDisplayStyle.Dollar : FeesDisplayStyle.Percentage,
        ) + asteriskValue
      );
    case ManagedAccountFieldType.Percentage:
      return (
        renderNumberFeesValue(
          analysisData?.value ?? "",
          analysisData?.value ?? "",
          FeesDisplayStyle.Percentage,
        ) + asteriskValue
      );
    case ManagedAccountFieldType.Warning:
      if (isEqual(analysisData?.value, ManagedAccountFieldTypeValue.NoBreach)) {
        return "Yes" + asteriskValue;
      }

      return analysisData?.value ? analysisData?.value + asteriskValue : "";
    default:
      return analysisData?.value ? analysisData?.value + asteriskValue : "";
  }
};

const displaySectionAnalysisData = (
  subProductMissingModels: string[],
  subProductMissingAllModels: string[],
  subProductComingSoon: string[],
  analysisData: MAAnalysisDataDTO,
  isDollarMode?: boolean,
  showTitleMissingModels: boolean = false,
): {
  value: string | number;
  type: string;
  format: string;
} => {
  const selectedSubProductComingSoon = subProductComingSoon.find(
    (item) => item === analysisData?.subProductId,
  );
  if (selectedSubProductComingSoon) {
    if (showTitleMissingModels) {
      return {
        value: "No data",
        type: "s",
        format: "",
      };
    }
  }

  const selectedSubProductMissingAllModel = subProductMissingAllModels.find(
    (item) => item === analysisData?.subProductId,
  );
  if (selectedSubProductMissingAllModel) {
    if (showTitleMissingModels) {
      return {
        value: "Missing all model(s)",
        type: "s",
        format: "",
      };
    }
  }

  const selectedSubProductMissingModels = subProductMissingModels.find(
    (item) => item === analysisData?.subProductId,
  );

  if (selectedSubProductMissingModels) {
    if (showTitleMissingModels) {
      return {
        value: "Missing model(s)",
        type: "s",
        format: "",
      };
    }
  }

  if (!analysisData || isNaN(parseFloat(analysisData.value ?? ""))) {
    return {
      value: analysisData?.value ?? "",
      type: "s",
      format: "",
    };
  }

  const numberDollar = parseFloat(analysisData.value ?? "0");
  const numberPercentage = parseFloat(analysisData?.valueInPercent ?? "0");

  if (isDollarMode || !analysisData?.valueInPercent) {
    const data = Intl.NumberFormat().format(numberDollar);
    return {
      value: "$" + data,
      type: "s",
      format: "",
    };
  }

  const numberDP = numberPercentage.toString().split(".")[1].length || 0;

  const formatPercentageDP =
    numberDP === 0 ? "0%" : numberDP === 1 ? "0.0%" : "0.00%";

  return {
    value: numberPercentage / 100,
    type: "n",
    format: formatPercentageDP,
  };
};
