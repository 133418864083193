import { DeleteButton } from "@components/buttons/delete";
import { PlusButton } from "@components/buttons/plus";
import { FeeCurrencyField } from "@pages/platform-analysis/_id/steps/fee/calculator/components/currency-field";
import {
  SHButton,
  SHButtonProps,
  SHStack,
  SHStackProps,
  SHTypography,
} from "@components/design-systems";
import { UserSVG } from "@components/svgs";
import { Collapse, useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { NumericFormat } from "react-number-format";
import { TransitionGroup } from "react-transition-group";
import {
  FamilyMemberAccountDTO,
  FamilyMemberDTO,
} from "@models/platform-analysis/entities/steps/fee";
import { calculateTotalMemberAccount } from "../../util";
import { useCallback } from "react";

export interface FamilyCardProps {
  order: number;
  canRemove?: boolean;
  disabledAddButton: boolean;
  disabled?: boolean;
  familyMember: FamilyMemberDTO;
  onRemove?: () => void;
  onChange: (member: FamilyMemberDTO) => void;
}
export const FamilyCard = ({
  order = 1,
  canRemove = true,
  disabledAddButton,
  disabled,
  onRemove,
  familyMember,
  onChange,
}: FamilyCardProps) => {
  const { palette } = useTheme();
  const handleOnChangeMember = (items: FamilyMemberAccountDTO[]) =>
    onChange({
      ...familyMember,
      familyMemberAccounts: items,
      totalBalance: calculateTotalMemberAccount(items),
    });
  return (
    <SHStack>
      <SHStack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        sx={{
          p: "15px",
          border: `1px solid ${palette.secondary[100]}`,
          backgroundColor: hexToRGBA(palette.secondary[100], 0.25),
        }}
      >
        <SHStack direction={"row"} alignItems={"center"}>
          <UserSVG height={22} />
          <SHTypography variant="subtitle2">
            {familyMember.name ?? `Family member/entity ${order}`}
          </SHTypography>
        </SHStack>
        <SHStack
          direction={"row"}
          alignItems={"center"}
          justifyContent="flex-end"
          spacing={"15px"}
        >
          <SHTypography variant="body2">Total assets</SHTypography>
          <SHTypography variant="subtitle2">
            $
            <NumericFormat
              displayType="text"
              value={calculateTotalMemberAccount(
                familyMember.familyMemberAccounts,
              )}
              thousandSeparator=","
              decimalScale={2}
            />
          </SHTypography>
          {canRemove && <RemoveButton onClick={onRemove} disabled={disabled} />}
        </SHStack>
      </SHStack>
      <SHStack direction={"row"} alignItems={"stretch"}>
        <CardBox
          sx={{
            borderWidth: "0 1px 1px 1px",
          }}
          disabledAddButton={disabledAddButton}
          account={familyMember.familyMemberAccounts || []}
          title={"IDPS"}
          isIDPS
          disabled={disabled}
          onChange={handleOnChangeMember}
        />
        <CardBox
          sx={{
            borderWidth: "0 1px 1px 0",
          }}
          disabledAddButton={disabledAddButton}
          account={familyMember.familyMemberAccounts || []}
          title={"Super/Pension"}
          isIDPS={false}
          disabled={disabled}
          onChange={handleOnChangeMember}
        />
      </SHStack>
    </SHStack>
  );
};

interface CardBoxProps extends Omit<SHStackProps, "onChange"> {
  title: string;
  disabledAddButton: boolean;
  disabled?: boolean;
  account: FamilyMemberAccountDTO[];
  onChange: (accounts: FamilyMemberAccountDTO[]) => void;
  isIDPS: boolean;
}
const CardBox = ({
  title,
  disabledAddButton,
  disabled,
  account,
  onChange,
  isIDPS,
  ...others
}: CardBoxProps) => {
  const { sx } = others;
  const { palette } = useTheme();
  const handleOnChange = (index: number, value: null | number) => {
    let clone = [...account];
    clone[index].balance = value || 0;
    onChange(clone);
  };
  const handleOnRemove = (index: number) => {
    let clone = [...account];
    clone.splice(index, 1);
    onChange(clone);
  };
  const handleOnAdd = () => {
    let newAccount = new FamilyMemberAccountDTO();
    newAccount.isIDPS = isIDPS ?? false;
    onChange([...account, newAccount]);
  };

  const getOrder = useCallback((account: FamilyMemberAccountDTO[]) => {
    let idpsIndex = 0;
    let superIndex = 0;
    let order: number[] = [];
    account.forEach((item) => {
      if (item.isIDPS) {
        idpsIndex += 1;
        order.push(idpsIndex);
      } else {
        superIndex += 1;
        order.push(superIndex);
      }
    });
    return order;
  }, []);
  const otherMapping = getOrder(account);

  return (
    <SHStack
      {...others}
      sx={{
        width: 0.5,
        borderColor: palette.secondary[100],
        borderStyle: "solid",
        p: "15px",
        ...sx,
      }}
      justifyContent={"space-between"}
      alignItems={"center"}
      spacing={account.length ? "25px" : 0}
    >
      <TransitionGroup
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          width: "100%",
          gap: "25px",
        }}
      >
        {account.map((item, index) => (
          <Collapse
            key={item.id}
            style={item.isIDPS !== isIDPS ? { display: "none" } : undefined}
          >
            <CardItem
              title={`${title} ${otherMapping[index]}`}
              value={item.balance}
              onChange={(value) => handleOnChange(index, value)}
              onRemove={() => handleOnRemove(index)}
              disabled={disabled}
            />
          </Collapse>
        ))}
      </TransitionGroup>
      <PlusButton
        variant="outlined"
        size="medium"
        onClick={handleOnAdd}
        disabled={disabledAddButton || disabled}
      >
        {title}
      </PlusButton>
    </SHStack>
  );
};

const RemoveButton = (props: SHButtonProps) => {
  const { palette } = useTheme();
  return (
    <SHButton
      variant="text"
      color="error"
      sx={{
        color: palette.error.main,
        fontSize: "14px",
        fontWeight: 600,
        p: 0,
        border: "none",
        height: "auto",
        "&:active": { border: "none" },
        "&:hover": { color: palette.error.dark },
      }}
      {...props}
    >
      Remove
    </SHButton>
  );
};

interface CardItemProps {
  title: string;
  value: number | null;
  disabled?: boolean;
  onChange: (value: number | null) => void;
  onRemove: () => void;
}
export const CardItem = ({
  title,
  value,
  disabled,
  onChange,
  onRemove,
}: CardItemProps) => {
  return (
    <SHStack>
      <SHTypography colorVariant={"third"} variant="subtitle2">
        {title}
      </SHTypography>
      <SHStack direction={"row"} alignItems={"center"} spacing={"10px"}>
        <FeeCurrencyField
          value={value}
          onChange={(event, value) => {
            onChange(value);
          }}
          fullWidth
          placeholder={""}
          disabled={disabled}
        />
        <DeleteButton onClick={onRemove} disabled={disabled} />
      </SHStack>
    </SHStack>
  );
};
