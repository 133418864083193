import { SHButton, SHDialog, SHStack } from "@components/design-systems";

interface ArchiveDocumentConfirmDialogProps {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onOk: () => void;
}

export const ArchiveDocumentConfirmDialog = ({
  open,
  isLoading,
  onClose,
  onOk,
}: ArchiveDocumentConfirmDialogProps) => {
  return (
    <SHDialog
      open={open}
      onClose={onClose}
      width={600}
      header={"Coming soon"}
      body={"Archive document feature is in development."}
      footer={
        <SHStack
          width="100%"
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          {/* <SHButton
            color="secondary"
            onClick={onClose}
            variant="text"
            size="extraMedium"
          >
            Cancel
          </SHButton> */}
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onOk}
          >
            Close
          </SHButton>
        </SHStack>
      }
    />
  );
};
