import { ViewStandard } from "../components/view-standard";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { useParams } from "react-router";
import { useEffect } from "react";
import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { SHStack } from "@components/design-systems";
import { ViewStandardSkeleton } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/view-standard/components/view-standard-skeleton";

export const ViewManagerTab = () => {
  const {
    investmentProductUI: { isManagerDataLoading },
    investmentProductManagerData,
  } = useSelector((state: RootState) => state.investmentProduct);
  const { investmentProductId } = useParams();
  const { loadInvestmentProductManager } = useInvestmentProduct();

  useEffect(() => {
    loadInvestmentProductManager(investmentProductId ?? "new", "view");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investmentProductId]);

  return isManagerDataLoading ? (
    <SHStack marginY="25px">
      <ViewStandardSkeleton />
    </SHStack>
  ) : (
    <SHStack marginY="25px">
      <ViewStandard
        sections={investmentProductManagerData?.group?.sections}
        investmentProductUpdateDate={
          investmentProductManagerData?.group?.pageUpdated
        }
      />
    </SHStack>
  );
};
