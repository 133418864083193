import { SHStack } from "@components/design-systems";
import { TopBar, TopBarContainer } from "@layouts/top-bar";
import { TopBarSkeleton } from "@pages/suppliers/_id/_products/_id/edit/components/skeletons/TopBarSkeleton";
import { generateTitle } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { DiscardDraftButton } from "@pages/suppliers/_id/_products/investment-product/_id/components/buttons/discard-draft";
import { InvestmentProductSaveDraftButton } from "@pages/suppliers/_id/_products/investment-product/_id/components/buttons/save-draft.tsx";
import { InvestmentProductSubmitForReviewButton } from "@pages/suppliers/_id/_products/investment-product/_id/components/buttons/submit-for-review";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { useMemo } from "react";
import { useIsNew } from "@hooks/useIsNew";
import { useUserPermissions } from "@hooks/userUserPermission";
import { InvestmentBannerStatus } from "@models/product/investment-product/enums/status";
import InvestmentProductRevertToDraftButton from "@pages/suppliers/_id/_products/investment-product/_id/components/buttons/revert-to-draft";
import { InvestmentProductApproveButton } from "@pages/suppliers/_id/_products/investment-product/_id/components/buttons/approve";
import { InvestmentProductRecallSubmissionButton } from "@pages/suppliers/_id/_products/investment-product/_id/components/buttons/recall-submission";
import { hideEditInvestmentProductTopBarButtons } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/util";

interface InvestmentProductTopBarContainerProps {
  isSubmitting?: boolean;
  isLoading?: boolean;
  isReverting?: boolean;
  isApproving?: boolean;
  isRecallingSubmission?: boolean;
  isDisabledSaveDraft?: boolean;
  showDiscardDraftButton?: boolean;

  onSaveDraft: () => void;
  onDiscard?: () => void;
  onSubmit: () => Promise<void>;
  onRevert: () => Promise<void>;
  onApprove: () => Promise<void>;
  onRecallSubmission: () => Promise<void>;
}

export const InvestmentProductTopBarContainer = ({
  isDisabledSaveDraft,
  isLoading,
  isSubmitting,
  isReverting,
  isApproving,
  isRecallingSubmission,
  showDiscardDraftButton,
  onSaveDraft,
  onSubmit,
  onDiscard,
  onRevert,
  onApprove,
  onRecallSubmission,
}: InvestmentProductTopBarContainerProps) => {
  const {
    investmentProductBannerInfo,
    investmentProductUI: {
      investmentProductName,
      isEditMode,
      isSaving,
      selectedEditTab,
    },
  } = useSelector((state: RootState) => state.investmentProduct);
  const isNew = useIsNew();
  const {
    canSubmitForReviewProduct,
    canApproveProduct,
    canRevertToDraftProduct,
    canRecallSubmissionProduct,
  } = useUserPermissions();

  const isHideAllButton = useMemo(
    () => hideEditInvestmentProductTopBarButtons(selectedEditTab),
    [selectedEditTab],
  );

  const { isPendingApproval, isApproved, isDraft } = useMemo(() => {
    const statuses =
      investmentProductBannerInfo?.map((banner) => banner.status) || [];

    return {
      isPendingApproval: statuses.includes(
        InvestmentBannerStatus.PendingApproval,
      ),
      isApproved: statuses.includes(InvestmentBannerStatus.Approved),
      isDraft: statuses.includes(InvestmentBannerStatus.Draft),
    };
  }, [investmentProductBannerInfo]);

  const showSaveDraftButton = useMemo(() => {
    return !isPendingApproval && !isHideAllButton;
  }, [isPendingApproval, isHideAllButton]);

  const showSubmitForReviewButton = useMemo(() => {
    return [
      !isNew,
      canSubmitForReviewProduct,
      isEditMode,
      !isPendingApproval,
      !isHideAllButton,
    ].every(Boolean);
  }, [
    isNew,
    canSubmitForReviewProduct,
    isEditMode,
    isPendingApproval,
    isHideAllButton,
  ]);

  const showRevertToDraftButton = useMemo(() => {
    return [
      !isNew,
      canRevertToDraftProduct,
      isEditMode,
      isPendingApproval,
      !isHideAllButton,
    ].every(Boolean);
  }, [
    isNew,
    canRevertToDraftProduct,
    isEditMode,
    isPendingApproval,
    isHideAllButton,
  ]);

  const showApproveButton = useMemo(() => {
    return [
      !isNew,
      canApproveProduct,
      isEditMode,
      isPendingApproval,
      !isHideAllButton,
    ].every(Boolean);
  }, [
    isNew,
    canApproveProduct,
    isEditMode,
    isPendingApproval,
    isHideAllButton,
  ]);

  const showRecallSubmissionButton = useMemo(() => {
    return [
      !isNew,
      canRecallSubmissionProduct,
      isEditMode,
      isPendingApproval,
      !isHideAllButton,
    ].every(Boolean);
  }, [
    isNew,
    canRecallSubmissionProduct,
    isEditMode,
    isPendingApproval,
    isHideAllButton,
  ]);

  return isLoading ? (
    <SHStack mb={3}>
      <TopBarSkeleton />
    </SHStack>
  ) : (
    <TopBarContainer>
      <TopBar
        title={generateTitle(investmentProductName)}
        tools={
          <>
            {showDiscardDraftButton && (
              <DiscardDraftButton onClick={onDiscard} />
            )}

            {showSaveDraftButton && (
              <InvestmentProductSaveDraftButton
                disabled={!!isDisabledSaveDraft || isSaving}
                isLoading={isSaving}
                onClick={onSaveDraft}
              />
            )}

            {showSubmitForReviewButton && (
              <InvestmentProductSubmitForReviewButton
                disabled={!isDraft}
                isApproved={isApproved}
                onSubmit={onSubmit}
                isSubmitting={isSubmitting}
              />
            )}

            {showRevertToDraftButton && (
              <InvestmentProductRevertToDraftButton
                onRevert={onRevert}
                isReverting={isReverting}
              />
            )}

            {showApproveButton && (
              <InvestmentProductApproveButton
                isApproving={isApproving}
                onApprove={onApprove}
              />
            )}

            {showRecallSubmissionButton && (
              <InvestmentProductRecallSubmissionButton
                isLoading={isRecallingSubmission}
                onRecallSubmission={onRecallSubmission}
              />
            )}
          </>
        }
      />
    </TopBarContainer>
  );
};
