import {
  DocumentFileNameDateFormat,
  FileTypeMap,
  FileTypes,
  TIMESTAMP_LENGTH,
} from "@pages/document-library/constant";
import { format, startOfDay, subMonths } from "date-fns";
import { isEmpty } from "lodash";

export const getFileType = (s3Key: string): FileTypes | null => {
  if (isEmpty(s3Key)) return null;

  const lastDotIndex = s3Key.lastIndexOf(".");
  if (lastDotIndex === -1 || lastDotIndex === s3Key.length - 1) return null;

  const extension = s3Key.slice(lastDotIndex + 1).toLowerCase();
  return FileTypeMap.get(extension) || null;
};

export const getDateRange = (offset: number) => {
  const toDate = startOfDay(new Date());
  const fromDate = subMonths(toDate, offset);

  return {
    fromDate,
    toDate,
  };
};

export const getDocumentDownloadFileName = ({
  url,
  productName,
  title,
  dateOfDocument,
}: {
  url: string;
  productName: string;
  title: string;
  dateOfDocument: Date;
}): string => {
  const fileNameFromUrl = url.split("/").pop() ?? "";
  const extension = fileNameFromUrl.split(".").pop();
  const fileNameDate = format(dateOfDocument, DocumentFileNameDateFormat);
  return `${productName} ${title} ${fileNameDate}.${extension}`;
};
export const getFileNameWithoutTimestamp = (url: string): string | null => {
  try {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    if (!pathname) return null;

    const segments = pathname.split("/").filter(Boolean);
    let fileName = segments.pop() || null;
    if (fileName) {
      // slice first 13 timestamp value
      return fileName.slice(TIMESTAMP_LENGTH);
    }

    return null;
  } catch (error) {
    return null;
  }
};
