import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { SHStack, SHTextField } from "@components/design-systems";
import {
  useEditManagerPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { FieldTypeId } from "@models/configuration";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { toStringValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { CustomLabel } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/custom-label";
import {
  MANAGER_CODE_REGEX,
  MAX_MANAGER_CODE_NUM_OF_CHARS,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/constant";
import {
  EditManagerInputControllerProps,
  handleOnEditManagerFieldChange,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { updateInvestmentProductName } from "@redux/slices/product/investment-product";
import { useAppDispatch } from "@redux/store";
import { debounce, defaultTo, isEqual } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useEffectOnce } from "react-use";

export const EditManagerShortTextInput = ({
  isCreatingInvestmentProduct,
  featureData,
  featureIndex,
  sectionIndex,
  isManagerCodeField,
  isManagerNameField,
  isDisabled,
  onChange,
}: EditManagerInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductManagerDTO>();

  const { featureProductDataPath, featureDataPath } = useEditManagerPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductManagerDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const dispatch = useAppDispatch();

  useEffectOnce(() => {
    if (!isManagerNameField) return;

    const productNameFormValue = toStringValue(
      getValues(featureProductDataPath.value),
      FieldTypeId.TextShort,
    );

    const productName = defaultTo(
      productNameFormValue,
      featureData.productData?.value || "",
    );
    dispatch(updateInvestmentProductName(productName));
  });

  const handleOnChange = (newValue: string) => {
    handleOnEditManagerFieldChange({
      isCreatingInvestmentProduct,
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });

    onChange && onChange({ sectionIndex, featureIndex, newValue });
    isManagerNameField && dispatch(updateInvestmentProductName(newValue || ""));
  };

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );
  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    setValue(
      featureProductDataPath.isModified,
      !isEqual(featureData.viewingGroupSelections, newValue),
    );
    onChange && onChange({ sectionIndex, featureIndex });
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );

  return (
    <SHStack
      width={{ xs: "100%", sm: "100%", md: 520 }}
      direction={"row"}
      gap={2}
    >
      <Controller
        name={featureProductDataPath.value}
        control={control}
        render={({ field: { ref, ...other } }) => (
          <SHTextField
            {...other}
            disabled={isDisabled}
            sx={{ flex: 1 }}
            value={getValues(featureProductDataPath.value)}
            fullWidth
            label={
              <CustomLabel
                featureData={featureData}
                isDisabled={isDisabled}
                dotProps={{
                  orangeDot: isShowOrangeDot,
                  blueDot: isShowBlueDot,
                  redDot: isManagerCodeField,
                  greenDot: !!featureData?.isBusinessMetric,
                }}
              />
            }
            placeholder={EditProductDefaults.PlaceHolder}
            onChange={(event) => {
              const targetValue = event.target.value;

              if (isManagerCodeField && !MANAGER_CODE_REGEX.test(targetValue)) {
                event.preventDefault();

                return;
              }

              const newValue = isManagerCodeField
                ? targetValue.toUpperCase()
                : targetValue;
              other.onChange(newValue);
              debouncedHandleInputChange(newValue);
            }}
            maxLength={
              isManagerCodeField
                ? MAX_MANAGER_CODE_NUM_OF_CHARS
                : EditProductDefaults.MaxNumOfChars
            }
            hasCounter
          />
        )}
      />
      {featureData.allowedVisibilitySetting && (
        <Controller
          name={featureDataPath.viewingGroupSelections}
          control={control}
          render={({ field: { ref, ...other } }) => (
            <SelectViewingGroups
              disabled={isDisabled}
              textFieldProps={{
                label: `Visible to`,
              }}
              sx={{
                flexBasis: "40%",
              }}
              onChange={(value) => {
                other.onChange(value);
                debounceVisibilityChange(value);
              }}
              value={
                (getValues(featureDataPath.viewingGroupSelections) as
                  | ViewingGroupSelectionDTO[]
                  | null) || []
              }
              viewingGroupData={featureData.viewingGroupSelections ?? []}
            />
          )}
        />
      )}
    </SHStack>
  );
};
