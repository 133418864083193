import { SHLabel } from "@components/design-systems";
import { useInputDotIndicator } from "@hooks/useInvestmentProduct";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { CustomLabel } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/custom-label";
import { SeriesPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series";
import { FRCell } from "@pages/suppliers/_id/_products/investment-product/_id/view/components/table-cell";
import { useFormContext } from "react-hook-form";

interface SeriesFeatureTableLabelProps {
  productFeatureData: InvestmentProductConfigurationFeature;
  featureDataPath: SeriesPath;
}

const SeriesFeatureTableLabel = ({
  productFeatureData,
  featureDataPath,
}: SeriesFeatureTableLabelProps) => {
  const { control, getValues } = useFormContext<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>();
  const { isShowOrangeDot, isShowBlueDot } = useInputDotIndicator<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>({
    getValues,
    control,
    paths: {
      highlightType: `${featureDataPath}.highlightType` as SeriesPath,
      dataStatus: `${featureDataPath}.dataStatus` as SeriesPath,
    },
  });

  return (
    <FRCell key={productFeatureData.id}>
      <SHLabel
        label={
          <CustomLabel
            featureData={productFeatureData}
            dotProps={{ orangeDot: isShowOrangeDot, blueDot: isShowBlueDot }}
          />
        }
      />
    </FRCell>
  );
};

export default SeriesFeatureTableLabel;
