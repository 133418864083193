import { SHButton, SHDialog, SHStack } from "@components/design-systems";

interface ArchiveConfirmDialogProps {
  open: boolean;
  isLoading: boolean;
  onClose: () => void;
  onOk: () => void;
  body: string;
  header: string;
  buttonLabel: {
    ok: string;
    cancel: string;
  };
}

export const ArchiveConfirmDialog = ({
  open,
  isLoading,
  onClose,
  onOk,
  header,
  body,
  buttonLabel,
}: ArchiveConfirmDialogProps) => {
  return (
    <SHDialog
      open={open}
      onClose={onClose}
      width={600}
      header={header}
      body={body}
      footer={
        <SHStack
          width="100%"
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          spacing={7}
        >
          <SHButton
            disabled={isLoading}
            color="secondary"
            onClick={onClose}
            variant="text"
            size="extraMedium"
          >
            {buttonLabel.cancel}
          </SHButton>
          <SHButton
            color="primary"
            size="extraMedium"
            variant="contained"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onOk}
          >
            {buttonLabel.ok}
          </SHButton>
        </SHStack>
      }
    />
  );
};
