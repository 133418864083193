import { SHStack } from "@components/design-systems";
import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { useUserPermissions } from "@hooks/userUserPermission";
import { ProductFeaturesLayout } from "@layouts/products/features";
import { FieldTypeId, SectionDisplayTypeId } from "@models/configuration";
import {
  InvestmentProductConfigurationModelSection,
  InvestmentProductModelsDTO,
} from "@models/product/investment-product/entities/model";
import {
  EditInvestmentProductFormRef,
  EditInvestmentProductSaveDraftResult,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit";
import { EditModelsCollapseMenu } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/edit-model-collapse-menu";
import { FeatureDatePicker } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/date-picker";
import { ModelTitleTextField } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/model-title";
import { FeatureTextEditor } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/text-editor";
import { ModelFeatureTextShort } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/form-controls/text-field";
import ModelPageTableEdit from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/components/table";
import { updateInvestmentProductModelsData } from "@redux/slices/product/investment-product";
import { RootState, useAppDispatch } from "@redux/store";
import { isEmpty, isObject } from "lodash";
import {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ModelsTabSkeleton } from "../../skeletons/ModelsTabSkeleton";
import { useIsNew } from "@hooks/useIsNew";
import { ModelFeatureNumberField } from "./components/form-controls/number-field";
import { ModelFeatureYesNoField } from "./components/form-controls/yesno-field";
import { ModelFeatureDropdown } from "./components/form-controls/dropdown-field";
import { ModelFeatureNumberTextField } from "./components/form-controls/number-text-field";
import { ModelFeatureCurrencyField } from "./components/form-controls/currency-field";
import { ModelFeatureCurrencyTextField } from "./components/form-controls/currency-text-field";
import { ModelFeaturePercentageTextField } from "./components/form-controls/percentage-text-field";
import { ModelFeaturePercentageField } from "./components/form-controls/percentage-field";
import { FormFieldContainer } from "./components/form-controls/form-field-container";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";

interface EditModelsTabProps {
  allowAddNewModel?: boolean;
}

export const EditModelsTab = forwardRef<
  EditInvestmentProductFormRef,
  EditModelsTabProps
>(({ allowAddNewModel = false }: EditModelsTabProps, ref) => {
  /** Hooks */
  const dispatch = useAppDispatch();
  const formMethod = useForm<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>({
    mode: "onChange",
    defaultValues: {
      investmentProductModels: [],
    },
  });
  const { updateInvestmentProductModels } = useInvestmentProduct();
  const { reset, getValues } = formMethod;
  const [selectedIndex, setSelectedIndex] = useState<[number, number]>([0, 0]);
  const { isAdviserTrialGroup } = useUserPermissions();
  const {
    investmentProductModelsData,
    investmentProductUI: { isModelsDataLoading },
  } = useSelector((state: RootState) => state.investmentProduct);
  const { investmentProductId } = useParams();
  const { loadInvestmentProductModels } = useInvestmentProduct();
  const isNewInvestmentProduct = useIsNew();
  const [productsIndex, modelsIndex] = selectedIndex;
  const selectedModel = useMemo(() => {
    return investmentProductModelsData?.[productsIndex]?.models?.[modelsIndex];
  }, [investmentProductModelsData, modelsIndex, productsIndex]);

  const {
    investmentProductUI: { isSaveDraftButtonDisabled },
  } = useSelector((state: RootState) => state.investmentProduct);

  const handleSaveDraft =
    async (): Promise<EditInvestmentProductSaveDraftResult> => {
      if (!investmentProductId) return { isSuccess: false };
      if (isSaveDraftButtonDisabled) return { isSuccess: true };
      const formData = getValues("investmentProductModels");
      if (isEmpty(formData) || !isObject(formData)) return { isSuccess: true };
      try {
        const response = await updateInvestmentProductModels(
          investmentProductId,
          formData,
        );
        if (!response) return { isSuccess: false };

        dispatch(updateInvestmentProductModelsData(response));

        return { isSuccess: true, reloadBanner: true };
      } catch {
        return { isSuccess: false };
      }
    };
  useImperativeHandle(ref, () => ({
    getValue() {
      return getValues();
    },
    submit: async () => {},
    resetForm() {
      reset();
    },
    changeTab: async () => await handleSaveDraft(),
    saveDraft: async () => await handleSaveDraft(),
    onPostSubmit: async () => {
      await loadInvestmentProductModels(investmentProductId ?? "new", "edit");
    },
  }));
  /** Side Effect */
  useEffect(() => {
    if (isNewInvestmentProduct) return;
    if (isAdviserTrialGroup) return;
    loadInvestmentProductModels(investmentProductId ?? "new", "edit");
    // eslint-disable-next-line
  }, [investmentProductId]);
  useEffect(() => {
    reset({
      investmentProductModels: investmentProductModelsData,
    });
    // eslint-disable-next-line
  }, [investmentProductModelsData]);

  /** Render */
  const renderFeatureFormFields = (
    feature: InvestmentProductConfigurationFeature,
    featureIndex: number,
    sectionIndex: number,
  ) => {
    // Case first model -> model title
    const defaultInputProps = {
      feature: feature,
      selectedIndex: selectedIndex,
      featureIndex: featureIndex,
      sectionIndex: sectionIndex,
    };

    if (featureIndex === 0 && sectionIndex === 0)
      return (
        <ModelTitleTextField feature={feature} selectedIndex={selectedIndex} />
      );

    switch (feature.fieldType?.id) {
      case FieldTypeId.TextLong: {
        return (
          <FormFieldContainer>
            <FeatureTextEditor {...defaultInputProps} />
          </FormFieldContainer>
        );
      }
      case FieldTypeId.DateTime: {
        return <FeatureDatePicker {...defaultInputProps} />;
      }
      case FieldTypeId.YesNo: {
        return (
          <FormFieldContainer>
            <ModelFeatureYesNoField {...defaultInputProps} />
          </FormFieldContainer>
        );
      }
      case FieldTypeId.Dropdown: {
        return (
          <FormFieldContainer>
            <ModelFeatureDropdown {...defaultInputProps} />
          </FormFieldContainer>
        );
      }
      case FieldTypeId.Number: {
        return (
          <FormFieldContainer>
            <ModelFeatureNumberField {...defaultInputProps} />
          </FormFieldContainer>
        );
      }
      case FieldTypeId.NumberText: {
        return (
          <FormFieldContainer>
            <ModelFeatureNumberTextField {...defaultInputProps} />
          </FormFieldContainer>
        );
      }
      case FieldTypeId.Currency: {
        return (
          <FormFieldContainer>
            <ModelFeatureCurrencyField {...defaultInputProps} />
          </FormFieldContainer>
        );
      }
      case FieldTypeId.CurrencyText: {
        return (
          <FormFieldContainer>
            <ModelFeatureCurrencyTextField {...defaultInputProps} />
          </FormFieldContainer>
        );
      }
      case FieldTypeId.Percentage: {
        return (
          <FormFieldContainer>
            <ModelFeaturePercentageField {...defaultInputProps} />
          </FormFieldContainer>
        );
      }
      case FieldTypeId.PercentageText: {
        return (
          <FormFieldContainer>
            <ModelFeaturePercentageTextField {...defaultInputProps} />
          </FormFieldContainer>
        );
      }

      // Default text short
      default: {
        return (
          <FormFieldContainer>
            <ModelFeatureTextShort {...defaultInputProps} />
          </FormFieldContainer>
        );
      }
    }
  };
  const renderSection = (
    section: InvestmentProductConfigurationModelSection,
    sectionIndex: number,
  ) => {
    const isFistSection = sectionIndex === 0;
    switch (section.displayType?.id) {
      case SectionDisplayTypeId.MainBodyPage: {
        return (
          <SHStack
            width={"100%"}
            gap={"25px"}
            marginTop={`${isFistSection ? "0px" : "10px"}`}
            marginBottom={"10px"}
          >
            {section.features?.map((feature, featureIndex) => {
              return (
                <Fragment key={feature.id}>
                  {renderFeatureFormFields(feature, featureIndex, sectionIndex)}
                </Fragment>
              );
            })}
          </SHStack>
        );
      }
      case SectionDisplayTypeId.Table: {
        return (
          <SHStack width={"100%"} paddingBottom="10px">
            <ModelPageTableEdit
              section={section}
              sectionIndex={sectionIndex}
              selectedIndex={selectedIndex}
            />
          </SHStack>
        );
      }
    }
  };

  if (isModelsDataLoading) return <ModelsTabSkeleton />;
  return (
    <FormProvider {...formMethod}>
      <form>
        <ProductFeaturesLayout
          emptyMessage="Please create a Series first"
          hasData={!isEmpty(investmentProductModelsData)}
          leftSide={
            <EditModelsCollapseMenu
              investmentProductModelsData={investmentProductModelsData}
              dotGroupProps={{
                orangeDot: true,
                blueDot: true,
              }}
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
              defaultExpandIndexes={[0]}
              allowAddNewModel={allowAddNewModel}
            />
          }
          rightSide={
            <SHStack
              justifyContent="space-between"
              flexWrap="wrap"
              direction="row"
            >
              {selectedModel?.sections?.map((section, index) => (
                <Fragment key={section.id}>
                  {renderSection(section, index)}
                </Fragment>
              ))}
            </SHStack>
          }
        />
      </form>
    </FormProvider>
  );
});
