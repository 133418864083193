import {
  SHButton,
  SHDialog,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { useEditProduct } from "@hooks/useEditProduct";
import { useEditInvestmentProduct } from "@hooks/useEditInvestmentProduct";
import { useNotification } from "@hooks/useNotification";
import { HubConnectionState } from "@microsoft/signalr";
import { DefaultSocketErrorMessage } from "@models/core/socket";
import { SignalRService } from "@services/signalr/signalRService";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ProductType } from "@models/product/enums/productType";
import {
  ConfirmKickoutDialogProductStateAction,
  getConfirmKickoutDialogProductConfig,
  getProductClaimingMethod,
} from "@pages/platform-profiles/util";

interface ConfirmKickOutDialogProps {
  supplierId?: string;
  productId?: string;
  productType?: ProductType;
}

export const ConfirmKickOutDialog = ({
  supplierId,
  productId,
  productType,
}: ConfirmKickOutDialogProps) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleOnClaimingEditingPermission:
      handlePlatformOnClaimingEditingPermission,
  } = useEditProduct();
  const {
    handleOnClaimingEditingPermission:
      handleInvestmentProductOnClaimingEditingPermission,
  } = useEditInvestmentProduct();
  const navigate = useNavigate();
  const { notify } = useNotification();
  productType ??= ProductType.Platform;

  const productConfig = getConfirmKickoutDialogProductConfig(productType);

  const productActions: ConfirmKickoutDialogProductStateAction = {
    updateEditMode: (payload: boolean) =>
      dispatch({
        type: productConfig.actionTypes.updateEditMode,
        payload: payload,
      }),
    updateLoadingEditMode: (payload: boolean) =>
      dispatch({
        type: productConfig.actionTypes.updateLoadingEditMode,
        payload: payload,
      }),
    updateShowKickoutDialog: (payload: boolean) =>
      dispatch({
        type: productConfig.actionTypes.updateShowKickoutDialog,
        payload: payload,
      }),
  };

  const showKickoutDialog = useSelector(productConfig.getShowKickoutDialog);

  const currentEditor = useSelector(productConfig.getCurrentEditor);

  const handleOnClickYes = async () => {
    setIsLoading(true);
    const connection = SignalRService.getHubConnection();
    if (connection && connection.state === HubConnectionState.Connected) {
      const productClaimingMethod = getProductClaimingMethod({
        productType: productType,
        platformClaimingMethod: handlePlatformOnClaimingEditingPermission,
        investmentClaimingMethod:
          handleInvestmentProductOnClaimingEditingPermission,
      });

      await productClaimingMethod(productId as string, () => {
        productActions.updateEditMode(true);
        navigate(
          productConfig.getNavigationPath(
            supplierId as string,
            productId as string,
          ),
        );
      });
      setIsLoading(false);
      productActions.updateShowKickoutDialog(false);
    } else {
      ///else show error here
      setIsLoading(false);
      notify(DefaultSocketErrorMessage, {
        variant: "error",
        close: true,
      });
    }
  };

  const handleOnClose = () => {
    productActions.updateShowKickoutDialog(false);
    productActions.updateLoadingEditMode(false);
    productActions.updateEditMode(false);
    setIsLoading(false);
  };

  return (
    <>
      <SHDialog
        open={showKickoutDialog || false}
        onClose={handleOnClose}
        width={600}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        header={
          <SHStack alignItems="center" textAlign={"center"} width={{ xs: 590 }}>
            <SHTypography variant="h2">
              WARNING: Another user is editing the Profile
            </SHTypography>
          </SHStack>
        }
        body={
          <SHStack>
            <SHTypography
              variant="body2"
              sx={{
                fontSize: 16,
                fontWeight: 400,
                width: 430,
              }}
            >
              {currentEditor} is currently editing the Profile. We only allow
              one user to edit the Profile at one time. If you continue, you
              will kick them out of edit mode, and their unsaved changes will be
              discarded.
            </SHTypography>
            <br />
            <SHTypography
              variant="body2"
              sx={{
                fontSize: 16,
                fontWeight: 400,
              }}
            >
              Proceed with kicking out {currentEditor}?
            </SHTypography>
          </SHStack>
        }
        footer={
          <SHStack
            width="100%"
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={7}
          >
            <SHButton
              color="primary"
              variant="text"
              onClick={handleOnClose}
              size="extraMedium"
            >
              Cancel
            </SHButton>
            <SHButton
              color="primary"
              variant="contained"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={handleOnClickYes}
              size="extraMedium"
            >
              Yes, kick them out
            </SHButton>
          </SHStack>
        }
      />
    </>
  );
};
