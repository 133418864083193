import { APIExtRoutes } from "@constants";
import { APIResponse } from "@models/core";
import { SupplierLovDTO } from "@models/supplier/entities/suppliers";
import {
  CreateViewingGroupDTO,
  UpdateViewingGroupDTO,
  ViewingGroupDTO,
  ViewingGroupSelectionDTO,
} from "@models/viewing-groups/entities/viewingGroups";
import { ViewingGroupStatus } from "@models/viewing-groups/enum/status";
import { APIGatewayService, handleErrorProxy } from "@services/shService";
import { generatePath } from "react-router";

export const getViewingGroupSelectionBySupplierId = async (
  supplierId: string,
): Promise<APIResponse<ViewingGroupSelectionDTO[]>> => {
  const response = await APIGatewayService.get<ViewingGroupSelectionDTO[]>(
    generatePath(APIExtRoutes.viewingGroupSelectionBySupplierId, {
      supplierId,
    }),
  );
  return handleErrorProxy(response);
};
export const getSupplierLov = async (): Promise<
  APIResponse<SupplierLovDTO[]>
> => {
  const response = await APIGatewayService.get<SupplierLovDTO[]>(
    APIExtRoutes.supplierLov,
  );
  return handleErrorProxy(response);
};

export const postViewingGroup = async (viewingGroup: CreateViewingGroupDTO) => {
  const response = await APIGatewayService.post<string>(
    APIExtRoutes.viewingGroups,
    viewingGroup,
  );
  return handleErrorProxy(response);
};
export const patchViewingGroupStatus = async (
  viewingGroupId: string,
  status: ViewingGroupStatus,
) => {
  const path = generatePath(APIExtRoutes.updateViewingGroupStatus, {
    viewingGroupId,
    status,
  });
  const response = await APIGatewayService.patch<string>(path);
  return handleErrorProxy(response);
};
export const getViewingGroupDetails = async (
  viewingGroupId: string,
): Promise<APIResponse<ViewingGroupDTO>> => {
  const path = generatePath(APIExtRoutes.viewingGroupsDetails, {
    viewingGroupId,
  });
  const response = await APIGatewayService.get<ViewingGroupDTO>(path);
  return handleErrorProxy(response);
};
export const putViewingGroup = async (viewingGroup: UpdateViewingGroupDTO) => {
  const response = await APIGatewayService.put<string>(
    APIExtRoutes.viewingGroups,
    viewingGroup,
  );
  return handleErrorProxy(response);
};
