import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import ToolbarPlugin, { ToolbarConfig } from "./components/text-editor-toolbar";
import {
  StyledContentEditable,
  StyledEditorContainer,
} from "./components/text-editor-styled";
import { SHLabel, SHLabelProps, SHStack } from "@components/design-systems";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { defaultEditorConfig, defaultToolbarConfig } from "./config";
import { TextEditorSharedStateProvider } from "./context/TextEditorSharedStateContext";
import DialogEditorPlugin from "./components/text-editor-dialog";
import { useToggle } from "react-use";
import ReadOnlyPlugin from "./components/text-editor-read-only";
import ValueControlPlugin from "./components/text-editor-value-control";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import AutoListPlugin from "./components/text-editor-auto-list";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";

interface SHRichTextEditor2Props {
  value?: string;
  required?: boolean;
  isDisabled?: boolean;
  config?: InitialConfigType;
  toolbarConfig?: ToolbarConfig;
  placeholder?: string;
  maxCharacters?: number;
  debounceTime?: number;
  onChange?: (textContent: string) => void;
  debounceAbortMethod?: "cancel" | "flush";
}

export default function SHRichTextEditor2({
  label,
  value = "",
  required = false,
  isDisabled = false,
  config = defaultEditorConfig,
  placeholder = "",
  maxCharacters = 1000,
  debounceTime = EditProductDefaults.DebounceTime,
  toolbarConfig = defaultToolbarConfig,
  onChange,
  postfixLabel,
  dotGroupProps,
  debounceAbortMethod = "cancel",
}: SHRichTextEditor2Props & SHLabelProps) {
  const [openCreateDialog, toggleOpenCreateDialog] = useToggle(false);
  const handleEditorChange = (htmlContent: string) => {
    onChange && onChange(htmlContent);
  };

  return (
    <TextEditorSharedStateProvider>
      <LexicalComposer initialConfig={config}>
        <StyledEditorContainer>
          <SHLabel
            label={label}
            disabled={isDisabled}
            postfixLabel={postfixLabel}
            required={required}
            dotGroupProps={dotGroupProps}
          />
          <SHStack
            className={"editor-container " + (isDisabled ? "disabled" : "")}
          >
            <SHStack className="editor-inner">
              <RichTextPlugin
                contentEditable={
                  <StyledContentEditable
                    aria-placeholder={placeholder}
                    placeholder={
                      <SHStack className="editor-placeholder">
                        {placeholder}
                      </SHStack>
                    }
                  />
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <ToolbarPlugin
                config={toolbarConfig}
                maxCharacters={maxCharacters}
                toggleOpenCreateDialog={toggleOpenCreateDialog}
                isDisabled={isDisabled}
              />
              <HistoryPlugin />
              <ListPlugin />
              <AutoListPlugin />
              <ReadOnlyPlugin isReadOnly={isDisabled} />
              <ValueControlPlugin
                onValueChange={handleEditorChange}
                initialValue={value}
                debounceMs={debounceTime}
                debounceAbortMethod={debounceAbortMethod}
              />
            </SHStack>
          </SHStack>
        </StyledEditorContainer>
      </LexicalComposer>
      <LexicalComposer
        initialConfig={{
          ...defaultEditorConfig,
          namespace: "text-editor-dialog",
        }}
      >
        <DialogEditorPlugin
          maxCharacters={maxCharacters}
          onClose={toggleOpenCreateDialog}
          isOpen={openCreateDialog}
          isDisabled={isDisabled}
          initialHtml={value}
        />
      </LexicalComposer>
    </TextEditorSharedStateProvider>
  );
}
