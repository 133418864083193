import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import {
  InvestmentProductModelsDTO,
  ProductModelValueDTO,
} from "@models/product/investment-product/entities/model";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { Limit100PercentageFeatures } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { tryParseJson } from "@pages/suppliers/_id/util";
import { produce } from "immer";
import { defaultTo, isEmpty, isObjectLike } from "lodash";
import { FieldPath } from "react-hook-form";

export type EditModelFieldPath = FieldPath<{
  investmentProductModels: InvestmentProductModelsDTO[];
}>;

export type FeatureProductDataPath = {
  value: EditModelFieldPath;
  publishedValue: EditModelFieldPath;
  highlightType: EditModelFieldPath;
  dataStatus: EditModelFieldPath;
  isModified: EditModelFieldPath;
  additionalValue: EditModelFieldPath;
  publishedAdditionalValue: EditModelFieldPath;
};

export type ModelPath = Omit<
  FeatureProductDataPath,
  "value" | "additionalValue" | "publishedAdditionalValue" | "publishedValue"
> & {
  id: EditModelFieldPath;
  name: EditModelFieldPath;
  sections: EditModelFieldPath;
};

export type SectionPath = Omit<ModelPath, "sections" | "id"> & {
  features: EditModelFieldPath;
};

export type SeriesPath = Omit<ModelPath, "sections" | "id"> & {
  root: EditModelFieldPath;
  models: EditModelFieldPath;
};

export type FeaturePath = Omit<ModelPath, "sections" | "id"> & {
  isBusinessMetric: EditModelFieldPath;
  hasAdditionalValue: EditModelFieldPath;
  viewingGroupSelections: EditModelFieldPath;
};

/**
 * @returns "investmentProductModels.${sectionIndex}.[Key]"
 */
export const getSeriesPath = (sectionIndex: number) => {
  const paths: SeriesPath = {
    root: `investmentProductModels.${sectionIndex}` as EditModelFieldPath,
    dataStatus:
      `investmentProductModels.${sectionIndex}.dataStatus` as EditModelFieldPath,
    highlightType:
      `investmentProductModels.${sectionIndex}.highlightType` as EditModelFieldPath,
    isModified:
      `investmentProductModels.${sectionIndex}.isModified` as EditModelFieldPath,
    name: `investmentProductModels.${sectionIndex}.name` as EditModelFieldPath,
    models:
      `investmentProductModels.${sectionIndex}.models` as EditModelFieldPath,
  };
  return paths;
};

/**
 * @returns  "investmentProductModels.${productsIndex}.models.${modelIndex}"
 */
export const initModelsFieldPath = (
  [productsIndex, modelsIndex]: [number, number] = [0, 0],
): EditModelFieldPath =>
  `investmentProductModels.${productsIndex}.models.${modelsIndex}` as EditModelFieldPath;

/**
 * @returns  "investmentProductModels.${productsIndex}.models.${modelIndex}.[key]"
 */
export const getModelPath = (selectedIndex: [number, number] = [0, 0]) => {
  const modelFieldPath = initModelsFieldPath(selectedIndex);
  const paths: ModelPath = {
    id: `${modelFieldPath}.id` as EditModelFieldPath,
    name: `${modelFieldPath}.name` as EditModelFieldPath,
    highlightType: `${modelFieldPath}.highlightType` as EditModelFieldPath,
    isModified: `${modelFieldPath}.isModified` as EditModelFieldPath,
    dataStatus: `${modelFieldPath}.dataStatus` as EditModelFieldPath,
    sections: `${modelFieldPath}.sections` as EditModelFieldPath,
  };
  return paths;
};

/**
 * @returns "investmentProductModels.${productsIndex}.models.${modelIndex}.sections.${sectionIndex}.features.${featureIndex}"
 */
export const getFeaturePaths = (
  selectedIndex: [number, number],
  sectionIndex: number,
  featureIndex: number,
) => {
  const modelFieldPath = initModelsFieldPath(selectedIndex);

  const paths: FeaturePath = {
    hasAdditionalValue:
      `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.hasAdditionalValue` as EditModelFieldPath,
    isBusinessMetric:
      `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.isBusinessMetric` as EditModelFieldPath,
    dataStatus:
      `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.dataStatus` as EditModelFieldPath,
    highlightType:
      `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.highlightType` as EditModelFieldPath,
    isModified:
      `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.isModified` as EditModelFieldPath,
    name: `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.name` as EditModelFieldPath,
    viewingGroupSelections:
      `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.viewingGroupSelections` as EditModelFieldPath,
  };
  return paths;
};

/**
 * @returns "investmentProductModels.${productsIndex}.models.${modelIndex}.sections.${sectionIndex}.features.${featureIndex}.productData.[key]"
 */
export const getFeatureProductDataPaths = (
  selectedIndex: [number, number],
  sectionIndex: number,
  featureIndex: number,
) => {
  const modelFieldPath = initModelsFieldPath(selectedIndex);

  const paths: FeatureProductDataPath = {
    value:
      `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.productData.value` as EditModelFieldPath,
    publishedValue:
      `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.productData.publishedValue` as EditModelFieldPath,
    highlightType:
      `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.productData.highlightType` as EditModelFieldPath,
    dataStatus:
      `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.productData.dataStatus` as EditModelFieldPath,
    isModified:
      `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.productData.isModified` as EditModelFieldPath,
    additionalValue:
      `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.productData.additionalValue` as EditModelFieldPath,
    publishedAdditionalValue:
      `${modelFieldPath}.sections.${sectionIndex}.features.${featureIndex}.productData.publishedAdditionalValue` as EditModelFieldPath,
  };

  return paths;
};

/**
 * @returns "investmentProductModels.${productsIndex}.models.${modelIndex}.sections.${sectionIndex}.[Key]"
 * */
export const getSectionPath = (
  selectedIndex: [number, number],
  sectionIndex: number,
) => {
  const modelFieldPath = initModelsFieldPath(selectedIndex);
  const paths: SectionPath = {
    name: `${modelFieldPath}.sections.${sectionIndex}.name` as EditModelFieldPath,
    highlightType:
      `${modelFieldPath}.sections.${sectionIndex}.highlightType` as EditModelFieldPath,
    isModified:
      `${modelFieldPath}.sections.${sectionIndex}.isModified` as EditModelFieldPath,
    dataStatus:
      `${modelFieldPath}.sections.${sectionIndex}.dataStatus` as EditModelFieldPath,
    features:
      `${modelFieldPath}.sections.${sectionIndex}.features` as EditModelFieldPath,
  };
  return paths;
};

/**
 * @description function to update composite field data, that match update deep Json object value matched with headerId
 * @returns raw json with updated value or string if it not json
 */
export const updateCompositeJsonField = (
  value: string,
  oldValue: string | null,
  headerId: string = "",
) => {
  if (isEmpty(headerId)) return value;
  const productDataValues: ProductModelValueDTO[] | string = tryParseJson(
    oldValue ?? "",
  );
  if (typeof productDataValues === "object") {
    const updatedValue = produce(productDataValues, (draft) => {
      const productDataValue = draft.find((item) => item.headerId === headerId);
      if (productDataValue) {
        productDataValue.value = value;
      }
    });
    return JSON.stringify(updatedValue);
  }
  return value;
};

export function areCompositeValuesEqual(publishedValue: string, value: string) {
  const arr1 = tryParseJson(publishedValue) as ProductModelValueDTO[];
  const arr2 = tryParseJson(value) as ProductModelValueDTO[];
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every((item1, index) => {
    const item2 = arr2[index];
    const item1Value = defaultTo(item1.value, "");
    const item2Value = defaultTo(item2.value, "");
    return item1.headerId === item2.headerId && item1Value === item2Value;
  });
}
// for specific percentage feature that has limit to 100
export const isPercentageFeatureWithinLimit = (
  feature?: InvestmentProductConfigurationFeature,
) => {
  if (!feature?.name) return false;
  return Limit100PercentageFeatures.includes(feature.name);
};
export function castToViewingGroupSelectionDTO(obj: unknown) {
  if (isObjectLike(obj)) return obj as ViewingGroupSelectionDTO[];
}
