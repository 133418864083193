import {
  SHBox,
  SHCheckbox,
  SHRadio,
  SHRadioGroup,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { SHCollapse } from "@components/design-systems/sh-collapse";
import { SHDatePicker } from "@components/design-systems/sh-date-picker";
import { SHFormControlLabel } from "@components/design-systems/sh-form-control-label";
import { NewsAndUpdatesMenuType } from "@models/news-and-updates/enums/menu";
import {
  PostPeriodValue,
  PostPeriod,
} from "@models/news-and-updates/enums/period";
import { useTheme } from "@mui/material";
import { hexToRGBA } from "@utils";
import { cloneDeep, isNil, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import {
  initialFilterState,
  initialStatusFilterForAdmin,
  initialStatusFilterForSupplier,
  newsAndUpdatesMenuSectionsConfig,
} from "./config";
import {
  NewsAndUpdatesMenuSections,
  PostFilter,
  SubSection,
} from "@models/news-and-updates/entities/filtersMenu";
import { useUserPermissions } from "@hooks/userUserPermission";
import { FilterField } from "@models/news-and-updates/enums/filterField";
import { FilterRoles } from "@models/news-and-updates/enums/filterRole";
import { useSelector } from "react-redux";
import { RootState } from "@redux/store";
import { useAppDispatch } from "@redux/store";
import { updateFilters } from "@redux/slices/news-feed";
import { NewsFeedProductDTO } from "@models/news-and-updates/entities/post";
import { FilterOptions } from "@models/news-and-updates/enums/filterOptions";
import { useNewsFeed } from "@hooks/useNewsFeed";
import {
  getDateRangeFilter,
  getFromDateFilter,
  getPeriodFilter,
  getPlatformFilters,
  getStatusFilters,
  getToDateFilter,
  getUpdatingSection,
  updateValueOfSelectedCheckbox,
  updateValueOfSelectedRadioButton,
} from "./utils";

interface NewsAndUpdatesCollapseMenuProps {
  productData: NewsFeedProductDTO[];
}
export const NewsAndUpdatesCollapseMenu = (
  props: NewsAndUpdatesCollapseMenuProps,
) => {
  const { productData } = props;
  const { palette } = useTheme();
  const {
    canViewAllFilterStatus,
    canViewOnlyPublishedFilterStatus,
    canViewSomeFilterStatus,
    isAdminGroup,
    isAdviserGroup,
    isAdviserTrialGroup,
  } = useUserPermissions();
  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([]);
  const [isShowDateRange, setIsShowDateRange] = useState<boolean>(false);

  const initialStatusFilter = isAdminGroup
    ? cloneDeep(initialStatusFilterForAdmin)
    : cloneDeep(initialStatusFilterForSupplier);
  const cloneState = cloneDeep(initialFilterState);
  cloneState.push(initialStatusFilter);

  const [filters, setFilters] = useState<PostFilter[]>(cloneState);

  const { loadPosts } = useNewsFeed();

  const [menuConfig, setMenuConfig] = useState<NewsAndUpdatesMenuSections[]>(
    newsAndUpdatesMenuSectionsConfig.filter(
      (config) =>
        (canViewSomeFilterStatus &&
          config.roles.includes(FilterRoles.Supplier)) ||
        (canViewAllFilterStatus && config.roles.includes(FilterRoles.Admin)) ||
        (canViewOnlyPublishedFilterStatus &&
          config.roles.includes(FilterRoles.SupplierSale)) ||
        (isAdviserGroup && config.roles.includes(FilterRoles.AdviserUser)),
    ),
  );

  const { postFilters } = useSelector((state: RootState) => state.newsFeed);
  const dispatch = useAppDispatch();

  // 2022/01/01
  const bottomLimitStartDate = new Date(Date.UTC(2022, 0, 1, 0, 0, 0, 0));
  const [fromDate, setFromDate] = useState<Date | null>(bottomLimitStartDate);
  const [toDate, setToDate] = useState<Date | null>(new Date());

  const handleSelectFilter = (
    section: NewsAndUpdatesMenuSections,
    subSection: SubSection,
  ) => {
    const isShowDate =
      filters
        .find((filter: PostFilter) => filter.section === FilterField.Period)
        ?.subsections?.find(
          (subsection: SubSection) => subsection.name === PostPeriod.Custom,
        )?.value ?? false;
    setIsShowDateRange(isShowDate);
    let newFilters = [...filters];
    switch (section.type) {
      case NewsAndUpdatesMenuType.RadioButton:
        newFilters = updateValueOfSelectedRadioButton(
          newFilters,
          subSection.name,
          section,
        );
        setIsShowDateRange(
          section.field === FilterField.Period &&
            subSection.name === PostPeriod.Custom,
        );
        break;
      case NewsAndUpdatesMenuType.CheckBox:
        let updateSection = getUpdatingSection(newFilters, section);
        updateValueOfSelectedCheckbox(updateSection, subSection);
        break;
      default:
        break;
    }
    setFilters(newFilters);
    getFilterValues(newFilters);
  };

  useEffect(() => {
    const clonedMenuConfig = cloneDeep(menuConfig);

    //Set data for Platform section in menu bar
    let platformSectionConfig: NewsAndUpdatesMenuSections | undefined =
      clonedMenuConfig.find(
        (section: NewsAndUpdatesMenuSections) =>
          section.field === FilterField.Platforms,
      );
    if (!platformSectionConfig) return;

    const productNameList: SubSection[] = productData?.map(
      (product: NewsFeedProductDTO) => {
        const subSection: SubSection = {
          name: product.name,
          key: product.id ?? "",
          value: true,
        };
        return subSection;
      },
    );
    const optionAll: SubSection = {
      name: FilterOptions[FilterOptions.All],
      key: FilterOptions.All.toString(),
      value: true,
    };
    platformSectionConfig.subSections = [optionAll, ...productNameList];
    setMenuConfig(clonedMenuConfig);

    //Set post filter data
    const subSections: SubSection[] = platformSectionConfig.subSections.map(
      (subSectionItem: SubSection) => {
        let result: SubSection = {
          name: subSectionItem.name,
          key: subSectionItem.key,
          value: subSectionItem.value,
        };
        return result;
      },
    );
    let newFilterValue = [...filters];
    const platform = newFilterValue.find(
      (filter) => filter.section === FilterField.Platforms,
    );
    if (isNil(platform)) {
      const platformFilter: PostFilter = {
        section: FilterField.Platforms,
        subsections: subSections,
      };
      newFilterValue.push(platformFilter);
    } else {
      newFilterValue.forEach((filter) => {
        if (filter.section === FilterField.Platforms) {
          filter.subsections = subSections;
        }
      });
    }

    setFilters(newFilterValue);
    //eslint-disable-next-line
  }, [productData]);

  const handleOnChangeFromDate = (newValue: Date | null) => {
    setFromDate(newValue);
    const date = getFromDateFilter(newValue, toDate);
    if (!isNull(date)) {
      getFilterValues(filters, date);
    }
  };

  const handleOnChangeToDate = (newValue: Date | null) => {
    setToDate(newValue);
    const date = getToDateFilter(newValue);
    if (!isNull(date)) {
      getFilterValues(filters, undefined, date);
    }
  };

  const getFilterValues = (
    filterValues: PostFilter[],
    startDate?: Date | null,
    endDate?: Date | null,
  ) => {
    let canDispatch = true;
    let monthsOffset = getPeriodFilter(filterValues);
    let dateRange = null;
    startDate = startDate ?? fromDate;
    endDate = endDate ?? toDate;
    if (monthsOffset === PostPeriodValue.Custom) {
      dateRange = getDateRangeFilter(startDate, endDate);
      canDispatch = !isNull(dateRange);
    }
    const statuses = getStatusFilters(filterValues);
    const platforms = getPlatformFilters(filterValues);
    const newFilterValue = {
      ...postFilters,
      monthsOffset,
      statuses,
      dateRange,
      productIds: platforms,
    };
    if (canDispatch) {
      dispatch(updateFilters(newFilterValue));
      loadPosts(newFilterValue);
    }
  };

  const isDisabledFilter = isAdviserTrialGroup;

  return (
    <SHStack sx={{ paddingBottom: 3 }}>
      {menuConfig?.map((section: NewsAndUpdatesMenuSections, sectionIndex) => {
        const isExpanded = expandedIndexes.includes(sectionIndex);
        return (
          <SHCollapse
            key={sectionIndex}
            title={
              <SHStack direction="row" spacing={0.5} alignItems="start">
                <SHTypography variant="subtitle1">{section.name}</SHTypography>
              </SHStack>
            }
            open={isExpanded}
            onCollapse={(open) => {
              setExpandedIndexes(
                open
                  ? [...expandedIndexes, sectionIndex]
                  : [...expandedIndexes].filter((id) => id !== sectionIndex),
              );
            }}
            width={"100%"}
            height="auto"
            sx={{
              padding: "20px 20px 20px 10px",
              cursor: "pointer",
              bgcolor: isExpanded ? palette.common.white : "transparent",
              borderColor: (palette.secondary as any)[100],
              borderBottomStyle: "solid",
              borderBottomWidth: isExpanded ? 0 : 1,
            }}
          >
            {/* Render sub sections with type checkbox */}
            {section.type === NewsAndUpdatesMenuType.CheckBox && (
              <SHStack defaultValue={0}>
                {section.subSections?.map((subSection, subSectionIndex) => {
                  return (
                    <SHStack
                      key={subSectionIndex}
                      sx={{
                        padding: "5px 15px",
                        cursor: "pointer",
                        bgcolor: "transparent",
                        "&:hover": {
                          bgcolor: hexToRGBA(palette.common.white, 0.5),
                        },
                        pointerEvents: isDisabledFilter ? "none" : "auto",
                      }}
                      direction="row"
                      alignItems="start"
                      onClick={() => handleSelectFilter(section, subSection)}
                    >
                      <SHFormControlLabel
                        disabled={isDisabledFilter}
                        onClick={(e) => {
                          e.preventDefault();
                        }}
                        size={"small"}
                        control={
                          <SHCheckbox
                            onClick={(e) => { 
                              e.preventDefault();
                            }}
                            size={"small"}
                          />
                        }
                        label={subSection.name}
                        checked={
                          filters
                            ?.find(
                              (filter: PostFilter) =>
                                filter.section === section.field,
                            )
                            ?.subsections.find(
                              (subSectionItem: SubSection) =>
                                subSectionItem.key === subSection.key,
                            )?.value
                        }
                      />
                    </SHStack>
                  );
                })}
              </SHStack>
            )}

            {/* Render sub sections with type radio button */}
            {section.type === NewsAndUpdatesMenuType.RadioButton && (
              <SHRadioGroup defaultValue={0}>
                {section.subSections?.map((subSection, subSectionIndex) => {
                  return (
                    <React.Fragment key={subSectionIndex}>
                      <SHStack
                        sx={{
                          padding: "5px 17px",
                          cursor: "pointer",
                          bgcolor: "transparent",
                          "&:hover": {
                            bgcolor: hexToRGBA(palette.common.white, 0.5),
                          },
                          pointerEvents: isDisabledFilter ? "none" : "auto",
                        }}
                        direction="row"
                        alignItems="start"
                        onClick={() => handleSelectFilter(section, subSection)}
                      >
                        <SHFormControlLabel
                          disabled={isDisabledFilter}
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                          value={subSectionIndex}
                          size={"small"}
                          control={
                            <SHRadio
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                              size={"small"}
                            />
                          }
                          label={subSection.name}
                          checked={
                            filters
                              .find(
                                (filter: PostFilter) =>
                                  filter.section === section.field,
                              )
                              ?.subsections.find(
                                (subSectionItem: SubSection) =>
                                  subSectionItem.name === subSection.name,
                              )?.value
                          }
                        />
                      </SHStack>

                      {/* Render date range if period - custom is selected */}
                      {section.field === "period" &&
                        subSection.name === PostPeriod.Custom &&
                        isShowDateRange && (
                          <SHStack paddingX={"12px"} paddingBottom={"15px"}>
                            <SHStack direction={"row"} alignItems={"center"}>
                              <SHDatePicker
                                maxDate={
                                  toDate && toDate < new Date()
                                    ? toDate
                                    : new Date()
                                }
                                minDate={new Date("1/1/2022")}
                                views={["year", "month"]}
                                inputFormat="MMM yyyy"
                                value={fromDate}
                                sxTextField={{
                                  "&>.MuiInput-root>input": {
                                    padding: "8px 5px 5px 5px",
                                  },
                                  width: "118px",
                                }}
                                textFieldProps={{
                                  error:
                                    !fromDate ||
                                    !getFromDateFilter(fromDate, toDate),
                                }}
                                onChange={(newValue: Date | null) => {
                                  handleOnChangeFromDate(newValue);
                                }}
                              />
                              <SHBox textAlign={"center"} paddingX={"5px"}>
                                -
                              </SHBox>
                              <SHDatePicker
                                maxDate={new Date()}
                                minDate={
                                  fromDate ? fromDate : new Date("1/1/2022")
                                }
                                views={["year", "month"]}
                                inputFormat="MMM yyyy"
                                value={toDate}
                                sxTextField={{
                                  "&>.MuiInput-root>input": {
                                    padding: "8px 5px 5px 5px",
                                  },
                                  width: "118px",
                                }}
                                textFieldProps={{
                                  error: !toDate || !getToDateFilter(toDate),
                                }}
                                onChange={(newValue: Date | null) => {
                                  handleOnChangeToDate(newValue);
                                }}
                              />
                            </SHStack>
                          </SHStack>
                        )}
                    </React.Fragment>
                  );
                })}
              </SHRadioGroup>
            )}
          </SHCollapse>
        );
      })}
    </SHStack>
  );
};
