import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";
import { SHDividerSkeleton } from "@components/skeletons/design-systems";
import { useTheme } from "@mui/material";
import React from "react";

export const SeriesTabSkeleton = () => {
  const { palette } = useTheme();

  return (
    <SHStack direction={"row"}>
      {/* Left Section Skeleton */}
      <SHStack
        width={"25%"}
        spacing={0.3}
        sx={{
          borderRight: 1,
          borderColor: palette.common.black,
        }}
      >
        {Array.from(Array(5).keys()).map((index) => (
          <SHSkeleton key={index} width={"100%"} height="80px" delay={false} />
        ))}
      </SHStack>
      <SHStack width={"75%"} sx={{ pl: "25px", py: "25px" }} spacing={2}>
        <SHStack spacing={1}>
          <SHSkeleton width={"300px"} height="40px" delay={false} />
          <SHSkeleton width={"25%"} height="20px" delay={false} />
        </SHStack>
        <SHDividerSkeleton delay={false} />
        {Array.from(Array(2).keys()).map((index) => (
          <React.Fragment key={index}>
            <SHStack spacing={1}>
              <SHSkeleton width="70%" height="40px" delay={false} />
              <SHSkeleton width="100%" height="80px" delay={false} />
              <SHSkeleton width="100%" height="120px" delay={false} />
            </SHStack>
            {/* Conditionally render divider except for the last iteration */}
            {index < 1 && <SHDividerSkeleton delay={false} />}
          </React.Fragment>
        ))}
      </SHStack>
    </SHStack>
  );
};
