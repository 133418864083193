import { DisclaimerAssumptionsButton } from "@components/buttons/disclaimer-assumptions";
import {
  SHAlert,
  SHBox,
  SHContainer,
  SHDivider,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { FeeCalculatorDialog } from "@components/dialogs/fee-calculator";
import { WarningSVG } from "@components/svgs/warning";
import { yupResolver } from "@hookform/resolvers/yup";
import { useReview } from "@hooks/useReview";
import { FieldGroup } from "@layouts/form/field-group";
import { StepCompProps, StepCompRef } from "@layouts/stepper";
import { StepName } from "@layouts/stepper/step-name";
import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import {
  AnalysisFeeCalculatorDTO,
  FamilyMemberDTO,
  FeeCalculatorDTO,
} from "@models/platform-analysis/entities/steps/fee";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { AnalysisSubStep } from "@models/platform-analysis/enums/subStep";
import { AnalysisVariable } from "@models/platform-analysis/enums/variableName";
import { ReviewGetFeeCalculatorDTO } from "@models/reviews/entities/steps/fee";
import { ReviewStep } from "@models/reviews/enums/step";
import { Collapse } from "@mui/material";
import { CalculatorField } from "@pages/platform-analysis/_id/steps/fee/calculator";
import { FamilyCard } from "@pages/platform-analysis/_id/steps/fee/calculator/components/family-card";
import { FamilyCardTotal } from "@pages/platform-analysis/_id/steps/fee/calculator/components/family-card-total";
import {
  ChooseOptions,
  MaxTotalAccount,
  MinAccountBalance,
  clearDataConditions,
} from "@pages/platform-analysis/_id/steps/fee/calculator/config";
import { AnalysisFeeCalculatorSkeleton } from "@pages/platform-analysis/_id/steps/fee/calculator/skeleton";
import {
  calculateTotals,
  feeCalculatorValidators,
  getIndexMapping,
} from "@pages/platform-analysis/_id/steps/fee/calculator/util";
import { NoBalancesDialog } from "@pages/platform-analysis/components/dialogs/no-balances";
import { RootState } from "@redux/store";
import {
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FieldPath, useFieldArray, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { TransitionGroup } from "react-transition-group";
import { useEffectOnce } from "react-use";
import { checkHasEmptyBalance } from "./util";
import { round } from "lodash";

export interface ReviewFeeCalculatorStepProps extends StepCompProps {}
export const ReviewFeeCalculatorStep: ForwardRefRenderFunction<
  StepCompRef,
  ReviewFeeCalculatorStepProps
> = ({ stepNumber }: ReviewFeeCalculatorStepProps, ref) => {
  const { reviewId } = useParams<{ reviewId: string }>();

  const {
    ui: { isLoading, isSubmitting, isValid, isDirty },
    isReadOnly,
    feeCalculator,
  } = useSelector((state: RootState) => state.review);

  const {
    setIsDirty,
    setIsValid,
    setReviewFeeCalculators,
    loadReviewFeeCalculators,
    loadReviewDisclaimers,
    updateReviewFeeCalculators,
  } = useReview();

  const {
    reset,
    getValues,
    watch,
    control,
    setValue,
    formState: { isValid: formValid, isDirty: formDirty },
  } = useForm({
    mode: "onChange",
    defaultValues: { ...new ReviewGetFeeCalculatorDTO() },
    resolver: yupResolver(feeCalculatorValidators),
  });
  const { fields: familyMembersFields } = useFieldArray({
    control,
    name: "familyMembers",
    keyName: "fieldId",
  });

  const [fetchedData, setFetchedData] = useState(false);
  const [disclaimers, setDisclaimers] = useState<DisclaimerDTO[]>();
  const [confirmDialog, setConfirmDialog] = useState<
    | {
        callback: (value: boolean | PromiseLike<boolean>) => void;
      }
    | undefined
  >(undefined);

  const indexMapping = getIndexMapping(watch("feeCalculators") || []);

  const nameOf = (variableName: AnalysisVariable) => {
    const field = `feeCalculators.${indexMapping.get(
      variableName,
    )}` as FieldPath<AnalysisFeeCalculatorDTO>;
    const value =
      `${field}.feeCalculatorData.value` as FieldPath<AnalysisFeeCalculatorDTO>;

    return { field, value };
  };

  const watchField = (name: AnalysisVariable) =>
    watch(nameOf(name).field) as FeeCalculatorDTO;
  const watchNumberValue = (name: AnalysisVariable) => {
    const strValue = watch(nameOf(name).value);
    if (!strValue) return 0;
    try {
      return Number(strValue);
    } catch (err) {
      return 0;
    }
  };
  const watchBooleanValue = (name: AnalysisVariable) =>
    watch(nameOf(name).value) === "true" || false;

  const isChooseMF = watchBooleanValue(AnalysisVariable.Choose_MF);
  const isChooseAU = watchBooleanValue(AnalysisVariable.Choose_AU);
  const isChooseIntl = watchBooleanValue(AnalysisVariable.Choose_Intl);
  const isChooseMA = watchBooleanValue(AnalysisVariable.Choose_MA);
  const isChooseRetIns = watchBooleanValue(AnalysisVariable.Choose_ret_ins);
  const isChooseBond = watchBooleanValue(AnalysisVariable.Choose_bond);

  const isShowHoldingsNumber = watch("isShowHoldingsNumber");

  const [showFeeDialog, setShowFeeDialog] = useState(false);
  const calculatedTotal = calculateTotals(watch("familyMembers"));

  const isValidAccountBalance = calculatedTotal.total > MinAccountBalance;
  const isValidAccountCount = calculatedTotal.account <= MaxTotalAccount;

  const hasIDPS = calculatedTotal.idps > 0;
  const hasSuper = calculatedTotal.super > 0;
  const hasRecalculate = feeCalculator?.hasRecalculate ?? false;

  const idpsCashBalance = hasIDPS
    ? watchNumberValue(AnalysisVariable.IDPS_Cash_balance)
    : 0;
  const superCashBalance = hasSuper
    ? watchNumberValue(AnalysisVariable.Super_Cash_balance)
    : 0;
  const mfBalanceI = isChooseMF
    ? watchNumberValue(AnalysisVariable.MF_balance_i)
    : 0;
  const auBalanceI = isChooseAU
    ? watchNumberValue(AnalysisVariable.AU_balance_i)
    : 0;
  const intlBalanceI = isChooseIntl
    ? watchNumberValue(AnalysisVariable.Intl_balance_i)
    : 0;
  const maBalanceI = isChooseMA
    ? watchNumberValue(AnalysisVariable.MA_balance_i)
    : 0;
  const bondBalanceI = isChooseBond
    ? watchNumberValue(AnalysisVariable.Bond_balance_i)
    : 0;

  const totalBalanceInvestmentClassSection =
    idpsCashBalance +
    superCashBalance +
    mfBalanceI +
    auBalanceI +
    intlBalanceI +
    maBalanceI +
    bondBalanceI;

  const isValidTotal =
    round(totalBalanceInvestmentClassSection, 2) <=
    round(calculatedTotal.total, 2);

  const isValidIDPS =
    idpsCashBalance === 0 || idpsCashBalance < calculatedTotal.idps;
  const isValidSuper =
    superCashBalance === 0 || superCashBalance < calculatedTotal.super;

  const isDisabled = isSubmitting || isLoading || isReadOnly;

  const showInvestmentClassSection =
    hasIDPS ||
    hasSuper ||
    isChooseMF ||
    isChooseMA ||
    isChooseAU ||
    isChooseIntl ||
    isChooseBond;
  const showTransactionOutsideSection =
    isChooseMF || isChooseAU || isChooseIntl || isChooseBond;
  const showTransactionWithinSection = isChooseMA;

  const getConfirm = async (): Promise<boolean> => {
    return await new Promise((res) => {
      setConfirmDialog({ callback: res });
    });
  };

  const handleOnSubmit = async () => {
    if (!reviewId) return false;
    const feeCalculators = getValues();
    const isHasEmptyBalance = checkHasEmptyBalance(
      feeCalculators.familyMembers,
    );
    if (isHasEmptyBalance) {
      const isContinue = await getConfirm();
      setConfirmDialog(undefined);
      if (!isContinue) {
        return false;
      }
    }

    const isSuccess = await updateReviewFeeCalculators({
      reviewId: reviewId,
      feeCalculators: feeCalculators,
    });
    if (isSuccess) {
      let clone = { ...feeCalculators };
      reset(clone);
      if (feeCalculator) {
        setReviewFeeCalculators({ ...feeCalculator, hasRecalculate: false });
      }
    }
    return isSuccess;
  };

  const handleOnChangeFamilyEntity = (
    index: number,
    state: FamilyMemberDTO,
  ) => {
    let entity = { ...state };
    setValue(`familyMembers.${index}`, entity, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  useImperativeHandle(ref, () => ({
    onChangeStep: async (newStep) => {
      if (isLoading) return true;
      const isBack = newStep?.key === AnalysisStep.BusinessMetric;
      const isNext = newStep?.key === AnalysisSubStep.Analysis;
      const shouldSubmit = ((isBack && isValid) || isNext) && isDirty;

      if (!shouldSubmit) return true;

      return await handleOnSubmit();
    },
    onChangeOtherStep: async () => {
      if (isLoading) return true;
      const shouldSubmit = isValid && isDirty;

      if (!shouldSubmit) return true;

      return await handleOnSubmit();
    },
    onSubmit: async () => {
      return await handleOnSubmit();
    },
  }));

  useEffect(() => {
    setIsDirty(formDirty || hasRecalculate);
    // eslint-disable-next-line
  }, [formDirty, hasRecalculate]);

  useEffect(() => {
    setIsValid(
      formValid &&
        isValidAccountBalance &&
        isValidAccountCount &&
        isValidTotal &&
        isValidIDPS &&
        isValidSuper,
    );
    // eslint-disable-next-line
  }, [
    formValid,
    isValidAccountBalance,
    isValidAccountCount,
    isValidTotal,
    isValidIDPS,
    isValidSuper,
  ]);

  useEffect(() => {
    if (!hasIDPS) {
      setValue(nameOf(AnalysisVariable.IDPS_Cash_balance).value, "0", {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line
  }, [hasIDPS]);

  useEffect(() => {
    if (!hasSuper) {
      setValue(nameOf(AnalysisVariable.Super_Cash_balance).value, "0", {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line
  }, [hasSuper]);

  useEffectOnce(() => {
    return () => {
      setIsValid(true);
      setIsDirty(false);
    };
  });

  // TODO: Load form ReviewFeeDisclaimers API
  const loadDisclaimers = async () => {
    if (reviewId && reviewId !== "new") {
      const disclaimers = await loadReviewDisclaimers(
        reviewId ?? "",
        ReviewStep.Fee,
      );
      setDisclaimers(disclaimers);
    }
  };

  const loadFeeCalculators = async (reviewId?: string) => {
    if (!reviewId || reviewId === "new") return;

    setFetchedData(false);
    const feeCalculator = await loadReviewFeeCalculators({
      reviewId: reviewId,
    });
    setFetchedData(true);

    if (!feeCalculator) return;

    if (!feeCalculator.familyMembers.length) {
      //Add a family member when in new mode
      return reset({
        ...feeCalculator,
        familyMembers: [new FamilyMemberDTO()],
      });
    }
    reset(feeCalculator);
  };

  useEffect(() => {
    loadDisclaimers();
    loadFeeCalculators(reviewId);
    // eslint-disable-next-line
  }, [reviewId]);

  if (isLoading) return <AnalysisFeeCalculatorSkeleton />;

  return (
    <form>
      <SHContainer sx={{ px: { xs: "16px", lg: 0 }, py: "25px" }}>
        <StepName name={"Fee calculator"} stepNumber={stepNumber} />
        <SHStack>
          <SHStack spacing={"15px"} py="25px">
            <SHTypography variant="subtitle1">Welcome! </SHTypography>
            <SHTypography variant="body4">
              You can fill as much or as little of the fee calculator as you
              like. We will use the information you provide to simulate an
              estimated cost for each platform. Most fields are not compulsory,
              but the more you fill out, the more comprehensive our fee
              estimates will be.
            </SHTypography>
            <SHTypography variant="body4" fontSize={"10px"}>
              Any result provided by this calculator is illustrative only. It
              relies upon information and assumptions that are subject to
              change. If varied, this information and assumptions may change the
              result. Any result is not intended to be relied on for the purpose
              of making a decision about a particular financial product,
              platform or provider.
            </SHTypography>
            <SHTypography variant="body4" fontSize={"10px"}>
              {`By using this calculator, you confirm that you have read and
              accepted the `}
              <DisclaimerAssumptionsButton
                onClick={() => setShowFeeDialog(true)}
              />
            </SHTypography>
          </SHStack>
          <SHDivider />
          <FieldGroup
            title="What do you expect the average holding balance of each account will be for the next 12 months? (Maximum 6 accounts)"
            subTitle={`<p>Please enter the approximate value, and consider any deposits and withdrawals you expect in the coming year</p><br/><p>Note: The fee calculator currently supports a maximum of 6 accounts</p>`}
            maxWidth={490}
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <TransitionGroup
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  gap: "25px",
                  width: "100%",
                }}
              >
                {familyMembersFields.map((entity, index) => (
                  <Collapse key={entity.id}>
                    <FamilyCard
                      order={index + 1}
                      disabled={isDisabled}
                      canRemove={false}
                      familyMember={watch(`familyMembers.${index}`)}
                      disabledAddButton={!isValidAccountCount}
                      onChange={(state) =>
                        handleOnChangeFamilyEntity(index, state)
                      }
                    />
                  </Collapse>
                ))}
              </TransitionGroup>
              <SHStack spacing={"5px"}>
                <Collapse in={!isValidAccountBalance && fetchedData}>
                  <SHAlert
                    severity="error"
                    textAlign={"left"}
                    icon={<WarningSVG />}
                  >
                    At least one account must have a balance
                  </SHAlert>
                </Collapse>
                <Collapse in={!isValidAccountCount && fetchedData}>
                  <SHAlert
                    severity="error"
                    textAlign={"left"}
                    icon={<WarningSVG />}
                  >
                    Maximum 6 accounts supported. Please remove one
                  </SHAlert>
                </Collapse>
                <FamilyCardTotal
                  title={"Total value of investments and super"}
                  content={
                    <>
                      $
                      <NumericFormat
                        displayType="text"
                        value={calculatedTotal.total}
                        thousandSeparator=","
                        decimalScale={2}
                      />
                    </>
                  }
                />
              </SHStack>
            </SHStack>
          </FieldGroup>
          <SHDivider />
          <FieldGroup
            title="Which of the following will be held through the platform?"
            subTitle={
              "Please select all the different solutions you expect your client(s) to hold. This will determine which fees we include in our modelling"
            }
            maxWidth={490}
          >
            <SHStack
              spacing={3}
              direction="column"
              sx={{ width: { xs: "100%", md: 520 } }}
            >
              <SHBox>
                {ChooseOptions.map((variableName) => {
                  const field = watchField(variableName);
                  return (
                    <CalculatorField
                      key={variableName}
                      control={control}
                      feeCalculator={field}
                      name={nameOf(variableName).value}
                      disabled={isDisabled}
                      onChange={(value) => {
                        if (value) return;
                        clearDataConditions
                          .get(variableName)
                          ?.forEach((name) => {
                            setValue(nameOf(name).value, "0", {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                          });
                      }}
                    />
                  );
                })}
              </SHBox>
            </SHStack>
          </FieldGroup>
          <SHDivider />
          {showInvestmentClassSection && (
            <FieldGroup
              title="Across all accounts, approximately how much do you expect will be held in each investment class?"
              subTitle={`<p>
              Please estimate the average total holding size for the next 12
              months
            </p>
            <br />
            <p>
              For non-managed account options, specify the amount that will
              be held <strong><u>outside</u></strong> managed accounts only. For example, if there
              are managed funds held both outside and inside managed
              accounts, in the ‘Managed funds’ field only enter the value
              that is held outside managed accounts
            </p>
            <br />
            <p>
              We will pro rata the fees charged for holding the non-cash
              investments across your client's IDPS and Super accounts. If
              you leave these fields blank, we will not include the cost of
              holding these investments in our fee estimates
            </p>`}
              maxWidth={490}
            >
              <SHStack
                direction="row"
                rowGap={3}
                columnGap={2}
                flexWrap="wrap"
                sx={{ width: { xs: "100%", md: 520 } }}
              >
                {hasIDPS && (
                  <CalculatorField
                    control={control}
                    disabled={isDisabled}
                    feeCalculator={watchField(
                      AnalysisVariable.IDPS_Cash_balance,
                    )}
                    name={nameOf(AnalysisVariable.IDPS_Cash_balance).value}
                  />
                )}
                {hasSuper && (
                  <CalculatorField
                    control={control}
                    disabled={isDisabled}
                    feeCalculator={watchField(
                      AnalysisVariable.Super_Cash_balance,
                    )}
                    name={nameOf(AnalysisVariable.Super_Cash_balance).value}
                  />
                )}
                {isChooseMF && (
                  <CalculatorField
                    control={control}
                    disabled={isDisabled}
                    feeCalculator={watchField(AnalysisVariable.MF_balance_i)}
                    name={nameOf(AnalysisVariable.MF_balance_i).value}
                  />
                )}
                {isChooseMA && (
                  <CalculatorField
                    control={control}
                    disabled={isDisabled}
                    feeCalculator={watchField(AnalysisVariable.MA_balance_i)}
                    name={nameOf(AnalysisVariable.MA_balance_i).value}
                  />
                )}
                {isChooseAU && (
                  <CalculatorField
                    control={control}
                    disabled={isDisabled}
                    feeCalculator={watchField(AnalysisVariable.AU_balance_i)}
                    name={nameOf(AnalysisVariable.AU_balance_i).value}
                  />
                )}
                {isChooseIntl && (
                  <CalculatorField
                    control={control}
                    disabled={isDisabled}
                    feeCalculator={watchField(AnalysisVariable.Intl_balance_i)}
                    name={nameOf(AnalysisVariable.Intl_balance_i).value}
                  />
                )}
                {isChooseBond && (
                  <CalculatorField
                    control={control}
                    disabled={isDisabled}
                    feeCalculator={watchField(AnalysisVariable.Bond_balance_i)}
                    name={nameOf(AnalysisVariable.Bond_balance_i).value}
                  />
                )}
                <SHStack spacing={"5px"} width="100%">
                  <Collapse in={!isValidTotal && fetchedData}>
                    <SHAlert
                      severity="error"
                      textAlign={"left"}
                      icon={<WarningSVG />}
                    >
                      Total balance in this section exceeds the total account
                      balances specified above
                    </SHAlert>
                  </Collapse>
                  <Collapse in={!isValidIDPS && fetchedData}>
                    <SHAlert
                      severity="error"
                      textAlign={"left"}
                      icon={<WarningSVG />}
                    >
                      Cash balance for IDPS accounts must be smaller than the
                      total balance of IDPS accounts specified above
                    </SHAlert>
                  </Collapse>
                  <Collapse in={!isValidSuper && fetchedData}>
                    <SHAlert
                      severity="error"
                      textAlign={"left"}
                      icon={<WarningSVG />}
                    >
                      Cash balance for Super/Pension accounts must be smaller
                      than the total balance of Super/Pension accounts specified
                      above
                    </SHAlert>
                  </Collapse>
                  <FamilyCardTotal
                    title={"Total allocation"}
                    content={
                      <>
                        {"$"}
                        <NumericFormat
                          displayType="text"
                          value={totalBalanceInvestmentClassSection}
                          thousandSeparator=","
                          decimalScale={2}
                        />
                        {" / $"}
                        <NumericFormat
                          displayType="text"
                          value={calculatedTotal.total}
                          thousandSeparator=","
                          decimalScale={2}
                        />
                      </>
                    }
                  />
                </SHStack>
              </SHStack>
            </FieldGroup>
          )}
          <SHDivider />
          {/* Only show the section if Macquarie Wrap is included in the analysis */}
          {isShowHoldingsNumber && (
            <FieldGroup
              title="How many different funds, listed investments and managed account models will be held across all accounts?"
              subTitle="Please estimate the total number of holdings. For example, if you have a portfolio with eight managed accounts plus shares in one company, the value will be 9. The figure you provide will affect the fee tiering used by some platforms. If you leave these fields blank, some admin fees may appear slightly lower than they actually are"
              maxWidth={490}
            >
              <SHStack
                direction="row"
                rowGap={3}
                columnGap={2}
                flexWrap="wrap"
                sx={{ width: { xs: "100%", md: 520 } }}
              >
                <CalculatorField
                  control={control}
                  disabled={isDisabled}
                  feeCalculator={watchField(AnalysisVariable.Holdings_number)}
                  name={nameOf(AnalysisVariable.Holdings_number).value}
                />
              </SHStack>
            </FieldGroup>
          )}

          {showTransactionOutsideSection && (
            <>
              <SHDivider />
              <FieldGroup
                title={`In the next 12 months, what is the total number of transactions you expect <u>outside</u> SMAs/MDAs, and what is your anticipated average transaction size?`}
                subTitle={`<p>Please count all transactions that you expect will happen <u>outside</u> the managed account structure. This includes any ad hoc trades you place plus any transactions from automatic buy, sell and rebalancing facilities. We will use this to estimate the transaction fees charged. If you leave these fields blank, we will not include transaction fees in our fee estimates</p>`}
                maxWidth={490}
              >
                <SHStack
                  spacing={3}
                  direction="column"
                  sx={{ width: { xs: "100%", md: 520 } }}
                >
                  {isChooseMF && (
                    <SHStack
                      spacing={2}
                      direction="row"
                      alignItems={"flex-end"}
                    >
                      <CalculatorField
                        control={control}
                        disabled={isDisabled}
                        feeCalculator={watchField(
                          AnalysisVariable.MF_transactions,
                        )}
                        name={nameOf(AnalysisVariable.MF_transactions).value}
                      />
                      <CalculatorField
                        control={control}
                        disabled={isDisabled}
                        feeCalculator={watchField(AnalysisVariable.MF_avg)}
                        name={nameOf(AnalysisVariable.MF_avg).value}
                      />
                    </SHStack>
                  )}
                  {isChooseAU && (
                    <SHStack
                      spacing={2}
                      direction="row"
                      alignItems={"flex-end"}
                    >
                      <CalculatorField
                        control={control}
                        disabled={isDisabled}
                        feeCalculator={watchField(
                          AnalysisVariable.AU_transactions,
                        )}
                        name={nameOf(AnalysisVariable.AU_transactions).value}
                      />
                      <CalculatorField
                        control={control}
                        disabled={isDisabled}
                        feeCalculator={watchField(AnalysisVariable.AU_avg)}
                        name={nameOf(AnalysisVariable.AU_avg).value}
                      />
                    </SHStack>
                  )}
                  {isChooseIntl && (
                    <SHStack
                      spacing={2}
                      direction="row"
                      alignItems={"flex-end"}
                    >
                      <CalculatorField
                        control={control}
                        disabled={isDisabled}
                        feeCalculator={watchField(
                          AnalysisVariable.Intl_transactions,
                        )}
                        name={nameOf(AnalysisVariable.Intl_transactions).value}
                      />
                      <CalculatorField
                        control={control}
                        disabled={isDisabled}
                        feeCalculator={watchField(AnalysisVariable.Intl_avg)}
                        name={nameOf(AnalysisVariable.Intl_avg).value}
                      />
                    </SHStack>
                  )}
                  {isChooseBond && (
                    <SHStack
                      spacing={2}
                      direction="row"
                      alignItems={"flex-end"}
                    >
                      <CalculatorField
                        control={control}
                        disabled={isDisabled}
                        feeCalculator={watchField(
                          AnalysisVariable.Bond_transactions,
                        )}
                        name={nameOf(AnalysisVariable.Bond_transactions).value}
                      />
                      <CalculatorField
                        control={control}
                        disabled={isDisabled}
                        feeCalculator={watchField(AnalysisVariable.Bond_avg)}
                        name={nameOf(AnalysisVariable.Bond_avg).value}
                      />
                    </SHStack>
                  )}
                </SHStack>
              </FieldGroup>
            </>
          )}
          {showTransactionWithinSection && (
            <>
              <SHDivider />
              <FieldGroup
                title={`<p>In the next 12 months, what is the total number of transactions you expect <u>within</u> the SMAs/MDAs your client holds, and what is your anticipated average transaction size?</p>`}
                subTitle={`<p>Please count all transactions that you expect will happen <u>within</u> the managed account structure. Most platforms have a different fee for transactions that occur within managed accounts than for those outside managed accounts. This can be difficult for you to estimate, as each manager varies in their trading activity. If you leave these fields blank, we will not include managed account transaction fees in our fee estimates</p>`}
                maxWidth={490}
              >
                <SHStack
                  spacing={3}
                  direction="column"
                  sx={{ width: { xs: "100%", md: 520 } }}
                >
                  <SHStack spacing={2} direction="row" alignItems={"flex-end"}>
                    <CalculatorField
                      control={control}
                      disabled={isDisabled}
                      feeCalculator={watchField(
                        AnalysisVariable.MA_MF_transactions,
                      )}
                      name={nameOf(AnalysisVariable.MA_MF_transactions).value}
                    />
                    <CalculatorField
                      control={control}
                      disabled={isDisabled}
                      feeCalculator={watchField(AnalysisVariable.MA_MF_avg)}
                      name={nameOf(AnalysisVariable.MA_MF_avg).value}
                    />
                  </SHStack>
                  <SHStack spacing={2} direction="row" alignItems={"flex-end"}>
                    <CalculatorField
                      control={control}
                      disabled={isDisabled}
                      feeCalculator={watchField(
                        AnalysisVariable.MA_AU_transactions,
                      )}
                      name={nameOf(AnalysisVariable.MA_AU_transactions).value}
                    />
                    <CalculatorField
                      control={control}
                      disabled={isDisabled}
                      feeCalculator={watchField(AnalysisVariable.MA_AU_avg)}
                      name={nameOf(AnalysisVariable.MA_AU_avg).value}
                    />
                  </SHStack>
                  <SHStack spacing={2} direction="row" alignItems={"flex-end"}>
                    <CalculatorField
                      control={control}
                      disabled={isDisabled}
                      feeCalculator={watchField(
                        AnalysisVariable.MA_Intl_transactions,
                      )}
                      name={nameOf(AnalysisVariable.MA_Intl_transactions).value}
                    />
                    <CalculatorField
                      control={control}
                      disabled={isDisabled}
                      feeCalculator={watchField(AnalysisVariable.MA_Intl_avg)}
                      name={nameOf(AnalysisVariable.MA_Intl_avg).value}
                    />
                  </SHStack>
                </SHStack>
              </FieldGroup>
            </>
          )}
          {isChooseRetIns && (hasIDPS || hasSuper) && (
            <>
              <SHDivider />
              <FieldGroup
                title="The retail insurance policies that will be held through platform accounts:"
                subTitle="Some platforms charge an admin fee for holding insurance. If you leave these fields blank, we will not include the admin fees on insurance in our fee estimates"
                maxWidth={490}
              >
                <SHStack
                  spacing={3}
                  direction="column"
                  sx={{ width: { xs: "100%", md: 520 } }}
                >
                  {hasIDPS && (
                    <SHStack
                      spacing={2}
                      direction="row"
                      alignItems={"flex-end"}
                    >
                      <CalculatorField
                        control={control}
                        feeCalculator={watchField(
                          AnalysisVariable.IDPS_num_ret_Ins,
                        )}
                        name={nameOf(AnalysisVariable.IDPS_num_ret_Ins).value}
                        disabled={isDisabled}
                      />
                    </SHStack>
                  )}
                  {hasSuper && (
                    <SHStack
                      spacing={2}
                      direction="row"
                      alignItems={"flex-end"}
                    >
                      <CalculatorField
                        control={control}
                        feeCalculator={watchField(
                          AnalysisVariable.Super_num_ret_Ins,
                        )}
                        name={nameOf(AnalysisVariable.Super_num_ret_Ins).value}
                        disabled={isDisabled}
                      />
                      <CalculatorField
                        control={control}
                        feeCalculator={watchField(AnalysisVariable.Premium_avg)}
                        name={nameOf(AnalysisVariable.Premium_avg).value}
                        disabled={isDisabled}
                      />
                    </SHStack>
                  )}
                </SHStack>
              </FieldGroup>
            </>
          )}
        </SHStack>
      </SHContainer>
      <FeeCalculatorDialog
        open={showFeeDialog}
        data={disclaimers}
        onClose={() => setShowFeeDialog(false)}
        isReview
      />
      {confirmDialog !== undefined && (
        <NoBalancesDialog
          onClose={() => confirmDialog.callback(false)}
          onSubmit={() => {
            confirmDialog.callback(true);
          }}
        />
      )}
    </form>
  );
};
