import { SHStack } from "@components/design-systems";
import SHSkeleton from "@components/design-systems/sh-skeleton";

export const InformationTabSkeleton = () => {
  return (
    <SHStack sx={{ paddingY: 3 }} spacing={3}>
      <SHStack spacing={1}>
        <SHSkeleton height={24} width="18%" delay={false} />
        <SHSkeleton height={20} width="100%" delay={false} />
      </SHStack>
      <SHSkeleton height={2} width="100%" delay={false} />
      <SHStack direction={"row"} justifyContent={"space-between"}>
        <SHStack width="30%" spacing={1}>
          <SHSkeleton height={24} width="60%" delay={false} />
          <SHSkeleton height={20} width="80%" delay={false} />
          <SHSkeleton height={20} width="80%" delay={false} />
        </SHStack>
        <SHStack width="45%" spacing={3}>
          <SHStack spacing={1}>
            <SHSkeleton height={24} width="30%" delay={false} />
            <SHSkeleton height={60} width="100%" delay={false} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={24} width="30%" delay={false} />
            <SHSkeleton height={60} width="100%" delay={false} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={24} width="30%" delay={false} />
            <SHSkeleton height={60} width="100%" delay={false} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={24} width="30%" delay={false} />
            <SHSkeleton height={60} width="100%" delay={false} />
          </SHStack>
          <SHStack spacing={1}>
            <SHSkeleton height={24} width="30%" delay={false} />
            <SHSkeleton height={60} width="100%" delay={false} />
          </SHStack>
        </SHStack>
      </SHStack>
    </SHStack>
  );
};
