import {
  SHBox,
  SHStack,
  SHTypography,
  SHTypographyProps,
} from "@components/design-systems";
import { TooltipAllowSVG } from "@components/svgs";
import { TooltipProps, useTheme } from "@mui/material";
import { HtmlTooltip } from "@pages/platform-analysis/_id/steps/fee/analysis/components/sub-product-card";
import { hexToRGBA } from "@utils";
import { forwardRef, ReactNode, useState } from "react";
import Truncate, { TruncateProps } from "react-truncate";

interface TruncatedPopupBlockProps {
  children?: ReactNode | string;
  typographyProps?: SHTypographyProps;
  truncateProps?: TruncateProps;
  tooltipProps?: Omit<TooltipProps, "children">;
}

export const TruncatedPopupBlock = forwardRef<
  HTMLDivElement,
  TruncatedPopupBlockProps
>((props, ref) => {
  const { children, typographyProps, truncateProps, tooltipProps } = props;
  const [isTruncated, setIsTruncated] = useState(false);
  const { palette, shadows } = useTheme();
  return (
    <HtmlTooltip
      {...tooltipProps}
      enterDelay={300}
      enterNextDelay={300}
      title={
        <SHStack
          sx={{
            bgcolor: hexToRGBA(palette.common.white, 0.9),
            border: "1px solid #E3E3E3",
            backdropFilter: "blur(4px)",
            borderRadius: "3px",
            boxShadow: shadows[1],
            position: "relative",
            ml: "-19px",
            p: 1,
            width: "300px",
          }}
        >
          <SHTypography variant="body1">{children}</SHTypography>
          <SHBox
            sx={{
              position: "absolute",
              bottom: "-11.7px",
            }}
          >
            <TooltipAllowSVG />
          </SHBox>
        </SHStack>
      }
      followCursor
      disableHoverListener={!isTruncated}
      placement="top-start"
    >
      <span>
        <SHTypography {...typographyProps} ref={ref}>
          <Truncate
            {...truncateProps}
            onTruncate={(isTruncated) => setIsTruncated(isTruncated)}
          >
            {children}
          </Truncate>
        </SHTypography>
      </span>
    </HtmlTooltip>
  );
});

TruncatedPopupBlock.displayName = "TruncatedPopupBlock";
