import { ProductType } from "@models/product/enums/productType";
import { SHFilterOption } from "@components/design-systems";

export class ProductTypeName {
  constructor(public readonly id: ProductType, public readonly name: string) {}
}

export class ProductTypeNames {
  public static readonly Platform = new ProductTypeName(
    ProductType.Platform,
    "Platform",
  );
  public static readonly ManagedInvestment = new ProductTypeName(
    ProductType.ManagedInvestment,
    "Managed investment",
  );
  public static readonly IndustryFund = new ProductTypeName(
    ProductType.IndustryFund,
    "Industry fund",
  );
  public static readonly Other = new ProductTypeName(
    ProductType.Other,
    "Other",
  );

  private static readonly ObjectValues: ProductTypeName[] =
    Object.values(ProductTypeNames);

  public static toFilterOptions(): SHFilterOption[] {
    return this.ObjectValues.map((typeName) => ({
      label: typeName.name,
      value: typeName.id,
    }));
  }

  public static renderTypeNameByTypeId(
    productType: ProductType | null,
  ): string {
    return (
      this.ObjectValues.find((type) => type.id === productType)?.name ?? ""
    );
  }
}
