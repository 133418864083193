import { forwardRef, useEffect, useImperativeHandle } from "react";
import { FieldPath, FormProvider, useForm } from "react-hook-form";
import { SeriesTabSkeleton } from "../../skeletons/SeriesTabSkeleton";
import EditSeriesPageLayout from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series/components/layout-page";
import { useParams } from "react-router";
import {
  EditInvestmentProductFormRef,
  EditInvestmentProductSaveDraftResult,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit";
import {
  toggleSaveDraftButtonDisabled,
  updateInvestmentProductSeriesData,
} from "@redux/slices/product/investment-product";
import { RootState, useAppDispatch } from "@redux/store";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { useInvestmentProduct } from "@hooks/useInvestmentProduct";
import { useSelector } from "react-redux";
import { useIsNew } from "@hooks/useIsNew";
import { setDefaultValueForNoRoleFields } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/series/utils";

interface EditSeriesTabProps {
  allowAddNewSeriesModel?: boolean;
}

export type SeriesPath = FieldPath<{
  investmentProductSeriesData: InvestmentProductSeriesDTO[];
}>;
export const EditSeriesTab = forwardRef<
  EditInvestmentProductFormRef,
  EditSeriesTabProps
>(({ allowAddNewSeriesModel = false }: EditSeriesTabProps, ref) => {
  const { loadInvestmentProductSeries } = useInvestmentProduct();
  const { investmentProductId } = useParams();
  const { updateInvestmentProductSeries } = useInvestmentProduct();
  const isNewInvestmentProduct = useIsNew();
  const dispatch = useAppDispatch();
  const formControls = useForm<{
    investmentProductSeriesData: InvestmentProductSeriesDTO[];
  }>({
    mode: "onChange",
    defaultValues: {
      investmentProductSeriesData: [],
    },
  });
  const {
    investmentProductUI: { isSaveDraftButtonDisabled, isSeriesDataLoading },
  } = useSelector((state: RootState) => state.investmentProduct);

  const handleOnSaveDraft =
    async (): Promise<EditInvestmentProductSaveDraftResult> => {
      dispatch(toggleSaveDraftButtonDisabled(true));
      const formValues = formControls.getValues();
      setDefaultValueForNoRoleFields(
        formValues.investmentProductSeriesData ?? [],
        formControls.getValues,
        formControls.setValue,
      );
      const response = await updateInvestmentProductSeries(
        investmentProductId ?? "",
        "edit",
        formValues.investmentProductSeriesData,
      );

      if (response) {
        dispatch(updateInvestmentProductSeriesData(response));
        formControls.reset({
          investmentProductSeriesData: response as any,
        });

        return {
          isSuccess: true,
          reloadBanner: true,
        };
      }

      return { isSuccess: false };
    };

  useImperativeHandle(ref, () => ({
    getValue() {},
    submit: async () => {},
    resetForm(data) {
      formControls.reset(data ?? {});
    },
    saveDraft: async () => {
      return await handleOnSaveDraft();
    },
    changeTab: async () => {
      if (!isSaveDraftButtonDisabled) {
        return await handleOnSaveDraft();
      }

      return {
        isSuccess: true,
      };
    },
    onPostSubmit: async () => {
      await loadInvestmentProductSeries(investmentProductId ?? "new", "edit");
    },
  }));

  useEffect(() => {
    if (isNewInvestmentProduct) return;
    loadInvestmentProductSeries(investmentProductId ?? "new", "edit");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investmentProductId]);

  if (isSeriesDataLoading) return <SeriesTabSkeleton />;

  return (
    <FormProvider {...formControls}>
      <EditSeriesPageLayout allowAddNewSeriesModel={allowAddNewSeriesModel} />
    </FormProvider>
  );
});
