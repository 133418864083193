import { APIExtRoutes } from "@constants/routes";
import { ConfigurationGroup } from "@models/configuration";
import { APIResponse } from "@models/core";
import {
  ExistingFeeScheduleDTO,
  ProductListDTO,
  ProductBDMUserDTO,
  ProductDataBannerInfoDTO,
  ProductBrandingDataDto,
} from "@models/product/entities/product";
import { compileProductGroups, compileProduct } from "@services/product/util";
import { ProductHistoryDTO } from "@models/product/entities/history";
import {
  APIGatewayService,
  getDataWithMinimumDelay,
  handleErrorProxy,
} from "@services/shService";
import { generatePath } from "react-router";

export async function postProduct(
  product?: ProductListDTO,
): Promise<APIResponse<ProductListDTO>> {
  const response = await APIGatewayService.post<ProductListDTO>(
    APIExtRoutes.products,
    product,
  );
  return handleErrorProxy(response, "Product has been created.");
}

export async function putProduct(
  product?: ProductListDTO,
): Promise<APIResponse<ProductListDTO>> {
  const response = await APIGatewayService.put<ProductListDTO>(
    APIExtRoutes.products,
    product,
  );
  return handleErrorProxy(response, "Saved as draft!");
}

export async function getProductGroups(
  productId?: string,
  version?: string,
  configurationVersion?: string,
): Promise<APIResponse<ConfigurationGroup[]>> {
  const response = await APIGatewayService.get<
    ConfigurationGroup[] | undefined
  >(
    generatePath(APIExtRoutes.productGroups, {
      productId: productId,
      version: version,
      configurationVersion: configurationVersion,
    }),
  );
  if (response?.data) {
    response.data = compileProductGroups(productId === "new", response?.data);
  }
  return handleErrorProxy(response);
}

export async function getProduct(
  productId?: string,
  groupId?: string,
  mode?: "view" | "edit",
): Promise<APIResponse<ProductListDTO>> {
  const response = await getDataWithMinimumDelay(
    APIGatewayService.get<ProductListDTO>(
      generatePath(APIExtRoutes.productGroup, {
        productId,
        groupId,
        mode,
      }),
    ),
  );

  if (response?.data) {
    compileProduct(response.data, mode);
  }

  return handleErrorProxy(response);
}

export async function getProductBannerInfo(
  productId: string,
): Promise<APIResponse<ProductDataBannerInfoDTO[]>> {
  const response = await APIGatewayService.get<ProductDataBannerInfoDTO[]>(
    generatePath(APIExtRoutes.productBannerInfo, { productId }),
  );
  return handleErrorProxy(response);
}

export async function submitForReview(productId: string): Promise<APIResponse> {
  const response = await APIGatewayService.patch(
    generatePath(APIExtRoutes.productSubmitForReview, { productId }),
  );
  return handleErrorProxy(response, "Changes have been submitted for review!");
}

export async function revertToDraft(productId: string): Promise<APIResponse> {
  const response = await APIGatewayService.patch(
    generatePath(APIExtRoutes.productRevertToDraft, { productId }),
  );
  return handleErrorProxy(
    response,
    "Platform profile has been reverted back to draft!",
  );
}

export async function publishDraft(productId: string): Promise<APIResponse> {
  const response = await APIGatewayService.patch(
    generatePath(APIExtRoutes.productPublishDraft, { productId }),
  );
  return handleErrorProxy(response, "Changes have been published!");
}

export async function recallSubmission(
  productId: string,
): Promise<APIResponse> {
  const response = await APIGatewayService.patch(
    generatePath(APIExtRoutes.productRecallSubmission, { productId }),
  );
  return handleErrorProxy(
    response,
    "Platform profile has been recalled back to draft!",
  );
}

export async function discardDraft(productId: string): Promise<APIResponse> {
  const response = await APIGatewayService.patch(
    generatePath(APIExtRoutes.productDiscardDraft, { productId }),
  );
  return handleErrorProxy(response, "Profile changes have been discarded.");
}

export async function getProductChangeHistory(
  productId: string,
): Promise<APIResponse<ProductHistoryDTO[]>> {
  const response = await APIGatewayService.get<ProductHistoryDTO[]>(
    generatePath(APIExtRoutes.productChangeHistory, { productId }),
  );
  return handleErrorProxy(response);
}

export async function getProductBDMUsers(
  productId: string,
  regionIds?: string[],
): Promise<APIResponse<ProductBDMUserDTO[]>> {
  const response = await getDataWithMinimumDelay(
    APIGatewayService.post<ProductBDMUserDTO[]>(
      generatePath(APIExtRoutes.productBDMUsers, { productId }),
      { regionIds: regionIds },
    ),
  );

  return handleErrorProxy(response);
}

export async function getProductReleaseLog(
  productId: string,
  version: string,
  configurationVersion: string,
  groupId: string,
): Promise<APIResponse<ProductListDTO>> {
  const response = await APIGatewayService.get<ProductListDTO>(
    generatePath(APIExtRoutes.productReleaseLog, {
      productId,
      version,
      configurationVersion,
      groupId,
    }),
  );
  if (response?.data) {
    compileProduct(response.data, "edit", true);
  }

  return handleErrorProxy(response);
}

export async function getExistingFeeSchedule(
  productId: string,
): Promise<APIResponse<ExistingFeeScheduleDTO[]>> {
  const response = await APIGatewayService.get<ExistingFeeScheduleDTO[]>(
    generatePath(APIExtRoutes.existingFeeSchedules, {
      productId,
    }),
  );
  return handleErrorProxy(response);
}

export async function getProductBrandingData(
  productId: string,
): Promise<APIResponse<ProductBrandingDataDto>> {
  const response = await APIGatewayService.get<ProductBrandingDataDto[]>(
    generatePath(APIExtRoutes.productBrandingData, {
      productId,
    }),
  );

  return handleErrorProxy(response);
}
