import boldIcon from "../../icons/type-bold.svg";
import italicIcon from "../../icons/type-italic.svg";
import underLineIcon from "../../icons/type-underline.svg";
import strikeThroughIcon from "../../icons/type-strikethrough.svg";
import leftAlignIcon from "../../icons/text-left.svg";
import rightAlignIcon from "../../icons/text-right.svg";
import centerAlignIcon from "../../icons/text-center.svg";
import redoIcon from "../../icons/arrow-clockwise.svg";
import undoIcon from "../../icons/arrow-counterclockwise.svg";
import orderListIcon from "../../icons/list-ol.svg";
import unOrderListIcon from "../../icons/list-ul.svg";
import popupIcon from "../../icons/box-arrow-up-right.svg";
import { styled } from "@mui/material";
import {
  ContentEditable,
  ContentEditableProps,
} from "@lexical/react/LexicalContentEditable";
import React, { forwardRef } from "react";
import { SHStack } from "@components/design-systems";

const CloneContentEditable = forwardRef(
  (props: ContentEditableProps, ref: any) => (
    <ContentEditable {...props} ref={ref} />
  ),
);

const iconBtnFormat = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  "button.toolbar-item": {
    border: 0,
    display: "flex",
    borderRadius: "10px",
    padding: "6px",
    cursor: "pointer",
    verticalAlign: "middle",
    background: "transparent",
    ":disabled": {
      cursor: "not-allowed",
    },
    ":hover": {
      // background: "red",
    },
    "i.format": {
      backgroundSize: "contain",
      display: "inline-block",
      height: "18px",
      width: "18px",
      marginTop: "2px",
      verticalAlign: "-0.25em",
      opacity: 0.6,
    },
    "i.pop-up": {
      marginTop: 0,
      marginRight: "2px",
      opacity: 1,
      backgroundSize: "auto",
      height: "16px",
      width: "16px",
    },
  },
  "button.spaced": {
    margin: "2px",
  },
  "button.active": {
    backgroundColor: "rgba(34,33,33,0.08)",
    i: {
      opacity: 1,
    },
  },
  "button.dialog": {
    display: "flex",
    alignItems: "center",
  },
};
const btnIcons = {
  "i.undo": {
    backgroundImage: `url(${undoIcon})`,
  },
  "i.redo": {
    backgroundImage: `url(${redoIcon})`,
  },
  "i.bold": {
    backgroundImage: `url(${boldIcon})`,
  },
  "i.italic": {
    backgroundImage: `url(${italicIcon})`,
  },
  "i.underline": {
    backgroundImage: `url(${underLineIcon})`,
  },
  "i.strikethrough": {
    backgroundImage: `url(${strikeThroughIcon})`,
  },
  "i.left-align": {
    backgroundImage: `url(${leftAlignIcon})`,
  },
  "i.right-align": {
    backgroundImage: `url(${rightAlignIcon})`,
  },
  "i.center-align": {
    backgroundImage: `url(${centerAlignIcon})`,
  },
  "i.ordered-list": {
    backgroundImage: `url(${orderListIcon})`,
  },
  "i.unordered-list": {
    backgroundImage: `url(${unOrderListIcon})`,
  },
  "i.pop-up": {
    backgroundImage: `url(${popupIcon})`,
  },
};
const toolbarLeftItems = {
  ...iconBtnFormat,
};
const toolbarRightItems = {
  ...iconBtnFormat,
};
const textFormat = {
  ".editor-text-bold": {
    fontWeight: 700,
  },
  ".editor-text-italic": {
    fontStyle: "italic",
  },
  ".editor-text-underline": {
    textDecorationLine: "underline",
  },
  ".editor-text-strikethrough": {
    textDecorationLine: "line-through",
  },
  "editor-text-underlineStrikethrough": {
    textDecorationLine: "underline line-through",
  },
  ".editor-listitem": {
    margin: "8px 32px 8px 32px",
  },
  ".editor-nested-listitem": {
    listStyleType: "none",
  },
  ".editor-list-ol": {
    padding: 0,
    margin: 0,
    marginLeft: "16px",
  },
  ".editor-list-ul": {
    padding: 0,
    margin: 0,
    marginLeft: "16px",
  },
};

export const StyledEditorContainer = styled(SHStack)(({ theme }) => ({
  minHeight: "250px",
  minWidth: "400px",
  width: "100%",
  height: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  ".editor-container": {
    fontSize: "14px",
    fontWeight: 400,
    fontFamily: "Epilogue, sans-serif",
    fontStretch: "100%",
    color: theme.palette.text.primary,
    borderRadius: "2px",
    width: "100%",
    height: "95%",
    position: "relative",
    lineHeight: "20px",
    textAlign: "left",
    border: "1px solid #ddd",
    overflowY: "auto",
    ".editor-inner": {
      position: "relative",
      width: "100%",
      height: "100%",
      ".editor-placeholder": {
        color: "#999",
        overFlow: "hidden",
        position: "absolute",
        textOverflow: "ellipsis",
        top: "15px",
        left: "10px",
        fontSize: "15px",
        userSelect: "none",
        display: "inline-block",
        pointerEvents: "none",
      },
      ...textFormat,
    },
  },
  ".disabled": {
    color: theme.palette.text.disabled,
  },
  ".editor-overlay": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "transparent",
    zIndex: 10,
    cursor: "not-allowed",
  },
}));

export const StyledContentEditable = styled(CloneContentEditable)({
  height: "100%",
  width: "100%",
  resize: "none",
  position: "relative",
  caretColor: "#444",
  tabSize: 1,
  outline: 0,
  padding: "15px 10px",
  overflowY: "auto",
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
  "::-webkit-scrollbar": {
    width: "6px",
  },
  "::-webkit-scrollbar-thumb": {
    borderRadius: "4px",
  },
  "::-webkit-scrollbar-thumb:hover": {
    background: "#555",
  },
});
export const StyledToolbarPlugin = styled(SHStack)({
  ".toolbar": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "1px",
    verticalAlign: "middle",
    borderTop: "1px solid #ddd",
    ".divider": {
      width: "1px",
      backgroundColor: "#eee",
      margin: "0 4px",
    },
    ".toolbar-left": {
      ...toolbarLeftItems,
    },
    ".toolbar-right": {
      ...toolbarRightItems,
      ".count-word": {
        marginRight: "5px",
      },
    },
  },
  ...btnIcons,
});
