import SelectViewingGroups from "@components/auto-completes/viewing-groups";
import { SHNumericField, SHStack } from "@components/design-systems";
import {
  useEditManagerPaths,
  useInputDotIndicator,
} from "@hooks/useInvestmentProduct";
import { FieldTypeId } from "@models/configuration";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { ViewingGroupSelectionDTO } from "@models/viewing-groups/entities/viewingGroups";
import { toStringValue } from "@pages/suppliers/_id/_products/_id/edit/components/tab-form/util";
import { CustomLabel } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/custom-label";
import {
  EditManagerInputControllerProps,
  handleOnEditManagerFieldChange,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import { EditProductDefaults } from "@pages/suppliers/_id/_products/investment-product/_id/edit/constants";
import { debounce, isEqual } from "lodash";
import { Controller, useFormContext } from "react-hook-form";

export const EditManagerNumberMillionInput = ({
  isCreatingInvestmentProduct,
  featureData,
  featureIndex,
  sectionIndex,
  isDisabled,
  onChange,
}: EditManagerInputControllerProps) => {
  const { control, getValues, setValue } =
    useFormContext<InvestmentProductManagerDTO>();

  const { featureProductDataPath, featureDataPath } = useEditManagerPaths(
    sectionIndex,
    featureIndex,
  );

  const { isShowOrangeDot, isShowBlueDot } =
    useInputDotIndicator<InvestmentProductManagerDTO>({
      getValues,
      control,
      paths: {
        highlightType: featureProductDataPath.highlightType,
        dataStatus: featureProductDataPath.dataStatus,
      },
    });

  const handleOnChange = (newValue: string) => {
    handleOnEditManagerFieldChange({
      isCreatingInvestmentProduct,
      originalValue: featureData.productData?.value,
      newValue,
      getValues,
      setValue,
      featureProductDataPath,
    });
    onChange && onChange({ sectionIndex, featureIndex });
  };

  const debouncedHandleInputChange = debounce(
    handleOnChange,
    EditProductDefaults.DebounceTime,
  );
  const handleVisibilityChange = (
    newValue: ViewingGroupSelectionDTO[] | null,
  ) => {
    setValue(
      featureProductDataPath.isModified,
      !isEqual(featureData.viewingGroupSelections, newValue),
    );
    onChange && onChange({ sectionIndex, featureIndex });
  };
  const debounceVisibilityChange = debounce(
    handleVisibilityChange,
    EditProductDefaults.DebounceTime,
  );

  return (
    <SHStack
      sx={{ width: { xs: "100%", md: 520, sm: 520 } }}
      direction={"row"}
      gap={2}
    >
      <Controller
        name={featureProductDataPath.value}
        control={control}
        render={({ field: { ref, ...other } }) => (
          <SHNumericField
            {...other}
            disabled={isDisabled}
            sx={{
              width: "100%",
              flex: 1,
            }}
            value={Number(other?.value)}
            onChange={(_, value: number | null) => {
              const newValue = toStringValue(value, FieldTypeId.Number);
              other.onChange(newValue);
              debouncedHandleInputChange(newValue);
            }}
            numericFormatProps={{
              thousandSeparator: true,
              prefix: "$",
              suffix: "m",
            }}
            fullWidth
            label={
              <CustomLabel
                featureData={featureData}
                isDisabled={isDisabled}
                dotProps={{
                  orangeDot: isShowOrangeDot,
                  blueDot: isShowBlueDot,
                  greenDot: !!featureData?.isBusinessMetric,
                }}
              />
            }
            placeholder={EditProductDefaults.PlaceHolder}
          />
        )}
      />
      {featureData.allowedVisibilitySetting && (
        <Controller
          name={featureDataPath.viewingGroupSelections}
          control={control}
          render={({ field: { ref, ...other } }) => (
            <SelectViewingGroups
              disabled={isDisabled}
              textFieldProps={{
                label: `Visible to`,
              }}
              sx={{
                flexBasis: "40%",
              }}
              onChange={(value) => {
                other.onChange(value);
                debounceVisibilityChange(value);
              }}
              value={
                (getValues(featureDataPath.viewingGroupSelections) as
                  | ViewingGroupSelectionDTO[]
                  | null) || []
              }
              viewingGroupData={featureData.viewingGroupSelections ?? []}
            />
          )}
        />
      )}
    </SHStack>
  );
};
