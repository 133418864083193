import { UserType } from "@models/auth";

export const AllUsersGroup = [
  UserType.SuperAdmin,
  UserType.StandardAdmin,
  UserType.SupplierBasic,
  UserType.SupplierBdmSale,
  UserType.SupplierPremiumOwner,
  UserType.SupplierPremiumStandard,
  UserType.AdviserTrial,
  UserType.AdviserAdviser,
  UserType.AdviserSupportStaff,
  UserType.AdviserAdminTrial,
  UserType.AdviserAdminAdviser,
  UserType.AdviserAdminSupportStaff,
];

export const SupplierUsersGroup = [
  UserType.SupplierBasic,
  UserType.SupplierBdmSale,
  UserType.SupplierPremiumOwner,
  UserType.SupplierPremiumStandard,
];

export const AdviserUsersGroup = [
  UserType.AdviserTrial,
  UserType.AdviserAdviser,
  UserType.AdviserSupportStaff,
  UserType.AdviserAdminTrial,
  UserType.AdviserAdminAdviser,
  UserType.AdviserAdminSupportStaff,
];

export const AdviserAdminUsersGroup = [
  UserType.AdviserAdminTrial,
  UserType.AdviserAdminAdviser,
  UserType.AdviserAdminSupportStaff,
];

export const AdviserTrialUsersGroup = [
  UserType.AdviserTrial,
  UserType.AdviserAdminTrial,
];

export const SuppliersAndAdvisersWithoutTrial = [
  UserType.SupplierBasic,
  UserType.SupplierBdmSale,
  UserType.SupplierPremiumOwner,
  UserType.SupplierPremiumStandard,
  UserType.AdviserAdviser,
  UserType.AdviserAdminAdviser,
  UserType.AdviserSupportStaff,
  UserType.AdviserAdminSupportStaff,
];

export const AdminUsersGroup = [UserType.SuperAdmin, UserType.StandardAdmin];
