import { FilterValue } from "@components/buttons/filter";
import { FeatureSectionName, FieldTypeId } from "@models/configuration";
import { AnalysisDTO } from "@models/platform-analysis/entities/analysis";
import { DisclaimerDTO } from "@models/platform-analysis/entities/disclaimer";
import {
  AnalysisFeatureDTO,
  AnalysisFeatureIncludeDTO,
  AnalysisSectionDTO,
} from "@models/platform-analysis/entities/steps/feature";
import {
  AnalysisFeeDTO,
  FamilyMemberAccountDTO,
  FeeSubProductDTO,
} from "@models/platform-analysis/entities/steps/fee";
import { FeesDisplayStyle } from "@models/platform-analysis/enums/fee/displayStyle";
import { FeeHeatmapMode } from "@models/platform-analysis/enums/fee/heatmapMode";
import { FeeMissingInvestmentsOption } from "@models/platform-analysis/enums/fee/missingInvestments";
import { AnalysisStep } from "@models/platform-analysis/enums/step";
import { TableViewMode } from "@models/platform-analysis/enums/tableViewMode";
import { HiddenSubProductDTO } from "@models/reviews/entities/steps/summary";
import {
  MAX_LIGHTNESS,
  MIN_LIGHTNESS,
} from "@pages/platform-analysis/_id/steps/fee/analysis/components/heatmap/config";
import {
  DEFAULT_COLORS,
  DEFAULT_COLOR_TICK,
} from "@pages/platform-analysis/_id/steps/fee/analysis/components/line-chart/config";
import { PDFObjectives } from "@pages/platform-analysis/components/buttons/export-pdf/components/sections/objectives";
import {
  FeatureSectionIcons,
  HtmlTag,
  PDFFeatureSectionIconPrefix,
} from "@pages/platform-analysis/components/buttons/export-pdf/config";
import {
  COMMENT_TABLE_HEADER_HEIGHT,
  CONTENT_WIDTH,
  MIN_HEIGHT_CHART,
  MARGIN_DEFAULT,
  MINIMUM_COMMENT_CELL_HEIGHT,
  PAGE_BODY_HEIGHT,
  REVIEW_PAGE_BODY_HEIGHT,
  SUB_TITLE_HEIGHT,
  TABLE_ROW_HEADER_LINE,
  TABLE_WIDTH,
  TITLE_HEIGHT,
  HEIGHT_LEGEND_CHART,
} from "@pages/platform-analysis/components/buttons/export-pdf/constant";
import {
  PDFAnalysisComment,
  PDFAnalysisControl,
  PDFAnalysisData,
  PDFAnalysisOverview,
  PDFAnalysisSectionDTO,
  PDFBusinessAnalysis,
  PDFFeatureAnalysis,
  PDFFeatureIncluded,
  PDFFeeAnalysis,
  PDFFeePortfolioDetail,
  PDFHtmlElement,
  PDFSummarySelectedProductDTO,
  PageTemplate,
} from "@pages/platform-analysis/components/buttons/export-pdf/model";
import {
  displayCurrencyNumber,
  getPortfolioDetails,
} from "@pages/platform-analysis/util";
import { generateHiddenIds } from "@pages/reviews/_id/steps/summary/util";
import {
  CommentDetail,
  ReviewCommentSeparated,
} from "@pages/reviews/components/buttons/export-pdf/model";
import { expandHTMLTagInPDF } from "@pages/reviews/components/buttons/export-pdf/util";
import { View } from "@react-pdf/renderer";
import { getHSLLightness, hslToHex } from "@utils";
import {
  chain,
  chunk,
  cloneDeep,
  groupBy,
  isEmpty,
  isNil,
  join,
  map,
  replace,
  some,
} from "lodash";
import { PDFDisclaimer } from "./components/disclaimer";
import { PDFFeeLineChart } from "./components/fee-line-chart";
import { PDFFeeLineChartSubProduct } from "./components/fee-line-chart-sub-product";
import { PDFBusinessAnalysisSection } from "./components/sections/business-analysis";
import { PDFAnalysisCommentSection } from "./components/sections/comments";
import { PDFFeatureAnalysisSection } from "./components/sections/feature-analysis";
import { PDFFeeAnalysisSection } from "./components/sections/fee-analysis";
import { PDFFeePortfolioDetailsSection } from "./components/sections/fee-portfolio-details";
import { PDFPlatformIncluded } from "./components/sections/platform-included";
import { PDFFeaturesIncludeSection } from "./components/tables/features-include";
import { PDFTitleSection } from "./components/title-section";
import { FeeInvestmentMenuOption } from "@models/platform-analysis/enums/fee/investmentMenu";

// TODO: Should be get from API
export function generatePDFData(analysis: AnalysisDTO): PDFAnalysisData {
  const analysisSummary = analysis?.summary;
  const showSubSection =
    analysisSummary?.featureAnalysisTableViewMode !== TableViewMode.Summary;
  let pdfData = {
    overview: {
      adviserName: analysisSummary?.adviserName,
      practiceName: analysisSummary?.practiceName,
      ownerName: analysisSummary?.ownerName,
      supplierName: analysisSummary?.supplierName,
      dataValidDate: analysis?.databaseVersion,
      serial: analysisSummary?.serial,
      lastModifiedDate: analysisSummary?.lastModifiedDate,
      analysisName: analysisSummary?.name,
    } as PDFAnalysisOverview,
    selectedProducts: analysisSummary?.selectedProducts,
    objectives: expandHTMLTagInPDF(
      analysisSummary?.objectives ?? "",
      HtmlTag.BR,
    ),
    concludingRemarks: expandHTMLTagInPDF(
      analysisSummary?.concludingRemarks ?? "",
      HtmlTag.BR,
    ),
    analysisComments: analysisSummary?.analysisComments?.map((comment) => {
      return {
        ...comment,
        feature: expandHTMLTagInPDF(comment?.feature ?? "", HtmlTag.BR),
        businessMetric: expandHTMLTagInPDF(
          comment?.businessMetric ?? "",
          HtmlTag.BR,
        ),
        fee: expandHTMLTagInPDF(comment?.fee ?? "", HtmlTag.BR),
        summary: expandHTMLTagInPDF(comment?.summary ?? "", HtmlTag.BR),
      };
    }),
    featureAnalysis:
      analysisSummary?.hasFeatureStep &&
      ({
        selectedProducts: analysisSummary?.featureAnalysis?.selectedProducts,
        overallScore: analysisSummary?.featureAnalysis?.overallScore,
        sections: convertToPDFFeatureAnalysisSection(
          analysis?.summary?.featureAnalysis
            ?.sections as PDFAnalysisSectionDTO[],
          showSubSection,
          analysisSummary?.featureAnalysisTableViewMode ===
            TableViewMode.Detail,
        ),
      } as PDFFeatureAnalysis),
    businessAnalysis:
      analysisSummary?.hasBusinessMetricStep &&
      ({
        selectedProducts:
          analysisSummary?.businessMetricAnalysis?.selectedProducts,
        overallScore: analysisSummary?.businessMetricAnalysis?.overallScore,
        sections: convertToPDFBusinessAnalysisSection(
          analysis?.summary?.businessMetricAnalysis
            ?.sections as PDFAnalysisSectionDTO[],
        ),
      } as PDFBusinessAnalysis),
    feePortfolioDetails: analysisSummary?.feePortfolioDetails,
    feeAnalysis: convertToPDFFeeAnalysis(
      analysisSummary?.feeAnalysis ?? null,
      analysisSummary?.hiddenSubProducts ?? [],
      analysisSummary?.feePortfolioDetails?.totalPortfolioValue ?? 0,
      analysisSummary?.feesDisplayStyle ?? FeesDisplayStyle.Dollar,
      analysisSummary?.feeMissingInvestmentsStyle ??
        FeeMissingInvestmentsOption?.AllPlatform,
      analysisSummary?.investmentMenu ?? FeeInvestmentMenuOption.AllPlatform,
    ),
    hiddenSubProductIds: generateHiddenIds(
      analysisSummary?.feeAnalysis?.subProducts ?? [],
      analysisSummary?.hiddenSubProducts ?? [],
    ),
    featureIncluded: analysisSummary?.selectedFeatures,
    controls: {
      showFeatureStep: analysisSummary?.hasFeatureStep,
      showBusinessMetricStep: analysisSummary?.hasBusinessMetricStep,
      showFeeStep: analysisSummary?.hasFeeStep,
      showComments: analysisSummary?.showComments,

      showHoldingNumber: analysisSummary?.feeAnalysis?.isShowHoldingsNumber,
      showSubSection: showSubSection,
      showSelectedFeatures:
        analysisSummary?.featureAnalysisTableViewMode !== TableViewMode.Detail
          ? analysisSummary?.showSelectedFeatures
          : false,
      showAnalysisScore: analysisSummary?.showAnalysisScore ?? true,
      showFeeAnalysisGraph: !analysisSummary?.hideFeeAnalysisGraph ?? true,
    } as PDFAnalysisControl,
    displayModes: {
      feesDisplayMode: analysisSummary?.feesDisplayStyle,
      feeMissingInvestmentsDisplayMode:
        analysisSummary?.feeMissingInvestmentsStyle,
      featureAnalysisTableViewMode:
        analysisSummary?.featureAnalysisTableViewMode,
      investmentMenu: analysisSummary?.investmentMenu,
    },
  } as PDFAnalysisData;

  return pdfData;
}

// TODO: Should be shared between Analysis & Review
export function convertToPDFFeatureAnalysisSection(
  sections: AnalysisSectionDTO[],
  showSubSection?: boolean,
  isDetailMode?: boolean,
): PDFAnalysisSectionDTO[] {
  const rows: PDFAnalysisSectionDTO[] = [];

  sections.forEach((section: AnalysisSectionDTO) => {
    rows?.push({
      id: section?.id,
      name: section?.name,
      description: section?.description,
      iconUrl: section?.iconUrl,
      order: section?.order,
      totalFeature: section?.totalFeature,
      totalSelectedFeature: section?.totalSelectedFeature,
      analysisData: section?.analysisData,
      type: "Section",
    } as PDFAnalysisSectionDTO);

    if (showSubSection) {
      section?.subSections?.forEach((subSection: AnalysisSectionDTO) => {
        rows.push({
          id: subSection?.id,
          name: subSection?.name,
          description: subSection?.description,
          iconUrl: subSection?.iconUrl,
          order: subSection?.order,
          totalFeature: subSection?.totalFeature,
          totalSelectedFeature: subSection?.totalSelectedFeature,
          analysisData: subSection?.analysisData,
          type: "SubSection",
        } as PDFAnalysisSectionDTO);
        if (isDetailMode) {
          subSection?.features?.forEach((feature) => {
            rows.push({
              id: feature?.id,
              name: feature?.name,
              description: feature?.description,
              order: feature?.order,
              analysisData: feature?.analysisData,
              type: "Feature",
            } as PDFAnalysisSectionDTO);
          });
        }
      });
    }
  });

  return rows;
}

export function convertToPDFBusinessAnalysisSection(
  sections: AnalysisSectionDTO[],
): PDFAnalysisSectionDTO[] {
  const rows: PDFAnalysisSectionDTO[] = [];

  sections.forEach((section) => {
    rows?.push({
      id: section?.id,
      name: section?.name,
      description: section?.description,
      iconUrl: section?.iconUrl,
      order: section?.order,
      totalFeature: section?.totalFeature,
      totalSelectedFeature: section?.totalSelectedFeature,
      analysisData: section?.analysisData,
      businessMetricTypeId: section?.businessMetricTypeId,
      businessMetricTypeName: section?.businessMetricTypeName,
      type: "Section",
    } as PDFAnalysisSectionDTO);

    section?.features?.forEach((feature: AnalysisFeatureDTO) => {
      rows.push({
        id: feature?.id,
        name: feature?.name,
        description: feature?.description,
        order: feature?.order,
        analysisData: feature?.analysisData,
        businessMetricTypeId: section?.businessMetricTypeId,
        businessMetricTypeName: section?.businessMetricTypeName,
        type: "Feature",
      } as PDFAnalysisSectionDTO);
    });
  });

  return rows;
}

export function convertToPDFFeeAnalysis(
  feeAnalysis: AnalysisFeeDTO | null,
  hiddenSubProducts: HiddenSubProductDTO[] | null,
  totalPortfolioValue: number,
  feesDisplayStyle: FeesDisplayStyle,
  feeMissingInvestmentsStyle: FeeMissingInvestmentsOption,
  feeInvestmentMenuStyle: FeeInvestmentMenuOption,
) {
  const originalSubProducts = feeAnalysis?.subProducts ?? [];
  const hiddenSubProductIds = chain(originalSubProducts)
    .filter({ isHidden: true })
    .map("id")
    .value();

  // Filter sub products by conditional
  let subProductsFiltered: FeeSubProductDTO[] = originalSubProducts;

  if (feeInvestmentMenuStyle !== FeeInvestmentMenuOption.AllPlatform) {
    subProductsFiltered = subProductsFiltered?.filter(
      (subProduct) => subProduct.investmentMenu === feeInvestmentMenuStyle,
    );
  }

  if (feeMissingInvestmentsStyle !== FeeMissingInvestmentsOption.AllPlatform) {
    subProductsFiltered =
      subProductsFiltered?.filter((subProduct) =>
        feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.Missing
          ? subProduct.warning
          : !subProduct.warning,
      ) ?? [];
  }
  if (!isEmpty(hiddenSubProductIds)) {
    subProductsFiltered = subProductsFiltered?.filter(
      (subProduct) => !hiddenSubProductIds?.includes(subProduct.id),
    );
  }

  const totalSubProductHidden =
    originalSubProducts && subProductsFiltered
      ? originalSubProducts?.length - subProductsFiltered?.length
      : 0;
  const showMissingInvestmentFilter = subProductsFiltered?.some(
    (subProduct: FeeSubProductDTO) => subProduct.warning,
  );
  const feeFilter: FilterValue[] | undefined =
    feeMissingInvestmentsStyle === FeeMissingInvestmentsOption.AllPlatform
      ? undefined
      : [
          {
            value: feeMissingInvestmentsStyle,
            label: feeMissingInvestmentsStyle,
          },
        ];

  return {
    subProducts: cloneDeep(subProductsFiltered) ?? [],
    hiddenSubProductIds: hiddenSubProductIds,
    feesDisplayStyle: feesDisplayStyle,
    showWarningCaptions:
      (!feeFilter && showMissingInvestmentFilter) ||
      some(feeFilter, {
        value: FeeMissingInvestmentsOption.Missing,
      }),
    totalPortfolioValue: totalPortfolioValue,
    totalSubProductHidden: totalSubProductHidden,
  } as PDFFeeAnalysis;
}

// Note: Shared between Analysis & Review
export const generatePDFImageIconUrl = (iconUrl?: string) => {
  let result = iconUrl;

  if (
    process.env.REACT_APP_AUTH0_REDIRECT_URL ===
      "https://app.dev.suitabilityhub.com" ||
    process.env.REACT_APP_AUTH0_REDIRECT_URL ===
      "https://local-app.suitabilityhub.com:3000"
  )
    result = result?.replace(
      "https://sh-dev-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com",
      process.env.REACT_APP_AUTH0_REDIRECT_URL,
    );

  if (
    process.env.REACT_APP_AUTH0_REDIRECT_URL ===
    "https://app.staging.suitabilityhub.com"
  )
    result = result?.replace(
      "https://sh-staging-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com",
      process.env.REACT_APP_AUTH0_REDIRECT_URL,
    );

  if (
    process.env.REACT_APP_AUTH0_REDIRECT_URL ===
    "https://app.prod.suitabilityhub.com"
  )
    result = result?.replace(
      "https://sh-prod-s3-bucket-public-apse2.s3.ap-southeast-2.amazonaws.com",
      process.env.REACT_APP_AUTH0_REDIRECT_URL,
    );

  return result;
};

export const featureSectionIconMapping = {
  [FeatureSectionName.ProductMenu]: FeatureSectionIcons?.ProductMenu,
  [FeatureSectionName.AccountManagement]:
    FeatureSectionIcons?.AccountManagement,
  [FeatureSectionName.PortfolioManagement]:
    FeatureSectionIcons?.PortfolioManagement,
  [FeatureSectionName.CashflowManagement]:
    FeatureSectionIcons?.CashflowManagement,
  [FeatureSectionName.DecisionSupport]: FeatureSectionIcons?.DecisionSupport,
  [FeatureSectionName.Tracking]: FeatureSectionIcons?.Tracking,
  [FeatureSectionName.ClientReporting]: FeatureSectionIcons?.ClientReporting,
  [FeatureSectionName.ClientAccess]: FeatureSectionIcons?.ClientAccess,
  [FeatureSectionName.PlanningCompliance]:
    FeatureSectionIcons?.PlanningCompliance,
  [FeatureSectionName.WholeView]: FeatureSectionIcons?.WholeView,
  [FeatureSectionName.Integration]: FeatureSectionIcons?.Integration,
};

export const generateFeatureSectionIconUrl = (iconUrl: string) => {
  const urlSplit = iconUrl.split("/").pop();
  if (!isNil(urlSplit)) {
    const fileName = urlSplit.substring(0, urlSplit.lastIndexOf("."));
    return PDFFeatureSectionIconPrefix + fileName + ".png";
  }

  return "";
};

export const chunkSubstr = (str: string, size: number) => {
  const numChunks = Math.ceil(str?.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str?.substring(o, size);
  }

  return chunks;
};

// #Region: Mockup DOM handler for html string
export const insertStringAtSpecificIndex = (
  target: string,
  index: number,
  valueAdded: string,
): string => {
  return target.substring(0, index) + valueAdded + target.substring(index);
};

export const regexLastIndexOf = (
  target: string,
  regex: RegExp,
  startpos?: number,
) => {
  regex = regex.global
    ? regex
    : new RegExp(
        regex.source,
        "g" + (regex.ignoreCase ? "i" : "") + (regex.multiline ? "m" : ""),
      );
  if (typeof startpos == "undefined") {
    startpos = target.length;
  } else if (startpos < 0) {
    startpos = 0;
  }
  const stringToWorkWith = target.substring(0, startpos + 1);
  let lastIndexOf = -1;
  let nextStop = 0;
  let result;
  while ((result = regex.exec(stringToWorkWith)) != null) {
    lastIndexOf = result.index;
    regex.lastIndex = ++nextStop;
  }
  return lastIndexOf;
};

const processHTMLElementsByNode = (
  node: any,
  cellWidth: string,
  spaceAvailable: number,
  isComment?: boolean,
  isFirstComment?: boolean,
): CommentDetail => {
  let clone = node.cloneNode(true);

  clone.style.cssText = `position:fixed;top: -9999px;opacity: 0;width:${cellWidth};padding: ${
    isComment ? 5 : 0
  }px;padding-bottom: 0px;font-size: ${isComment ? 7 : 10}px;line-height: 120%`;

  document.body.appendChild(clone);

  let result = parseToHTMLElements(
    clone,
    spaceAvailable,
    isComment,
    isFirstComment,
    cellWidth,
  );

  const height = result.reduce((accumulator, currentValue) => {
    return accumulator + (currentValue.height ?? 0);
  }, 0);
  // cleanup
  clone.parentNode.removeChild(clone);

  return {
    elements: result,
    totalHeight: height,
  } as CommentDetail;
};

const parseToHTMLElements = (
  elementRef: Element,
  spaceAvailable: number,
  isComment?: boolean,
  isFirstComment?: boolean,
  cellWidth?: string,
) => {
  let htmlElements: PDFHtmlElement[] = [];
  const pageBodyHeight = PAGE_BODY_HEIGHT;
  let additionalHeight = TITLE_HEIGHT;
  if (isComment) additionalHeight += COMMENT_TABLE_HEADER_HEIGHT;
  const maxContentHeight = pageBodyHeight - additionalHeight;

  const pushElement = (contentPerPage: string, totalHeight: number) => {
    htmlElements?.push({
      content: contentPerPage,
      height: totalHeight,
    });
  };

  if (elementRef) {
    let { children } = elementRef;
    if (children?.length) {
      let totalHeight = 0;
      let contentPerPage = "";
      let isHandleBigElement = false;
      for (let index = 0; index < children.length; index++) {
        const elementHeight =
          children.item(index)?.getBoundingClientRect()?.height ?? 0;

        const currentElementHeight = isComment
          ? Math.max(elementHeight, MINIMUM_COMMENT_CELL_HEIGHT)
          : elementHeight;

        if (
          currentElementHeight > spaceAvailable &&
          !isFirstComment &&
          isComment
        ) {
          spaceAvailable = maxContentHeight;
        }

        const currentElementContent =
          children.item(index)?.outerHTML ??
          children.item(index)?.textContent ??
          "";

        if (currentElementHeight < spaceAvailable) {
          totalHeight += currentElementHeight;
          contentPerPage += currentElementContent;
          spaceAvailable -= currentElementHeight;
        } else {
          isHandleBigElement = !!(spaceAvailable > 100 || totalHeight === 0);
          if (isHandleBigElement) {
            if (totalHeight !== 0) {
              pushElement(contentPerPage, totalHeight);
            }
            const cloneNode = children
              .item(index)
              ?.cloneNode(true) as ChildNode;

            if (!isEmpty(htmlElements) && !isComment)
              spaceAvailable = pageBodyHeight - additionalHeight;

            const splitElement = dsfToBreakBigElements(cloneNode) ?? null;

            const breakElement = dsfParseToHTMLElements(
              splitElement,
              spaceAvailable,
              isComment,
              cellWidth,
            );

            htmlElements = htmlElements.concat(breakElement?.elements ?? []);
            spaceAvailable = breakElement?.remainingHeight ?? 0;
            totalHeight = 0;
            contentPerPage = "";
          } else {
            pushElement(contentPerPage, totalHeight);
            contentPerPage = currentElementContent;
            totalHeight = currentElementHeight;
            spaceAvailable -= currentElementHeight;
          }
        }

        if (index === children.length - 1 && !isHandleBigElement) {
          htmlElements?.push({
            content: contentPerPage,
            height: totalHeight,
          });
          break;
        }
      }
      // Objectives section
      if (
        !isComment &&
        htmlElements?.length > 1 &&
        htmlElements?.[0]?.content
      ) {
        const lastIndexOfCloseTag = regexLastIndexOf(
          htmlElements[0].content,
          /<\/.+>/,
          undefined,
        );
        if (htmlElements[0].content.substring(lastIndexOfCloseTag) === "</p>") {
          htmlElements[0].content = insertStringAtSpecificIndex(
            htmlElements[0].content,
            lastIndexOfCloseTag,
            " ...(Continued)",
          );
        } else {
          htmlElements[0].content += "<p>...(Continued)</p>";
        }
      }
      return htmlElements;
    }
    return htmlElements;
  }
  return htmlElements;
};

const createElementNode = (htmlString: string) => {
  let tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString ?? "";
  return tempElement;
};

const dsfParseToHTMLElements = (
  splitElement: Element | null,
  spaceAvailable: number,
  isComment?: boolean,
  cellWidth?: string,
) => {
  if (!splitElement) {
    return;
  }

  let htmlElements: PDFHtmlElement[] = [];
  const parentCloseTag = `</${splitElement.tagName.toLowerCase()}>`;
  const parentOpenTag = parentCloseTag.replace("/", "");

  let contentPerPage = "";
  let totalHeight = 0;
  Array.from(splitElement?.childNodes ?? []).forEach((child, index) => {
    const element = child as Element;
    const nextContentAppended =
      contentPerPage + `${element.outerHTML ?? element.textContent}`;

    const nextHeightAppended =
      getElementHeight(
        createElementNode(nextContentAppended),
        cellWidth,
        isComment,
      ) ?? 0;

    if (spaceAvailable > nextHeightAppended) {
      contentPerPage = nextContentAppended;
      totalHeight = nextHeightAppended;
      if (index === (splitElement?.childNodes?.length ?? 0) - 1) {
        spaceAvailable -= totalHeight;
      }
    } else {
      htmlElements.push({
        content: parentOpenTag + contentPerPage + parentCloseTag,
        height: totalHeight,
      });

      contentPerPage = element.outerHTML ?? element.textContent;
      totalHeight = getElementHeight(
        createElementNode(element.outerHTML ?? element.textContent),
        cellWidth,
        isComment,
      );
      spaceAvailable =
        PAGE_BODY_HEIGHT -
        ((isComment ? COMMENT_TABLE_HEADER_HEIGHT : 0) + TITLE_HEIGHT + 10);
    }
    if (index === (splitElement?.childNodes?.length ?? 0) - 1) {
      htmlElements.push({
        content: parentOpenTag + contentPerPage + parentCloseTag,
        height: totalHeight,
      });
    }
  });

  return {
    elements: htmlElements,
    remainingHeight: spaceAvailable,
  };
};

const dsfToBreakBigElements = (node: ChildNode | null) => {
  if (!node) {
    return;
  }

  if (node.nodeType === Node.TEXT_NODE) {
    const nodeContentArray = node.textContent?.trim()?.split(" ");
    if (nodeContentArray && nodeContentArray.length > 4) {
      const breakNodeContents = chunk(nodeContentArray, 4);
      let nodes: Node[] = [];
      breakNodeContents.forEach((content, index) => {
        const node = document.createElement("span");
        node.textContent = content.join(" ") + " ";
        nodes.push(node);
      });
      node.after(...nodes);
      node.remove();
    }
  } else {
    const element = node as Element;
    Array.from(element.childNodes).map((childNode) =>
      dsfToBreakBigElements(childNode),
    );
  }

  return node as Element;
};

const getElementHeight = (
  node: any,
  cellWidth?: string,
  isComment?: boolean,
) => {
  let clone = node.cloneNode(true);
  // hide the measured (cloned) element
  clone.style.cssText = `position:fixed;top: -9999px;opacity: 0;width:${cellWidth};padding: 5px;padding-bottom: ${
    isComment ? 5 : 0
  }px;font-size: ${isComment ? 7 : 10}px;line-height: 120%`;
  // add the clone to the DOM
  document.body.appendChild(clone);
  let result = clone.clientHeight;
  // cleanup
  clone.parentNode.removeChild(clone);

  return result;
};
// #Region: Pages handler
export const pagesHandler = (
  pages: PageTemplate[],
  component: JSX.Element,
  isAppend?: boolean,
) => {
  if (isAppend) {
    const lastPage = pages[pages?.length - 1];
    lastPage?.components?.push(<View style={{ marginTop: 15 }}></View>);
    lastPage?.components?.push(component);
  } else {
    pages?.push({
      components: [component],
    });
  }
};

// Objectives
export const objectivesPageHandler = (
  pages: PageTemplate[],
  objectives: string,
  spaceAvailable: number,
  isConcluding: boolean,
  isSuitabilityReview: boolean,
) => {
  const pageBodyHeight = isSuitabilityReview
    ? REVIEW_PAGE_BODY_HEIGHT
    : PAGE_BODY_HEIGHT;
  const isAppend = spaceAvailable > pageBodyHeight / 2 && isConcluding;
  spaceAvailable = (isAppend ? spaceAvailable : pageBodyHeight) - TITLE_HEIGHT;
  let isUpdateAvailablePage = false;

  const objectiveNodeCreated = createElementNode(objectives ?? "");
  const objectiveSeparated = processHTMLElementsByNode(
    objectiveNodeCreated,
    `${CONTENT_WIDTH}px`,
    spaceAvailable,
    false,
  );
  const objectivesArray = objectiveSeparated.elements ?? [];
  objectivesArray.forEach((objective, index) => {
    if (isAppend && !isUpdateAvailablePage) {
      pagesHandler(
        pages,
        <PDFObjectives
          key={index}
          content={objective?.content}
          isContinue={index > 0}
          isConcluding={isConcluding}
        />,
        true,
      );
      isUpdateAvailablePage = true;
    } else {
      pagesHandler(
        pages,
        <PDFObjectives
          key={index}
          content={objective?.content}
          isContinue={index > 0}
          isConcluding={isConcluding}
        />,
        false,
      );
    }
    if (index === objectivesArray?.length - 1) {
      if (objectivesArray?.length > 1)
        spaceAvailable = pageBodyHeight - TITLE_HEIGHT;
      spaceAvailable -= objective?.height ?? 0;
    }
  });

  return spaceAvailable - MARGIN_DEFAULT;
};

export function calculateAvailableRowCount(
  rowsData: PDFAnalysisSectionDTO[],
  remainingHeight: number,
  analysisType: "Feature" | "Business",
): {
  rowCount: number;
  totalRowHeight: number;
} {
  const maxChars = 48;
  const singleRowHeight = 20;
  const doubleRowHeight = 28;
  const footerHeight = 15;

  let isIncludedFooterHeight = false;

  let totalRowHeight = 0;
  let rowCount = 0;

  for (const row of rowsData) {
    const rowHeight =
      row?.name?.length! > maxChars ? doubleRowHeight : singleRowHeight;

    if (!isIncludedFooterHeight) {
      const hasFooter = row.analysisData?.some(
        (item) => item.fieldTypeId === FieldTypeId.TextLong,
      );

      if (hasFooter) {
        totalRowHeight += footerHeight;
        isIncludedFooterHeight = true;
      }
    }

    totalRowHeight += rowHeight;

    if (totalRowHeight <= remainingHeight) {
      rowCount++;
    } else {
      break;
    }
  }

  return { rowCount, totalRowHeight };
}

// Feature Analysis
export const processSectionData = (
  spaceAvailable: number,
  sections: PDFAnalysisSectionDTO[],
  isTableExpand: boolean,
  isBusinessSection?: boolean,
  isDetailMode?: boolean,
) => {
  const overscoreHeight = isBusinessSection || isDetailMode ? 30 : 20;

  const additionalHeight =
    TITLE_HEIGHT +
    (TABLE_ROW_HEADER_LINE + 5) +
    (!isTableExpand ? overscoreHeight : 0);

  const remainingHeight = spaceAvailable - additionalHeight;
  const { rowCount, totalRowHeight } = calculateAvailableRowCount(
    sections,
    remainingHeight,
    "Feature",
  );

  let occupyHeight = 0;
  let hasContinue = false;
  let dataSeparated: PDFAnalysisSectionDTO[] = [];

  if (rowCount > 0) {
    if (sections.length > rowCount) {
      dataSeparated = sections.splice(0, rowCount);
      occupyHeight = spaceAvailable;
      hasContinue = true;
    } else if (sections.length <= rowCount) {
      dataSeparated = sections;
      occupyHeight = totalRowHeight + additionalHeight;
      hasContinue = false;
    }
  } else {
    hasContinue = true;
  }

  return {
    name: "Feature",
    occupyHeight: occupyHeight,
    data: dataSeparated,
    hasContinue: hasContinue,
  };
};

export const featureAnalysisPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  featureAnalysis: PDFFeatureAnalysis,
  showSubSection?: boolean,
  isDetailMode?: boolean,
  showAnalysisScore?: boolean,
) => {
  const pageBodyHeight = PAGE_BODY_HEIGHT;
  const isAppend =
    spaceAvailable > pageBodyHeight / 2 && spaceAvailable !== pageBodyHeight;

  spaceAvailable = isAppend ? spaceAvailable : pageBodyHeight;

  const featureAnalysisComp = (
    currSections: PDFAnalysisSectionDTO[],
    isTableExpand: boolean,
  ) => {
    return (
      <PDFFeatureAnalysisSection
        showSubSection={showSubSection}
        isTableExpand={isTableExpand}
        featureAnalysis={
          {
            ...featureAnalysis,
            sections: currSections,
          } as PDFFeatureAnalysis
        }
        isDetailMode={isDetailMode}
        showAnalysisScore={showAnalysisScore}
      />
    );
  };

  const sections = cloneDeep(featureAnalysis?.sections);

  let sectionProcessed = processSectionData(
    spaceAvailable,
    sections,
    false,
    false,
    isDetailMode,
  );

  pagesHandler(
    pages,
    featureAnalysisComp(sectionProcessed.data, false),
    isAppend,
  );

  while (sectionProcessed.hasContinue === true && !isEmpty(sections)) {
    spaceAvailable = pageBodyHeight;
    sectionProcessed = processSectionData(
      spaceAvailable,
      sections,
      true,
      false,
      isDetailMode,
    );
    pagesHandler(
      pages,
      featureAnalysisComp(sectionProcessed.data, true),
      false,
    );
  }

  return spaceAvailable - sectionProcessed.occupyHeight - MARGIN_DEFAULT;
};

// Business Analysis
export const businessAnalysisPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  businessAnalysis: PDFBusinessAnalysis,
) => {
  const pageBodyHeight = PAGE_BODY_HEIGHT;
  const isAppend =
    spaceAvailable > pageBodyHeight / 2 && spaceAvailable !== pageBodyHeight;
  spaceAvailable = isAppend ? spaceAvailable : pageBodyHeight;

  const businessAnalysisComp = (
    currSections: PDFAnalysisSectionDTO[],
    isTableExpand: boolean,
  ) => {
    return (
      <PDFBusinessAnalysisSection
        isTableExpand={isTableExpand}
        businessMetricAnalysis={
          {
            ...businessAnalysis,
            sections: currSections,
          } as PDFBusinessAnalysis
        }
      />
    );
  };

  const sections = cloneDeep(businessAnalysis?.sections);

  let sectionSeparated = processSectionData(
    spaceAvailable,
    sections ?? [],
    false,
    true,
  );

  pagesHandler(
    pages,
    businessAnalysisComp(sectionSeparated?.data, false),
    isAppend,
  );

  while (sectionSeparated.hasContinue === true && !isEmpty(sections)) {
    spaceAvailable = PAGE_BODY_HEIGHT;
    sectionSeparated = processSectionData(
      spaceAvailable,
      sections ?? [],
      true,
      true,
    );
    pagesHandler(
      pages,
      businessAnalysisComp(sectionSeparated?.data, true),
      false,
    );
  }

  return spaceAvailable - sectionSeparated.occupyHeight - MARGIN_DEFAULT;
};

// Portfolio details for fee estimates
export const portfolioDetailPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  feePortfolioDetails: PDFFeePortfolioDetail,
  isShowHoldingNumber?: boolean,
) => {
  const pageBodyHeight = PAGE_BODY_HEIGHT;

  const { occupyHeight } = processPortfolioDetailsData(
    feePortfolioDetails,
    isShowHoldingNumber,
  );

  const isAppend =
    spaceAvailable > pageBodyHeight / 2 &&
    spaceAvailable > occupyHeight &&
    spaceAvailable !== pageBodyHeight;

  const portfolioDetailsComp = (
    <PDFFeePortfolioDetailsSection
      data={feePortfolioDetails}
      isShowHoldingsNumber={isShowHoldingNumber}
    />
  );

  pagesHandler(pages, portfolioDetailsComp, isAppend);

  spaceAvailable = (isAppend ? spaceAvailable : pageBodyHeight) - occupyHeight;

  return spaceAvailable - MARGIN_DEFAULT;
};

export const processPortfolioDetailsData = (
  feePortfolioDetailData: PDFFeePortfolioDetail,
  isShowHoldingNumber?: boolean,
) => {
  const LIMIT_CHARACTER = 95;
  const additionalHeight = SUB_TITLE_HEIGHT;
  let heightPortfolioDetails = additionalHeight;

  const getFeePortfolioHeight = (feePortfolioStr: string) => {
    if (feePortfolioStr.length < LIMIT_CHARACTER) return 30;
    return 50;
  };

  const getStringFamilyMemberAccounts = (data: FamilyMemberAccountDTO[]) => {
    return chain(data)
      ?.map(
        (data) =>
          `${replace(
            data.variableName ? data.variableName : "",
            "_",
            " ",
          )}: ${displayCurrencyNumber(data.balance)}`,
      )
      ?.join(", ")
      ?.value();
  };

  const { transactionsOutside, transactionsWithin, investments } =
    getPortfolioDetails(feePortfolioDetailData);

  feePortfolioDetailData?.familyMembers?.forEach((familyMember) => {
    heightPortfolioDetails += getFeePortfolioHeight(
      getStringFamilyMemberAccounts(familyMember.familyMemberAccounts),
    );
  });

  if (feePortfolioDetailData?.totalPortfolioValue) {
    heightPortfolioDetails += getFeePortfolioHeight(
      feePortfolioDetailData?.totalPortfolioValue.toString(),
    );
  }

  if (isShowHoldingNumber && feePortfolioDetailData?.totalDifferentInvestments)
    heightPortfolioDetails += getFeePortfolioHeight(
      feePortfolioDetailData?.totalDifferentInvestments.toString(),
    );

  if (investments?.length) {
    heightPortfolioDetails += getFeePortfolioHeight(investments.join(", "));
  }

  if (transactionsOutside?.length) {
    heightPortfolioDetails += getFeePortfolioHeight(
      transactionsOutside.join(", "),
    );
  }

  if (transactionsWithin?.length) {
    heightPortfolioDetails += getFeePortfolioHeight(
      transactionsWithin.join(", "),
    );
  }

  return {
    name: "Portfolio details",
    occupyHeight: heightPortfolioDetails,
    dataSeparated: feePortfolioDetailData,
    hasContinue: false,
  };
};

// Fee estimates
const processFeeEstimatesData = (
  spaceAvailable: number,
  data: FeeSubProductDTO[],
  heatmapMode?: FeeHeatmapMode,
  showFeeAnalysisText?: boolean,
  showFeeEstimatesText?: boolean,
) => {
  const ROW_HEIGHT = 35;
  const FOOTER_HEIGHT = 40;
  const LABEL_LINE_DETAIL_HEIGHT = 25;
  const LABEL_LINE_PROJECTION_HEIGHT = 10;
  const MARGIN_HEIGHT = 25;

  let titleHeight = 17;
  if (showFeeAnalysisText) titleHeight += 25;
  if (showFeeEstimatesText) titleHeight += 17;

  const additionalHeight =
    MARGIN_HEIGHT +
    FOOTER_HEIGHT +
    (heatmapMode === FeeHeatmapMode.Projection
      ? LABEL_LINE_PROJECTION_HEIGHT
      : LABEL_LINE_DETAIL_HEIGHT) *
      2 +
    titleHeight;

  const remainingHeight = spaceAvailable - additionalHeight;
  const numberRowAvailable = Math.floor(remainingHeight / ROW_HEIGHT);

  let occupyHeight = 0;
  let hasContinue = false;
  let dataSeparated: FeeSubProductDTO[] = [];

  if (numberRowAvailable > 0) {
    if (data.length > numberRowAvailable) {
      dataSeparated = data.splice(0, numberRowAvailable);
      occupyHeight = spaceAvailable;
      hasContinue = true;
    } else if (data.length === numberRowAvailable) {
      dataSeparated = data;
      occupyHeight = spaceAvailable;
      hasContinue = false;
    } else if (data.length < numberRowAvailable) {
      dataSeparated = data;
      occupyHeight = additionalHeight + data.length * ROW_HEIGHT;
      hasContinue = false;
    }
  } else {
    if (numberRowAvailable === 0 && data.length === 0) {
      hasContinue = false;
      occupyHeight = additionalHeight;
    } else {
      hasContinue = true;
    }
  }

  return {
    name: "Fee estimates",
    occupyHeight: occupyHeight,
    dataSeparated: dataSeparated,
    hasContinue: hasContinue,
  };
};

const fittedPreviousSection = (
  pages: PageTemplate[],
  spaceAvailable: number,
  feeAnalysis: PDFFeeAnalysis,
  heatmapMode: FeeHeatmapMode,
) => {
  const {
    totalSubProductHidden,
    totalPortfolioValue,
    feesDisplayStyle,
    showWarningCaptions,
  } = feeAnalysis;
  let subProductData = cloneDeep(feeAnalysis.subProducts);
  let data = processFeeEstimatesData(
    spaceAvailable,
    subProductData ?? [],
    heatmapMode,
  );

  const showFeeEstimatesText = heatmapMode !== FeeHeatmapMode.Projection;

  if (data.hasContinue === false && data.occupyHeight) {
    let feeEstimatesComp = (
      <PDFFeeAnalysisSection
        totalSubProductHidden={totalSubProductHidden}
        subProducts={data.dataSeparated ?? []}
        totalPortfolioValue={totalPortfolioValue}
        mode={feesDisplayStyle as any}
        heatmapMode={heatmapMode}
        showFeeEstimatesText={showFeeEstimatesText}
        showWarningCaptions={showWarningCaptions}
      />
    );
    pagesHandler(pages, feeEstimatesComp, true);

    return data.occupyHeight;
  }
  return -1;
};

const feeEstimatesModeHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  feeAnalysis: PDFFeeAnalysis,
  heatmapMode: FeeHeatmapMode,
  allowShowFeeEstimatesText: boolean,
) => {
  const pageBodyHeight = PAGE_BODY_HEIGHT;
  const {
    totalSubProductHidden,
    totalPortfolioValue,
    feesDisplayStyle,
    showWarningCaptions,
  } = feeAnalysis;

  const fittedHeight = fittedPreviousSection(
    pages,
    spaceAvailable,
    feeAnalysis,
    heatmapMode,
  );

  if (fittedHeight !== -1) return spaceAvailable - fittedHeight;

  const feeEstimatesComp = (
    subProducts: FeeSubProductDTO[],
    showFeeAnalysisText?: boolean,
    showFeeEstimatesText?: boolean,
  ) => {
    return (
      <PDFFeeAnalysisSection
        totalSubProductHidden={totalSubProductHidden}
        subProducts={subProducts ?? []}
        totalPortfolioValue={totalPortfolioValue}
        mode={feesDisplayStyle as any}
        heatmapMode={heatmapMode}
        showWarningCaptions={showWarningCaptions}
        showFeeAnalysisText={showFeeAnalysisText}
        showFeeEstimatesText={showFeeEstimatesText}
      />
    );
  };

  const isAppend = spaceAvailable > pageBodyHeight / 2;

  spaceAvailable = isAppend ? spaceAvailable : pageBodyHeight;

  let subProductData = cloneDeep(feeAnalysis.subProducts);
  const showFeeAnalysisText = !isAppend; //Fee analysis
  const showFeeEstimatesText = allowShowFeeEstimatesText; //Fee estimate

  let data = processFeeEstimatesData(
    spaceAvailable,
    subProductData ?? [],
    heatmapMode,
    showFeeAnalysisText,
    showFeeEstimatesText,
  );

  pagesHandler(
    pages,
    feeEstimatesComp(
      data?.dataSeparated,
      showFeeAnalysisText,
      showFeeEstimatesText,
    ),
    isAppend,
  );

  while (data.hasContinue === true) {
    spaceAvailable = pageBodyHeight;

    data = processFeeEstimatesData(
      spaceAvailable,
      subProductData ?? [],
      heatmapMode,
      true,
      false,
    );
    pagesHandler(
      pages,
      feeEstimatesComp(data?.dataSeparated, true, false),
      false,
    );
  }

  return spaceAvailable - data.occupyHeight - MARGIN_DEFAULT;
};

export const feesAnalysisPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  feeAnalysis: PDFFeeAnalysis,
) => {
  spaceAvailable = feeEstimatesModeHandler(
    pages,
    spaceAvailable,
    feeAnalysis,
    FeeHeatmapMode.Detail,
    true,
  );

  spaceAvailable = feeEstimatesModeHandler(
    pages,
    spaceAvailable,
    feeAnalysis,
    FeeHeatmapMode.Projection,
    false,
  );

  return spaceAvailable;
};

// Platform specific comments
// TODO: Review & refactor to share between PA and SR
export const commentPagesHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  comments: PDFAnalysisComment[],
  currentSteps: AnalysisStep[],
) => {
  const pageBodyHeight = PAGE_BODY_HEIGHT;
  const isAppend = spaceAvailable > pageBodyHeight / 2;
  const additionalHeight = COMMENT_TABLE_HEADER_HEIGHT + TITLE_HEIGHT + 10;

  spaceAvailable = isAppend ? spaceAvailable : pageBodyHeight;
  spaceAvailable -= additionalHeight;

  const cellWidth = `${(0.884 / currentSteps?.length) * TABLE_WIDTH}px`;
  const commentsExtracted = extractReviewComments(
    comments,
    spaceAvailable,
    cellWidth,
  );
  let commentsSeparated: PDFAnalysisComment[] = [];

  let isAppended = false;
  let isContinued = false;

  const setPageValues = () => {
    if (isAppend && !isAppended) {
      pagesHandler(
        pages,
        <PDFAnalysisCommentSection
          comments={commentsSeparated ?? []}
          isContinued={isContinued}
          currentSteps={currentSteps}
        />,
        true,
      );
      isAppended = true;
    } else {
      pagesHandler(
        pages,
        <PDFAnalysisCommentSection
          comments={commentsSeparated ?? []}
          isContinued={isContinued}
          currentSteps={currentSteps}
        />,
        false,
      );
    }
  };

  commentsExtracted?.forEach((comment, index) => {
    if (comment.height < spaceAvailable) {
      commentsSeparated.push(comment);
      spaceAvailable -= comment.height;
    } else {
      setPageValues();
      commentsSeparated = [comment];
      isContinued = true;
      spaceAvailable = pageBodyHeight - additionalHeight - comment.height;
    }
    if (index === commentsExtracted.length - 1) {
      setPageValues();
    }
  });

  return spaceAvailable - MARGIN_DEFAULT;
};

const extractReviewComments = (
  originalComments: PDFAnalysisComment[],
  spaceAvailable: number,
  cellWidth: string,
) => {
  let result: PDFAnalysisComment[] = [];
  originalComments?.forEach((item, index) => {
    let comment = { ...item };

    const featureNodeCreated = createElementNode(item.feature ?? "");
    const businessMetricNodeCreated = createElementNode(
      item.businessMetric ?? "",
    );
    const feeNodeCreated = createElementNode(item.fee ?? "");

    const isFirstComment = index === 0;
    const isComment = true;

    if (isNoAnyComment(item)) {
      result.push({
        ...comment,
        feature: null,
        businessMetric: null,
        fee: null,
        summary: null,
        height: MINIMUM_COMMENT_CELL_HEIGHT,
      });
      spaceAvailable -= MINIMUM_COMMENT_CELL_HEIGHT;
    } else {
      const commentSeparated: ReviewCommentSeparated = {
        featureDetail: processHTMLElementsByNode(
          featureNodeCreated,
          cellWidth,
          spaceAvailable,
          isComment,
          isFirstComment,
        ),
        businessMetricDetail: processHTMLElementsByNode(
          businessMetricNodeCreated,
          cellWidth,
          spaceAvailable,
          isComment,
          isFirstComment,
        ),
        feeDetail: processHTMLElementsByNode(
          feeNodeCreated,
          cellWidth,
          spaceAvailable,
          isComment,
          isFirstComment,
        ),
      };

      const separated = separateComments(
        commentSeparated,
        comment,
        spaceAvailable,
      );

      spaceAvailable = separated.remainingHeight;
      result = result.concat(separated.commentsExtracted);
    }
  });
  return result;
};

const separateComments = (
  commentSeparated: ReviewCommentSeparated,
  commentExtracted: PDFAnalysisComment,
  spaceAvailable: number,
) => {
  let commentsExtracted: PDFAnalysisComment[] = [];
  const { featureDetail, businessMetricDetail, feeDetail } = commentSeparated;

  const biggestSeparated = Math.max(
    featureDetail?.elements?.length ?? 0,
    businessMetricDetail?.elements?.length ?? 0,
    feeDetail?.elements?.length ?? 0,
  );

  if (biggestSeparated >= 1) {
    for (let index = 0; index < biggestSeparated; index++) {
      const height = getHighestCommentHeight(commentSeparated, index);
      commentsExtracted.push({
        ...commentExtracted,
        feature: featureDetail?.elements?.length
          ? featureDetail?.elements[index]?.content ?? ""
          : null,
        businessMetric: businessMetricDetail?.elements?.length
          ? businessMetricDetail.elements[index]?.content ?? ""
          : null,
        fee: feeDetail?.elements?.length
          ? feeDetail.elements[index]?.content ?? ""
          : null,
        productName:
          biggestSeparated > 1 && index > 0
            ? `${commentExtracted.productName} (Continued)`
            : commentExtracted.productName,
        height,
        isContinued: biggestSeparated > 1 && index > 0,
      });

      if (spaceAvailable > height) {
        spaceAvailable -= height;
      } else {
        spaceAvailable =
          PAGE_BODY_HEIGHT -
          (TITLE_HEIGHT + COMMENT_TABLE_HEADER_HEIGHT) -
          height;
      }
    }
  }

  return {
    commentsExtracted,
    remainingHeight: spaceAvailable,
  };
};

const isNoAnyComment = (comment: PDFAnalysisComment): boolean => {
  return !comment.feature && !comment.businessMetric && !comment.fee;
};
const getHighestCommentHeight = (
  comment: ReviewCommentSeparated,
  index: number,
) => {
  return Math.max(
    comment?.featureDetail?.elements?.[index]?.height ?? 0,
    comment?.businessMetricDetail?.elements?.[index]?.height ?? 0,
    comment?.feeDetail?.elements?.[index]?.height ?? 0,
  );
};

// Platform selected
export const platformsIncludedPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  selectedProducts: PDFSummarySelectedProductDTO[],
) => {
  const pageBodyHeight = PAGE_BODY_HEIGHT;

  const platformDisplayed = join(map(selectedProducts, "name"), ", ");
  const platformsSelectedCompHeight =
    TITLE_HEIGHT + Math.ceil(platformDisplayed?.split(" ")?.length / 16) * 23;

  let isAppend = spaceAvailable >= platformsSelectedCompHeight;
  if (!isAppend) spaceAvailable = pageBodyHeight;
  spaceAvailable -= platformsSelectedCompHeight;

  const platformSelectedSectionComp = (
    <PDFPlatformIncluded platformDisplayed={platformDisplayed} />
  );

  pagesHandler(pages, platformSelectedSectionComp, isAppend);

  return spaceAvailable - MARGIN_DEFAULT;
};

// Feature Included
export const featureIncludedPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  originalFeatureIncluded?: PDFFeatureIncluded[],
) => {
  const pageBodyHeight = PAGE_BODY_HEIGHT;
  const isAppend = spaceAvailable > pageBodyHeight / 2;

  spaceAvailable = isAppend ? spaceAvailable : pageBodyHeight;

  let featureIncludedComp = (
    dataSeparated: PDFFeatureIncluded[],
    isContinued?: boolean,
  ) => (
    <View>
      <PDFTitleSection
        title={`Features included in the analysis${
          isContinued ? " (continued)" : ""
        }`}
      />
      <PDFFeaturesIncludeSection featureInclude={dataSeparated} />
    </View>
  );

  let featureIncluded = cloneDeep(originalFeatureIncluded);
  let dataProcessed = processFeatureIncluded(
    spaceAvailable,
    featureIncluded ?? [],
  );

  pagesHandler(
    pages,
    featureIncludedComp(dataProcessed?.dataSeparated),
    isAppend,
  );

  while (dataProcessed.hasContinue === true) {
    spaceAvailable = pageBodyHeight;
    dataProcessed = processFeatureIncluded(
      spaceAvailable,
      featureIncluded ?? [],
    );
    pagesHandler(
      pages,
      featureIncludedComp(dataProcessed?.dataSeparated, true),
      false,
    );
  }

  return spaceAvailable - dataProcessed.occupyHeight - MARGIN_DEFAULT;
};

export const processFeatureIncluded = (
  spaceAvailable: number,
  data: PDFFeatureIncluded[],
) => {
  const TABLE_HEADER_HEIGHT = 25;
  const additionalHeight = TITLE_HEIGHT + TABLE_HEADER_HEIGHT;

  spaceAvailable -= additionalHeight;

  let remainingHeight = spaceAvailable;

  let occupyHeight = 0;
  let hasContinue = false;
  let dataSeparated: PDFFeatureIncluded[] = [];

  for (const feature of data) {
    let heightRow = getFeatureIncludedHeight(feature);

    if (remainingHeight - heightRow > 0) {
      dataSeparated?.push(feature);
      remainingHeight -= heightRow;
      occupyHeight = spaceAvailable - remainingHeight;
    } else {
      occupyHeight = spaceAvailable;
      break;
    }
  }

  if (dataSeparated?.length < data?.length) {
    data?.splice(0, dataSeparated.length);
    hasContinue = true;
  } else {
    hasContinue = false;
  }

  return {
    name: "Features included",
    occupyHeight: occupyHeight,
    dataSeparated: dataSeparated,
    hasContinue: hasContinue,
  };
};

export const getFeatureIncludedHeight = (row: AnalysisFeatureIncludeDTO) => {
  const MAX_LENGTH = 80;
  const INIT_HEIGHT = 30;
  const HEIGHT_ROW = 10;
  const COMMA_AND_BLANK_TEXT = 2;

  let height = INIT_HEIGHT;
  let textLengthPrevious = 0;

  row.features.forEach((feature) => {
    textLengthPrevious += feature.featureName.length + COMMA_AND_BLANK_TEXT;

    if (textLengthPrevious >= MAX_LENGTH) {
      textLengthPrevious = feature.featureName.length;
      height += HEIGHT_ROW;
    }
  });

  return height;
};

// Disclaimers
export const disclaimerPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  disclaimers: DisclaimerDTO[],
  showFeeStep?: boolean,
) => {
  const isAppend = !showFeeStep && spaceAvailable >= 135;

  pagesHandler(
    pages,
    <PDFDisclaimer hasFeeStep={showFeeStep} disclaimers={disclaimers} />,
    isAppend,
  );
};

export const feesAnalysisGraphPageHandler = (
  pages: PageTemplate[],
  spaceAvailable: number,
  feeSubProducts: FeeSubProductDTO[],
  feesDisplayStyle: FeesDisplayStyle,
  fullFeeSubProducts?: FeeSubProductDTO[],
  colors?: { [key in string]: string },
) => {
  let fullGraphLegendHeight = 0;

  chunk(feeSubProducts, 5).forEach((items) => {
    let maxLength = 0;
    items.forEach((item) => {
      maxLength = item.name.length > maxLength ? item.name.length : maxLength;
    });
    const MAX_TEXT = 24;
    const countExtraLine = Math.floor(maxLength / MAX_TEXT);

    fullGraphLegendHeight +=
      HEIGHT_LEGEND_CHART + countExtraLine * (HEIGHT_LEGEND_CHART / 2);
  });

  const occupyHeight = MIN_HEIGHT_CHART + fullGraphLegendHeight;
  const isAppend = spaceAvailable >= occupyHeight;

  const getColorsLineChart = (): Record<string, string> => {
    const groups = groupBy(fullFeeSubProducts, (obj) => obj.productId);
    const colors: Record<string, string> = {};
    Object.keys(groups).forEach((key, index) => {
      const group = groups[key];
      const colorRoot = DEFAULT_COLORS[index];
      if (group.length === 1) {
        colors[group[0].id] = hslToHex(
          colorRoot.hue,
          colorRoot.saturation,
          colorRoot.lightness,
        );
      } else {
        const tickColor = (MAX_LIGHTNESS - colorRoot.lightness) / group.length;
        const minLightness =
          tickColor >= DEFAULT_COLOR_TICK
            ? colorRoot.lightness
            : Math.max(
                MAX_LIGHTNESS - DEFAULT_COLOR_TICK * group.length,
                MIN_LIGHTNESS,
              );

        let maxLightness = Math.min(
          colorRoot.lightness + DEFAULT_COLOR_TICK * 1.5,
          MAX_LIGHTNESS,
        );

        if (group.length > 5) {
          maxLightness = Math.min(
            colorRoot.lightness + DEFAULT_COLOR_TICK * (group.length - 1),
            MAX_LIGHTNESS,
          );
        }

        group.forEach((item, itemIndex) => {
          const lightness = getHSLLightness(
            1,
            group.length,
            itemIndex + 1,
            minLightness,
            maxLightness,
          );
          colors[item.id] = hslToHex(
            colorRoot.hue,
            colorRoot.saturation,
            lightness,
          );
        });
      }
    });
    return colors;
  };

  const lineChartColors = colors || getColorsLineChart();

  pagesHandler(
    pages,
    <View
      style={{
        textAlign: "center",
        justifyContent: "center",
      }}
    >
      <PDFFeeLineChart
        feeSubProducts={feeSubProducts}
        chartMode={feesDisplayStyle}
        colors={lineChartColors}
        isAppend={isAppend}
        title="Fee analysis (continued)"
      />
      {chunk(feeSubProducts, 5).map((items) => (
        <View
          style={{
            textAlign: "center",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <PDFFeeLineChartSubProduct
            subProducts={items}
            colors={lineChartColors}
          />
        </View>
      ))}
    </View>,
    isAppend,
  );

  if (isAppend) {
    return spaceAvailable - occupyHeight;
  }

  return PAGE_BODY_HEIGHT - occupyHeight - TITLE_HEIGHT - SUB_TITLE_HEIGHT;
};
