import { useEffect, useMemo, useRef } from "react";
import { $getRoot, $insertNodes, $setSelection } from "lexical";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import sanitizeHtml from "sanitize-html";
import { debounce, isNil } from "lodash";
import { useSharedState } from "../../context/TextEditorSharedStateContext";

interface ValueControlPluginProps {
  initialValue?: string;
  onValueChange: (html: string) => void;
  debounceMs?: number;
  debounceAbortMethod?: "cancel" | "flush";
}

const ValueControlPlugin = ({
  initialValue,
  onValueChange,
  debounceMs = 300,
  debounceAbortMethod = "cancel",
}: ValueControlPluginProps) => {
  const isSettingValueRef = useRef(false);
  const [editor] = useLexicalComposerContext();
  const { dialogState, setDialogState } = useSharedState();
  const lastExternalValueRef = useRef<string>(""); // Value from parent
  const lastEditorValueRef = useRef<string>(""); // Value sent to parent

  const sanitizeHtmlOption: sanitizeHtml.IOptions = {
    allowedTags: ["p", "strong", "i", "u", "ul", "li", "ol", "br"],
    allowedAttributes: {},
    disallowedTagsMode: "discard",
    transformTags: {
      span: sanitizeHtml.simpleTransform("", {}),
    },
  };

  const handleOnChange = () => {
    const html = editor.getEditorState().read(() => {
      const plainText = $getRoot().getTextContent();
      return plainText === "" ? "" : $generateHtmlFromNodes(editor, null);
    });
    const cleanHtml = sanitizeHtml(html, sanitizeHtmlOption);
    onValueChange(cleanHtml);
    lastEditorValueRef.current = cleanHtml;
  };

  const debounceOnValueChange = useMemo(() => {
    return debounce(() => handleOnChange(), debounceMs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, onValueChange, debounceMs]);

  const isEditorOutOfSync = (): boolean => {
    const currentHtml = editor
      .getEditorState()
      .read(() => $generateHtmlFromNodes(editor, null));
    const cleanCurrentHtml = sanitizeHtml(currentHtml, sanitizeHtmlOption);
    return !isNil(initialValue) && initialValue !== cleanCurrentHtml;
  };

  // Set value from initialValue
  useEffect(() => {
    if (isNil(initialValue) || initialValue === lastExternalValueRef.current)
      return;

    if (isEditorOutOfSync() && initialValue !== lastEditorValueRef.current) {
      isSettingValueRef.current = true;
      editor.update(() => {
        const root = $getRoot();
        root.clear();

        const cleanHtml = sanitizeHtml(initialValue, sanitizeHtmlOption);
        const parser = new DOMParser();
        const dom = parser.parseFromString(cleanHtml, "text/html");
        const nodes = $generateNodesFromDOM(editor, dom);

        $insertNodes(nodes);
        $setSelection(null); // Clear autofocus
      }, {});
      isSettingValueRef.current = false;
    }
    lastExternalValueRef.current = initialValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, initialValue]);

  // Get value from editor
  useEffect(() => {
    const subscribeValueChange = editor.registerUpdateListener(() => {
      debounceOnValueChange();
    });

    const handleBlur = () => {
      debounceOnValueChange.flush();
    };
    editor.getRootElement()?.addEventListener("blur", handleBlur);

    return () => {
      subscribeValueChange();
      editor.getRootElement()?.removeEventListener("blur", handleBlur);
      debounceAbortMethod === "cancel"
        ? debounceOnValueChange.cancel()
        : debounceOnValueChange.flush();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, onValueChange, debounceMs]);

  // Set value from dialog editor
  useEffect(() => {
    if (dialogState?.isUpdate) {
      editor.update(() => {
        const root = $getRoot();
        root.clear();

        const parser = new DOMParser();
        const dom = parser.parseFromString(
          dialogState.textContent,
          "text/html",
        );
        const nodes = $generateNodesFromDOM(editor, dom);
        $insertNodes(nodes);
        setDialogState({ ...dialogState, isUpdate: false });
        root.selectEnd();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogState]);

  return null;
};

export default ValueControlPlugin;
