import {
  SHDotGroup,
  SHDotGroupProps,
  SHStack,
  SHTypography,
} from "@components/design-systems";
import { HighlightType } from "@models/configuration";
import {
  InvestmentProductConfigurationModel,
  InvestmentProductConfigurationModelSection,
  InvestmentProductModelsDTO,
} from "@models/product/investment-product/entities/model";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import { useTheme } from "@mui/material";
import { getModelPath } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import { hexToRGBA } from "@utils";
import { isNil } from "lodash";
import { useEffect, useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface SubMenuItemProps {
  models: InvestmentProductConfigurationModel;
  sectionIndex: number;
  subSectionIndex: number;
  selectedIndex: [number, number];
  onChange?: (index: [number, number]) => void;
  dotGroupProps?: Omit<SHDotGroupProps, "disabled">;
}
export const SubMenuItem = ({
  models,
  sectionIndex,
  subSectionIndex,
  selectedIndex,
  onChange = (index) => {},
  dotGroupProps,
}: SubMenuItemProps) => {
  // Hooks
  const { control, setValue, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();

  const modelPath = useMemo(
    () => getModelPath([sectionIndex, subSectionIndex]),
    [sectionIndex, subSectionIndex],
  );

  const sections = useWatch({
    control,
    name: modelPath.sections,
  }) as InvestmentProductConfigurationModelSection[] | undefined;

  const isAnySectionModified = sections?.some((section) => section.isModified);
  const isAnySectionEdited = sections?.some(
    (section) => section.highlightType === HighlightType.Edited,
  );
  const isAnySectionMissingData = sections?.some(
    (section) => section.dataStatus === InvestmentDataStatus.MissingData,
  );
  // Handlers
  const handleChangeHighlightType = () => {
    const modelId = getValues(modelPath.id);
    const isNewModel = isNil(modelId);
    if (isNewModel) return;
    if (isAnySectionEdited) {
      setValue(modelPath.highlightType, HighlightType.Edited);
    } else {
      setValue(modelPath.highlightType, null);
    }
  };
  const handleChangeIsModified = () => {
    if (isAnySectionModified) {
      setValue(modelPath.isModified, true);
    } else {
      setValue(modelPath.isModified, false);
    }
  };
  const handleChangeDataStatus = () => {
    if (isAnySectionMissingData) {
      setValue(modelPath.dataStatus, InvestmentDataStatus.MissingData);
    } else {
      setValue(modelPath.dataStatus, InvestmentDataStatus.Filled);
    }
  };
  // Effects
  useEffect(() => {
    handleChangeHighlightType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnySectionEdited]);
  useEffect(() => {
    handleChangeIsModified();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnySectionModified]);
  useEffect(() => {
    handleChangeDataStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnySectionMissingData]);

  const [dataStatus, highlightType, name] = useWatch({
    control,
    name: [modelPath.dataStatus, modelPath.highlightType, modelPath.name],
  }) as [
    InvestmentDataStatus | undefined,
    HighlightType | undefined,
    string | undefined,
  ];

  const { palette } = useTheme();
  const selectedSubSection =
    selectedIndex[0] === sectionIndex && selectedIndex[1] === subSectionIndex;
  return (
    <SHStack
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      flex={1}
      key={models.id}
      sx={{
        padding: "20px 10px",
        cursor: "pointer",
        bgcolor: selectedSubSection
          ? hexToRGBA(palette.primary.light, 0.1)
          : "transparent",
        "&:hover": {
          bgcolor: selectedSubSection
            ? hexToRGBA(palette.primary.light, 0.15)
            : hexToRGBA(palette.common.white, 0.5),
        },
      }}
      onClick={() => {
        onChange([sectionIndex, subSectionIndex]);
      }}
    >
      <SHStack direction="row" spacing={0.5} alignItems="start">
        <SHTypography
          sx={{
            wordBreak: "break-all",
          }}
          variant={selectedSubSection ? "subtitle2" : "body1"}
        >
          {name ?? models.name}
        </SHTypography>

        <SHDotGroup
          blueDot={
            dotGroupProps?.blueDot &&
            dataStatus === InvestmentDataStatus.MissingData
          }
          orangeDot={
            dotGroupProps?.orangeDot && highlightType === HighlightType.Edited
          }
          paddingTop={"5px"}
        />
      </SHStack>
      {/*TODO: implement this */}
      {/* <SHStack alignItems="center" flexDirection={"row"}>
        <RemoveButton
          onClick={(e) => {
            e.stopPropagation();
            console.log("Remove sub section button clicked!!", subSectionIndex);
          }}
        />
        <BurgerButton
          onClick={(e) => {
            e.stopPropagation();
            console.log("Burger button clicked!!", subSectionIndex);
          }}
        />
      </SHStack> */}
    </SHStack>
  );
};
