import { SHSelect } from "@components/design-systems";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import {
  InvestmentProductModelsDTO,
} from "@models/product/investment-product/entities/model";
import { SxProps, Theme } from "@mui/material";
import { getFeatureProductDataPaths } from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import React, { useEffect, useMemo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useMount } from "react-use";
interface TableDropDownProps {
  selectedIndex: [number, number];
  featureIndex: number;
  sectionIndex: number;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onClear: (value: string | undefined) => void;
  feature: InvestmentProductConfigurationFeature;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}
export const TableDropDown = ({
  selectedIndex,
  sectionIndex,
  featureIndex,
  onChange,
  onClear,
  feature,
  sx,
  disabled = false,
}: TableDropDownProps) => {
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const defaultValue = useMemo(
    () => feature.lovData?.find((opt) => opt.isDefault)?.id,
    [feature.lovData],
  );
  const featureProductDataPath = useMemo(
    () => getFeatureProductDataPaths(selectedIndex, sectionIndex, featureIndex),
    [selectedIndex, sectionIndex, featureIndex],
  );
  const { control, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  const watchedValue = useWatch({
    control,
    name: featureProductDataPath.value,
  });
  const productDataValue = useMemo(
    () => getValues(featureProductDataPath.value),
    // eslint-disable-next-line
    [watchedValue, selectedIndex],
  ) as string | null;
  const [currentValue, setCurrentValue] = React.useState<string | null>(
    productDataValue,
  );
  useMount(() => {
    setIsMounted(true);
  });
  useEffect(() => {
    if (isMounted) setCurrentValue(productDataValue);
    // eslint-disable-next-line
  }, [selectedIndex, isMounted]);
  return (
    <SHSelect
      sx={sx}
      allowClear={!defaultValue && !!currentValue}
      onClear={(value) => {
        onClear(value);
        setCurrentValue(
          getValues(featureProductDataPath.value) as string | null,
        );
      }}
      disabled={disabled}
      dataItemKey="id"
      displayField="name"
      value={currentValue || defaultValue}
      placeholder="Select"
      data={feature.lovData || []}
      onChange={(e) => {
        setCurrentValue(e.target.value);
        onChange(e);
      }}
    />
  );
};
