import { useEffect } from "react";
import {
  $getSelection,
  $isRangeSelection,
  KEY_DOWN_COMMAND,
  COMMAND_PRIORITY_LOW,
  TextNode,
} from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
} from "@lexical/list";

const AutoListPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const unregister = editor.registerCommand(
      KEY_DOWN_COMMAND,
      (event) => {
        if (event.key !== " ") return false;

        editor.update(() => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) return false;

          const anchorNode = selection.anchor.getNode();
          if (!(anchorNode instanceof TextNode)) return false;

          const textContent = anchorNode.getTextContent();
          const bulletMatch = /^\s*-/.test(textContent);
          const orderedMatch = /^\s*\d+\./.test(textContent);

          if (!bulletMatch && !orderedMatch) return false;
          event.preventDefault();

          let content = "";

          if (bulletMatch) {
            const match = textContent.match(/^\s*-\s*(.*)$/);
            content = match ? match[1] : "";
          } else if (orderedMatch) {
            const match = textContent.match(/^\s*\d+\.\s*(.*)$/);
            content = match ? match[1] : "";
          }

          const command = bulletMatch
            ? INSERT_UNORDERED_LIST_COMMAND
            : INSERT_ORDERED_LIST_COMMAND;
          editor.dispatchCommand(command, undefined);

          const newSelection = $getSelection();
          if ($isRangeSelection(newSelection)) {
            const node = newSelection.anchor.getNode();
            if (node instanceof TextNode) {
              node.setTextContent(content || "");
              newSelection.setTextNodeRange(
                node,
                content.length,
                node,
                content.length,
              );
            }
          }

          return true;
        });
        return false;
      },
      COMMAND_PRIORITY_LOW,
    );

    return () => unregister();
  }, [editor]);

  return null;
};

export default AutoListPlugin;
