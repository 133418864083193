import {
  createInvestmentProductConfigurationModelThunk,
  createInvestmentProductThunk,
  investmentProductPublishDraftThunk,
  investmentProductRecallSubmissionThunk,
  investmentProductRevertToDraftThunk,
  investmentProductSubmitForReviewThunk,
  loadInvestmentGroupsThunk,
  loadInvestmentProductBannerInfoThunk,
  loadInvestmentProductBrandingDataThunk,
  loadInvestmentProductESGDataThunk,
  loadInvestmentProductManagerThunk,
  loadInvestmentProductModelsThunk,
  loadInvestmentProductSeriesThunk,
  updateActiveInvestmentGroup,
  updateInvestmentProductBannerInfo,
  updateInvestmentProductEsgThunk,
  updateInvestmentProductManagerThunk,
  updateInvestmentProductModelsThunk,
  updateInvestmentProductSeriesThunk,
} from "@redux/slices/product/investment-product";

import {
  ProductBannerType,
  TopNotificationItem,
  TopNotificationType,
} from "@components/top-notifications/model";
import { LocalStorage } from "@constants";
import { DateFormat, TimeFormat } from "@constants/format";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { useProduct } from "@hooks/useProduct";
import { useUser } from "@hooks/useUser";
import { HighlightType } from "@models/configuration";
import { APIResponse } from "@models/core";
import { InvestmentProductBannerInfoDTO } from "@models/product/investment-product/entities/bannerInfo";
import { InvestmentProductEsgDTO } from "@models/product/investment-product/entities/esg";
import { InvestmentProductConfigurationFeature } from "@models/product/investment-product/entities/investmentProduct";
import { InvestmentConfigurationGroup } from "@models/product/investment-product/entities/investmentProductTabs";
import { InvestmentProductManagerDTO } from "@models/product/investment-product/entities/manager";
import { InvestmentProductModelsDTO } from "@models/product/investment-product/entities/model";
import { InvestmentProductSeriesDTO } from "@models/product/investment-product/entities/series";
import { InvestmentDataStatus } from "@models/product/investment-product/enums/status";
import {
  getEditESGFeatureProductDataPath,
  getEditESGGroupDataFieldPath,
  getEditESGSectionDataPath,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/esg/util";
import {
  getEditManagerFeatureDataPath,
  getEditManagerFeatureProductDataPath,
  getEditManagerGroupDataFieldPath,
  getEditManagerSectionDataPath,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/manager/util";
import {
  getFeaturePaths,
  getFeatureProductDataPaths,
  getSectionPath,
  getSeriesPath,
  initModelsFieldPath,
} from "@pages/suppliers/_id/_products/investment-product/_id/edit/components/tabs/model/utils";
import { updateProductProfileNotifications } from "@redux/slices/top-notifications";
import { useAppDispatch } from "@redux/store";
import { format } from "date-fns";
import { isEqual } from "lodash";
import { useMemo } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useFormContext,
  UseFormGetValues,
  useWatch,
} from "react-hook-form";
import { useNotification } from "./useNotification";
interface UseInputDotIndicatorProps<TFieldValues extends FieldValues> {
  getValues: UseFormGetValues<TFieldValues>;
  control: Control<TFieldValues>;
  paths: {
    highlightType: FieldPath<TFieldValues>;
    dataStatus: FieldPath<TFieldValues>;
  };
}

interface UseInputValueComparatorProps<
  TFieldValues extends FieldValues,
  TCompare = any,
> {
  getValues: UseFormGetValues<TFieldValues>;
  control: Control<TFieldValues>;
  inputPath: FieldPath<TFieldValues>;
  compareWith: TCompare;
}

export const useInvestmentProduct = () => {
  const dispatch = useAppDispatch();
  const { notify } = useNotification();
  const { getLocalStorageItem, setLocalStorageItem } = useLocalStorage();
  const { user } = useUser();
  const { isShowProductBanner } = useProduct(); // TODO: check this when do the reset banners

  const investmentProductBannerItemKey = `${LocalStorage.investmentProductBannerInfo}+${user?.auth0Id}`;

  const handleApiResponse = <T>({
    response,
    showSuccessMessage = false,
  }: {
    response: APIResponse<T>;
    showSuccessMessage?: boolean;
  }): T | undefined => {
    if (!response.isSuccess || !response.data) {
      notify(response.message, {
        variant: "error",
        close: true,
      });

      return;
    }

    showSuccessMessage &&
      notify(response.message, {
        variant: "success",
        close: true,
      });

    return response.data;
  };

  const loadInvestmentProductManager = async (
    investmentProductId: string,
    mode: "view" | "edit",
    supplierId?: string,
  ) => {
    supplierId = isEqual(investmentProductId, "new") ? supplierId : undefined;
    const response = await dispatch(
      loadInvestmentProductManagerThunk({
        investmentProductId,
        mode,
        supplierId,
      }),
    ).unwrap();

    return handleApiResponse({ response });
  };

  const loadInvestmentProductSeries = async (
    investmentProductId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadInvestmentProductSeriesThunk({ investmentProductId, mode }),
    ).unwrap();

    return handleApiResponse({ response });
  };

  const loadInvestmentProductModels = async (
    investmentProductId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadInvestmentProductModelsThunk({ investmentProductId, mode }),
    ).unwrap();

    return handleApiResponse({ response });
  };

  const loadInvestmentProductEsg = async (
    investmentProductId: string,
    mode: "view" | "edit",
  ) => {
    const response = await dispatch(
      loadInvestmentProductESGDataThunk({ investmentProductId, mode }),
    ).unwrap();

    return handleApiResponse({ response });
  };

  const loadInvestmentProductBrandingInfo = async (productId: string) => {
    const response = await dispatch(
      loadInvestmentProductBrandingDataThunk(productId),
    ).unwrap();

    return handleApiResponse({ response });
  };

  const loadInvestmentProductBannerInfo = async (productId: string) => {
    const response = await dispatch(
      loadInvestmentProductBannerInfoThunk(productId),
    ).unwrap();
    if (response.isSuccess) {
      if (response.data) {
        const bannerInfo = response.data;
        dispatch(updateInvestmentProductBannerInfo(bannerInfo));
        const notifications = bannerInfo?.map(
          (item: InvestmentProductBannerInfoDTO) => {
            let notification = {} as TopNotificationItem;
            const lastModifiedDate = item.lastModifiedDate ?? new Date();

            switch (item.status) {
              case "Draft":
                notification = {
                  ...notification,
                  title:
                    "Draft in progress. Please follow orange dots to find the changes.",
                  productBannerType: ProductBannerType.Draft,
                  message: `Last updated by ${
                    item.lastUpdatedUserName
                  } at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}.`,
                  showCloseButton: true,
                  productId,
                  lastModifiedDate: lastModifiedDate.valueOf(),
                };
                break;
              case "Missing data":
                notification = {
                  ...notification,
                  title:
                    "One or more fields are missing data. Please follow the blue dots.",
                  productBannerType: ProductBannerType.MissingData,
                  message: `We added or modified fields at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}. Please follow the blue dots to complete the missing fields.`,
                  showCloseButton: true,
                  productId,
                  lastModifiedDate: lastModifiedDate.valueOf(),
                };
                break;
              case "Pending approval":
                notification = {
                  ...notification,
                  title: "This investment product profile is frozen.",
                  productBannerType: ProductBannerType.Pending,
                  message: `The SuitabilityHub team is now reviewing the changes that were submitted at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}. Please contact us if you have any questions.`,
                  type: TopNotificationType.Warning,
                };
                break;
              case "Approved":
                notification = {
                  ...notification,
                  title: "Latest submission was approved.",
                  productBannerType: ProductBannerType.Approved,
                  message: `Profile approved at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}.`,
                  type: TopNotificationType.Success,
                  showCloseButton: true,
                  productId,
                  lastModifiedDate: lastModifiedDate.valueOf(),
                };
                break;
              case "Rejected":
                notification = {
                  ...notification,
                  title:
                    "Latest submission was not accepted and not published.",
                  productBannerType: ProductBannerType.Rejected,
                  message: `Profile reverted back to draft at ${format(
                    lastModifiedDate,
                    `${TimeFormat} 'on' ${DateFormat}`,
                  )}.`,
                  type: TopNotificationType.Error,
                  showCloseButton: true,
                  productId,
                  lastModifiedDate: lastModifiedDate.valueOf(),
                };
                break;
            }
            return notification;
          },
        );

        const investmentProductBannerInfo = getLocalStorageItem(
          investmentProductBannerItemKey,
        );

        if (investmentProductBannerInfo) {
          if (!Object.keys(investmentProductBannerInfo).includes(productId)) {
            setLocalStorageItem(investmentProductBannerItemKey, {
              ...investmentProductBannerInfo!,
              [productId]: notifications
                .filter((notification) =>
                  Object.keys(notification).includes("productId"),
                )
                .map((notification) => {
                  return {
                    ...notification,
                    show: true,
                  };
                }),
            });
          } else {
            const newNotifications = notifications.filter((notification) =>
              Object.keys(notification).includes("productId"),
            );
            if (newNotifications.length > 0) {
              setLocalStorageItem(investmentProductBannerItemKey, {
                ...investmentProductBannerInfo!,
                [productId]: newNotifications.map((notification) => {
                  return {
                    ...notification,
                    show: isShowProductBanner(
                      newNotifications,
                      notification,
                      investmentProductBannerInfo[productId],
                      notification.productBannerType,
                    ),
                  };
                }),
              });
            }
          }
        } else {
          setLocalStorageItem(investmentProductBannerItemKey, {
            [productId]: notifications
              .filter((notification) =>
                Object.keys(notification).includes("productId"),
              )
              .map((notification) => ({
                ...notification,
                show: true,
              })),
          });
        }

        dispatch(updateProductProfileNotifications(notifications));
        return;
      }
    } else {
      notify(response.message, {
        variant: "error",
        close: true,
      });
    }
  };

  const loadInvestmentProductGroups = async (
    productId?: string,
    version?: string,
    configurationVersion?: string,
    investmentConfigurationVersion?: string,
  ) => {
    const response = await dispatch(
      loadInvestmentGroupsThunk({
        productId,
        version,
        configurationVersion,
        investmentConfigurationVersion,
      }),
    ).unwrap();

    handleApiResponse({ response });
  };

  const updateInvestmentProductModels = async (
    investmentProductId: string,
    investmentProductData: InvestmentProductModelsDTO[],
  ) => {
    const response = await dispatch(
      updateInvestmentProductModelsThunk({
        investmentProductId,
        investmentProductData,
      }),
    ).unwrap();

    return handleApiResponse({ response, showSuccessMessage: true });
  };

  const updateInvestmentProductSeries = async (
    investmentProductId: string,
    mode: "view" | "edit",
    seriesDto: InvestmentProductSeriesDTO[],
  ) => {
    const response = await dispatch(
      updateInvestmentProductSeriesThunk({
        investmentProductId,
        mode,
        seriesDto,
      }),
    ).unwrap();

    return handleApiResponse({ response, showSuccessMessage: true });
  };

  const createInvestmentProductConfigurationModel = async (
    seriesId: string,
    investmentProductId: string,
  ) => {
    const response = await dispatch(
      createInvestmentProductConfigurationModelThunk({
        seriesId,
        investmentProductId,
      }),
    ).unwrap();

    return handleApiResponse({ response });
  };

  const createNewInvestmentProductAsync = async (
    product: InvestmentProductManagerDTO,
  ) => {
    const response = await dispatch(
      createInvestmentProductThunk(product),
    ).unwrap();

    return handleApiResponse({ response, showSuccessMessage: true });
  };

  const updateExistingInvestmentProductManagerAsync = async (
    productId: string,
    managerData: InvestmentProductManagerDTO,
  ) => {
    const response = await dispatch(
      updateInvestmentProductManagerThunk({ productId, managerData }),
    ).unwrap();

    return handleApiResponse({ response, showSuccessMessage: true });
  };

  const updateExistingInvestmentProductEsgAsync = async (
    productId: string,
    esgData: InvestmentProductEsgDTO,
  ) => {
    const response = await dispatch(
      updateInvestmentProductEsgThunk({
        productId,
        esgData,
      }),
    ).unwrap();

    return handleApiResponse({ response, showSuccessMessage: true });
  };

  const submitForReviewAsync = async (investmentProductId: string) => {
    const response = await dispatch(
      investmentProductSubmitForReviewThunk(investmentProductId),
    ).unwrap();

    return handleApiResponse({ response, showSuccessMessage: true });
  };

  const revertDraftAsync = async (investmentProductId: string) => {
    const response = await dispatch(
      investmentProductRevertToDraftThunk(investmentProductId),
    ).unwrap();

    return handleApiResponse({ response, showSuccessMessage: true });
  };

  const approveAsync = async (investmentProductId: string) => {
    const response = await dispatch(
      investmentProductPublishDraftThunk(investmentProductId),
    ).unwrap();

    return handleApiResponse({ response, showSuccessMessage: true });
  };

  const recallSubmissionAsync = async (investmentProductId: string) => {
    const response = await dispatch(
      investmentProductRecallSubmissionThunk(investmentProductId),
    ).unwrap();

    return handleApiResponse({ response, showSuccessMessage: true });
  };

  const resetInvestmentProductBannerInfo = () => {
    dispatch(updateInvestmentProductBannerInfo([]));
    dispatch(updateProductProfileNotifications([]));
  };

  return {
    loadInvestmentProductManager,
    loadInvestmentProductSeries,
    loadInvestmentProductModels,
    loadInvestmentProductBrandingInfo,
    loadInvestmentProductEsg,
    loadInvestmentProductBannerInfo,
    loadInvestmentProductGroups,
    updateInvestmentProductModels,
    updateInvestmentProductSeries,
    createNewInvestmentProductAsync,
    updateExistingInvestmentProductManagerAsync,
    createInvestmentProductConfigurationModel,
    resetInvestmentProductBannerInfo,
    updateExistingInvestmentProductEsgAsync,
    submitForReviewAsync,
    revertDraftAsync,
    approveAsync,
    recallSubmissionAsync,
  };
};

export const useInputDotIndicator = <TFieldValues extends FieldValues>({
  getValues,
  control,
  paths,
}: UseInputDotIndicatorProps<TFieldValues>) => {
  // trigger re-rendering when registered input change purpose
  const watchedValues = useWatch({
    control,
    name: [paths.highlightType, paths.dataStatus],
  });

  const isShowOrangeDot = useMemo(() => {
    const highlightType = getValues(
      paths.highlightType,
    ) as HighlightType | null;
    return isEqual(highlightType, HighlightType.Edited);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paths.highlightType, watchedValues]);

  const isShowBlueDot = useMemo(() => {
    const dataStatus = getValues(
      paths.dataStatus,
    ) as InvestmentDataStatus | null;
    return !dataStatus || isEqual(dataStatus, InvestmentDataStatus.MissingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paths.dataStatus, watchedValues]);

  return {
    isShowOrangeDot,
    isShowBlueDot,
  };
};

/**
 * @description Compare input value from specified path with compare value ,trigger re-rendering when registered input change, isolate rendering in hook level
 * @example
 * interface MyFormValues {
 *   username: string;
 * }
 *
 * const { control, getValues } = useForm<MyFormValues>({
 *   defaultValues: { username: 'David' },
 * });
 *
 * const compareWith = 'John';
 *
 * const isUsernameEqual = useInputValueComparator<MyFormValues, string>({
 *   getValues,
 *   control,
 *   inputPath: 'username',
 *   compareWith,
 * })
 * --> "isUsernameEqual return false"
 */
export const useInputValueComparator = <
  TFieldValues extends FieldValues,
  TCompare = any,
>({
  getValues,
  control,
  inputPath,
  compareWith,
}: UseInputValueComparatorProps<TFieldValues, TCompare>) => {
  // trigger re-rendering when registered input change purpose
  const watchedValues = useWatch({
    control,
    name: inputPath,
  });

  const isValueEqual = useMemo(() => {
    const inputValue = getValues(inputPath) as TCompare;
    return isEqual(inputValue, compareWith);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputPath, watchedValues, getValues, compareWith]);

  return isValueEqual;
};

export const useUpdateSectionData = (
  selectedIndex: [number, number],
  sectionIndex: number,
) => {
  // React Hook Form path
  const sectionPath = useMemo(
    () => getSectionPath(selectedIndex, sectionIndex),
    [selectedIndex, sectionIndex],
  );
  // React Hook Form context
  const { setValue, getValues } = useFormContext<{
    investmentProductModels: InvestmentProductModelsDTO[];
  }>();
  // Function
  /**
   * @description Update section data (only used for models page) , It's must call when any data related
   * to feature has changed to update dot group and isModified
   * @returns handleUpdateSectionData
   */
  const handleUpdateSectionData = () => {
    const features = getValues(sectionPath.features) as
      | InvestmentProductConfigurationFeature[]
      | undefined;

    if (!features) return;

    const isAnyFeatureModified = features.some(
      (feature: InvestmentProductConfigurationFeature) =>
        feature.isModified === true,
    );
    const isAnyFeatureEdited = features.some(
      (feature: InvestmentProductConfigurationFeature) =>
        feature.highlightType === HighlightType.Edited,
    );
    const isAnyFeatureMissingData = features.some(
      (feature: InvestmentProductConfigurationFeature) =>
        feature.dataStatus === InvestmentDataStatus.MissingData,
    );

    setValue(
      sectionPath.dataStatus,
      isAnyFeatureMissingData
        ? InvestmentDataStatus.MissingData
        : InvestmentDataStatus.Filled,
    );
    setValue(sectionPath.isModified, isAnyFeatureModified);
    setValue(
      sectionPath.highlightType,
      isAnyFeatureEdited ? HighlightType.Edited : null,
    );
  };

  return handleUpdateSectionData;
};

export const useUpdateModelsTab = () => {
  const dispatch = useAppDispatch();

  const handleUpdateModelsTab = ({
    isAnySectionModified,
    isAnySectionEdited,
    isAnySectionMissingData,
  }: {
    isAnySectionModified: boolean;
    isAnySectionEdited: boolean;
    isAnySectionMissingData: boolean;
  }) => {
    const configurationGroup: Partial<InvestmentConfigurationGroup> = {
      isModified: isAnySectionModified,
      dataStatus: isAnySectionMissingData
        ? InvestmentDataStatus.MissingData
        : InvestmentDataStatus.Filled,
      highlightType: isAnySectionEdited
        ? HighlightType.Edited
        : HighlightType.Added,
    };
    try {
      dispatch(updateActiveInvestmentGroup(configurationGroup));
    } catch (error) {
      console.error("Failed to update investment group:", error);
    }
  };

  return handleUpdateModelsTab;
};

export const useEditManagerPaths = (
  sectionIndex: number,
  featureIndex: number,
) => {
  const groupDataPath = useMemo(() => getEditManagerGroupDataFieldPath(), []);

  const sectionDataPath = useMemo(
    () => getEditManagerSectionDataPath(sectionIndex),
    [sectionIndex],
  );

  const featureProductDataPath = useMemo(
    () => getEditManagerFeatureProductDataPath(sectionIndex, featureIndex),
    [sectionIndex, featureIndex],
  );
  const featureDataPath = useMemo(
    () => getEditManagerFeatureDataPath(sectionIndex, featureIndex),
    [sectionIndex, featureIndex],
  );

  return {
    groupDataPath,
    sectionDataPath,
    featureProductDataPath,
    featureDataPath,
  };
};

export const useEditESGPaths = (sectionIndex: number, featureIndex: number) => {
  const groupDataPath = useMemo(() => getEditESGGroupDataFieldPath(), []);

  const sectionDataPath = useMemo(
    () => getEditESGSectionDataPath(sectionIndex),
    [sectionIndex],
  );

  const featureProductDataPath = useMemo(
    () => getEditESGFeatureProductDataPath(sectionIndex, featureIndex),
    [sectionIndex, featureIndex],
  );

  return {
    groupDataPath,
    sectionDataPath,
    featureProductDataPath,
  };
};

export const useEditModelPaths = (
  selectedIndex: [number, number],
  sectionIndex: number,
  featureIndex: number,
) => {
  const modelFieldPath = useMemo(
    () => initModelsFieldPath(selectedIndex),
    [selectedIndex],
  );

  const sectionPath = useMemo(
    () => getSectionPath(selectedIndex, sectionIndex),
    [selectedIndex, sectionIndex],
  );
  const featureProductDataPath = useMemo(
    () => getFeatureProductDataPaths(selectedIndex, sectionIndex, featureIndex),
    [selectedIndex, sectionIndex, featureIndex],
  );
  const seriesPath = useMemo(
    () => getSeriesPath(selectedIndex[0]),
    [selectedIndex],
  );
  const featurePath = useMemo(
    () => getFeaturePaths(selectedIndex, sectionIndex, featureIndex),
    [selectedIndex, sectionIndex, featureIndex],
  );

  return {
    featurePath,
    sectionPath,
    seriesPath,
    featureProductDataPath,
    modelFieldPath,
  };
};
