import { SHIconLoading } from "@components/design-systems";
import { DownloadSVG } from "@components/svgs";
import { DELAY_TIME } from "@constants";
import {
  IconButton,
  SxProps,
  Theme,
  Tooltip,
  tooltipClasses,
  useTheme,
} from "@mui/material";
import shadows from "@mui/material/styles/shadows";
import React from "react";
export interface DownloadButtonProps {
  width?: string;
  height?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  disabled?: boolean;
  tooltipTitle?: string;
  borderColor?: string;
  backgroundColor?: string;
  iconColor?: string;
  sx?: SxProps<Theme>;
  variant?: "primary" | "outlined";
  [key: string]: any;
}
export const DownloadButton = ({
  width = "30px",
  height = "30px",
  isLoading = false,
  disabled = false,
  tooltipTitle = "Download",
  backgroundColor,
  borderColor,
  iconColor,
  sx,
  variant = "primary",
  ...others
}: DownloadButtonProps) => {
  const { palette } = useTheme();
  const isPrimary = variant === "primary";

  return (
    <Tooltip
      title={tooltipTitle}
      enterDelay={DELAY_TIME}
      enterNextDelay={DELAY_TIME}
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
              {
                marginTop: "6px",
              },
          },
        },
      }}
    >
      <IconButton
        {...others}
        disableRipple
        disabled={disabled}
        sx={{
          ...sx,
          width,
          height,
          p: 0,
          backgroundColor: isPrimary
            ? backgroundColor ?? palette.primary.main
            : backgroundColor ?? palette.common.white,
          borderColor: isPrimary
            ? borderColor
            : borderColor ?? palette.common.black,
          borderStyle: isPrimary ? "none" : "solid",
          borderWidth: isPrimary ? 0 : "1px",
          boxShadow: shadows[1],
          "&:hover": {
            backgroundColor: isPrimary ? palette.primary.dark : undefined,
            borderColor: isPrimary ? undefined : palette.primary[800],
            boxShadow: "none",
            "&>svg>path": {
              fill: isPrimary ? palette.common.white : undefined,
            },
          },
          "&:active": {
            backgroundColor: isPrimary
              ? palette.primary.main
              : palette.primary.dark,
            borderColor: isPrimary ? palette.error.dark : palette.common.black,
            boxShadow: `inset 0 4px 4px ${palette.primary[900]}`,
            "&>svg>path": {
              fill: palette.common.white,
            },
          },
          "&:disabled": {
            border: `1px solid ${palette.text.disabled}`,
            backgroundColor: palette.common.white,
            "&>svg>path": {
              fill: palette.text.disabled,
            },
          },
        }}
      >
        {isLoading ? (
          <SHIconLoading size={15} />
        ) : (
          <DownloadSVG
            color={
              iconColor ??
              (isPrimary ? palette.common.white : palette.common.black)
            }
          />
        )}
      </IconButton>
    </Tooltip>
  );
};
